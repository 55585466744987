import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { Page, UserLink, Entity } from '../model/model';
import moment from 'moment';

class UserLinkApi {
    list = async (page: number, size: number, searchText?: string): Promise<Page<UserLink>> => {
        const response: AxiosResponse<Page<UserLink>> = await apiAxios.get<Page<UserLink>>('/user-links', {
            params: { page, size, searchText },
        });
        return response.data;
    };

    listCurrentEntities = async (): Promise<Entity[]> => {
        const response: AxiosResponse<Entity[]> = await apiAxios.get<Entity[]>('/user-links/current/entities', {});
        return response.data;
    };

    get = async (id: number): Promise<UserLink> => {
        const response: AxiosResponse<UserLink> = await apiAxios.get<UserLink>(`/user-links/${id}`, this.requestConfig);
        return response.data;
    };

    create = async (userLink: UserLink): Promise<UserLink> => {
        const response: AxiosResponse<UserLink> = await apiAxios.post<UserLink>(
            '/user-links',
            userLink,
            this.requestConfig,
        );
        return response.data;
    };

    update = async (userLink: UserLink): Promise<UserLink> => {
        const response: AxiosResponse<UserLink> = await apiAxios.put<UserLink>(
            `/user-links/${userLink.id}`,
            userLink,
            this.requestConfig,
        );
        return response.data;
    };

    delete = async (userLink: UserLink): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/user-links/${userLink.id}`);
        return response.data;
    };

    private transformResponse = (userLink: UserLink): UserLink | undefined => {
        let transformResponse: UserLink | undefined;
        if (userLink) {
            transformResponse = Object.assign({}, userLink, {
                sent: moment(userLink.sent),
            });
        }

        return transformResponse;
    };

    private requestConfig: AxiosRequestConfig = {
        transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(this.transformResponse),
    };
}

const userLinkApi: UserLinkApi = new UserLinkApi();
export default userLinkApi;
