import React, { Component } from 'react';
import styles from './PersonsPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { Person, Status, Page, CustomType, ScreenSizeProps } from '../../model/model';
import personApi from '../../api/PersonApi';
import { Button, message, List, Row, Col } from 'antd';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { PaginationConfig } from 'antd/lib/table';
import CustomContext from '../../service/CustomContext';
import dateService from '../../service/DateService';
import errorService from '../../service/ErrorService';
import Search from 'antd/lib/input/Search';
import { Link } from 'react-router-dom';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import settingsService from '../../service/SettingsService';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import FormattedMessageComponent from '../Shared/FormattedMessageComponent';
import i18nService from '../../service/I18nService';

class PersonsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            persons: [],
            pagination: { current: 1, pageSize: 20, hideOnSinglePage: true, onChange: this.handleTableChange },
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
    };

    list = async (page: number = 1, pageSize: number = 20, searchText?: string) => {
        const personsPage: Page<Person> = await personApi.list(page - 1, pageSize, searchText);
        const persons: Person[] = personsPage.content;
        const pagination: PaginationConfig = Object.assign({}, this.state.pagination, {
            current: page,
            total: personsPage.totalElements,
            showTotal: (total: number) => <FormattedMessage id="persons.total" values={{ total }} />,
        });
        this.setState({ persons, pagination, searchText });
    };

    handleSearch = async (searchText: string) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(1, 20, searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    handleTableChange = async (page: number, pageSize?: number) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(page, pageSize);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    /** COMPONENTS */

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessageComponent id="navigation.persons" />
                    </h1>
                    <p>
                        <FormattedMessageComponent id="navigation.persons.desc" />
                    </p>
                </div>
            </div>
        );
    };

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.search}>{this.renderSearch()}</div>
                <div className={styles.add}>{this.renderCreateButton()}</div>
            </div>
        );
    };

    renderSearch = (): JSX.Element => {
        const placeholder: string = this.props.intl.formatMessage({
            id: i18nService.getCustomMessage('persons.search.placeholder', this.context.user),
        });
        return <Search placeholder={placeholder} onSearch={this.handleSearch} size="large" className={styles.search} />;
    };

    renderCreateButton = (): JSX.Element => {
        return (
            <Link to="/persons/new/profile" data-test="add">
                <Button type="primary" size="large" icon="plus">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                </Button>
            </Link>
        );
    };

    renderPersonDesc = (person: Person): JSX.Element => {
        const genderCustomType: CustomType = settingsService.getGenderByValue(person.gender as string);
        return <>{genderCustomType.label}</>;
    };

    renderPersonsList = (): JSX.Element => {
        const format: string = dateService.getDateFormat(this.context.user);

        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.persons}
                locale={{
                    emptyText: (
                        <div data-test="empty">
                            <p>
                                <FormattedMessageComponent id="persons.empty" />
                            </p>
                            <Link to="/persons/new/profile">
                                <Button size="large" type="primary" ghost>
                                    <FormattedMessageComponent id="persons.empty.add" />
                                </Button>
                            </Link>
                        </div>
                    ),
                }}
                pagination={this.state.pagination}
                renderItem={person => (
                    <Link to={`/persons/${person.id}`}>
                        <List.Item data-test="item">
                            <List.Item.Meta
                                avatar={<AvatarComponent name={person.firstName} />}
                                title={person.fullName}
                                description={this.renderPersonDesc(person)}
                            />
                            <div className={styles.description}>
                                <>{person.birthdate && person.birthdate.format(format)}</>
                            </div>
                        </List.Item>
                    </Link>
                )}
                data-test="list"
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex">
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderToolbar()}
                            {this.renderPersonsList()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(PersonsPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {}

interface State {
    persons: Person[];
    searchText?: string;
    pagination: PaginationConfig;
    status?: Status;
}
