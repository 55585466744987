import React, { Component } from 'react';
import { Person, Status } from '../../../../model/model';
import { Layout } from 'antd';
import moment, { Moment } from 'moment';
import CustomContext from '../../../../service/CustomContext';
import errorService from '../../../../service/ErrorService';
import CalendarComponent from '../../../Shared/CalendarComponent/CalendarComponent';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class DiaryMonthPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.date !== prevProps.match.params.date) {
            try {
                this.setState({ status: 'loading' });
                this.init();
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ status: undefined });
            }
        }
    }

    /** METHODS **/

    init = () => {
        const date = this.props.match.params.date ? moment.utc(this.props.match.params.date) : moment().utc();
        this.setState({ date });
    };

    /** COMPONENTS **/

    renderCalendar = (): JSX.Element => {
        return this.state.date ? <CalendarComponent date={this.state.date} person={this.props.person} /> : <></>;
    };

    render() {
        return <Layout.Content>{this.renderCalendar()}</Layout.Content>;
    }
}
export default withRouter(DiaryMonthPage);

interface Props extends RouteComponentProps {
    match: any;
    person: Person;
}

interface State {
    date?: Moment;
    status?: Status;
}
