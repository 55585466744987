import React, { Component } from 'react';
import styles from './FormattedDateTimeComponent.module.scss';
import CustomContext from '../../../service/CustomContext';
import { Moment } from 'moment';
import dateService from '../../../service/DateService';

class FormattedDateTimeComponent extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    render() {
        const dateFormat = dateService.getDateFormat(this.context.user);
        return (
            <>
                {this.props.date && this.props.date.format(dateFormat)}
                <span className={styles.time}> {this.props.time && this.props.time.format('HH:mm')}</span>
            </>
        );
    }
}
export default FormattedDateTimeComponent;

interface Props {
    dateFormat?: string;
    date?: Moment;
    time?: Moment;
}
