import React, { Component, FormEvent } from 'react';
import styles from './DocumentPage.module.scss';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import {
    Status,
    Document,
    ActionStatus,
    Person,
    DocumentFile,
    ScreenSizeProps,
    Template,
    Page,
    User,
    DocumentType,
} from '../../../../model/model';
import CustomContext from '../../../../service/CustomContext';
import {
    Layout,
    Form,
    DatePicker,
    Button,
    Input,
    Popconfirm,
    message,
    Upload,
    Icon,
    List,
    Avatar,
    Select,
    Modal,
    Row,
    Col,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import dateService from '../../../../service/DateService';
import moment from 'moment';
import errorService from '../../../../service/ErrorService';
import documentApi from '../../../../api/DocumentApi';
import { RcFile } from 'antd/lib/upload';
import documentService from '../../../../service/DocumentService';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import templateApi from '../../../../api/TemplateApi';
import TemplateComponent from './TemplateComponent/TemplateComponent';
import TextArea from 'antd/lib/input/TextArea';

class DocumentPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly maxFileSize: number = 100;

    constructor(props: Props) {
        super(props);
        this.state = {
            document: {
                files: [],
            },
            files: [],
            report: '',
            templates: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        if (this.props.match.params.id && this.props.match.params.id === 'new') {
            const medicalSpeciality = documentService.getMedicalSpecialityFromParameters();
            const document: Document = {
                personId: this.props.person.id,
                documentDate: moment.utc().startOf('day'),
                medicalSpeciality,
                files: [],
            };
            this.setState({ document });
            this.props.form.setFieldsValue({
                name: document.name,
                documentDate: document.documentDate,
                medicalSpeciality,
                remarks: document.remarks,
            });
        } else {
            const documentId: number = this.props.match.params.id;
            const document: Document = await documentApi.get(documentId);
            const readonly = document.personId !== this.props.person.id;
            const report: string = document.report || '';
            this.setState({ document, report, readonly });
            this.props.form.setFieldsValue({
                name: document.name,
                documentDate: moment.utc(document.documentDate),
                medicalSpeciality: document.medicalSpeciality,
                remarks: document.remarks,
            });
        }

        this.listTemplates();
    };

    changeDocumentType = (documentType: DocumentType) => {
        const document = Object.assign({}, this.state.document);
        document.documentType = documentType;
        this.setState({ document });

        const name = documentService.getDefaultDocumentName(document);
        this.props.form.setFieldsValue({
            name: name,
        });
    };

    listTemplates = async (): Promise<Template[]> => {
        const templatesPage: Page<Template> = await templateApi.list(0, 100);
        const templates: Template[] = templatesPage.content;
        this.setState({ templates });
        return templates;
    };

    changeTemplate = async (id?: number) => {
        const template: Template | undefined = id ? await templateApi.get(id) : undefined;
        this.setState({ template });
        if (template) {
            Modal.confirm({
                title: this.props.intl.formatMessage({ id: 'document.report.template.confirm.title' }),
                content: this.props.intl.formatMessage({ id: 'document.report.template.confirm.desc' }),
                okText: this.props.intl.formatMessage({ id: 'common.ok' }),
                cancelText: this.props.intl.formatMessage({ id: 'common.cancel' }),
                onOk: () => this.setTemplate(template),
            });
        }
    };

    showTemplate = async (templateId?: number) => {
        this.setState({
            templateId,
            templateVisible: true,
        });
    };

    hideTemplate = async (refresh?: boolean, templateId?: number) => {
        this.setState({
            templateVisible: false,
        });

        // refresh templates
        if (refresh) {
            const templates: Template[] = await this.listTemplates();
            if (templateId) {
                templates.some(t => t.id === templateId)
                    ? this.changeTemplate(templateId)
                    : this.changeTemplate(undefined);
            }
        }
    };

    setTemplate = (template: Template) => {
        const user = this.context.user as User;
        const date = this.props.form.getFieldValue('documentDate') || moment().startOf('day');
        const content: string = template.content as string;
        const report: string = documentService.formatReport(content, user, this.props.person, date);
        this.setState({ report });
    };

    save = async (values: any) => {
        let document: Document;
        if (this.state.document.id) {
            document = Object.assign({}, this.state.document, {
                name: values.name,
                documentDate: values.documentDate.format('YYYY-MM-DDT00:00:00.000') + 'Z',
                medicalSpeciality: values.medicalSpeciality,
                files: undefined,
                remarks: values.remarks,
                report: this.state.report,
            });
            document = await documentApi.update(document);
        } else {
            document = Object.assign({}, this.state.document, {
                name: values.name,
                documentDate: values.documentDate,
                medicalSpeciality: values.medicalSpeciality,
                remarks: values.remarks,
                report: this.state.report,
            });
            document = await documentApi.create(document, this.state.files);
        }

        this.setState({ document });
    };

    delete = async () => {
        await documentApi.delete(this.state.document);
        this.props.history.push(`/persons/${this.props.person.id}/documents/specialities`);
    };

    download = async () => {
        await documentApi.getReport(this.state.document);
    };

    downloadDocumentFile = async (documentFile: DocumentFile) => {
        await documentApi.getFile(documentFile);
    };

    uploadFile = (file: RcFile): void => {
        const files: RcFile[] = [...this.state.files, file];
        if (files.map(f => f.size).reduce((a, b) => a + b, 0) > this.maxFileSize * 1024 * 1024) {
            errorService.displayCustomMessage('document.files.error.size', { size: this.maxFileSize });
        } else {
            this.setState({ files });
            if (!this.props.form.getFieldValue('name')) {
                this.props.form.setFieldsValue({
                    name: file.name,
                });
            }
        }
    };

    removeFile = (fileUid: string): void => {
        const files: RcFile[] = this.state.files.filter(f => f.uid !== fileUid);
        this.setState({ files });
        this.props.form.setFieldsValue({
            file: (this.props.form.getFieldValue('files') as RcFile[]).filter(f => f.uid !== fileUid),
        });
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.save(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error, [
                    [413, 'document.files.error.size', { size: this.maxFileSize }],
                    [402, 'document.files.error.totalSize'],
                ]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDownload = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'downloading' });
            await this.download();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.downloaded' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDownloadDocumentFile = async (documentFile: DocumentFile): Promise<void> => {
        try {
            this.setState({ actionStatus: documentFile.id });
            await this.downloadDocumentFile(documentFile);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.downloaded' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleFileBeforeUpload = (uploadFile: RcFile, uploadFiles: RcFile[]): boolean | PromiseLike<void> => {
        try {
            this.uploadFile(uploadFile);
        } catch (error) {
            errorService.displayMessage();
        }
        return false;
    };

    handleFileNormalization = (event: any): any => {
        if (Array.isArray(event)) {
            return event;
        }
        return event && event.fileList;
    };

    handleFileRemove = (fileId: string): void => {
        try {
            this.removeFile(fileId);
        } catch (error) {
            errorService.displayMessage();
        }
    };

    handleReportChange = (report: string): void => {
        try {
            this.setState({ report });
        } catch (error) {
            errorService.displayMessage();
        }
    };

    handleTemplateChange = async (templateId?: number): Promise<void> => {
        try {
            await this.changeTemplate(templateId);
        } catch (error) {
            errorService.displayMessage();
        }
    };

    handleShowTemplate = (template?: Template) => {
        try {
            message.destroy();
            const templateId: number | undefined = template && template.id;
            this.showTemplate(templateId);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleHideTemplate = async (refresh?: boolean, templateId?: number) => {
        try {
            message.destroy();
            this.hideTemplate(refresh, templateId);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleDocumentTypeChange = (documentType: DocumentType) => {
        try {
            this.changeDocumentType(documentType);
        } catch (error) {
            errorService.displayMessage();
        }
    };

    /** COMPONENTS **/

    renderFiles = (): JSX.Element => {
        if (!this.state.document.id || (this.state.document.files && this.state.document.files.length > 0)) {
            return (
                <>
                    {this.renderFileUpload()}
                    {this.renderFileList()}
                </>
            );
        } else {
            return <></>;
        }
    };

    renderFileUpload = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        if (this.state.document.id) {
            return <Form.Item label={<FormattedMessage id="document.files" />} className={styles.empty} />;
        } else {
            return (
                <Form.Item
                    label={<FormattedMessage id="document.files" />}
                    extra={<FormattedMessage id="document.files.extra" values={{ size: this.maxFileSize }} />}
                >
                    {getFieldDecorator('files', {
                        valuePropName: 'fileList',
                        getValueFromEvent: this.handleFileNormalization,
                    })(
                        <Upload
                            beforeUpload={this.handleFileBeforeUpload}
                            showUploadList={false}
                            disabled={this.state.readonly}
                            data-test="upload"
                        >
                            <Button size="large" className={styles.upload} data-test="uploadButton">
                                <Icon type="upload" /> <FormattedMessage id="document.files.upload" />
                            </Button>
                        </Upload>,
                    )}
                </Form.Item>
            );
        }
    };

    renderFileAvatar = (documentFile: DocumentFile): JSX.Element => {
        const icon = documentService.getDocumentFileIcon(documentFile.contentType);
        return (
            <span onClick={() => documentFile && this.handleDownloadDocumentFile(documentFile)}>
                <Avatar icon={icon} shape="square" className={styles.avatar} />
            </span>
        );
    };

    renderFileName = (documentFile: DocumentFile): JSX.Element => {
        const documentFileName =
            this.props.isLg || this.props.isMd
                ? documentFile.name
                : documentService.getTruncatedDocumentFileName(documentFile.name!, 20);

        return <span onClick={() => this.handleDownloadDocumentFile(documentFile)}>{documentFileName}</span>;
    };

    renderFile = (documentFile: DocumentFile): JSX.Element => {
        const size = documentService.getFormattedSize(documentFile.size!);
        return (
            <List.Item
                actions={[
                    <Button
                        icon="download"
                        onClick={() => this.handleDownloadDocumentFile(documentFile)}
                        loading={this.state.actionStatus === documentFile.id}
                        disabled={!!this.state.actionStatus && this.state.actionStatus !== documentFile.id}
                    />,
                ]}
                data-test="file"
            >
                <List.Item.Meta
                    avatar={this.renderFileAvatar(documentFile)}
                    title={this.renderFileName(documentFile)}
                    description={size}
                />
            </List.Item>
        );
    };

    renderUploadedFile = (file: RcFile): JSX.Element => {
        const name: string = this.props.isLg ? file.name : documentService.getTruncatedDocumentFileName(file.name, 20);
        const size = documentService.getFormattedSize(file.size);
        return (
            <List.Item
                actions={[
                    <Button
                        icon="delete"
                        onClick={() => this.handleFileRemove(file.uid)}
                        data-test="fileDelete"
                    ></Button>,
                ]}
                data-test="file"
            >
                <List.Item.Meta
                    avatar={<Avatar icon="link" shape="square" className={styles.avatar} />}
                    title={name}
                    description={size}
                />
            </List.Item>
        );
    };

    renderFileList = (): JSX.Element => {
        if (this.state.document.id && this.state.document.files && this.state.document.files.length > 0) {
            const files: DocumentFile[] = this.state.document.files.sort((a, b) => a.name!.localeCompare(b.name!));
            return (
                <List
                    itemLayout="horizontal"
                    dataSource={files}
                    data-test="files"
                    locale={{ emptyText: <></> }}
                    renderItem={(documentFile: DocumentFile) => this.renderFile(documentFile)}
                />
            );
        } else if (this.state.files && this.state.files.length > 0) {
            return (
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.files}
                    data-test="files"
                    locale={{ emptyText: <></> }}
                    renderItem={(file: RcFile) => this.renderUploadedFile(file)}
                />
            );
        } else {
            return <></>;
        }
    };

    renderDocumentReport = (): JSX.Element | undefined => {
        const { document, readonly, report } = this.state;

        if (document.documentType === 'MANUAL' && readonly) {
            return (
                <>
                    <p className={styles.label}>
                        <FormattedMessage id="document.report" />
                    </p>
                    <ReactQuill
                        value={report}
                        readOnly={true}
                        theme="bubble"
                        className={styles.report}
                        data-test="report"
                    />
                </>
            );
        } else if (document.documentType === 'MANUAL') {
            return (
                <>
                    <p className={styles.label}>
                        <FormattedMessage id="document.report" />
                    </p>
                    {this.renderDocumentTemplates()}
                    <ReactQuill value={report} onChange={this.handleReportChange} data-test="report" />
                </>
            );
        }
    };

    renderDocumentTemplates = (): JSX.Element => {
        const templateOptions = this.state.templates.map(template => (
            <Select.Option value={template.id} key={template.id}>
                {template.name}
            </Select.Option>
        ));
        return (
            <>
                <div className={styles.templates}>
                    <Select
                        size="large"
                        value={this.state.template && this.state.template.id}
                        allowClear={true}
                        onChange={this.handleTemplateChange}
                        placeholder={<FormattedMessage id="document.report.template.placeholder" />}
                        data-test="template"
                    >
                        {templateOptions}
                    </Select>
                    <Button
                        size="large"
                        icon="edit"
                        onClick={() => this.handleShowTemplate(this.state.template)}
                        disabled={!this.state.template}
                        data-test="templateEdit"
                    />
                    <Button size="large" icon="plus" onClick={() => this.handleShowTemplate()} />
                </div>
                <TemplateComponent
                    visible={this.state.templateVisible}
                    handleClose={this.handleHideTemplate}
                    templateId={this.state.templateId}
                    readonly={this.state.readonly}
                />
            </>
        );
    };

    renderDocumentForm = (): JSX.Element => {
        const { document, readonly } = this.state;
        const { getFieldDecorator } = this.props.form;
        const format: string = dateService.getDateFormat(this.context.user);
        const medicalSpecialityOptions = this.context.settings.medicalSpecialityTypes
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(medicalSpecialityType => (
                <Select.Option value={medicalSpecialityType.value} key={medicalSpecialityType.value}>
                    {medicalSpecialityType.label}
                </Select.Option>
            ));

        return (
            <div className={styles.layout}>
                <Form onSubmit={this.handleSave}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item label={<FormattedMessage id="document.name" />}>
                                {getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: <FormattedMessage id="document.name.error.required" />,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        maxLength={200}
                                        disabled={readonly}
                                        addonBefore={this.renderDocumentTypeLabel(document.documentType!)}
                                        data-test="name"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="document.medicalSpeciality" />}>
                                {getFieldDecorator('medicalSpeciality', {})(
                                    <Select
                                        size="large"
                                        showSearch
                                        allowClear
                                        dropdownMatchSelectWidth={false}
                                        filterOption={(input, option) =>
                                            (option.props.children as string)
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={readonly}
                                        data-test="medicalSpeciality"
                                    >
                                        {medicalSpecialityOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<FormattedMessage id="document.documentDate" />}
                                extra={dateService.getPeriod(
                                    this.props.person.birthdate,
                                    this.props.form.getFieldValue('documentDate'),
                                )}
                                className={styles.date}
                            >
                                {getFieldDecorator('documentDate', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: <FormattedMessage id="document.documentDate.error.required" />,
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        size="large"
                                        format={format}
                                        allowClear={false}
                                        disabled={readonly}
                                        onFocus={(e: any) => (e.target.readOnly = true)}
                                        data-test="documentDate"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24} hidden={document.documentType === 'MANUAL'}>
                            <Form.Item label={<FormattedMessage id="document.remarks" />}>
                                {getFieldDecorator('remarks')(
                                    <TextArea maxLength={5000} rows={2} disabled={readonly} data-test="remarks" />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.renderDocumentReport()}
                    {this.renderFiles()}
                    {this.renderDocumentButtons()}
                </Form>
            </div>
        );
    };

    renderDocumentType = (): JSX.Element => {
        return (
            <div className={styles.type}>
                <h2>
                    <FormattedMessage id="document.documentType.title" />
                </h2>
                <Row gutter={[16, 16]} type="flex">
                    <Col xs={24} sm={12} md={6}>
                        <div
                            className={`${styles.card} ${styles.report}`}
                            data-test="report"
                            onClick={() => this.handleDocumentTypeChange('REPORT')}
                        >
                            <Icon type="file" />
                            <div>
                                <p>
                                    <FormattedMessage id="document.documentType.report" />
                                </p>
                                <p>
                                    <FormattedMessage id="document.documentType.report.desc" />
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div
                            className={`${styles.card} ${styles.laboratory}`}
                            data-test="laboratory"
                            onClick={() => this.handleDocumentTypeChange('LABORATORY')}
                        >
                            <Icon type="experiment" />
                            <div>
                                <p>
                                    <FormattedMessage id="document.documentType.laboratory" />
                                </p>
                                <p>
                                    <FormattedMessage id="document.documentType.laboratory.desc" />
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div
                            className={`${styles.card} ${styles.radiology}`}
                            data-test="radiology"
                            onClick={() => this.handleDocumentTypeChange('RADIOLOGY')}
                        >
                            <Icon type="alert" />
                            <div>
                                <p>
                                    <FormattedMessage id="document.documentType.radiology" />
                                </p>
                                <p>
                                    <FormattedMessage id="document.documentType.radiology.desc" />
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6}>
                        <div
                            className={`${styles.card} ${styles.manual}`}
                            data-test="manual"
                            onClick={() => this.handleDocumentTypeChange('MANUAL')}
                        >
                            <Icon type="edit" />
                            <div>
                                <p>
                                    <FormattedMessage id="document.documentType.manual" />
                                </p>
                                <p>
                                    <FormattedMessage id="document.documentType.manual.desc" />
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    renderDocumentTypeLabel = (documentType: DocumentType): JSX.Element | undefined => {
        const documentTypeName = documentService.getDocumentTypeName(documentType);
        switch (documentType) {
            case 'REPORT':
                return (
                    <>
                        <Icon type="file" /> {documentTypeName}
                    </>
                );
            case 'LABORATORY':
                return (
                    <>
                        <Icon type="experiment" /> {documentTypeName}
                    </>
                );
            case 'RADIOLOGY':
                return (
                    <>
                        <Icon type="alert" /> {documentTypeName}
                    </>
                );
            case 'MANUAL':
                return (
                    <>
                        <Icon type="edit" /> {documentTypeName}
                    </>
                );
            default:
                break;
        }
    };

    renderDocumentButtons = (): JSX.Element => {
        const { document, actionStatus, readonly } = this.state;

        return (
            <Row gutter={[24, 0]} className={styles.buttons}>
                <Col xs={9} md={8} hidden={readonly}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                        loading={actionStatus === 'saving'}
                        disabled={!!actionStatus && actionStatus !== 'saving'}
                        data-test="submit"
                    >
                        <FormattedMessage id="common.save" />
                    </Button>
                </Col>
                <Col xs={9} md={8}>
                    <Button
                        onClick={this.handleDownload}
                        size="large"
                        block
                        loading={actionStatus === 'downloading'}
                        disabled={!!actionStatus && actionStatus !== 'downloading'}
                        hidden={document.id === undefined || !document.report}
                        className="alternative"
                        data-test="download"
                    >
                        <FormattedMessage id="common.download" />
                    </Button>
                </Col>
                <Col xs={6} md={8}>
                    <Popconfirm
                        title={<FormattedMessage id="document.confirm.delete" values={{ name: document.name }} />}
                        onConfirm={this.handleDelete}
                        okText={<FormattedMessage id="common.ok" />}
                        cancelText={<FormattedMessage id="common.cancel" />}
                    >
                        <Button
                            type="link"
                            size="large"
                            loading={actionStatus === 'deleting'}
                            disabled={!!actionStatus && actionStatus !== 'deleting'}
                            hidden={document.id === undefined || readonly}
                            className={styles.delete}
                            data-test="delete"
                        >
                            <FormattedMessage id="common.delete" />
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        );
    };

    render() {
        const { document } = this.state;
        const isDocumentTypeShowed =
            this.props.match.params.id && this.props.match.params.id === 'new' && !document.documentType;
        return (
            <>
                <Layout.Content>
                    <div hidden={!isDocumentTypeShowed}>{this.renderDocumentType()}</div>
                    <div hidden={isDocumentTypeShowed}>{this.renderDocumentForm()}</div>
                </Layout.Content>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(Form.create<Props>()(DocumentPage))));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
    person: Person;
}

interface State {
    document: Document;
    files: RcFile[];
    report: string;
    templates: Template[];
    template?: Template;
    templateId?: number;
    templateVisible?: boolean;
    status?: Status;
    actionStatus?: ActionStatus | number;
    readonly?: boolean;
}
