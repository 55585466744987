import React, { Component } from 'react';
import styles from './Template.module.scss';
import { Layout } from 'antd';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import SignInPage from '../SignInPage/SignInPage';
import HomePage from '../HomePage/HomePage';
import CustomContext from '../../service/CustomContext';
import Header from '../Header/Header';
import SignUpPage from '../SignUpPage/SignUpPage';
import PrivacyPolicyPage from '../Static/PrivacyPolicyPage/PrivacyPolicyPage';
import LegalNoticePage from '../Static/LegalNoticePage/LegalNoticePage';
import CookiesPolicyPage from '../Static/CookiesPolicyPage/CookiesPolicyPage';
import Footer from '../Footer/Footer';
import ContactUsPage from '../Static/ContactUsPage/ContactUsPage';
import EmailVerificationPage from '../SignUpPage/EmailVerificationPage/EmailVerificationPage';
import PasswordResetPage from '../SignInPage/PasswordResetPage/PasswordResetPage';
import PasswordChangePage from '../SignInPage/PasswordChangePage/PasswordChangePage';
import PersonsPage from '../PersonsPage/PersonsPage';
import TermsOfUsePage from '../Static/TermsOfUsePage/TermsOfUsePage';
import UserPage from '../UserPage/UserPage';
import AlertCancellationPage from '../SignInPage/AlertCancellationPage/AlertCancellationPage';
import DashboardPage from '../DashaboardPage/DashboardPage';
import SubscriptionsPage from '../SubscriptionsPage/SubscriptionsPage';
import SubscriptionPage from '../SubscriptionsPage/SubscriptionPage/SubscriptionPage';
import InvoicesPage from '../InvoicesPage/InvoicesPage';
import TermsOfContractPage from '../Static/TermsOfContractPage/TermsOfContractPage';
import PersonPage from '../PersonPage/PersonPage';
import GrowthChartPage from '../ToolsPage/GrowthChartPage/GrowthChartPage';
import MapPage from '../Static/MapPage/MapPage';
import CommunitiesPage from '../CommunitiesPage/CommunitiesPage';
import CommunityPage from '../CommunityPage/CommunityPage';
import CoronavirusPage from '../ToolsPage/CoronavirusPage/CoronavirusPage';
import CreditsPage from '../Static/CreditsPage/CreditsPage';
import BmiCalculatorPage from '../ToolsPage/BmiCalculatorPage/BmiCalculatorPage';
import pathService from '../../service/PathService';
import NotFoundPage from '../../errors/NotFoundPage/NotFoundPage';
import BmiCalculatorChildrenPage from '../ToolsPage/BmiCalculatorChildrenPage/BmiCalculatorChildrenPage';
import AgeCalculatorPage from '../ToolsPage/AgeCalculatorPage/AgeCalculatorPage';
import ToolsPage from '../ToolsPage/ToolsPage';
import BmrCalculatorPage from '../ToolsPage/BmrCalculatorPage/BmrCalculatorPage';
import DiaryTemplatesPage from '../DiaryTemplatesPage/DiaryTemplatesPage';
import DiaryTemplatePage from '../DiaryTemplatesPage/DiaryTemplatePage/DiaryTemplatePage';
import NotificationsPage from '../NotificationsPage/NotificationsPage';
import LinkApprovalPage from '../PersonPage/LinksPage/LinkApprovalPage/LinkApprovalPage';
import ProtectedRoute from '../Shared/ProtectedRoute';
import MessagesPage from '../MessagesPage/MessagesPage';
import HomeDoctorsPage from '../HomePage/HomeDoctorsPage/HomeDoctorsPage';
import ScrollToTopComponent from '../Shared/ScrollToTopComponent';
import HomePricesPage from '../HomePage/HomePricesPage/HomePricesPage';
import UserLinksPage from '../UserLinksPage/UserLinksPage';
import UserLinkPage from '../UserLinksPage/UserLinkPage/UserLinkPage';
import UserLinkApprovalPage from '../UserLinksPage/UserLinkApprovalPage/UserLinkApprovalPage';
import HomePatientsPage from '../HomePage/HomePatientsPage/HomePatientsPage';
import HomeVideoConsultationPage from '../HomePage/HomeVideoConsultationPage/HomeVideoConsultationPage';
import CalendarPage from '../CalendarPage/CalendarPage';
import CalendarDatePage from '../CalendarPage/CalendarDatePage/CalendarDatePage';
import CalendarAppointmentPage from '../CalendarPage/CalendarAppointmentPage/CalendarAppointmentPage';
import CalendarRecordPage from '../CalendarPage/CalendarRecordPage/CalendarRecordPage';
import CalendarMedicationPage from '../CalendarPage/CalendarMedicationPage/CalendarMedicationPage';
import BankDetailsPage from '../BankDetailsPage/BankDetailsPage';
import MidparentalHeightCalculatorPage from '../ToolsPage/MidParentalHeightCalculatorPage/MidParentalHeightCalculatorPage';

class Template extends Component<RouteComponentProps> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    readonly homePaths: string[] = [
        '/',
        pathService.getPath('professionals'),
        pathService.getPath('patients'),
        pathService.getPath('video-consultation'),
    ];

    isChat = (): boolean => {
        const path: string = window.location.pathname;
        return path.includes('/chats/') && !path.endsWith('/chats/');
    };

    /** COMPONENTS **/

    renderRoutes = (): JSX.Element => {
        return (
            <>
                <ScrollToTopComponent />
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path={pathService.getPath('professionals')} component={HomeDoctorsPage} />
                    <Route exact path={pathService.getPath('patients')} component={HomePatientsPage} />
                    <Route exact path={pathService.getPath('prices')} component={HomePricesPage} />
                    <Route
                        exact
                        path={pathService.getPath('video-consultation')}
                        component={HomeVideoConsultationPage}
                    />

                    <Route exact path={pathService.getPath('communities')} component={CommunitiesPage} />
                    <Route
                        exact
                        path={`${pathService.getPath('posts')}/:condition`}
                        render={() => <CommunityPage selected="posts" />}
                    />
                    <Route
                        exact
                        path={`${pathService.getPath('posts')}/:condition/:id`}
                        render={() => <CommunityPage selected="posts" />}
                    />
                    <Route
                        exact
                        path={`${pathService.getPath('questions')}/:condition`}
                        render={() => <CommunityPage selected="questions" />}
                    />
                    <Route
                        exact
                        path={`${pathService.getPath('members')}/:condition`}
                        render={() => <CommunityPage selected="members" />}
                    />
                    <Route
                        exact
                        path={`${pathService.getPath('doctors')}/:condition`}
                        render={() => <CommunityPage selected="doctors" />}
                    />
                    <Route
                        exact
                        path={`${pathService.getPath('researchers')}/:condition`}
                        render={() => <CommunityPage selected="researchers" />}
                    />

                    <Route exact path={pathService.getPath('tools')} component={ToolsPage} />
                    <Route
                        exact
                        path={pathService.getPath('growth-chart')}
                        render={() => <GrowthChartPage type="growthChart" key="growthChart" />}
                    />
                    <Route
                        exact
                        path={pathService.getPath('bmi-tracker')}
                        render={() => <GrowthChartPage type="bmiTracker" key="bmiTracker" />}
                    />
                    <Route exact path={pathService.getPath('bmi-calculator')} component={BmiCalculatorPage} />
                    <Route
                        exact
                        path={pathService.getPath('bmi-calculator-children')}
                        component={BmiCalculatorChildrenPage}
                    />
                    <Route
                        exact
                        path={pathService.getPath('calorie-intake-calculator')}
                        component={BmrCalculatorPage}
                    />
                    <Route
                        exact
                        path={pathService.getPath('midparental-height-calculator')}
                        component={MidparentalHeightCalculatorPage}
                    />
                    <Route exact path={pathService.getPath('age-calculator')} component={AgeCalculatorPage} />
                    <Route exact path={pathService.getPath('coronavirus-test')} component={CoronavirusPage} />

                    <Route exact path={pathService.getPath('map')} component={MapPage} />
                    <Route exact path={pathService.getPath('contact-us')} component={ContactUsPage} />
                    <Route exact path={pathService.getPath('legal-notice')} component={LegalNoticePage} />
                    <Route exact path={pathService.getPath('privacy-policy')} component={PrivacyPolicyPage} />
                    <Route exact path={pathService.getPath('cookies-policy')} component={CookiesPolicyPage} />
                    <Route exact path={pathService.getPath('terms-of-use')} component={TermsOfUsePage} />
                    <Route exact path={pathService.getPath('terms-of-contract')} component={TermsOfContractPage} />
                    <Route exact path={pathService.getPath('credits')} component={CreditsPage} />

                    <Route exact path="/signup" component={SignUpPage} />
                    <Route exact path="/email-verification" component={EmailVerificationPage} />
                    <Route exact path="/password-reset" component={PasswordResetPage} />
                    <Route exact path="/password-change" component={PasswordChangePage} />
                    <Route exact path="/signin" component={SignInPage} />
                    <Route exact path="/alerts/cancellation" component={AlertCancellationPage} />

                    <Route exact path="/dashboard" component={DashboardPage} />
                    <ProtectedRoute exact path="/user" component={UserPage} />
                    <ProtectedRoute exact path="/persons" component={PersonsPage} />
                    <ProtectedRoute path="/persons/:id" component={PersonPage} />
                    <ProtectedRoute exact path="/calendar/:date?" component={CalendarPage} />
                    <ProtectedRoute exact path="/calendar/dates/:date" component={CalendarDatePage} />
                    <ProtectedRoute exact path="/calendar/appointments/:id" component={CalendarAppointmentPage} />
                    <ProtectedRoute exact path="/calendar/medications/:id" component={CalendarMedicationPage} />
                    <ProtectedRoute exact path="/calendar/records/:id" component={CalendarRecordPage} />
                    <ProtectedRoute exact path="/employees" component={UserLinksPage} />
                    <ProtectedRoute exact path="/employees/:id" component={UserLinkPage} />
                    <ProtectedRoute exact path="/employees/approvals/:id" component={UserLinkApprovalPage} />
                    <ProtectedRoute exact path="/diary-templates" component={DiaryTemplatesPage} />
                    <ProtectedRoute exact path="/diary-templates/:id" component={DiaryTemplatePage} />
                    <ProtectedRoute exact path="/messages" component={MessagesPage} />
                    <ProtectedRoute exact path="/notifications" component={NotificationsPage} />
                    <ProtectedRoute exact path="/links/approvals/:id" component={LinkApprovalPage} />
                    <ProtectedRoute exact path="/subscriptions" component={SubscriptionsPage} />
                    <ProtectedRoute exact path="/subscriptions/:id" component={SubscriptionPage} />
                    <ProtectedRoute exact path="/bank-details" component={BankDetailsPage} />
                    <ProtectedRoute exact path="/invoices" component={InvoicesPage} />

                    <Route path="" component={NotFoundPage} />
                </Switch>
            </>
        );
    };

    render() {
        const headerStyles: string = this.homePaths.includes(this.props.location.pathname) ? styles.home : '';

        return (
            <Layout className={`${styles.layout} ${headerStyles} ${this.isChat() ? styles.chat : ''}`}>
                <Layout.Header className={styles.header}>
                    <Header />
                </Layout.Header>
                <Layout className={styles.body}>{this.renderRoutes()}</Layout>
                <Layout.Footer className={styles.footer} hidden={this.isChat()}>
                    <Footer />
                </Layout.Footer>
            </Layout>
        );
    }
}

export default withRouter(Template);
