import React, { Component } from 'react';
import styles from './TermsOfContractPage.module.scss';
import { FormattedHTMLMessage } from 'react-intl';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

export default class TermsOfContractPage extends Component {
    render() {
        return (
            <>
                <HeadMetadata titleKey="footer.termsOfContract" />
                <div className={styles.layout}>
                    <FormattedHTMLMessage id="termsOfContract" />
                </div>
            </>
        );
    }
}
