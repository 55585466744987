import apiAxios from './CustomAxios';
import { PaymentMethod } from '../model/model';
import { AxiosResponse } from 'axios';

class PaymentMethodApi {
    list = async (): Promise<PaymentMethod[]> => {
        const response: AxiosResponse<PaymentMethod[]> = await apiAxios.get<PaymentMethod[]>('/payment-methods');
        return response.data;
    };

    create = async (paymentMethod: PaymentMethod): Promise<PaymentMethod> => {
        const response: AxiosResponse<PaymentMethod> = await apiAxios.post<PaymentMethod>(
            '/payment-methods',
            paymentMethod,
        );
        return response.data;
    };
}

const paymentMethodApi: PaymentMethodApi = new PaymentMethodApi();
export default paymentMethodApi;
