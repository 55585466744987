import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Template, Page } from '../model/model';

class TemplateApi {
    list = async (page: number, size: number): Promise<Page<Template>> => {
        const response: AxiosResponse<Page<Template>> = await apiAxios.get<Page<Template>>('/templates', {
            params: { page, size },
        });
        return response.data;
    };

    get = async (id: number): Promise<Template> => {
        const response: AxiosResponse<Template> = await apiAxios.get<Template>(`/templates/${id}`);
        return response.data;
    };

    create = async (template: Template): Promise<Template> => {
        const response: AxiosResponse<Template> = await apiAxios.post<Template>('/templates', template);
        return response.data;
    };

    update = async (template: Template): Promise<Template> => {
        const response: AxiosResponse<Template> = await apiAxios.put<Template>(`/templates/${template.id}`, template);
        return response.data;
    };

    delete = async (template: Template): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/templates/${template.id}`);
        return response.data;
    };
}

const templateApi: TemplateApi = new TemplateApi();
export default templateApi;
