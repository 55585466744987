import React, { Component, FormEvent } from 'react';
import styles from './UserPage.module.scss';
import { Form, Input, Button, message, Popconfirm, Select, Checkbox, Row, Col, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import CustomContext from '../../service/CustomContext';
import userApi from '../../api/UserApi';
import userLinkApi from '../../api/UserLinkApi';
import { User, Status, ActionStatus, Entity } from '../../model/model';
import authService from '../../service/AuthService';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../service/ErrorService';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import i18nService from '../../service/I18nService';

class UserPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            user: {},
            entities: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const user = await userApi.getCurrentUser();
        const entities = await userLinkApi.listCurrentEntities();
        this.setState({ user, entities });

        this.props.form.setFieldsValue({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            entityId: user.entity && user.entity.id,
            userType: user.userType,
            profession: user.profession,
            medicalSpecialities: user.medicalSpecialities,
            phone: user.phone,
            address: user.address && user.address.address,
            city: user.address && user.address.city,
            state: user.address && user.address.state,
            country: user.address && user.address.country,
            postalCode: user.address && user.address.postalCode,
            measurementSystem: user.measurementSystem,
            dateFormat: user.dateFormat,
            alerts: user.alerts,
        });
    };

    save = async (values: any) => {
        // save user
        const entity = { id: values.entityId };
        let user: User = Object.assign({}, this.state.user, {
            firstName: values.firstName,
            lastName: values.lastName,
            username: values.username,
            entity: entity,
            userType: values.userType,
            profession: values.profession,
            medicalSpecialities: values.medicalSpecialities,
            phone: values.phone,
            address: {
                address: values.address,
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.postalCode,
            },
            measurementSystem: values.measurementSystem,
            dateFormat: values.dateFormat,
            alerts: values.alerts,
            admin: undefined,
            associate: undefined,
        });
        user = await userApi.update(user);

        // refresh user
        this.setState({ user });
        this.context.updateUser && this.context.updateUser(user);
    };

    delete = async () => {
        await userApi.delete(this.state.user);
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.save(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'user.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7, () =>
                authService.signOut('signout'),
            );
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleUserTypeChange = (userType: string): void => {
        if (userType === 'PATIENT') {
            this.props.form.setFieldsValue({
                profession: undefined,
                medicalSpecialities: undefined,
            });
        }
    };

    handleProfessionChange = (profession: string): void => {
        if (profession !== 'DOCTOR') {
            this.props.form.setFieldsValue({
                medicalSpecialities: undefined,
            });
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="user.title" />
                    </h1>
                    <p> </p>
                </div>
            </div>
        );
    };

    renderNameFields = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        if (this.props.form.getFieldValue('userType') === 'MEDICAL_CENTER') {
            return (
                <Row gutter={[24, 0]}>
                    <Col xs={24}>
                        <Form.Item label={<FormattedMessage id="user.name" />}>
                            {getFieldDecorator('firstName', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="user.name.error.required" />,
                                    },
                                ],
                            })(<Input size="large" maxLength={50} data-test="firstName" />)}
                        </Form.Item>
                    </Col>
                    <Col hidden={true}>
                        <Form.Item label={<FormattedMessage id="user.lastName" />}>
                            {getFieldDecorator('lastName', {
                                rules: [
                                    { required: true, message: <FormattedMessage id="user.lastName.error.required" /> },
                                ],
                            })(<Input size="large" maxLength={50} data-test="lastName" />)}
                        </Form.Item>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row gutter={[24, 0]}>
                    <Col xs={12}>
                        <Form.Item label={<FormattedMessage id="user.firstName" />}>
                            {getFieldDecorator('firstName', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="user.firstName.error.required" />,
                                    },
                                ],
                            })(<Input size="large" maxLength={50} data-test="firstName" />)}
                        </Form.Item>
                    </Col>
                    <Col xs={12}>
                        <Form.Item label={<FormattedMessage id="user.lastName" />}>
                            {getFieldDecorator('lastName', {
                                rules: [
                                    { required: true, message: <FormattedMessage id="user.lastName.error.required" /> },
                                ],
                            })(<Input size="large" maxLength={50} data-test="lastName" />)}
                        </Form.Item>
                    </Col>
                </Row>
            );
        }
    };

    renderUserForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        const entityOptions = this.state.entities.map(entity => {
            return (
                <Select.Option value={entity.id} key={entity.id}>
                    {entity.fullName}
                </Select.Option>
            );
        });
        if (this.state.user) {
            const user = this.state.user;
            entityOptions.push(
                <Select.Option value={user.id} key={user.id}>
                    {user.fullName}
                </Select.Option>,
            );
        }
        const userTypeOptions = this.context.settings.userTypes.map(userType => (
            <Select.Option value={userType.value} key={userType.value}>
                {userType.label}
            </Select.Option>
        ));
        const professionOptions = this.context.settings.professionTypes.map(professionType => (
            <Select.Option value={professionType.value} key={professionType.value}>
                {professionType.label}
            </Select.Option>
        ));
        const medicalSpecialityOptions = this.context.settings.medicalSpecialityTypes.map(medicalSpecialityType => (
            <Select.Option value={medicalSpecialityType.value} key={medicalSpecialityType.value}>
                {medicalSpecialityType.label}
            </Select.Option>
        ));
        const measurementSystemOptions = this.context.settings.measurementSystemTypes.map(measurementSystemType => (
            <Select.Option value={measurementSystemType.value} key={measurementSystemType.value}>
                {measurementSystemType.label}
            </Select.Option>
        ));
        const dateFormatOptions = this.context.settings.dateFormatTypes.map(dateFormatType => (
            <Select.Option value={dateFormatType.value} key={dateFormatType.value}>
                {dateFormatType.label}
            </Select.Option>
        ));
        const countryOptions = i18nService.getCountries().map(country => (
            <Select.Option value={country.code} key={country.code}>
                {country.name}
            </Select.Option>
        ));

        return (
            <Form onSubmit={this.handleSave}>
                <Row gutter={[24, 0]}>
                    <Col xs={24}>
                        <Form.Item label={<FormattedMessage id="user.email" />}>
                            {getFieldDecorator('email')(<Input size="large" disabled={true} data-test="email" />)}
                        </Form.Item>
                    </Col>
                </Row>
                {this.renderNameFields()}
                <Row gutter={[24, 0]} hidden={this.state.entities.length === 0}>
                    <Col xs={24}>
                        <Form.Item label={<FormattedMessage id="user.entity" />}>
                            {getFieldDecorator('entityId', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="user.entity.error.required" />,
                                    },
                                ],
                            })(
                                <Select size="large" data-test="entity">
                                    {entityOptions}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={12}>
                        <Form.Item label={<FormattedMessage id="user.userType" />}>
                            {getFieldDecorator('userType', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="user.userType.error.required" />,
                                    },
                                ],
                            })(
                                <Select size="large" onChange={this.handleUserTypeChange} data-test="userType">
                                    {userTypeOptions}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div hidden={this.props.form.getFieldValue('userType') !== 'HEALTH_PROFESSIONAL'}>
                            <Form.Item label={<FormattedMessage id="user.profession" />}>
                                {getFieldDecorator('profession')(
                                    <Select size="large" onChange={this.handleProfessionChange} data-test="profession">
                                        {professionOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col>
                        <div hidden={this.props.form.getFieldValue('profession') !== 'DOCTOR'}>
                            <Form.Item label={<FormattedMessage id="user.medicalSpecialities" />}>
                                {getFieldDecorator('medicalSpecialities')(
                                    <Select size="large" mode="multiple" data-test="medicalSpecialities">
                                        {medicalSpecialityOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Tabs defaultActiveKey="1" className={styles.tabs}>
                    <Tabs.TabPane tab={<FormattedMessage id="user.contact" />} key="1" data-test="contactTab">
                        <Row gutter={[24, 12]} type="flex">
                            <Col span={24}>
                                <Form.Item label={<FormattedMessage id="user.address.address" />}>
                                    {getFieldDecorator('address')(
                                        <Input size="large" maxLength={100} data-test="address" />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 12]} type="flex">
                            <Col span={12}>
                                <Form.Item label={<FormattedMessage id="user.address.city" />}>
                                    {getFieldDecorator('city')(<Input size="large" maxLength={50} data-test="city" />)}
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label={<FormattedMessage id="user.address.state" />}>
                                    {getFieldDecorator('state')(
                                        <Input size="large" maxLength={50} data-test="state" />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 12]} type="flex">
                            <Col span={12}>
                                <Form.Item label={<FormattedMessage id="user.address.country" data-test="country" />}>
                                    {getFieldDecorator('country')(
                                        <Select size="large" allowClear={true}>
                                            {countryOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={<FormattedMessage id="user.address.postalCode" data-test="postalCode" />}
                                >
                                    {getFieldDecorator('postalCode')(<Input size="large" maxLength={10} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 12]} type="flex">
                            <Col span={12}>
                                <Form.Item label={<FormattedMessage id="user.phone" />}>
                                    {getFieldDecorator('phone')(
                                        <Input maxLength={25} size="large" data-test="phone" />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<FormattedMessage id="user.advanced" />} key="2" data-test="advancedTab">
                        <Row gutter={[24, 0]}>
                            <Col xs={24}>
                                <Form.Item label={<FormattedMessage id="user.username" />}>
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                required: true,
                                                message: <FormattedMessage id="user.username.error.required" />,
                                            },
                                            { min: 3, message: <FormattedMessage id="user.username.error.invalid" /> },
                                        ],
                                    })(<Input size="large" maxLength={100} data-test="username" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24} sm={12}>
                                <Form.Item label={<FormattedMessage id="user.measurementSystem" />}>
                                    {getFieldDecorator('measurementSystem', {
                                        rules: [
                                            {
                                                required: true,
                                                message: (
                                                    <FormattedMessage id="user.measurementSystem.error.required" />
                                                ),
                                            },
                                        ],
                                    })(
                                        <Select size="large" data-test="measurementSystem">
                                            {measurementSystemOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item label={<FormattedMessage id="user.dateFormat" />}>
                                    {getFieldDecorator('dateFormat', {
                                        rules: [
                                            {
                                                required: true,
                                                message: <FormattedMessage id="user.dateFormat.error.required" />,
                                            },
                                        ],
                                    })(
                                        <Select size="large" data-test="dateFormat">
                                            {dateFormatOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col xs={24}>
                                <Form.Item>
                                    {getFieldDecorator('alerts', {
                                        valuePropName: 'checked',
                                    })(
                                        <Checkbox className={styles.alerts} data-test="alerts">
                                            <FormattedMessage id="user.alerts" />
                                        </Checkbox>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>

                <Row gutter={[24, 0]} className={styles.buttons}>
                    <Col xs={12} md={8}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Col>
                    <Col xs={12} md={16}>
                        <Popconfirm
                            title={<FormattedMessage id="user.confirm.delete" />}
                            onConfirm={this.handleDelete}
                            okText={<FormattedMessage id="common.ok" />}
                            cancelText={<FormattedMessage id="common.cancel" />}
                        >
                            <Button
                                type="link"
                                size="large"
                                loading={this.state.actionStatus === 'deleting'}
                                disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                className={styles.delete}
                                data-test="delete"
                            >
                                <FormattedMessage id="common.delete" />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19}>
                            <div className="panel">
                                {this.renderHeader()}
                                {this.renderUserForm()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default injectIntl(Form.create()(withRouter(UserPage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps {}

interface State {
    user: User;
    entities: Entity[];
    status?: Status;
    actionStatus?: ActionStatus;
}
