import apiAxios, { apiNoAuthAxios } from './CustomAxios';
import { AxiosResponse } from 'axios';
import { StandardChart } from '../model/model';
import { Moment } from 'moment';

class StandardChartApi {
    list = async (language: string): Promise<StandardChart[]> => {
        const response: AxiosResponse<StandardChart[]> = await apiNoAuthAxios.get<StandardChart[]>('/standard-charts', {
            params: { language },
        });

        return response.data;
    };

    get = async (
        id: number,
        language: string,
        birthdate: Moment,
        maxDate: Moment,
        personId?: number,
        series?: Array<string>,
        reduced?: boolean,
    ): Promise<StandardChart> => {
        const params: any = {
            language: language,
            birthdate: birthdate.format('YYYY-MM-DD'),
            maxDate: maxDate.format('YYYY-MM-DD'),
        };
        if (personId) {
            params.personId = personId;
        }
        if (series) {
            params.series = series.join(',');
        }
        if (reduced) {
            params.reduced = reduced;
        }

        const response: AxiosResponse<StandardChart> = await apiAxios.get<StandardChart>(`/standard-charts/${id}`, {
            params,
        });
        return response.data;
    };
}

const standardChartApi: StandardChartApi = new StandardChartApi();
export default standardChartApi;
