import { Avatar, Button } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Entity, User } from '../../../model/model';
import CustomContext from '../../../service/CustomContext';
import FormattedMessageComponent from '../FormattedMessageComponent';
import styles from './MenuComponent.module.scss';

class MenuComponent extends Component {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /** COMPONENTS **/

    render() {
        const user = this.context.user as User;
        const entity = user.entity as Entity;

        return (
            <>
                <div className={styles.menu}>
                    <h2>
                        <FormattedMessage id="menu.title" />
                    </h2>
                    <ul>
                        <li>
                            <Link to="/user">
                                <Avatar icon="user" shape="square" className={styles.icon} />
                                <FormattedMessage id="menu.settings" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/persons">
                                <Avatar icon="team" shape="square" className={styles.icon} />
                                <FormattedMessageComponent id="menu.profiles.list" />
                            </Link>
                        </li>
                        <li hidden={user.userType === 'PATIENT'}>
                            <Link to="/employees">
                                <Avatar icon="bank" shape="square" className={styles.icon} />
                                <FormattedMessage id="menu.userLinks.list" />
                            </Link>
                        </li>

                        <li>
                            <Link to="/calendar">
                                <Avatar icon="calendar" shape="square" className={styles.icon} />
                                <FormattedMessage id="menu.calendar.list" />
                            </Link>
                        </li>

                        <li>
                            <Link to="/diary-templates">
                                <Avatar icon="read" shape="square" className={styles.icon} />
                                <FormattedMessage id="menu.diaryTemplates.list" />
                            </Link>
                        </li>
                        <li>
                            <Link to="/notifications">
                                <Avatar icon="bell" shape="square" className={styles.icon} />
                                <FormattedMessage id="menu.notifications" />
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.menu} ${styles.entity}`} hidden={!entity}>
                    <span></span>
                    <ul>
                        <li>
                            {user.entity && entity.fullName}{' '}
                            <Link to="/user">
                                <Button icon="swap" type="link"></Button>
                            </Link>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}
export default MenuComponent;
