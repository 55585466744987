import React, { Component } from 'react';
import styles from './StripePayment.module.scss';
import { Button } from 'antd';
import { ActionStatus } from '../../../model/model';
import { FormattedMessage } from 'react-intl';
import { CardElement } from '@stripe/react-stripe-js';

class StripePayment extends Component<any> {
    /** HANDLERS **/

    handlePayment = async (): Promise<void> => {
        this.props.handleSave();
    };

    /** COMPONENTS **/

    render() {
        const props: Props = this.props as Props;
        return (
            <>
                <div className="ant-col ant-form-item-label">
                    <label className="ant-form-item-required">
                        <FormattedMessage id="subscription.payment.card.number" />
                    </label>
                </div>
                <CardElement
                    options={{ hidePostalCode: true, classes: { base: `ant-input ant-input-lg ${styles.base}` } }}
                />
                {this.props.legalCheckbox}
                <Button
                    type="primary"
                    icon="credit-card"
                    onClick={this.handlePayment}
                    size="large"
                    loading={props.actionStatus === 'saving'}
                    disabled={props.actionStatus && props.actionStatus !== 'saving'}
                >
                    <FormattedMessage id={props.buttonLabel} />
                </Button>
            </>
        );
    }
}
export default StripePayment;

interface Props {
    handleSave: () => void;
    legalCheckbox: React.ReactElement;
    buttonLabel: string;
    actionStatus?: ActionStatus;
}
