import videoApi from '../api/VideoApi';
import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration,
    AudioVideoObserver,
    VideoTileState,
    AudioVideoFacade,
    DefaultModality,
    DevicePermission,
    MeetingSessionStatus,
} from 'amazon-chime-sdk-js';
import { VideoCall } from '../model/model';

class VideoService {
    meetingSession?: DefaultMeetingSession;
    audioElement?: HTMLAudioElement;
    videoLocalElement?: HTMLVideoElement;
    videoRemoteElement?: HTMLVideoElement;
    callbackMicrophoneUpdated?: (muted: boolean) => void;
    callbackVideoUpdated?: (muted: boolean) => void;
    callbackVideoDidStartConnecting?: () => void;
    callbackVideoDidStop?: (sessionStatus: MeetingSessionStatus) => void;
    callbackAttendeePresent?: (externalUserId?: string) => void;
    callbackLogs?: (log: string) => void;

    connect = async (chatId: number): Promise<void> => {
        if (!this.audioElement || !this.videoLocalElement || !this.videoRemoteElement) {
            return;
        }

        // get meeting and attendee from backend api
        const meetingResponse = await videoApi.createMeeting(chatId);
        const attendeeResponse = await videoApi.createAttendee(meetingResponse.Meeting.MeetingId);

        // create meeting session configuration
        const configuration = new MeetingSessionConfiguration(meetingResponse.Meeting, attendeeResponse.Attendee);

        // create meeting session
        const logger = new ConsoleLogger('SDK', LogLevel.ERROR);
        const deviceController = new DefaultDeviceController(logger);
        this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
        const audioVideo = this.meetingSession.audioVideo;

        // bind audio to meeting
        audioVideo.bindAudioElement(this.audioElement);

        // select devices (audio, video)
        await this.setDevices();

        // subscribe to events
        audioVideo.realtimeSubscribeToAttendeeIdPresence(this.onAttendeePresence);
        audioVideo.realtimeSubscribeToVolumeIndicator(attendeeResponse.Attendee.AttendeeId, this.onVolumeIndicator);

        // add video observer
        audioVideo.addObserver(this.createVideoObserver(audioVideo, this.videoLocalElement, this.videoRemoteElement));

        // start video
        audioVideo.start();
        audioVideo.startLocalVideoTile();
    };

    join = async (videoCall: VideoCall) => {
        if (!this.audioElement || !this.videoLocalElement || !this.videoRemoteElement) {
            return;
        }

        // get meeting and attendee from backend api
        const meetingResponse = videoCall.videoMeeting;
        const attendeeResponse = await videoApi.createAttendee(meetingResponse.Meeting.MeetingId);

        // create meeting session configuration
        const configuration = new MeetingSessionConfiguration(meetingResponse.Meeting, attendeeResponse.Attendee);

        // create meeting session
        const logger = new ConsoleLogger('SDK', LogLevel.ERROR);
        const deviceController = new DefaultDeviceController(logger);
        this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
        const audioVideo = this.meetingSession.audioVideo;

        // bind audio to meeting
        audioVideo.bindAudioElement(this.audioElement);

        // select devices (audio, video)
        await this.setDevices();

        // subscribe to events
        audioVideo.realtimeSubscribeToAttendeeIdPresence(this.onAttendeePresence);
        audioVideo.realtimeSubscribeToVolumeIndicator(attendeeResponse.Attendee.AttendeeId, this.onVolumeIndicator);

        // add video observer
        audioVideo.addObserver(this.createVideoObserver(audioVideo, this.videoLocalElement, this.videoRemoteElement));

        audioVideo.start();
        audioVideo.startLocalVideoTile();
    };

    stop = () => {
        if (this.meetingSession && this.videoLocalElement) {
            const audioVideo = this.meetingSession.audioVideo;
            audioVideo.stop();

            if (this.meetingSession.configuration.meetingId) {
                videoApi.deleteMeeting(this.meetingSession.configuration.meetingId);
            }
        }
    };

    reject = async (meetingId: string) => {
        await videoApi.deleteMeeting(meetingId);
    };

    setDevices = async (audioInputDevice?: string, audioOutputDevice?: string, videoInputDevice?: string) => {
        if (this.meetingSession) {
            // get default devices if not defined
            const audioVideo = this.meetingSession.audioVideo;
            if (!audioInputDevice) {
                const audioInputDevices = await audioVideo.listAudioInputDevices();
                audioInputDevice = audioInputDevices[0].deviceId;
            }
            if (!audioOutputDevice) {
                const audioOutputDevices = await audioVideo.listAudioOutputDevices();
                audioOutputDevice = audioOutputDevices[0].deviceId;
            }
            if (!videoInputDevice) {
                const videoInputDevices = await audioVideo.listVideoInputDevices();
                videoInputDevice = videoInputDevices[0].deviceId;
            }

            // set-up devices
            audioInputDevice && (await audioVideo.chooseAudioInputDevice(audioInputDevice));
            audioOutputDevice && (await audioVideo.chooseAudioOutputDevice(audioOutputDevice));
            videoInputDevice && (await this.setVideoDevice(videoInputDevice));
        }
    };

    setVideoDevice = async (videoInputDevice: string) => {
        if (this.meetingSession) {
            const audioVideo = this.meetingSession.audioVideo;
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            console.log(width, height);
            this.callbackLogs && this.callbackLogs('dimension: ' + width + ' - ' + height);

            if (width >= height) {
                audioVideo.chooseVideoInputQuality(960, 540, 15, 1400);
                const devicePermission = await audioVideo.chooseVideoInputDevice(videoInputDevice);
                if (devicePermission === DevicePermission.PermissionDeniedByBrowser) {
                    audioVideo.chooseVideoInputQuality(640, 360, 15, 600);
                    await audioVideo.chooseVideoInputDevice(videoInputDevice);
                }
            } else {
                audioVideo.chooseVideoInputQuality(960, 540, 15, 1400);
                const devicePermission = await audioVideo.chooseVideoInputDevice(videoInputDevice);
                if (devicePermission === DevicePermission.PermissionDeniedByBrowser) {
                    audioVideo.chooseVideoInputQuality(640, 360, 15, 600);
                    await audioVideo.chooseVideoInputDevice(videoInputDevice);
                }
            }
        }
    };

    toggleMicrophone = () => {
        if (this.meetingSession && this.videoLocalElement) {
            const audioVideo = this.meetingSession.audioVideo;
            if (audioVideo.realtimeIsLocalAudioMuted()) {
                audioVideo.realtimeUnmuteLocalAudio();
            } else {
                audioVideo.realtimeMuteLocalAudio();
            }
        }
    };

    toggleVideo = async () => {
        if (this.meetingSession && this.videoLocalElement) {
            const audioVideo = this.meetingSession.audioVideo;
            console.log(audioVideo.hasStartedLocalVideoTile());
            if (audioVideo.hasStartedLocalVideoTile()) {
                audioVideo.stopLocalVideoTile();
            } else {
                const videoInputDevices = await audioVideo.listVideoInputDevices();
                await audioVideo.chooseVideoInputDevice(videoInputDevices[0].deviceId);
                audioVideo.startLocalVideoTile();
            }
        }
    };

    private onAttendeePresence = (
        presentAttendeeId: string,
        present: boolean,
        externalUserId?: string,
        dropped?: boolean,
    ) => {
        if (present) {
            this.callbackAttendeePresent && this.callbackAttendeePresent(externalUserId);
        } else {
            console.log('attendee leaves meeting', presentAttendeeId);
        }
    };

    private onVolumeIndicator = (
        attendeeId: string,
        volume: number | null,
        muted: boolean | null,
        signalStrength: number | null,
    ) => {
        const baseAttendeeId = new DefaultModality(attendeeId).base();
        if (baseAttendeeId !== attendeeId) {
            console.log(`The volume of ${baseAttendeeId}'s content changes`);
        }

        if (muted !== null) {
            this.callbackMicrophoneUpdated && this.callbackMicrophoneUpdated(muted);
        }
    };

    private createVideoObserver = (
        audioVideo: AudioVideoFacade,
        videoLocalElement: HTMLVideoElement,
        videoRemoteElement: HTMLVideoElement,
    ): AudioVideoObserver => {
        return {
            audioVideoDidStartConnecting: () => {
                this.callbackVideoDidStartConnecting && this.callbackVideoDidStartConnecting();
            },
            audioVideoDidStart: () => {
                console.log('audioVideoDidStart');
            },
            videoTileDidUpdate: (tileState: VideoTileState) => {
                console.log('videoTileDidUpdate', tileState);
                tileState.tileId === 1
                    ? audioVideo.bindVideoElement(tileState.tileId as number, videoLocalElement)
                    : audioVideo.bindVideoElement(tileState.tileId as number, videoRemoteElement);
                this.callbackVideoUpdated && this.callbackVideoUpdated(!tileState.active);
            },
            audioVideoDidStop: (sessionStatus: MeetingSessionStatus) => {
                console.log('audioVideoDidStop', sessionStatus);
                this.callbackVideoDidStop && this.callbackVideoDidStop(sessionStatus);
            },
        };
    };
}

const videoService = new VideoService();
export default videoService;
