import moment, { Moment } from 'moment';
import { AuthUser, Person } from '../model/model';
import i18nService from './I18nService';

class DateService {
    getDateFormat = (user?: AuthUser): string => {
        return user && user.dateFormat ? user.dateFormat.toUpperCase() : 'DD MMM YYYY';
    };

    isPastDate = (current?: Moment | null): boolean => {
        return !!current && current.isBefore(moment());
    };

    isFutureDate = (current?: Moment | null): boolean => {
        return !!current && current.isAfter(moment());
    };

    isAllowedMeasureDate = (person?: Person, date?: Moment): boolean => {
        let isAllowedMeasureDate: boolean = false;
        if (person && date) {
            const isSameOrAfterAfterBirthdate: boolean = date.isSameOrAfter(person.birthdate);
            isAllowedMeasureDate = isSameOrAfterAfterBirthdate && !this.isFutureDate(date);
        }
        return isAllowedMeasureDate;
    };

    getPeriod = (startMoment?: Moment, endMoment?: Moment): string => {
        let period: string = '';
        let years: number;
        let months: number;
        let days: number;
        if (startMoment && endMoment) {
            const start: Moment = moment.utc(startMoment);
            const end: Moment = moment.utc(endMoment);
            let tempStart = start.clone();
            years = end.diff(tempStart, 'years');
            tempStart.add(years, 'years');
            months = end.diff(tempStart, 'months');
            tempStart.add(months, 'months');
            days = end.diff(tempStart, 'days');

            if (years) {
                period = `${years} ${
                    years === 1
                        ? i18nService.intl.formatMessage({ id: 'time.year' })
                        : i18nService.intl.formatMessage({ id: 'time.years' })
                }`;
            }
            if (months) {
                period = period.length > 0 ? `${period}, ` : period;
                period = `${period}${months} ${
                    months === 1
                        ? i18nService.intl.formatMessage({ id: 'time.month' })
                        : i18nService.intl.formatMessage({ id: 'time.months' })
                }`;
            }
            if (days) {
                period = period.length > 0 ? `${period}, ` : period;
                period = `${period}${days} ${
                    days === 1
                        ? i18nService.intl.formatMessage({ id: 'time.day' })
                        : i18nService.intl.formatMessage({ id: 'time.days' })
                }`;
            }

            if (!years && !months && !days) {
                period = i18nService.intl.formatMessage({ id: 'time.birth' });
            }
        }
        return period;
    };

    getPeriodInYears = (startMoment?: Moment, endMoment?: Moment): number | undefined => {
        let years: number | undefined;
        if (startMoment && endMoment) {
            const start: Moment = moment.utc(startMoment);
            const end: Moment = moment.utc(endMoment);
            let tempStart = start.clone();
            years = end.diff(tempStart, 'years');
        }
        return years;
    };

    getPeriodFromBirthdate = (endMoment: Moment, person?: Person): string => {
        const birthdate: Moment | undefined = person && person.birthdate;
        return this.getPeriod(birthdate, endMoment);
    };

    calculateMaxDate = (startMoment: Moment, endMoment: Moment, chartType?: string): Moment => {
        const start: Moment = startMoment.clone();
        const end: Moment = endMoment.clone();
        let maxDate: Moment;
        if (end.diff(start, 'months') < 6) {
            maxDate = start.add(6, 'months');
        } else if (end.diff(start, 'years') < 2) {
            maxDate = start.add(2, 'years');
        } else if (end.diff(start, 'years') < 5) {
            maxDate = start.add(5, 'years');
        } else if (chartType === 'HEIGHT' || chartType === 'BMI') {
            maxDate = start.add(18, 'years');
        } else {
            maxDate = start.add(100, 'years');
        }
        return maxDate;
    };

    getDateFromPeriod(start: Moment, period: string): Moment {
        let date: Moment = start.clone();
        if (period && period.length > 0) {
            let tempPeriod: string = period.substring(1);
            if (period.includes('Y')) {
                date = date.add(+tempPeriod.substring(0, tempPeriod.indexOf('Y')), 'years');
                tempPeriod = tempPeriod.substring(tempPeriod.indexOf('Y') + 1);
            }
            if (period.includes('M')) {
                date = date.add(+tempPeriod.substring(0, tempPeriod.indexOf('M')), 'months');
                tempPeriod = tempPeriod.substring(tempPeriod.indexOf('M') + 1);
            }
            if (period.includes('D')) {
                date = date.add(tempPeriod.substring(0, tempPeriod.indexOf('D')), 'days');
            }
        }

        return date;
    }

    formatDateTime = (dateFormat: string, date?: Moment, time?: Moment): string => {
        let formattedDateTime = '';
        if (date) {
            formattedDateTime = date.format(dateFormat);
        } else if (time) {
            formattedDateTime = formattedDateTime + ' ' + time.format('HH:mm');
        }

        return formattedDateTime;
    };
}

const dateService: DateService = new DateService();
export default dateService;
