import apiAxios from './CustomAxios';
import { Subscription } from '../model/model';
import { AxiosResponse } from 'axios';
import moment from 'moment';

class SubscriptionApi {
    list = async (): Promise<Subscription[]> => {
        const response: AxiosResponse<Subscription[]> = await apiAxios.get<Subscription[]>('/subscriptions');
        response.data.forEach(p => {
            p.currentPeriodEnd = p.currentPeriodEnd && moment(p.currentPeriodEnd);
            p.cancelAt = p.cancelAt && moment(p.cancelAt);
        });
        return response.data;
    };

    create = async (subscription: Subscription): Promise<Subscription> => {
        const response: AxiosResponse<Subscription> = await apiAxios.post<Subscription>('/subscriptions', subscription);
        response.data.currentPeriodEnd = response.data.currentPeriodEnd && moment(response.data.currentPeriodEnd);
        response.data.cancelAt = response.data.cancelAt && moment(response.data.cancelAt);
        return response.data;
    };

    update = async (subscription: Subscription): Promise<Subscription> => {
        subscription.currentPeriodEnd = undefined;
        const response: AxiosResponse<Subscription> = await apiAxios.put<Subscription>(
            `/subscriptions/${subscription.id}`,
            subscription,
        );
        response.data.currentPeriodEnd = response.data.currentPeriodEnd && moment(response.data.currentPeriodEnd);
        response.data.cancelAt = response.data.cancelAt && moment(response.data.cancelAt);
        return response.data;
    };

    activate = async (subscription: Subscription): Promise<Subscription> => {
        const response: AxiosResponse<Subscription> = await apiAxios.post<Subscription>(
            `/subscriptions/${subscription.id}/active`,
        );
        response.data.currentPeriodEnd = response.data.currentPeriodEnd && moment(response.data.currentPeriodEnd);
        response.data.cancelAt = response.data.cancelAt && moment(response.data.cancelAt);
        return response.data;
    };

    cancel = async (subscription: Subscription): Promise<Subscription> => {
        const response: AxiosResponse<Subscription> = await apiAxios.post<Subscription>(
            `/subscriptions/${subscription.id}/cancelled`,
        );
        response.data.currentPeriodEnd = response.data.currentPeriodEnd && moment(response.data.currentPeriodEnd);
        response.data.cancelAt = response.data.cancelAt && moment(response.data.cancelAt);
        return response.data;
    };
}

const subscriptionApi: SubscriptionApi = new SubscriptionApi();
export default subscriptionApi;
