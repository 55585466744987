import React, { Component, FormEvent, MouseEvent } from 'react';
import styles from './SignUpPage.module.scss';
import { Form, Input, Icon, Button, Checkbox, Result, Col, Row, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import authService from '../../service/AuthService';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import userApi from '../../api/UserApi';
import { User, ActionStatus } from '../../model/model';
import { Link } from 'react-router-dom';
import CustomContext from '../../service/CustomContext';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedHTMLMessage } from 'react-intl';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import errorService from '../../service/ErrorService';
import ga from '../Helper/GoogleAnalytics/GoogleAnalytics';
import logService from '../../service/LogService';
import settingsService from '../../service/SettingsService';
import pathService from '../../service/PathService';
import i18nService from '../../service/I18nService';
import authApi from '../../api/AuthApi';

class SignUpPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            user: {},
        };
    }

    componentDidMount() {
        ga.createDefaultEvent('sign up', 'sign up - init');
        this.processErrors();
        this.init();
    }

    /** METHODS **/

    processErrors = () => {
        if (window.location.search.includes('error=oauth2')) {
            const params: URLSearchParams = new URLSearchParams(window.location.search);
            const code = params.get('code');
            if (code === 'EMAIL_IS_NULL.GOOGLE') {
                message.error(this.props.intl.formatMessage({ id: 'signIn.error.oidc.emailIsNull.google' }), 10);
            } else if (code === 'EMAIL_IS_NULL.FACEBOOK') {
                message.error(this.props.intl.formatMessage({ id: 'signIn.error.oidc.emailIsNull.facebook' }), 10);
            } else {
                message.error(this.props.intl.formatMessage({ id: 'signIn.error.oidc' }), 10);
            }

            this.props.history.push({
                pathname: '/signup',
            });
        }
    };

    init = async (): Promise<void> => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const token: string | undefined = params.get('token') === null ? undefined : (params.get('token') as string);

        const user: User = Object.assign({}, this.state.user, {
            language: i18nService.intl.locale.toUpperCase(),
            measurementSystem: settingsService.settings.measurementSystemTypes[0].value,
            dateFormat: settingsService.settings.dateFormatTypes[0].value,
            consent: 'local',
            token,
        });
        this.setState({ user });
    };

    signUp = async (values: any) => {
        const token = this.state.user.token;
        let user: User = Object.assign({}, this.state.user, {
            firstName: '',
            lastName: '',
            email: values.email,
            password: values.password,
        });

        user = await userApi.create(user);
        if (user.status === 'ACTIVE' && token) {
            await this.signIn(values.email, values.password);
        } else {
            this.setState({ status: 'success' });
        }
    };

    signIn = async (email: string, password: string) => {
        await authApi.signIn(email, password);
        authService.initOAuthImplicitFlow();
    };

    /** HANDLERS **/

    handleSignUp = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        ga.createDefaultEvent('sign up', 'sign up - signing up - 0');
        this.props.form.validateFields(async (validationError, values) => {
            try {
                logService.info('sign up');
                ga.createDefaultEvent('sign up', 'sign up - signing up');
                this.setState({ actionStatus: 'saving' });
                if (validationError) {
                    logService.info('sign up - form errors');
                    ga.createDefaultEvent('sign up', 'sign up - form errors');
                } else {
                    await this.signUp(values);
                    logService.info('sign up - success');
                    ga.createDefaultEvent('sign up', 'sign up - success');
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'signUp.error.duplicated']]);
                ga.createDefaultEvent('sign up', 'sign up - error');
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleSignUpWithGoogle = (e: MouseEvent): void => {
        try {
            e.preventDefault();
            logService.info('sign up - google');
            ga.createDefaultEvent('sign up', 'sign up - google - signing up');
            authService.initOAuthImplicitFlowWithSocialProvider('google');
            ga.createDefaultEvent('sign up', 'sign up - google - success');
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('sign up', 'sign up - google - error');
        }
    };

    handleSignUpWithFacebook = (e: MouseEvent): void => {
        try {
            e.preventDefault();
            logService.info('sign up - facebook');
            ga.createDefaultEvent('sign up', 'sign up - facebook - signing up');
            authService.initOAuthImplicitFlowWithSocialProvider('facebook');
            ga.createDefaultEvent('sign up', 'sign up - facebook - success');
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('sign up', 'sign up - facebook - error');
        }
    };

    /** COMPONENTS **/

    renderSignUpInfo = (): JSX.Element => {
        return (
            <div className={styles.info}>
                <h2>
                    <FormattedMessage id="signUp.info.title" />
                </h2>
                <p>
                    <Icon type="check-circle" theme="filled" style={{ color: 'rgba(157, 150, 216, 0.65)' }} />{' '}
                    <FormattedMessage id="signUp.info.desc.0" />
                </p>
                <p>
                    <Icon type="check-circle" theme="filled" style={{ color: 'rgba(157, 150, 216, 0.65)' }} />{' '}
                    <FormattedMessage id="signUp.info.desc.1" />
                </p>
                <p>
                    <Icon type="check-circle" theme="filled" style={{ color: 'rgba(157, 150, 216, 0.65)' }} />{' '}
                    <FormattedMessage id="signUp.info.desc.2" />
                </p>
                <p>
                    <Icon type="check-circle" theme="filled" style={{ color: 'rgba(157, 150, 216, 0.65)' }} />{' '}
                    <FormattedMessage id="signUp.info.desc.3" />
                </p>
                <p>
                    <Icon type="check-circle" theme="filled" style={{ color: 'rgba(157, 150, 216, 0.65)' }} />{' '}
                    <FormattedMessage id="signUp.info.desc.4" />
                </p>
                <p>
                    <Icon type="check-circle" theme="filled" style={{ color: 'rgba(157, 150, 216, 0.65)' }} />{' '}
                    <FormattedMessage id="signUp.info.desc.5" />
                </p>

                <p>
                    <Icon type="file-protect" /> <FormattedMessage id="signUp.info.desc.6" />
                </p>
            </div>
        );
    };

    renderSignUp = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        return (
            <>
                <div className={styles.local}>
                    <h1>
                        <FormattedMessage id="signUp.title" />
                    </h1>
                    <p className={styles.signin}>
                        <FormattedMessage id="signUp.alreadySignedUp" />{' '}
                        <Link to="/signin">
                            <FormattedMessage id="signUp.signIn" />
                        </Link>
                    </p>
                    <Form onSubmit={this.handleSignUp}>
                        <Form.Item className={styles.field}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="signUp.email.error.required" />,
                                    },
                                    { type: 'email', message: <FormattedMessage id="signUp.email.error.invalid" /> },
                                ],
                            })(
                                <Input
                                    prefix={<Icon type="user" className={styles.inputIcon} />}
                                    placeholder={this.props.intl.formatMessage({ id: 'signUp.email' })}
                                    maxLength={100}
                                    size="large"
                                    type="email"
                                    data-test="email"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item className={styles.field}>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="signUp.password.error.required" />,
                                    },
                                    {
                                        pattern: new RegExp('^(?=.*[0-9])(?=.*[^0-9]).{8,20}$'),
                                        message: <FormattedMessage id="signUp.password.error.invalid" />,
                                    },
                                ],
                            })(
                                <Input.Password
                                    prefix={<Icon type="lock" className={styles.inputIcon} />}
                                    placeholder={this.props.intl.formatMessage({ id: 'signUp.password' })}
                                    type="password"
                                    maxLength={20}
                                    size="large"
                                    data-test="password"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item className={styles.field}>
                            {getFieldDecorator('legal', {
                                valuePropName: 'checked',
                                initialValue: false,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="signUp.consent.error.required" />,
                                        transform: value => value || undefined,
                                        type: 'boolean',
                                    },
                                ],
                            })(
                                <Checkbox className={styles.legal} data-test="legal">
                                    <span>
                                        <FormattedMessage id="signUp.consent" />{' '}
                                        <Link to={pathService.getPath('legal-notice')}>
                                            <FormattedMessage id="signUp.legalNotice" />
                                        </Link>{' '}
                                        <FormattedMessage id="common.and" />{' '}
                                        <Link to={pathService.getPath('privacy-policy')}>
                                            <FormattedMessage id="signUp.privacyPolicy" />
                                        </Link>
                                    </span>
                                </Checkbox>,
                            )}
                        </Form.Item>
                        <div className={styles.buttons}>
                            <Button
                                type="primary"
                                block
                                size="large"
                                htmlType="submit"
                                loading={this.state.actionStatus === 'saving'}
                                data-test="submit"
                            >
                                <FormattedMessage id="signUp.signUp" />
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className={styles.social}>
                    <p>
                        <FormattedMessage id="signUp.orSocial" />{' '}
                        <Button type="link" size="large" onClick={this.handleSignUpWithGoogle} data-test="google">
                            <FormattedMessage id="signUp.google" />
                        </Button>{' '}
                        <FormattedMessage id="common.or" />{' '}
                        <Button type="link" size="large" onClick={this.handleSignUpWithFacebook} data-test="facebook">
                            <FormattedMessage id="signUp.facebook" />
                        </Button>
                    </p>
                </div>
            </>
        );
    };

    renderSignUpSuccess = (): JSX.Element => {
        return (
            <Result
                status="success"
                title={<FormattedMessage id="signUp.success.title" />}
                subTitle={<FormattedHTMLMessage id="signUp.success.subtitle" />}
                className={styles.success}
            />
        );
    };

    render() {
        if (this.context.user) {
            return <Redirect to="/dashboard" />;
        } else if (this.state.status) {
            return (
                <div data-test="success">
                    <HeadMetadata titleKey="signUp.meta.title" />
                    {this.renderSignUpSuccess()}
                </div>
            );
        } else {
            return (
                <div className={styles.layout}>
                    <HeadMetadata titleKey="signUp.meta.title" />
                    <Row gutter={[24, 0]}>
                        <Col xs={0} md={12}>
                            {this.renderSignUpInfo()}
                        </Col>
                        <Col xs={24} md={12} xl={8}>
                            {this.renderSignUp()}
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}
export default injectIntl(Form.create()(withRouter(SignUpPage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps {}

interface State {
    user: User;
    status?: 'success';
    actionStatus?: ActionStatus;
}
