import pathsEs from '../model/es.paths';
import pathsPl from '../model/pl.paths';
import pathsIt from '../model/it.paths';
import { KeyPath, LocalePath, LocalePaths } from '../model/paths';
import i18nService from './I18nService';
import { Locale } from '../model/model';

class PathService {
    readonly localePaths: LocalePaths[] = [
        { locale: 'es', paths: pathsEs },
        { locale: 'pl', paths: pathsPl },
        { locale: 'it', paths: pathsIt },
    ];
    readonly communityPaths: string[] = ['posts', 'questions', 'doctors', 'members', 'researchers'];

    getAbsolutePath = (fullPath: string, targetLocale: Locale): string => {
        const currentLocale = i18nService.getLocaleFromPath();
        const currentLocalePaths = this.getLocalePaths(currentLocale);
        const targetLocalePaths = this.getLocalePaths(targetLocale);

        let targetPath: string;
        const keysPath = fullPath.split('/').map(path => this.getKeyByPath(path, currentLocalePaths));
        if (keysPath.length > 1 && this.communityPaths.includes(keysPath[1])) {
            targetPath = '/' + this.getPathByKey('communities', targetLocalePaths);
        } else {
            targetPath = keysPath.map(key => this.getPathByKey(key, targetLocalePaths)).join('/');
        }
        const targetLocalePath = targetLocale === i18nService.defaultLocale ? '' : `/${targetLocale}`;

        return targetLocalePath + targetPath;
    };

    getPath = (key: KeyPath, selectedLocale?: Locale): string => {
        const locale = selectedLocale || i18nService.getLocaleFromPath();
        const localePaths = this.getLocalePaths(locale);
        const path = this.getPathByKey(key, localePaths);
        return '/' + path;
    };

    getKey = (path: string, selectedLocale?: Locale): string => {
        const locale = selectedLocale || i18nService.getLocaleFromPath();
        const localePaths = this.getLocalePaths(locale);
        const key = this.getKeyByPath(path, localePaths);
        return key;
    };

    private getLocalePaths = (locale: Locale): LocalePath[] => {
        const localePaths: LocalePaths | undefined = this.localePaths.find(localePath => localePath.locale === locale);
        return localePaths ? localePaths.paths : [];
    };

    private getPathByKey = (key: KeyPath | string, localePaths: LocalePath[]): string => {
        let localePath: LocalePath | undefined = localePaths.find(path => path.key === key);
        return localePath && localePath.path ? localePath.path : key;
    };

    private getKeyByPath = (path: string, localePaths: LocalePath[]): string => {
        let localePath: LocalePath | undefined = localePaths.find(localePath => localePath.path === path);
        return localePath && localePath.key ? localePath.key : path;
    };
}

const pathService = new PathService();
export default pathService;
