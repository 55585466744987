import { apiNoAuthAxios } from './CustomAxios';
import axios, { AxiosResponse, AxiosTransformer, AxiosRequestConfig } from 'axios';
import { UserMeasure, UserMeasureMetadata } from '../model/model';
import moment, { Moment } from 'moment';

class UserMeasureMetadataApi {
    list = async (
        birthdate: Moment,
        standardChartId: number,
        userMeasures: UserMeasure[],
    ): Promise<UserMeasureMetadata[]> => {
        const requestConfig: AxiosRequestConfig = Object.assign({}, this.requestConfig, {
            params: { birthdate: birthdate.format('YYYY-MM-DD'), standardChartId },
        });
        const response: AxiosResponse<UserMeasureMetadata[]> = await apiNoAuthAxios.post<UserMeasureMetadata[]>(
            '/user-measure-metadatas',
            userMeasures,
            requestConfig,
        );
        response.data.forEach(umd => (umd.userMeasure.date = moment(umd.userMeasure.date)));
        return response.data;
    };

    private transformRequest = (userMeasures: UserMeasure[]): any => {
        return userMeasures.map(userMeasure =>
            Object.assign({}, userMeasure, {
                date: userMeasure.date ? userMeasure.date.format('YYYY-MM-DDT00:00:00.000') + 'Z' : undefined,
                time: userMeasure.time ? userMeasure.time.format('HH:mm') : undefined,
            }),
        );
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
    };
}

const userMeasureMetadataApi: UserMeasureMetadataApi = new UserMeasureMetadataApi();
export default userMeasureMetadataApi;
