import apiAxios, { apiNoAuthAxios } from './CustomAxios';
import { User, UserToken, UserEmail, UserTokenPassword, Page, PublicUser } from '../model/model';
import { AxiosResponse } from 'axios';

class UserApi {
    list = async (
        page: number,
        size: number,
        searchText?: string,
        userType?: string,
        condition?: string,
    ): Promise<Page<PublicUser>> => {
        const response: AxiosResponse<Page<PublicUser>> = await apiNoAuthAxios.get<Page<User>>('/users', {
            params: { page, size, searchText, userType, condition },
        });
        return response.data;
    };

    getCurrentUser = async (): Promise<User> => {
        const response: AxiosResponse<User> = await apiAxios.get<User>('/users/current');
        return response.data;
    };

    create = async (user: User): Promise<User> => {
        const response: AxiosResponse<User> = await apiNoAuthAxios.post<User>('/users', user);
        return response.data;
    };

    update = async (user: User): Promise<User> => {
        user.stripeCustomerId = undefined; // TODO: to be deleted from dto
        user.status = undefined; // TODO: to be deleted from dto
        const response: AxiosResponse<User> = await apiAxios.put<User>(`/users/${user.id}`, user);
        return response.data;
    };

    delete = async (user: User): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/users/${user.id}`);
        return response.data;
    };

    verifyEmail = async (userToken: UserToken): Promise<void> => {
        await apiNoAuthAxios.post<void>('/users/email-verifications', userToken);
    };

    resetPassword = async (userEmail: UserEmail): Promise<void> => {
        await apiNoAuthAxios.post<void>('/users/password-resets', userEmail);
    };

    changePassword = async (userTokenPassword: UserTokenPassword): Promise<void> => {
        await apiNoAuthAxios.post<void>('/users/password-changes', userTokenPassword);
    };
}

const userApi: UserApi = new UserApi();
export default userApi;
