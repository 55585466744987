import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import ReactGA, { EventArgs } from 'react-ga';

ReactGA.initialize('UA-9440096-10', {
    gaOptions: {},
});
export const withGATracker = (WrappedComponent: any, options = {}) => {
    const trackPage = (page: string) => {
        ReactGA.set({
            page,
            ...options,
        });
        ReactGA.pageview(page);
    };

    const HOC = class extends Component<RouteComponentProps> {
        componentDidMount() {
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page);
        }

        componentDidUpdate(prevProps: RouteComponentProps) {
            const currentPage = prevProps.location.pathname + prevProps.location.search;
            const nextPage = this.props.location.pathname + this.props.location.search;
            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

class GoogleAnalytics {
    createEvent = (args: EventArgs): void => {
        ReactGA.event(args);
    };

    createDefaultEvent = (category: string, action: string, label?: string): void => {
        ReactGA.event({ category, action, label });
    };
}

const ga: GoogleAnalytics = new GoogleAnalytics();
export default ga;
