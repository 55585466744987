import React, { Component } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import CustomContext from '../../service/CustomContext';

class FormattedMessageComponent extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    render() {
        let id = this.props.id;
        if (this.context.user && this.context.user.userType !== 'PATIENT') {
            id = id + '.doctor';
        }

        return <FormattedMessage id={id} values={this.props.values} />;
    }
}
export default FormattedMessageComponent;

interface Props extends MessageDescriptor {
    values?: any;
}
