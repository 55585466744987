import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { DiaryTemplatePerson } from '../model/model';

class DiaryTemplatePersonApi {
    list = async (personId: number): Promise<DiaryTemplatePerson[]> => {
        const response: AxiosResponse<DiaryTemplatePerson[]> = await apiAxios.get<DiaryTemplatePerson[]>(
            '/diary-template-persons',
            {
                params: { personId },
            },
        );
        return response.data;
    };

    create = async (diaryTemplatePerson: DiaryTemplatePerson): Promise<DiaryTemplatePerson> => {
        const response: AxiosResponse<DiaryTemplatePerson> = await apiAxios.post<DiaryTemplatePerson>(
            '/diary-template-persons',
            diaryTemplatePerson,
        );
        return response.data;
    };

    delete = async (diaryTemplatePerson: DiaryTemplatePerson): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/diary-template-persons/${diaryTemplatePerson.id}`);
        return response.data;
    };
}

const diaryTemplatePersonApi: DiaryTemplatePersonApi = new DiaryTemplatePersonApi();
export default diaryTemplatePersonApi;
