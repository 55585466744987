import { UnitType, UserChart, Measure, Chart, BmiMeasure, Unit } from '../model/model';
import settingsService from './SettingsService';
import i18nService from './I18nService';

class UnitService {
    listUnits = (): UnitType[] => {
        return settingsService.settings.unitTypes;
    };

    getUnitTypeByValue = (value: string): UnitType => {
        return this.listUnits().find(u => u.value === value) as UnitType;
    };

    getUnitTypeByChartType = (chartTypeValue: string, measurementSystem: string): UnitType => {
        const chartType = settingsService.getChartTypeByValue(chartTypeValue as string);
        const unitTypes: UnitType[] = this.listUnits().filter(u => chartType.units.includes(u.value));

        // get unity type ofr measurement system or get default one from international metric system
        let unitType = unitTypes.find(t => t.measurementSystem === measurementSystem);
        if (!unitType) {
            unitType = unitTypes[0];
            unitType.measurementSystem = measurementSystem;
        }

        return unitType;
    };

    getUnitTypeByUserMeasure = (measure: Measure): UnitType => {
        const unit: UnitType = this.getUnitTypeByValue(measure.unit as string);
        return unit;
    };

    getUnitTypeByUserChart = (userChart: UserChart, measurementSystem: string): UnitType => {
        return this.getUnitTypeByChartType(userChart.chartType as string, measurementSystem);
    };

    getUnitLabelByUserChart = (userChart: UserChart, measurementSystem: string): string => {
        return this.getUnitTypeByUserChart(userChart, measurementSystem).label;
    };

    getUnitValueByUserChart = (userChart: UserChart, measurementSystem: string): string => {
        return this.getUnitTypeByUserChart(userChart, measurementSystem).value;
    };

    getSecondaryUnitTypeByUnitType = (unitType?: UnitType): UnitType | undefined => {
        return unitType && this.getUnitTypeByValue(unitType.secondaryUnit);
    };

    getSecondaryUnitLabelByUserChart = (userChart: UserChart, measurementSystem: string): string => {
        const unitType: UnitType = this.getUnitTypeByUserChart(userChart, measurementSystem);
        const secondaryUnitType: UnitType | undefined = this.getSecondaryUnitTypeByUnitType(unitType);
        return secondaryUnitType ? secondaryUnitType.label : '';
    };

    convertChart = (chart: Chart, measurementSystem: string): Chart => {
        const targetUnitType: UnitType = this.getUnitTypeByUserChart(chart, measurementSystem) as UnitType;

        const convertedChart = Object.assign({}, chart);
        convertedChart.series = convertedChart.series.map(s => Object.assign({}, s));
        convertedChart.series.forEach(s => (s.measures = s.measures.map(m => this.convertMeasure(m, targetUnitType))));
        return convertedChart;
    };

    convertMeasure = (measure: Measure, targetUnitType: UnitType): Measure => {
        const convertedMeasure: Measure = this.convertSingleMeasure(measure, targetUnitType);
        if ('height' in convertedMeasure && (convertedMeasure as BmiMeasure).height) {
            const heightUnitType = unitService.getUnitTypeByChartType('HEIGHT', targetUnitType.measurementSystem);
            (convertedMeasure as BmiMeasure).height = this.convertSingleMeasure(
                (convertedMeasure as BmiMeasure).height as Measure,
                heightUnitType,
            );
        }
        if ('weight' in convertedMeasure && (convertedMeasure as BmiMeasure).weight) {
            const weightUnitType = unitService.getUnitTypeByChartType('WEIGHT', targetUnitType.measurementSystem);
            (convertedMeasure as BmiMeasure).weight = this.convertSingleMeasure(
                (convertedMeasure as BmiMeasure).weight as Measure,
                weightUnitType,
            );
        }

        return convertedMeasure;
    };

    private convertSingleMeasure = (measure: Measure, targetUnitType: UnitType): Measure => {
        const convertedMeasure: Measure = Object.assign({}, measure);

        if (convertedMeasure.unit) {
            let sourceValue: number = convertedMeasure.value as number;
            let secondarySourceValue: number = convertedMeasure.secondaryValue as number;

            // get source units
            let sourceUnitType = this.getUnitTypeByUserMeasure(convertedMeasure);
            let secondarySourceUnitType: UnitType = this.getUnitTypeByValue(sourceUnitType.secondaryUnit);

            // get target units
            let secondaryTargetUnitType: UnitType = this.getUnitTypeByValue(targetUnitType.secondaryUnit);

            // convert value by units
            let value: number = sourceValue;
            if (sourceUnitType.value !== targetUnitType.value) {
                // sum and convert total value
                value = 0;
                if (sourceValue) {
                    value = value + this.convertValue(sourceValue, sourceUnitType, targetUnitType);
                }
                if (secondarySourceValue && secondarySourceUnitType) {
                    value = value + this.convertValue(secondarySourceValue, secondarySourceUnitType, targetUnitType);
                }

                // assign value to measure value fields (value, secondary value)
                if (targetUnitType.secondaryUnit) {
                    convertedMeasure.value = Math.floor(value);
                    convertedMeasure.secondaryValue = this.convertValue(
                        value - convertedMeasure.value,
                        targetUnitType,
                        secondaryTargetUnitType,
                    );
                } else {
                    convertedMeasure.value = value;
                    convertedMeasure.secondaryValue = undefined;
                }
                convertedMeasure.unit = targetUnitType.value;
            }
        }
        convertedMeasure.unit = targetUnitType.value;

        return convertedMeasure;
    };

    getTotalMeasure = (measure: Measure): number => {
        let value: number = measure.value ? measure.value : 0;
        if (measure.secondaryValue && measure.unit) {
            let unitType = this.getUnitTypeByValue(measure.unit);
            let secondaryUnitType: UnitType = this.getUnitTypeByValue(unitType.secondaryUnit);
            value = value + unitService.convertValue(measure.secondaryValue, secondaryUnitType, unitType);
        }
        return value;
    };

    getFormattedMeasure = (measure: Measure, unitLabel: string): string => {
        const unit = measure.unit as Unit;
        let formattedMeasure: string = '';
        if (unit === 'MMHG') {
            const value = i18nService.intl.formatNumber(measure.value || 0, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });
            const secondaryValue = i18nService.intl.formatNumber(measure.secondaryValue || 0, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });
            formattedMeasure = `${value} / ${secondaryValue} ${unitLabel}`;
        } else {
            const totalMeasure: number = unitService.getTotalMeasure(measure);
            if (!isNaN(totalMeasure) && (measure.value || measure.secondaryValue)) {
                const value = i18nService.intl.formatNumber(totalMeasure || 0, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                formattedMeasure = `${value} ${unitLabel}`;
            }
        }
        return formattedMeasure;
    };

    formatNumber = (value: any, decimals: number): string => {
        let truncatedValue: string = value ? value.toString() : '';
        if (value && !isNaN(value)) {
            const separator = value.toString().includes(',') ? ',' : '.';
            const parts: string[] = value.toString().split(separator);
            if (parts.length > 1 && parts[1].length > decimals) {
                truncatedValue = parts[0] + separator + parts[1].substring(0, 2);
            }
        }

        return truncatedValue;
    };

    convertValue = (value: number, sourceUnitType: UnitType, targetUnitType: UnitType): number => {
        const sourceUnit = sourceUnitType.value as Unit;
        const targetUnit = targetUnitType.value as Unit;

        let result: number;
        // LENGTH
        if (sourceUnit === 'CM' && targetUnit === 'FT') {
            result = value / 30.48;
        } else if (sourceUnit === 'CM' && targetUnit === 'IN') {
            result = value / 2.54;
        } else if (sourceUnit === 'FT' && targetUnit === 'CM') {
            result = value * 30.48;
        } else if (sourceUnit === 'FT' && targetUnit === 'IN') {
            result = value * 12;
        } else if (sourceUnit === 'IN' && targetUnit === 'CM') {
            result = value * 2.54;
        } else if (sourceUnit === 'IN' && targetUnit === 'FT') {
            result = value / 12;
        }
        // MASS
        else if (sourceUnit === 'KG' && targetUnit === 'LB') {
            result = value * 2.20462;
        } else if (sourceUnit === 'KG' && targetUnit === 'OZ') {
            result = value * 35.274;
        } else if (sourceUnit === 'LB' && targetUnit === 'KG') {
            result = value / 2.20462;
        } else if (sourceUnit === 'LB' && targetUnit === 'OZ') {
            result = value * 16;
        } else if (sourceUnit === 'OZ' && targetUnit === 'KG') {
            result = value / 35.274;
        } else if (sourceUnit === 'OZ' && targetUnit === 'LB') {
            result = value / 16;
        }
        // TEMPERATURE
        else if (sourceUnit === 'CELSIUS' && targetUnit === 'FAHRENHEIT') {
            result = value * 1.8 + 32;
        } else if (sourceUnit === 'FAHRENHEIT' && targetUnit === 'CELSIUS') {
            result = (value - 32) / 1.8;
        }
        // OTHERS
        else {
            result = value;
        }

        return result;
    };
}

const unitService: UnitService = new UnitService();
export default unitService;
