import React, { Component } from 'react';
import { Button, Result } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { UserToken } from '../../../model/model';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import alertApi from '../../../api/AlertApi';
import Loader from '../../Loader/Loader';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

class AlertCancellationPage extends Component<RouteComponentProps & WrappedComponentProps, State> {
    constructor(props: RouteComponentProps & WrappedComponentProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        try {
            const params: URLSearchParams = new URLSearchParams(window.location.search);
            const id: number = (params.get('id') as unknown) as number;
            const token: string = (params.get('token') as unknown) as string;
            const userToken: UserToken = { token };
            await alertApi.cancel(id, userToken);
            this.setState({ status: 'success' });
        } catch (error) {
            this.setState({ status: 'failure' });
        }
    };

    /** COMPONENTS **/

    renderSuccess = (): JSX.Element => {
        return (
            <Result
                status="success"
                title={<FormattedMessage id="alertCancellation.success.title" />}
                subTitle={<FormattedMessage id="alertCancellation.success.subtitle" />}
                extra={[
                    <Link to="/" key="home">
                        <Button type="primary" size="large">
                            <FormattedMessage id="alertCancellation.success.button" />
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    renderFailure = (): JSX.Element => {
        return (
            <Result
                status="warning"
                title={<FormattedMessage id="alertCancellation.failure.title" />}
                subTitle={<FormattedMessage id="alertCancellation.failure.subtitle" />}
                extra={[
                    <Link to="/" key="home">
                        <Button type="primary" size="large">
                            <FormattedMessage id="alertCancellation.failure.button" />
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    renderAlertCancellation = (): JSX.Element => {
        if (this.state.status === 'success') {
            return this.renderSuccess();
        } else if (this.state.status === 'failure') {
            return this.renderFailure();
        } else {
            return <Loader message={this.props.intl.formatMessage({ id: 'alertCancellation.loading.title' })} />;
        }
    };

    render() {
        return (
            <>
                <HeadMetadata />
                {this.renderAlertCancellation()}
            </>
        );
    }
}
export default injectIntl(withRouter(AlertCancellationPage));

interface State {
    status?: 'success' | 'failure';
}
