import axios, { AxiosResponse } from 'axios';
import logService from './LogService';

class LocationService {
    country?: string;

    getCountry = async (): Promise<string | undefined> => {
        try {
            if (!this.country) {
                const ip: string = await this.getIpAddress();
                const location: any = await this.getGeoLocation(ip);
                this.country = location.country;
            }
        } catch (error) {
            logService.warn('location - error getting location via ip address');
        }
        return this.country;
    };

    private getIpAddress = async (): Promise<string> => {
        const response: AxiosResponse<any> = await axios.get('https://api.ipify.org', {
            params: { format: 'json' },
        });
        return response.data.ip;
    };

    private getGeoLocation = async (ip: string): Promise<Location> => {
        const response: AxiosResponse<Location> = await axios.get(`https://ipapi.co/${ip}/json`);
        return response.data;
    };
}

const locationServiceService = new LocationService();
export default locationServiceService;
