import React, { Component } from 'react';
import { Button, Result } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import userApi from '../../../api/UserApi';
import { UserToken } from '../../../model/model';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import Loader from '../../Loader/Loader';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

class EmailVerificationPage extends Component<RouteComponentProps & WrappedComponentProps, State> {
    constructor(props: RouteComponentProps & WrappedComponentProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        try {
            ga.createDefaultEvent('email verification', 'email verification - init');
            const params: URLSearchParams = new URLSearchParams(window.location.search);
            const token: string | undefined =
                params.get('token') === null ? undefined : (params.get('token') as string);
            const userToken: UserToken = { token };
            await userApi.verifyEmail(userToken);
            this.setState({ status: 'success' });
            ga.createDefaultEvent('email verification', 'email verification - success');
        } catch (error) {
            this.setState({ status: 'failure' });
            ga.createDefaultEvent('email verification', 'email verification - error');
        }
    };

    /** COMPONENTS **/

    renderSuccess = (): JSX.Element => {
        return (
            <Result
                status="success"
                title={<FormattedMessage id="emailVerification.success.title" />}
                subTitle={<FormattedMessage id="emailVerification.success.subtitle" />}
                extra={[
                    <Link to="/signin" key="signin">
                        <Button type="primary" size="large">
                            <FormattedMessage id="emailVerification.success.button" />
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    renderFailure = (): JSX.Element => {
        return (
            <Result
                status="warning"
                title={<FormattedMessage id="emailVerification.failure.title" />}
                subTitle={<FormattedMessage id="emailVerification.failure.subtitle" />}
                extra={[
                    <Link to="/signup" key="signup">
                        <Button type="primary" size="large">
                            <FormattedMessage id="emailVerification.failure.button" />
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    renderEmailVerification = (): JSX.Element => {
        if (this.state.status === 'success') {
            return this.renderSuccess();
        } else if (this.state.status === 'failure') {
            return this.renderFailure();
        } else {
            return <Loader message={this.props.intl.formatMessage({ id: 'emailVerification.loading.title' })} />;
        }
    };

    render() {
        return (
            <>
                <HeadMetadata />
                {this.renderEmailVerification()}
            </>
        );
    }
}
export default injectIntl(withRouter(EmailVerificationPage));

interface State {
    status?: 'success' | 'failure';
}
