import { CustomType, Person, User, DocumentType, Document } from '../model/model';
import i18nService from './I18nService';
import settingsService from './SettingsService';
import { Moment } from 'moment';
import dateService from './DateService';
import unitService from './UnitService';

class DocumentService {
    getUnclassified = (): CustomType => {
        return {
            value: 'UNCLASSIFIED',
            label: i18nService.intl.formatMessage({ id: 'document.medicalSpeciality.unclassified' }),
        };
    };

    formatReport = (template: string, user: User, person: Person, date: Moment): string => {
        const format = 'D MMMM YYYY';
        const personGender = person.gender && settingsService.getGenderByValue(person.gender).label;
        const personBirthdate = person.birthdate && person.birthdate.format(format);
        const personAge = dateService.getPeriod(person.birthdate, date);

        let report: string = template;
        report = report.split('#date#').join(date.format(format));
        report = report.split('#user.email#').join(user.email);
        report = report.split('#user.firstName#').join(user.firstName);
        report = report.split('#user.lastName#').join(user.lastName);
        report = report.split('#user.username#').join(user.username);
        report = report.split('#person.firstName#').join(person.firstName);
        report = report.split('#person.lastName#').join(person.lastName);
        report = report.split('#person.birthdate#').join(personBirthdate);
        report = report.split('#person.age#').join(personAge);
        report = report.split('#person.gender#').join(personGender);

        return report;
    };

    getMedicalSpeciality = (medicalSpeciality?: string): CustomType | undefined => {
        let medicalSpecialityType: CustomType | undefined;
        if (medicalSpeciality && medicalSpeciality !== this.getUnclassified().value) {
            medicalSpecialityType = settingsService.getMedicalSpecialityByValue(medicalSpeciality);
        }

        return medicalSpecialityType;
    };

    getVirtualMedicalSpeciality = (medicalSpeciality?: string): CustomType => {
        let medicalSpecialityType: CustomType;
        if (medicalSpeciality && medicalSpeciality !== this.getUnclassified().value) {
            medicalSpecialityType = settingsService.getMedicalSpecialityByValue(medicalSpeciality);
        } else {
            medicalSpecialityType = this.getUnclassified();
        }

        return medicalSpecialityType;
    };

    getMedicalSpecialityFromParameters = (): string | undefined => {
        let medicalSpeciality: string | undefined = this.getVirtualMedicalSpecialityFromParameters();
        return medicalSpeciality === this.getUnclassified().value ? undefined : medicalSpeciality;
    };

    getVirtualMedicalSpecialityFromParameters = (): string | undefined => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        return params.get('speciality') === null ? undefined : (params.get('speciality') as string);
    };

    getTruncatedDocumentFileName = (filename: string, length: number): string => {
        return filename.length > length ? filename.substring(0, length - 3) + '...' : filename;
    };

    getDocumentFileIcon = (contentType: string | undefined): string => {
        let icon: string = 'file';
        if (contentType && contentType.startsWith('image/')) {
            icon = 'file-image';
        } else if (contentType && contentType === 'application/pdf') {
            icon = 'file-pdf';
        } else if (
            contentType &&
            [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
            ].includes(contentType)
        ) {
            icon = 'file-word';
        } else if (contentType && contentType === 'text/plain') {
            icon = 'file-text';
        }
        return icon;
    };

    getDocumentTypeName = (documentType: DocumentType): string => {
        let label: string;
        switch (documentType) {
            case 'REPORT':
                label = i18nService.intl.formatMessage({ id: 'document.documentType.report' });
                break;
            case 'LABORATORY':
                label = i18nService.intl.formatMessage({ id: 'document.documentType.laboratory' });
                break;
            case 'RADIOLOGY':
                label = i18nService.intl.formatMessage({ id: 'document.documentType.radiology' });
                break;
            case 'MANUAL':
                label = i18nService.intl.formatMessage({ id: 'document.documentType.manual' });
                break;
            default:
                label = '';
                break;
        }
        return label;
    };

    getDocumentTypeColor = (documentType: DocumentType): string | undefined => {
        let color: string | undefined;
        switch (documentType) {
            case 'REPORT':
                color = 'rgba(75, 64, 122, 0.65)';
                break;
            case 'LABORATORY':
                color = 'rgba(154, 127, 168, 0.65)';
                break;
            case 'RADIOLOGY':
                color = 'rgba(77, 94, 126, 0.65)';
                break;
            case 'MANUAL':
                color = 'rgba(157, 150, 216, 0.65)';
                break;
            default:
                break;
        }
        return color;
    };

    getDefaultDocumentName = (document: Document): string => {
        let name: string = '';
        switch (document.documentType) {
            case 'REPORT':
                name = i18nService.intl.formatMessage({ id: 'document.documentType.report' });
                break;
            case 'LABORATORY':
                name = i18nService.intl.formatMessage({ id: 'document.documentType.laboratory' });
                break;
            case 'RADIOLOGY':
                name = i18nService.intl.formatMessage({ id: 'document.documentType.radiology' });
                break;
            case 'MANUAL':
                name = i18nService.intl.formatMessage({ id: 'document.documentType.report' });
                break;
            default:
                break;
        }
        let speciality = this.getMedicalSpeciality(document.medicalSpeciality);
        if (speciality) {
            name = `${name} - ${speciality.label}`;
        }

        return name;
    };

    getFormattedSize = (sizeInBytes: number): string => {
        let formattedSize;
        if (sizeInBytes > 1024 * 1024 * 1024) {
            const size = unitService.formatNumber(sizeInBytes / 1024 / 1024 / 1024, 0);
            formattedSize = `${size} GB`;
        } else if (sizeInBytes > 1024 * 1024) {
            const size = unitService.formatNumber(sizeInBytes / 1024 / 1024, 0);
            formattedSize = `${size} MB`;
        } else if (sizeInBytes > 1024) {
            const size = unitService.formatNumber(sizeInBytes / 1024, 0);
            formattedSize = `${size} KB`;
        } else {
            formattedSize = `${sizeInBytes} bytes`;
        }

        return formattedSize;
    };
}

const documentService = new DocumentService();
export default documentService;
