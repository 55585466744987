import React, { Component } from 'react';
import styles from './ContactUsPage.module.scss';
import { FormattedMessage } from 'react-intl';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import { Row, Col } from 'antd';

export default class ContactUsPage extends Component {
    render() {
        return (
            <>
                <HeadMetadata titleKey="footer.contactUs" />
                <div className={styles.contact}>
                    <div className="panel-header">
                        <h1>
                            <FormattedMessage id="contactUs.title" />
                        </h1>
                    </div>
                    <Row type="flex" justify="center" align="top">
                        <Col xs={24} lg={12} xl={12} className={styles.message}>
                            <p>
                                <FormattedMessage id="contactUs.1" />
                            </p>
                            <p>
                                <span>
                                    <FormattedMessage id="contactUs.2" />
                                </span>{' '}
                                <a href="mailto:support@walfen.com">support@walfen.com</a>
                            </p>
                        </Col>
                        <Col xs={24} lg={12} xl={12} className={styles.image}>
                            <div>
                                <img src="/images/contact.png" alt="contact" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
