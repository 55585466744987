import React, { Component } from 'react';
import { message, Form, Input, Avatar, List, Button } from 'antd';
import { ActionStatus, Subscription, PaymentMethod } from '../../../../model/model';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import { FormComponentProps } from 'antd/lib/form';
import StripePayment from '../../../Shared/StripePayment/StripePayment';
import paymentMethodApi from '../../../../api/PaymentMethodApi';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js';

class SubscriptionPaymentChange extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    save = async (values: any): Promise<PaymentMethod | undefined> => {
        let paymentMethod: PaymentMethod | undefined;
        if (this.props.stripe && this.props.elements) {
            // get payment method from stripe
            const cardElement = this.props.elements.getElement(CardElement) as StripeCardElement;
            const response = await this.props.stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: values.cardholderName,
                },
            });

            // create payment method (it deletes existing one)
            if (response.paymentMethod) {
                paymentMethod = { id: response.paymentMethod.id };
                paymentMethod = await paymentMethodApi.create(paymentMethod);
            }
        }

        return paymentMethod;
    };

    isSubscriptionActive = () => this.props.subscription && !this.props.subscription.cancelAt;

    /** HANDLERS **/

    handleTogglePaymentMethodForm = (): void => {
        const paymentMethodFormVisible: boolean = this.state.paymentMethodFormVisible ? false : true;
        this.setState({ paymentMethodFormVisible });
    };

    handleSave = async (): Promise<void> => {
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const paymentMethod: PaymentMethod | undefined = await this.save(values);
                    if (paymentMethod) {
                        message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                        await this.props.handlePaymentMethodChange();
                        this.setState({ paymentMethodFormVisible: false });
                    }
                }
            } catch (error) {
                errorService.displayMessage(error, [[402, 'common.notification.paymentError']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    /** COMPONENTS **/

    renderPaymentMethods = (): JSX.Element => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={this.props.paymentMethods}
                renderItem={paymentMethod => (
                    <List.Item
                        actions={[
                            <Button key="replace" onClick={this.handleTogglePaymentMethodForm}>
                                <FormattedMessage id="common.change" />
                            </Button>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar icon="credit-card" />}
                            title={paymentMethod.name}
                            description={`**** **** **** ${paymentMethod.last4} ${paymentMethod.expMonth}/${paymentMethod.expYear}`}
                        />
                    </List.Item>
                )}
            />
        );
    };

    renderPaymentMethodForm = (): JSX.Element => {
        if (this.state.paymentMethodFormVisible) {
            const { getFieldDecorator } = this.props.form;
            return (
                <Form>
                    <Form.Item label={<FormattedMessage id="subscription.paymentChange.card.name" />}>
                        {getFieldDecorator('cardholderName', {
                            rules: [
                                {
                                    required: true,
                                    message: <FormattedMessage id="subscription.paymentChange.card.name.required" />,
                                },
                            ],
                        })(<Input size="large" maxLength={50} />)}
                    </Form.Item>

                    <StripePayment
                        buttonLabel="subscription.paymentChange.button"
                        handleSave={this.handleSave}
                        actionStatus={this.state.actionStatus}
                    />
                </Form>
            );
        } else {
            return <></>;
        }
    };

    render() {
        if (this.isSubscriptionActive()) {
            return (
                <>
                    <h2>
                        <FormattedMessage id={'subscription.paymentChange.title'} />
                    </h2>
                    {this.renderPaymentMethods()}
                    {this.renderPaymentMethodForm()}
                </>
            );
        } else {
            return <></>;
        }
    }
}
export default injectIntl(Form.create<Props>()(SubscriptionPaymentChange));

interface Props extends FormComponentProps, WrappedComponentProps {
    subscription: Subscription;
    paymentMethods: PaymentMethod[];
    stripe: Stripe;
    elements: StripeElements;
    handlePaymentMethodChange: () => Promise<void>;
}

interface State {
    paymentMethodFormVisible?: boolean;
    actionStatus?: ActionStatus;
}
