import React, { Component } from 'react';
import styles from './CommunityMenuComponent.module.scss';
import { FormattedMessage } from 'react-intl';
import { CommunityMenuType, MedicalConditionType, ScreenSizeProps } from '../../../model/model';
import { Link } from 'react-router-dom';
import withSizes from 'react-sizes';
import responsiveService from '../../../service/ResponsiveService';
import { Dropdown, Menu, Icon } from 'antd';
import pathService from '../../../service/PathService';
import { KeyPath } from '../../../model/paths';
class CommunityMenuComponent extends Component<Props> {
    getMenuItemUrl = (type: CommunityMenuType, condition?: MedicalConditionType): string => {
        let url: string = pathService.getPath(type as KeyPath);
        if (condition) {
            url = url + '/' + condition.path;
        }
        return url;
    };

    /** COMPONENTS **/

    renderMenuItem = (type: CommunityMenuType, disabled?: boolean): JSX.Element => {
        if (disabled) {
            return (
                <span className={styles.disabled}>
                    <FormattedMessage id={`community.menu.${type}`} />
                </span>
            );
        } else {
            return (
                <Link
                    to={this.getMenuItemUrl(type, this.props.condition)}
                    className={this.props.selected === type ? styles.selected : ''}
                >
                    <FormattedMessage id={`community.menu.${type}`} />
                </Link>
            );
        }
    };

    render() {
        if (this.props.isLg) {
            return (
                <ul className={styles.menu}>
                    <li>{this.renderMenuItem('posts')}</li>
                    <li>{this.renderMenuItem('questions')}</li>
                    <li>{this.renderMenuItem('doctors')}</li>
                    <li>{this.renderMenuItem('members')}</li>
                    <li>{this.renderMenuItem('researchers')}</li>
                    <li>{this.renderMenuItem('foundations', true)}</li>
                </ul>
            );
        } else if (this.props.isMd || this.props.isSm) {
            return (
                <ul className={styles.menu}>
                    <li>{this.renderMenuItem('posts')}</li>
                    <li>{this.renderMenuItem('questions')}</li>
                    <li>{this.renderMenuItem('doctors')}</li>
                    <li>
                        <Dropdown
                            placement="bottomRight"
                            overlay={
                                <Menu>
                                    <Menu.Item key="members">{this.renderMenuItem('members')}</Menu.Item>
                                    <Menu.Item key="researchers">{this.renderMenuItem('researchers')}</Menu.Item>
                                    <Menu.Item key="foundations">{this.renderMenuItem('foundations', true)}</Menu.Item>
                                </Menu>
                            }
                        >
                            <span>
                                <FormattedMessage id="community.menu.others" /> <Icon type="down" />
                            </span>
                        </Dropdown>
                    </li>
                </ul>
            );
        } else {
            return (
                <ul className={styles.menu}>
                    <li>{this.renderMenuItem('posts')}</li>
                    <li>{this.renderMenuItem('questions')}</li>
                    <li>
                        <Dropdown
                            placement="bottomRight"
                            overlay={
                                <Menu>
                                    <Menu.Item key="doctors">{this.renderMenuItem('doctors')}</Menu.Item>
                                    <Menu.Item key="members">{this.renderMenuItem('members')}</Menu.Item>
                                    <Menu.Item key="researchers">{this.renderMenuItem('researchers')}</Menu.Item>
                                    <Menu.Item key="foundations">{this.renderMenuItem('foundations', true)}</Menu.Item>
                                </Menu>
                            }
                        >
                            <span>
                                <FormattedMessage id="community.menu.others" /> <Icon type="down" />
                            </span>
                        </Dropdown>
                    </li>
                </ul>
            );
        }
    }
}
export default withSizes(responsiveService.mapSizesToProps)(CommunityMenuComponent);

interface Props extends ScreenSizeProps {
    selected: CommunityMenuType;
    condition: MedicalConditionType;
}
