import {
    Person,
    UserChart,
    StandardChart,
    TemplateChart,
    ChartType,
    TemplateChartType,
    Page,
    StandardSeries,
} from '../model/model';
import personApi from '../api/PersonApi';
import userChartApi from '../api/UserChartApi';
import standardChartApi from '../api/StandardChartApi';
import moment, { Moment } from 'moment';
import dateService from './DateService';
import unitService from './UnitService';
import bmiService from './BmiService';

class ChartTemplateService {
    listTemplateCharts = async (language: string): Promise<TemplateChart[]> => {
        let templateCharts: TemplateChart[] = [];

        // get persons
        const personsPage: Page<Person> = await personApi.list(0, 1000); // TODO: add filter for persons pagination
        const persons: Person[] = personsPage.content;

        // get standard charts
        const standardCharts: StandardChart[] = await standardChartApi.list(language);
        const standardTemplateCharts: TemplateChart[] = standardCharts.map(sc => {
            return { id: `standard-${sc.id}`, type: 'standard', chart: sc, name: sc.name };
        });
        templateCharts = templateCharts.concat(standardTemplateCharts);

        // get user charts
        const userCharts: UserChart[] = await userChartApi.list();
        const userTemplateCharts: TemplateChart[] = userCharts.map(uc => {
            const person: Person = persons.find(p => p.id === uc.personId) as Person;
            return {
                id: `user-${uc.id}`,
                type: 'user',
                chart: uc,
                name: person.fullName as string,
                birthdate: person.birthdate,
            };
        });
        templateCharts = templateCharts.concat(userTemplateCharts);
        templateCharts = templateCharts.sort((a, b) => a.name.localeCompare(b.name));

        return templateCharts;
    };

    listTemplateChartsByType = (
        templateCharts: TemplateChart[],
        templateChartType: TemplateChartType,
    ): TemplateChart[] => {
        return templateCharts.filter(tc => tc.type === templateChartType);
    };

    getTemplateChartById = (templateCharts: TemplateChart[], templateChartId?: string): TemplateChart | undefined => {
        return templateCharts.find(tc => tc.id === templateChartId);
    };

    getTemplateChartIdByUserChart = (userChart: UserChart): string | undefined => {
        let templateChartId: string | undefined;
        if (userChart.standardChartId) {
            templateChartId = `standard-${userChart.standardChartId}`;
        } else if (userChart.personChartId) {
            templateChartId = `user-${userChart.personChartId}`;
        }
        return templateChartId;
    };

    getTemplateChartWithMeasures = async (
        measurementSystem: string,
        language: string,
        person: Person,
        templateChart?: TemplateChart,
    ): Promise<TemplateChart | undefined> => {
        let templateChartWithMeasures: TemplateChart | undefined;
        if (templateChart && templateChart.type === 'standard') {
            templateChartWithMeasures = await this.getStandardTemplateChartWithMeasures(
                measurementSystem,
                language,
                person,
                templateChart,
            );
            const adultSeries = chartTemplateService.listStandardAdultSeries(templateChartWithMeasures.chart.chartType);
            templateChartWithMeasures.chart.series = templateChartWithMeasures.chart.series.concat(adultSeries);
        } else if (templateChart && templateChart.type === 'user') {
            templateChartWithMeasures = await this.getUserTemplateChartWithMeasures(
                measurementSystem,
                person,
                templateChart,
            );
        }

        return templateChartWithMeasures;
    };

    getStandardTemplateChartWithMeasures = async (
        measurementSystem: string,
        language: string,
        person: Person,
        templateChart: TemplateChart,
    ): Promise<TemplateChart> => {
        const maxDate: Moment = dateService.calculateMaxDate(
            person.birthdate as Moment,
            moment().utc(),
            templateChart.chart.chartType,
        );
        const defaultSeries = this.listDefaultSeries(templateChart.chart.chartType);
        let templateStandardChart: StandardChart = await standardChartApi.get(
            templateChart.chart.id,
            language,
            person.birthdate as Moment,
            maxDate,
            person.id,
            defaultSeries,
        );
        templateStandardChart = unitService.convertChart(templateStandardChart, measurementSystem) as StandardChart;
        const templateChartWithMeasures: TemplateChart = Object.assign({}, templateChart, {
            chart: templateStandardChart,
        });

        return templateChartWithMeasures;
    };

    listDefaultSeries = (chartType?: string): string[] => {
        let defaultSeries: string[] = [];
        if (chartType && ['HEIGHT', 'WEIGHT', 'BMI', 'HEAD_CIRCUMFERENCE', 'ARM_CIRCUMFERENCE'].includes(chartType)) {
            defaultSeries = ['P3', 'P15', 'P50', 'P85', 'P97', 'SD3neg', 'SD2neg', 'SD0', 'SD2', 'SD3'];
        }
        return defaultSeries;
    };

    getUserTemplateChartWithMeasures = async (
        measurementSystem: string,
        person: Person,
        templateChart: TemplateChart,
    ): Promise<TemplateChart> => {
        const birthdate: Moment = person.birthdate as Moment;
        const personBirthdate: Moment = templateChart.birthdate as Moment;
        const difference: number = birthdate.diff(personBirthdate, 'days');
        let templateUserChart: UserChart = await userChartApi.get(templateChart.chart.id);
        templateUserChart = unitService.convertChart(templateUserChart, measurementSystem) as UserChart;
        templateUserChart.series[0].measures.forEach(m => (m.date = (m.date as Moment).add(difference, 'days')));
        const templateChartWithMeasures: TemplateChart = Object.assign({}, templateChart, {
            chart: templateUserChart,
        });
        return templateChartWithMeasures;
    };

    updateUserChartTemplate = async (userChart: UserChart, templateChart: TemplateChart | undefined): Promise<void> => {
        let selectedUserChart: UserChart = Object.assign({}, userChart, {
            series: null,
            standardChartId: templateChart && templateChart.type === 'standard' ? templateChart.chart.id : null,
            personChartId: templateChart && templateChart.type === 'user' ? templateChart.chart.id : null,
        });
        if (
            userChart.standardChartId !== selectedUserChart.standardChartId ||
            userChart.personChartId !== selectedUserChart.personChartId
        ) {
            await userChartApi.update(selectedUserChart);
        }
    };

    getUserChartFromTemplateCharts = (
        templateCharts: TemplateChart[],
        chartType: ChartType,
        person: Person,
    ): UserChart => {
        const templateUserCharts: TemplateChart[] = this.listTemplateChartsByType(templateCharts, 'user');
        const userCharts: UserChart[] = templateUserCharts.map(tc => tc.chart as UserChart);
        return userCharts.find(uc => uc.chartType === chartType.value && uc.personId === person.id) as UserChart;
    };

    listStandardAdultSeries = (chartType: string): StandardSeries[] => {
        const standardAdultSeries: StandardSeries[] = [];
        if (chartType === 'BMI') {
            const bmiLevels = bmiService.listBmiLevels();
            for (const bmiLevel of bmiLevels) {
                standardAdultSeries.push({
                    name: bmiLevel.level,
                    measures: [
                        'P18Y',
                        'P20Y',
                        'P25Y',
                        'P30Y',
                        'P35Y',
                        'P40Y',
                        'P45Y',
                        'P50Y',
                        'P60Y',
                        'P70Y',
                        'P80Y',
                        'P90Y',
                    ].map((period: string) => {
                        return { id: 0, period, value: bmiLevel.value, unit: 'KG_M2' };
                    }),
                });
            }
        }

        return standardAdultSeries;
    };
}

const chartTemplateService: ChartTemplateService = new ChartTemplateService();
export default chartTemplateService;
