import React, { Component, FormEvent } from 'react';
import styles from './SubscriptionUpgrade.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Subscription, ActionStatus, Plan } from '../../../../model/model';
import errorService from '../../../../service/ErrorService';
import subscriptionApi from '../../../../api/SubscriptionApi';
import { Button, Checkbox } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Link } from 'react-router-dom';
import pathService from '../../../../service/PathService';

class SubscriptionUpgrade extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    upgrade = async (): Promise<Subscription> => {
        const subscription: Subscription = Object.assign({}, this.props.subscription, {
            planId: this.props.plan.id,
            taxRate: undefined,
        });
        return await subscriptionApi.update(subscription);
    };

    /** HANDLERS **/

    handleUpgrade = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const subscription: Subscription = await this.upgrade();
                    this.setState({ actionStatus: undefined });

                    this.props.handleUpgrade(subscription);
                }
            } catch (error) {
                this.setState({ actionStatus: undefined });
                errorService.displayMessage(error);
            }
        });
    };

    /** COMPONENTS **/

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <Form.Item className={styles.legal}>
                    {getFieldDecorator('legal', {
                        valuePropName: 'checked',
                        initialValue: false,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="subscription.consent.error.required" />,
                                transform: value => value || undefined,
                                type: 'boolean',
                            },
                        ],
                    })(
                        <Checkbox>
                            <FormattedMessage id="subscription.consent" />{' '}
                            <Link to={pathService.getPath('terms-of-contract')} className={styles.legal}>
                                <FormattedMessage id="subscription.termsOfContract" />
                            </Link>
                        </Checkbox>,
                    )}
                </Form.Item>
                <Button
                    type="primary"
                    size="large"
                    className={styles.button}
                    onClick={this.handleUpgrade}
                    loading={this.state.actionStatus === 'saving'}
                >
                    <FormattedMessage id="subscription.upgrade.button" />
                </Button>
            </Form>
        );
    }
}
export default injectIntl(Form.create<Props>()(SubscriptionUpgrade));

interface Props extends FormComponentProps, WrappedComponentProps {
    plan: Plan;
    subscription: Subscription;
    handleUpgrade: (subscription: Subscription) => void;
}

interface State {
    actionStatus?: ActionStatus;
}
