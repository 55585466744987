import React, { Component } from 'react';
import styles from './PersonComponent.module.scss';
import { Layout, Row, Col, Card, Icon } from 'antd';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router';
import NotFoundPage from '../../errors/NotFoundPage/NotFoundPage';
import BiometricsPage from './BiometricsPage/BiometricsPage';
import CustomContext from '../../service/CustomContext';
import DocumentsPage from './DocumentsPage/DocumentsPage';
import DocumentPage from './DocumentsPage/DocumentPage/DocumentPage';
import LinksPage from './LinksPage/LinksPage';
import ProfilePage from './ProfilePage/ProfilePage';
import { Person, Status, ScreenSizeProps, User } from '../../model/model';
import personApi from '../../api/PersonApi';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import withSizes from 'react-sizes';
import responsiveService from '../../service/ResponsiveService';
import { Link } from 'react-router-dom';
import LinkPage from './LinksPage/LinkPage/LinkPage';
import Meta from 'antd/lib/card/Meta';
import DocumentsBySpecialityPage from './DocumentsPage/DocumentsBySpecialityPage/DocumentsBySpecialityPage';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import PersonHeader from './PersonHeader/PersonHeader';
import DiaryMonthPage from './DiaryPage/DiaryMonthPage/DiaryMonthPage';
import DiaryDatePage from './DiaryPage/DiaryDatePage/DiaryDatePage';
import DiaryRecordPage from './DiaryPage/DiaryRecordPage/DiaryRecordPage';
import moment from 'moment';
import FormattedMessageComponent from '../Shared/FormattedMessageComponent';
import ChatPage from './ChatPage/ChatPage';
import ChatsPage from './ChatsPage/ChatsPage';
import dateService from '../../service/DateService';
import settingsService from '../../service/SettingsService';
import userService from '../../service/UserService';
import DiaryAppointmentPage from './DiaryPage/DiaryAppointmentPage/DiaryAppointmentPage';
import DiaryMedicationPage from './DiaryPage/DiaryMedicationPage/DiaryMedicationPage';

class PersonComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            status: 'loading',
        };
    }

    async componentDidMount() {
        try {
            await this.init();
            this.setState({ status: undefined });
        } catch (error) {
            this.setState({ status: 'error' });
        }
    }

    /** METHODS **/

    init = async () => {
        let person: Person;
        if (this.props.id) {
            person = await personApi.get(this.props.id);
            userService.setSelectedPersonId(person.id as number);
        } else {
            const user: User = this.context.user as User;
            person = {
                entityId: user.entity!.id,
                address: {},
            };
        }
        const personsCount = await personApi.count();

        this.setState({ person, personsCount });
    };

    save = async (values: any): Promise<Person> => {
        // save person
        let person: Person = Object.assign({}, this.state.person, {
            firstName: values.firstName,
            lastName: values.lastName,
            birthdate: values.birthdate,
            gender: values.gender,
            email: values.email,
            phone: values.phone,
            idCard: values.idCard,
            healthCard: values.healthCard,
            profession: values.profession,
            address: {
                address: values.address,
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.postalCode,
            },
            identifier: values.identifier,
            redirected: values.redirected,
            background: values.background,
            remarks: values.remarks,
            tutorFirstName: values.tutorFirstName,
            tutorLastName: values.tutorLastName,
            tutorRelationship: values.tutorRelationship,
        });
        person = person.id ? await personApi.update(person) : await personApi.create(person);
        this.setState({ person });

        return person;
    };

    delete = async () => {
        const person: Person = this.state.person as Person;
        await personApi.delete(person);
        this.props.history.push('/persons');
    };

    getPersonDescription = (): string => {
        let description = '';
        if (this.state.person) {
            const format = dateService.getDateFormat(this.context.user);
            const birthdate = this.state.person.birthdate && this.state.person.birthdate.format(format);
            const gender = settingsService.getGenderByValue(this.state.person.gender as string);
            description = `${gender.label} - ${birthdate}`;
        }

        return description;
    };

    /** HANDLERS **/

    /** COMPONENTS **/

    renderDashboard = (person: Person): JSX.Element => {
        const id: number | string = this.props.id || 'new';
        const date = moment().format('YYYY-MM-DD');

        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={this.getPersonDescription()}
                />
                <Layout.Content>
                    <Row gutter={[16, 16]} className={styles.cards} type="flex">
                        <Col xs={12} sm={8} md={6} lg={6} xl={4} data-test="profile">
                            <Link to={`/persons/${id}/profile`}>
                                <Card className={`${styles.card} ${styles.profile}`}>
                                    <Icon type="user" />
                                    <Meta
                                        title={<FormattedMessage id="navigation.person" />}
                                        description={<FormattedMessageComponent id="navigation.person.desc" />}
                                        className={styles.meta}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={6} xl={4} data-test="biometrics">
                            <Link to={`/persons/${id}/biometrics`}>
                                <Card className={`${styles.card} ${styles.biometrics}`}>
                                    <Icon type="line-chart" />
                                    <Meta
                                        title={<FormattedMessage id="navigation.biometrics" />}
                                        description={<FormattedMessageComponent id="navigation.biometrics.desc" />}
                                        className={styles.meta}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={6} xl={4} data-test="documents">
                            <Link to={`/persons/${id}/documents/specialities`}>
                                <Card className={`${styles.card} ${styles.documents}`}>
                                    <Icon type="file" />
                                    <Meta
                                        title={<FormattedMessage id="navigation.documents" />}
                                        description={<FormattedMessageComponent id="navigation.documents.desc" />}
                                        className={styles.meta}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={6} xl={4} data-test="diary">
                            <Link to={`/persons/${id}/diary/months/${date}`}>
                                <Card className={`${styles.card} ${styles.diary}`}>
                                    <Icon type="read" />
                                    <Meta
                                        title={<FormattedMessage id="navigation.diary" />}
                                        description={<FormattedMessageComponent id="navigation.diary.desc" />}
                                        className={styles.meta}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={6} xl={4} data-test="chats">
                            <Link to={`/persons/${id}/chats`}>
                                <Card className={`${styles.card} ${styles.chats}`}>
                                    <Icon type="message" />
                                    <Meta
                                        title={<FormattedMessage id="navigation.chats" />}
                                        description={<FormattedMessageComponent id="navigation.chats.desc" />}
                                        className={styles.meta}
                                    />
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={12} sm={8} md={6} lg={6} xl={4} data-test="personLinks">
                            <Link to={`/persons/${id}/links`}>
                                <Card className={`${styles.card} ${styles.links}`}>
                                    <Icon type="share-alt" />
                                    <Meta
                                        title={<FormattedMessageComponent id="navigation.links" />}
                                        description={<FormattedMessageComponent id="navigation.links.desc" />}
                                        className={styles.meta}
                                    />
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </Layout.Content>
            </>
        );
    };

    renderPerson = (): JSX.Element => {
        if (this.state.status === 'loading') {
            return <></>;
        } else if (this.state.status === 'error') {
            return <NotFoundPage />;
        } else {
            const person: Person = this.state.person as Person;
            const id: number | string = this.props.id || 'new';

            return (
                <Layout className={styles.layout}>
                    <Switch>
                        <Route exact path={`/persons/${id}/profile`} render={() => this.renderProfile(person)} />
                        <Route
                            exact
                            path={`/persons/${id}/diary/months/:date`}
                            render={() => this.renderDiaryRecordsByMonth(person)}
                        />
                        <Route
                            exact
                            path={`/persons/${id}/diary/dates/:date`}
                            render={() => this.renderDiaryRecordsByDate(person)}
                        />
                        <Route
                            exact
                            path={`/persons/${id}/diary/records/:id`}
                            render={() => this.renderDiaryRecord(person)}
                        />
                        <Route
                            exact
                            path={`/persons/${id}/diary/appointments/:id`}
                            render={() => this.renderDiaryAppointment(person)}
                        />
                        <Route
                            exact
                            path={`/persons/${id}/diary/medications/:id`}
                            render={() => this.renderDiaryMedication(person)}
                        />
                        <Route exact path={`/persons/${id}/biometrics`} render={() => this.renderBiometrics(person)} />
                        <Route exact path={`/persons/${id}/documents`} render={() => this.renderDocuments(person)} />
                        <Route
                            exact
                            path={`/persons/${id}/documents/specialities`}
                            render={() => this.renderDocumentsBySpeciality(person)}
                        />
                        <Route exact path={`/persons/${id}/documents/:id`} render={() => this.renderDocument(person)} />
                        <Route exact path={`/persons/${id}/links`} render={() => this.renderLinks(person)} />
                        <Route exact path={`/persons/${id}/links/:id`} render={() => this.renderLink(person)} />
                        <Route exact path={`/persons/${id}/chats`} render={() => this.renderChats(person)} />
                        <Route exact path={`/persons/${id}/chats/:id`} render={() => this.renderChat(person)} />
                        <Route exact path={`/persons/${id}`} render={() => this.renderDashboard(person)} />
                        <Route
                            exact
                            path="/dashboard"
                            render={() => (this.props.id ? this.renderDashboard(person) : this.renderProfile(person))}
                        />
                    </Switch>
                </Layout>
            );
        }
    };

    renderProfile = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.person.desc" />}
                />
                <ProfilePage
                    person={person}
                    personsCount={this.state.personsCount}
                    save={this.save}
                    delete={this.delete}
                />
            </>
        );
    };

    renderDiaryRecordsByMonth = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.diary.desc" />}
                />
                <DiaryMonthPage person={person} />
            </>
        );
    };

    renderDiaryRecordsByDate = (person: Person): JSX.Element => {
        const regex = new RegExp(`/persons/${person.id}/diary/dates/(.*)$`);
        const results = regex.exec(window.location.pathname);
        let date = moment().format('YYYY-MM-DD');
        if (results && results.length > 1) {
            date = results[1];
        }
        const backUrl = `/persons/${person.id}/diary/months/${date}`;

        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.diary.desc" />}
                    backUrl={backUrl}
                />
                <DiaryDatePage person={person} />
            </>
        );
    };

    renderDiaryRecord = (person: Person): JSX.Element => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);

        let backUrl: string | undefined;
        if (params.has('diaryTemplateType')) {
            backUrl = `/persons/${person.id}/diary/records/new?date=${params.get('date')}`;
        } else {
            backUrl = `/persons/${person.id}/diary/dates/${params.get('date')}`;
        }

        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.diary.desc" />}
                    backUrl={backUrl}
                />
                <DiaryRecordPage person={person} />
            </>
        );
    };

    renderDiaryAppointment = (person: Person): JSX.Element => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);

        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.diary.desc" />}
                    backUrl={`/persons/${person.id}/diary/dates/${params.get('date')}`}
                />
                <DiaryAppointmentPage person={person} />
            </>
        );
    };

    renderDiaryMedication = (person: Person): JSX.Element => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);

        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.diary.desc" />}
                    backUrl={`/persons/${person.id}/diary/dates/${params.get('date')}`}
                />
                <DiaryMedicationPage person={person} />
            </>
        );
    };

    renderBiometrics = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.biometrics.desc" />}
                />
                <BiometricsPage person={person} />
            </>
        );
    };

    renderDocuments = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.documents.desc" />}
                    backUrl={`/persons/${person.id}/documents/specialities`}
                />
                <DocumentsPage person={person} />
            </>
        );
    };

    renderDocumentsBySpeciality = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.documents.desc" />}
                />
                <DocumentsBySpecialityPage person={person} />
            </>
        );
    };

    renderDocument = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.documents.desc" />}
                    backUrl={`/persons/${person.id}/documents/specialities`}
                />
                <DocumentPage person={person} />
            </>
        );
    };

    renderLinks = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.links.desc" />}
                />
                <LinksPage person={person} />
            </>
        );
    };

    renderLink = (person: Person): JSX.Element => {
        const backUrl = `/persons/${person.id}/links`;
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.links.desc" />}
                    backUrl={backUrl}
                />
                <LinkPage person={person} />
            </>
        );
    };

    renderChats = (person: Person): JSX.Element => {
        return (
            <>
                <PersonHeader
                    person={person}
                    personsCount={this.state.personsCount}
                    description={<FormattedMessageComponent id="navigation.chats.desc" />}
                />
                <ChatsPage person={person} />
            </>
        );
    };

    renderChat = (person: Person): JSX.Element => {
        return <ChatPage person={person} />;
    };

    render() {
        return (
            <>
                <HeadMetadata />
                {this.renderPerson()}
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(PersonComponent)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    id?: number;
}

interface State {
    person?: Person;
    personsCount?: number;
    status?: Status;
}
