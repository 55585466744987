import React, { Component, FormEvent } from 'react';
import styles from './TemplateComponent.module.scss';
import withSizes from 'react-sizes';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { message, Button, Drawer, Form, Popconfirm, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form';
import CustomContext from '../../../../../service/CustomContext';
import { Template, ActionStatus, ScreenSizeProps, User } from '../../../../../model/model';
import responsiveService from '../../../../../service/ResponsiveService';
import errorService from '../../../../../service/ErrorService';
import templateApi from '../../../../../api/TemplateApi';
import i18nService from '../../../../../service/I18nService';
import ReactQuill from 'react-quill';

class TemplateComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            template: {},
            content: '',
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        await this.init(this.props.templateId);
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.visible && this.props.visible !== prevProps.visible) {
            await this.init(this.props.templateId);
        }
    }

    /** METHODS **/

    init = async (templateId?: number): Promise<void> => {
        const user: User = this.context.user as User;
        let template: Template;
        let content: string;
        if (templateId) {
            template = await templateApi.get(templateId);
            content = template.content || '';
            this.props.form.setFieldsValue({
                name: template.name,
                content: template.content,
            });
        } else {
            template = {
                entityId: user.entity!.id,
                language: i18nService.getLocaleFromPath().toUpperCase(),
            };
            content = '';
            this.props.form.setFieldsValue({
                name: template.name,
                content: template.content,
            });
        }
        this.setState({ template, content });
    };

    save = async (values: any): Promise<Template> => {
        let template = Object.assign({}, this.state.template, {
            name: values.name,
            content: this.state.content || '<p></p>',
            shared: undefined,
        });

        return this.state.template.id ? await templateApi.update(template) : await templateApi.create(template);
    };

    delete = async (): Promise<void> => {
        await templateApi.delete(this.state.template);
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const template: Template = await this.save(values);
                    await message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);

                    this.init();
                    this.props.handleClose(true, template.id);
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'template.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            await message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);

            this.props.handleClose(true, this.props.templateId);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleClose = () => {
        this.props.handleClose();
    };

    handleContentChange = (content: string): void => {
        try {
            this.setState({ content });
        } catch (error) {
            errorService.displayMessage();
        }
    };

    /** COMPONENTS **/

    renderTemplateContent = (): JSX.Element => {
        const user: User = this.context.user as User;
        return (
            <>
                <Form.Item label={<FormattedMessage id="template.content" />} className={styles.empty} />
                <ReactQuill
                    value={this.state.content}
                    onChange={this.handleContentChange}
                    readOnly={this.props.readonly || this.state.template.entityId !== user.entity!.id}
                />
            </>
        );
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const user: User = this.context.user as User;
        const width: string = this.props.isLg ? '720' : '100vw';

        return (
            <Drawer
                title={<FormattedMessage id="template.title" />}
                placement="right"
                width={width}
                onClose={this.handleClose}
                visible={this.props.visible}
                className="root-drawer"
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Form>
                    <Form.Item label={<FormattedMessage id="template.name" />}>
                        {getFieldDecorator('name', {
                            rules: [
                                { required: true, message: <FormattedMessage id="template.name.error.required" /> },
                            ],
                        })(
                            <Input
                                size="large"
                                maxLength={100}
                                disabled={this.props.readonly || this.state.template.entityId !== user.entity!.id}
                            />,
                        )}
                    </Form.Item>
                </Form>

                {this.renderTemplateContent()}

                <div className={styles.buttons} hidden={this.props.readonly}>
                    <Popconfirm
                        title={<FormattedMessage id="template.confirm.delete" />}
                        onConfirm={this.handleDelete}
                        okText={<FormattedMessage id="common.ok" />}
                        cancelText={<FormattedMessage id="common.cancel" />}
                    >
                        <Button
                            type="link"
                            size="large"
                            loading={this.state.actionStatus === 'deleting'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                            className={styles.delete}
                            hidden={!this.state.template.id || this.state.template.entityId !== user.entity!.id}
                        >
                            <FormattedMessage id="common.delete" />
                        </Button>
                    </Popconfirm>
                    <Button size="large" onClick={this.handleClose}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        onClick={this.handleSave}
                        loading={this.state.actionStatus === 'saving'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                        hidden={this.state.template.entityId !== user.entity!.id}
                    >
                        <FormattedMessage id="common.save" />
                    </Button>
                </div>
            </Drawer>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(
    withRouter(injectIntl(Form.create<Props>()(TemplateComponent))),
);

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    templateId?: number;
    visible: boolean;
    readonly: boolean;
    handleClose: (refresh?: boolean, templateId?: number) => void;
}

interface State {
    template: Template;
    content: string;
    actionStatus?: ActionStatus;
}
