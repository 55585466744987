import React, { Component } from 'react';
import styles from './CalendarTemplatesComponent.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    DiaryTemplate,
    ActionStatus,
    Status,
    Page,
    Locale,
    ScreenSizeProps,
    Person,
    DiaryTemplateType,
    DiaryTemplatePerson,
} from '../../../../../model/model';
import { List, Button, message, Row, Col, Tooltip, Modal } from 'antd';
import errorService from '../../../../../service/ErrorService';
import diaryTemplateApi from '../../../../../api/DiaryTemplateApi';
import i18nService from '../../../../../service/I18nService';
import responsiveService from '../../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import AvatarComponent from '../../../AvatarComponent/AvatarComponent';
import diaryTemplatePersonApi from '../../../../../api/DiaryTemplatePersonApi';
import FormattedMessageComponent from '../../../FormattedMessageComponent';
import Loader from '../../../../Loader/Loader';

class CalendarTemplatesComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            diaryTemplates: [],
            diaryTemplatePersons: [],
            page: 0,
            last: true,
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (prevProps.diaryTemplateType !== this.props.diaryTemplateType) {
            try {
                this.setState({ status: 'loading' });
                await this.init();
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ status: undefined });
            }
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list(this.props.diaryTemplateType);
    };

    list = async (type?: DiaryTemplateType, page: number = 1, searchText?: string) => {
        // list diary templates
        const locale = i18nService.intl.locale as Locale;
        const personId = this.props.person ? this.props.person.id : undefined;
        const diaryTemplatesPages = await Promise.all([
            diaryTemplateApi.listStandards(page - 1, 500, locale, searchText),
            diaryTemplateApi.listUsers(page - 1, 500, searchText),
            personId ? diaryTemplateApi.listPersons(page - 1, 500, personId, searchText) : Promise.resolve(undefined),
        ]);

        // get diary template type
        let diaryTemplateType: DiaryTemplateType | undefined = this.props.diaryTemplateType;
        if (
            !diaryTemplateType &&
            (!diaryTemplatesPages[1] || diaryTemplatesPages[1].empty) &&
            (!diaryTemplatesPages[2] || diaryTemplatesPages[2].empty)
        ) {
            diaryTemplateType = 'standard';
        }

        // load selected diary templates
        let diaryTemplatesPage: Page<DiaryTemplate> | undefined;
        if (diaryTemplateType === 'standard') {
            diaryTemplatesPage = diaryTemplatesPages[0];
        } else if (diaryTemplateType === 'user') {
            diaryTemplatesPage = diaryTemplatesPages[1];
        } else if (diaryTemplateType === 'person') {
            diaryTemplatesPage = diaryTemplatesPages[2];
        }
        let diaryTemplates = page === 1 ? [] : [...this.state.diaryTemplates];
        diaryTemplates = diaryTemplatesPage ? diaryTemplates.concat(diaryTemplatesPage.content) : [];
        const last = diaryTemplatesPage ? diaryTemplatesPage.last : true;

        // list diary template persons
        let diaryTemplatePersons: DiaryTemplatePerson[] = [];
        if (diaryTemplatesPages[2] && !diaryTemplatesPages[2].empty) {
            diaryTemplatePersons = await this.listDiaryTemplatePersons(this.props.person as Person);
        }

        this.setState({ diaryTemplateType, diaryTemplates, page, last, searchText, diaryTemplatePersons });
    };

    listDiaryTemplatePersons = async (person: Person): Promise<DiaryTemplatePerson[]> => {
        return await diaryTemplatePersonApi.list(person.id as number);
    };

    get = async (id: number): Promise<DiaryTemplate> => {
        return await diaryTemplateApi.get(id);
    };

    createDiaryTemplatePerson = async (id: number, person: Person) => {
        const diaryTemplatePerson: DiaryTemplatePerson = {
            diaryTemplateId: id,
            personId: person.id,
        };
        await diaryTemplatePersonApi.create(diaryTemplatePerson);

        const diaryTemplatePersons = await this.listDiaryTemplatePersons(person);
        this.setState({ diaryTemplatePersons });
    };

    deleteDiaryTemplatePerson = async (id: number, person: Person) => {
        const diaryTemplatePerson = this.state.diaryTemplatePersons.find(
            dtp => dtp.diaryTemplateId === id,
        ) as DiaryTemplatePerson;

        if (diaryTemplatePerson.personId === person.id) {
            await diaryTemplatePersonApi.delete(diaryTemplatePerson);
            if (this.state.diaryTemplateType === 'person') {
                this.list(this.state.diaryTemplateType, 1);
            } else {
                const diaryTemplatePersons = await this.listDiaryTemplatePersons(person);
                this.setState({ diaryTemplatePersons });
            }
        } else {
            const userName = diaryTemplatePerson.entity && diaryTemplatePerson.entity.fullName;
            Modal.error({
                title: this.props.intl.formatMessage({ id: 'diaryRecord.templates.list.unlike.forbidden.title' }),
                content: this.props.intl.formatMessage(
                    { id: 'diaryRecord.templates.list.unlike.forbidden.desc' },
                    { userName },
                ),
                okText: this.props.intl.formatMessage({ id: 'common.ok' }),
                cancelButtonProps: { style: { display: 'none' } },
            });
        }
    };

    getNewRecordPath = (date: string): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId
            ? `/persons/${personId}/diary/records/new?date=${this.props.date}`
            : `/calendar/records/new?date=${date}`;
    };

    getNewTemplatePath = (date: string): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/diary-templates/new?personId=${personId}&date=${date}` : `/diary-templates/new`;
    };

    /** HANDLERS **/

    handleCreateDiaryTemplatePerson = async (e: React.MouseEvent, id: number, person: Person) => {
        e.stopPropagation();
        try {
            this.createDiaryTemplatePerson(id, person);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleDeleteDiaryTemplatePerson = async (e: React.MouseEvent, id: number, person: Person) => {
        e.stopPropagation();
        try {
            this.deleteDiaryTemplatePerson(id, person);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleSelectDiaryTemplate = async (id: number) => {
        try {
            const diaryTemplate = await this.get(id);
            this.props.onSelectDiaryTemplate(diaryTemplate);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleLoadMore = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'loading' });
            const diaryTemplateType = this.state.diaryTemplateType as DiaryTemplateType;
            await this.list(diaryTemplateType, this.state.page + 1, this.state.searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderType = (): JSX.Element => {
        if (this.state.diaryTemplateType === 'standard' && !this.props.diaryTemplateType) {
            return <h2> </h2>;
        } else if (this.state.diaryTemplateType) {
            return (
                <h2 className={styles[this.state.diaryTemplateType]}>
                    <FormattedMessage id={`diaryRecord.templates.type.${this.state.diaryTemplateType}`} />
                </h2>
            );
        } else {
            return (
                <div className={styles.type}>
                    <h2>
                        <FormattedMessage id="diaryRecord.templates.type.title" />
                    </h2>

                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Link to={`${this.getNewRecordPath(this.props.date)}&diaryTemplateType=standard`}>
                                <div className={`${styles.card} ${styles.standard}`} data-test="standard">
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.type.standard" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.type.standard.desc" />
                                    </p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={24} hidden={!this.props.person}>
                            <Link to={`${this.getNewRecordPath(this.props.date)}&diaryTemplateType=person`}>
                                <div className={`${styles.card} ${styles.person}`} data-test="person">
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.type.person" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.type.person.desc" />
                                    </p>
                                </div>
                            </Link>
                        </Col>
                        <Col xs={24}>
                            <Link to={`${this.getNewRecordPath(this.props.date)}&diaryTemplateType=user`}>
                                <div className={`${styles.card} ${styles.user}`} data-test="user">
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.type.user" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.type.user.desc" />
                                    </p>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        }
    };

    renderTemplatePersonButton = (diaryTemplate: DiaryTemplate): JSX.Element => {
        if (
            this.props.person &&
            this.state.diaryTemplatePersons.some(dtp => dtp.diaryTemplateId === diaryTemplate.id)
        ) {
            return (
                <Tooltip title={<FormattedMessageComponent id="diaryRecord.templates.list.unlike.tooltip" />}>
                    <Button
                        icon="heart"
                        type="primary"
                        onClick={e =>
                            this.handleDeleteDiaryTemplatePerson(
                                e,
                                diaryTemplate.id as number,
                                this.props.person as Person,
                            )
                        }
                        data-test="unassign"
                    />
                </Tooltip>
            );
        } else if (this.props.person) {
            return (
                <Tooltip title={<FormattedMessageComponent id="diaryRecord.templates.list.like.tooltip" />}>
                    <Button
                        icon="heart"
                        onClick={e =>
                            this.handleCreateDiaryTemplatePerson(
                                e,
                                diaryTemplate.id as number,
                                this.props.person as Person,
                            )
                        }
                        data-test="assign"
                    />
                </Tooltip>
            );
        } else {
            return <></>;
        }
    };

    renderTemplates = (): JSX.Element => {
        if (this.state.diaryTemplateType) {
            return (
                <div className={styles.templates}>
                    <h2>
                        <FormattedMessage id="diaryRecord.templates.list.title" />
                    </h2>
                    <List
                        itemLayout="horizontal"
                        dataSource={this.state.diaryTemplates}
                        locale={{
                            emptyText: (
                                <div data-test="empty">
                                    <p>
                                        <FormattedMessage id="diaryRecord.templates.list.empty" />
                                    </p>
                                    <Link to={this.getNewTemplatePath(this.props.date)}>
                                        <Button size="large" type="primary" ghost>
                                            <FormattedMessage id="diaryRecord.templates.list.empty.add" />
                                        </Button>
                                    </Link>
                                </div>
                            ),
                        }}
                        loadMore={
                            <div className={styles.load} hidden={this.state.last}>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={this.handleLoadMore}
                                    loading={this.state.actionStatus === 'loading'}
                                >
                                    <FormattedMessage id="common.loadMore" />
                                </Button>
                            </div>
                        }
                        renderItem={diaryTemplate => (
                            <List.Item
                                className={styles.item}
                                onClick={() => this.handleSelectDiaryTemplate(diaryTemplate.id as number)}
                                actions={[this.renderTemplatePersonButton(diaryTemplate), <Button icon="select" />]}
                                data-test="item"
                            >
                                <List.Item.Meta
                                    avatar={<AvatarComponent name={diaryTemplate.name} />}
                                    title={diaryTemplate.name}
                                    description={diaryTemplate.description}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderEmpty = (): JSX.Element => {
        if (this.state.diaryTemplateType && this.state.diaryTemplates.length > 0 && this.state.last) {
            return (
                <p className={styles.empty}>
                    <FormattedMessage id="diaryRecord.templates.list.new" />{' '}
                    <Link to={this.getNewTemplatePath(this.props.date)}>
                        <FormattedMessage id="diaryRecord.templates.list.new.link" />
                    </Link>
                </p>
            );
        } else {
            return <></>;
        }
    };

    render() {
        if (this.state.status === 'loading') {
            return <Loader />;
        } else {
            return (
                <div className={styles.templates}>
                    {this.renderType()}
                    {this.renderTemplates()}
                    {this.renderEmpty()}
                </div>
            );
        }
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(CalendarTemplatesComponent)));

interface Props extends ScreenSizeProps, RouteComponentProps, WrappedComponentProps {
    person?: Person;
    date: string;
    diaryTemplateType?: DiaryTemplateType;
    onSelectDiaryTemplate: (diaryTemplate?: DiaryTemplate) => void;
}

interface State {
    diaryTemplateType?: DiaryTemplateType;
    diaryTemplates: DiaryTemplate[];
    searchText?: string;
    page: number;
    last: boolean;
    diaryTemplatePersons: DiaryTemplatePerson[];
    status?: Status;
    actionStatus?: ActionStatus;
}
