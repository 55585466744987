import React, { Component } from 'react';
import styles from './LegalNoticePage.module.scss';
import { FormattedHTMLMessage } from 'react-intl';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

export default class LegalNoticePage extends Component {
    render() {
        return (
            <>
                <HeadMetadata titleKey="footer.legalNotice" />
                <div className={styles.layout}>
                    <FormattedHTMLMessage id="legalNotice" />
                </div>
            </>
        );
    }
}
