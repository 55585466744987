import React, { Component, ReactElement } from 'react';
import styles from './MedicationField.module.scss';
import { Form, InputNumber, Input, Row, Col, Select, AutoComplete } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import unitService from '../../../service/UnitService';
import settingsService from '../../../service/SettingsService';
import i18nService from '../../../service/I18nService';
import logService from '../../../service/LogService';
import medicineApi from '../../../api/MedicineApi';
import { Medicine } from '../../../model/model';

class MedicationField extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            medicines: [],
        };
    }

    /** HANDLERS **/

    handleSearchMedicine = async (value: string): Promise<void> => {
        try {
            const country = i18nService.intl.locale === 'es' ? 'ES' : 'US';
            const medicinesPage = await medicineApi.list(country, 0, 20, value);
            const medicines = medicinesPage.content;
            this.setState({ medicines });
        } catch (error) {
            logService.error('external measure service - error saving external measure');
        }
    };

    /** COMPONENTS **/

    renderMeasureValueFieldForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const medicine = i18nService.intl.formatMessage({ id: 'measure.medication.medicine' });
        const dosage = i18nService.intl.formatMessage({ id: 'measure.medication.dosage' });
        const units = i18nService.intl.formatMessage({ id: 'measure.medication.units' });

        const unitOptions = settingsService.settings.unitTypes
            .filter(u => u.medicine)
            .map(unitType => (
                <Select.Option value={unitType.value} key={unitType.value}>
                    {unitType.label}
                </Select.Option>
            ));
        const medicineOptions = this.state.medicines.map(medicine => (
            <Select.Option value={medicine.name} key={medicine.id}>
                {medicine.name}
            </Select.Option>
        ));

        return (
            <Row gutter={[12, 0]}>
                <Col xs={24}>
                    <Form.Item
                        label={this.props.label}
                        className={styles.label}
                        colon={this.props.colon}
                        required={this.props.required}
                    />
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item>
                        {getFieldDecorator(this.props.fieldName, {
                            initialValue: this.props.initialValue,
                            rules: [
                                {
                                    required: this.props.required,
                                    message: this.props.requiredLabel,
                                },
                            ],
                        })(
                            <AutoComplete
                                onSearch={this.handleSearchMedicine}
                                size={this.props.size}
                                disabled={this.props.disabled}
                                placeholder={medicine}
                                data-test="medicine"
                            >
                                {medicineOptions}
                            </AutoComplete>,
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item className={styles.value}>
                        <Input.Group compact>
                            {getFieldDecorator(this.props.fieldMeasureName, {
                                initialValue: this.props.initialMeasureValue,
                            })(
                                <InputNumber
                                    min={0}
                                    maxLength={8}
                                    formatter={value => unitService.formatNumber(value, 2)}
                                    size={this.props.size}
                                    disabled={this.props.disabled}
                                    placeholder={dosage}
                                    style={{ width: 120 }}
                                    data-test="dosage"
                                />,
                            )}
                            {getFieldDecorator(this.props.fieldUnitName, {
                                initialValue: this.props.initialMeasureUnit,
                            })(
                                <Select
                                    size={this.props.size}
                                    disabled={this.props.disabled}
                                    placeholder={units}
                                    allowClear
                                    style={{ width: 120 }}
                                    data-test="unit"
                                >
                                    {unitOptions}
                                </Select>,
                            )}
                        </Input.Group>
                    </Form.Item>
                </Col>
            </Row>
        );
    };

    render() {
        return this.renderMeasureValueFieldForm();
    }
}
export default MedicationField;

interface Props extends FormComponentProps {
    initialValue?: string; // required for initialization on modals
    initialMeasureValue?: number; // required for initialization on modals
    initialMeasureUnit?: string; // required for initialization on modals
    fieldName: string;
    fieldMeasureName: string;
    fieldUnitName: string;
    label?: React.ReactElement | null;
    colon?: boolean;
    size?: 'large' | 'small' | 'default';
    disabled?: boolean;
    required?: boolean;
    requiredLabel?: ReactElement | null;
}

interface State {
    medicines: Medicine[];
}
