import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { UserChart } from '../model/model';
import moment from 'moment';

class UserChartApi {
    list = async (): Promise<UserChart[]> => {
        const response: AxiosResponse<UserChart[]> = await apiAxios.get<UserChart[]>('/user-charts');
        return response.data;
    };

    get = async (id: number): Promise<UserChart> => {
        const response: AxiosResponse<UserChart> = await apiAxios.get<UserChart>(`/user-charts/${id}`);
        response.data.series[0].measures.forEach(m => {
            m.date = moment(m.date).utc();
            m.time = m.time ? moment(m.time, 'HH:mm') : undefined;
        });
        return response.data;
    };

    update = async (userChart: UserChart): Promise<UserChart> => {
        const response: AxiosResponse<UserChart> = await apiAxios.put<UserChart>(
            `/user-charts/${userChart.id}`,
            userChart,
        );
        return response.data;
    };
}

const userChartApi: UserChartApi = new UserChartApi();
export default userChartApi;
