import React, { Component, FormEvent, MouseEvent } from 'react';
import styles from './SignInPage.module.scss';
import children from '../HomePage/images/children.png';
import { Form, Input, Icon, Button, message, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import authService from '../../service/AuthService';
import authApi from '../../api/AuthApi';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import { ActionStatus } from '../../model/model';
import { Link } from 'react-router-dom';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import errorService from '../../service/ErrorService';
import CustomContext from '../../service/CustomContext';
import ga from '../Helper/GoogleAnalytics/GoogleAnalytics';
import logService from '../../service/LogService';

class SignInPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        ga.createDefaultEvent('sign in', 'sign in - init');
        if (window.location.search.includes('error=oauth2')) {
            message.error(this.props.intl.formatMessage({ id: 'signIn.error.oidc' }), 10);
            this.props.history.push({
                pathname: '/signin',
            });
        }
    }

    /** METHODS **/

    signIn = async (values: any) => {
        await authApi.signIn(values.email, values.password);
        authService.initOAuthImplicitFlow();
    };

    /** HANDLERS **/

    handleSignIn = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        ga.createDefaultEvent('sign in', 'sign in - signing in - 0');
        this.props.form.validateFields(async (error, values) => {
            try {
                logService.info('sign in');
                ga.createDefaultEvent('sign in', 'sign in - signing in');
                this.setState({ actionStatus: 'saving' });
                !error && (await this.signIn(values));
                ga.createDefaultEvent('sign in', 'sign in - success');
            } catch (error) {
                message.error(this.props.intl.formatMessage({ id: 'signIn.error' }), 10);
                ga.createDefaultEvent('sign in', 'sign in - error');
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleSignInWithGoogle = (e: MouseEvent): void => {
        try {
            e.preventDefault();
            logService.info('sign in - google');
            ga.createDefaultEvent('sign in', 'sign in - google - signing in');
            authService.initOAuthImplicitFlowWithSocialProvider('google');
            ga.createDefaultEvent('sign in', 'sign in - google - success');
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('sign in', 'sign in - google - error');
        }
    };

    handleSignInWithFacebook = (e: MouseEvent): void => {
        try {
            e.preventDefault();
            logService.info('sign in - facebook');
            ga.createDefaultEvent('sign in', 'sign in - facebook - signing in');
            authService.initOAuthImplicitFlowWithSocialProvider('facebook');
            ga.createDefaultEvent('sign in', 'sign in - facebook - success');
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('sign in', 'sign in - facebook - error');
        }
    };

    /** COMPONENTS **/

    renderSignIn = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.layout}>
                <div className={styles.local}>
                    <h1>
                        <FormattedMessage id="signIn.title" />
                    </h1>
                    <p className={styles.signup}>
                        <FormattedMessage id="signIn.notSignedUp" />{' '}
                        <Link to="/signup">
                            <FormattedMessage id="signIn.signUp" />
                        </Link>
                    </p>
                    <Form onSubmit={this.handleSignIn}>
                        <Form.Item className={styles.field}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="signIn.email.error.required" />,
                                    },
                                    {
                                        type: 'email',
                                        message: <FormattedMessage id="signIn.email.error.invalid" />,
                                    },
                                ],
                            })(
                                <Input
                                    prefix={<Icon type="user" className={styles.inputIcon} />}
                                    placeholder={this.props.intl.formatMessage({ id: 'signIn.email' })}
                                    maxLength={100}
                                    size="large"
                                    type="email"
                                    data-test="email"
                                />,
                            )}
                        </Form.Item>

                        <Form.Item className={styles.field}>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="signIn.password.error.required" />,
                                    },
                                ],
                            })(
                                <Input.Password
                                    prefix={<Icon type="lock" className={styles.inputIcon} />}
                                    placeholder={this.props.intl.formatMessage({ id: 'signIn.password' })}
                                    type="password"
                                    maxLength={20}
                                    size="large"
                                    data-test="password"
                                />,
                            )}
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            size="large"
                            loading={this.state.actionStatus === 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="signIn.signIn" />
                        </Button>
                        <Link to="/password-reset" className={styles.reset}>
                            <FormattedMessage id="signIn.forgotPassword" />
                        </Link>
                    </Form>
                </div>
                <div className={styles.social}>
                    <Button
                        icon="google"
                        size="large"
                        block
                        className={styles.google}
                        onClick={this.handleSignInWithGoogle}
                    >
                        <FormattedMessage id="signIn.signInWithGoogle" />
                    </Button>
                    <Button
                        icon="facebook"
                        size="large"
                        block
                        className={styles.facebook}
                        onClick={this.handleSignInWithFacebook}
                    >
                        <FormattedMessage id="signIn.signInWithFacebook" />
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        if (this.context.user) {
            return <Redirect to="/dashboard" />;
        } else {
            return (
                <>
                    <HeadMetadata titleKey="signIn.meta.title" />
                    <Row gutter={[0, 0]} type="flex" align="middle">
                        <Col xs={0} lg={12} className={styles.info}>
                            <img src={children} alt="children" />
                        </Col>
                        <Col xs={24} lg={12} xl={8}>
                            {this.renderSignIn()}
                        </Col>
                    </Row>
                </>
            );
        }
    }
}
export default injectIntl(Form.create()(withRouter(SignInPage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps {}

interface State {
    actionStatus?: ActionStatus;
}
