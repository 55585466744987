import i18nService from './I18nService';
import { message } from 'antd';
import { PrimitiveType } from 'intl-messageformat';
import authService from './AuthService';
import logService from './LogService';

class ErrorService {
    displayMessage = (
        error?: any,
        messageIdsByStatus?: [number, string, Record<string, PrimitiveType>?][],
        duration?: number,
    ) => {
        const status: number | undefined = error.response && error.response.status;
        const messageIdByStatus: [number, string, Record<string, PrimitiveType>?] | undefined =
            messageIdsByStatus && messageIdsByStatus.find(ms => ms[0] === status);
        const messageId: string = messageIdByStatus ? messageIdByStatus[1] : this.getDefaultMessageId(status);
        const messageValues: Record<string, PrimitiveType> | undefined = messageIdByStatus && messageIdByStatus[2];

        if (status === 401) {
            authService.signOut('unauthorized');
        } else {
            this.displayCustomMessage(messageId, messageValues, duration);
            logService.error();
        }
    };

    displayCustomMessage = (id: string, values?: Record<string, PrimitiveType>, duration: number = 5) => {
        message.error(i18nService.intl.formatMessage({ id }, values), duration);
    };

    private getDefaultMessageId = (status?: number): string => {
        let messageId: string;
        switch (status) {
            case 400:
                messageId = 'common.notification.validationError';
                break;
            case 403:
                messageId = 'common.notification.authorizationError';
                break;
            case 404:
                messageId = 'common.notification.notFoundError';
                break;
            case 409:
                messageId = 'common.notification.duplicateError';
                break;
            case 412:
                messageId = 'common.notification.concurrencyError';
                break;
            default:
                messageId = 'common.notification.internalError';
                break;
        }
        return messageId;
    };
}

const errorService = new ErrorService();
export default errorService;
