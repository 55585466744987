import React, { Component } from 'react';
import styles from './DocumentsPage.module.scss';
import { Layout, Button, message, List, Tag, Empty, Tooltip, Badge } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import {
    Document,
    Status,
    Person,
    Page,
    ActionStatus,
    CustomType,
    ScreenSizeProps,
    DocumentFile,
} from '../../../model/model';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import documentApi from '../../../api/DocumentApi';
import dateService from '../../../service/DateService';
import { Link } from 'react-router-dom';
import Search from 'antd/lib/input/Search';
import nameService from '../../../service/NameService';
import documentService from '../../../service/DocumentService';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import settingsService from '../../../service/SettingsService';
import AvatarComponent from '../../Shared/AvatarComponent/AvatarComponent';

class DocumentsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            documents: [],
            page: 0,
            last: true,
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const medicalSpeciality: string | undefined = documentService.getVirtualMedicalSpecialityFromParameters();
        await this.list(this.state.page, medicalSpeciality);
    };

    list = async (page: number, medicalSpeciality?: string, searchText?: string) => {
        const documentsPage: Page<Document> = await documentApi.list(
            this.props.person.id as number,
            page,
            20,
            medicalSpeciality && medicalSpeciality !== documentService.getUnclassified().value
                ? medicalSpeciality
                : undefined,
            medicalSpeciality && medicalSpeciality === documentService.getUnclassified().value ? true : undefined,
            searchText,
        );
        const documents: Document[] =
            page === 0 ? documentsPage.content : this.state.documents.concat(documentsPage.content);
        const last: boolean = documentsPage.last;
        this.setState({ documents, page, last, medicalSpeciality, searchText });
    };

    downloadReport = async (document: Document) => {
        await documentApi.getReport(document);
    };

    downloadFile = async (documentFile: DocumentFile) => {
        await documentApi.getFile(documentFile);
    };

    downloadFiles = async (document: Document) => {
        await documentApi.getFiles(document);
    };

    getMedicalSpeciality = (): string | undefined => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        return params.get('speciality') === null ? undefined : (params.get('speciality') as string);
    };

    /** HANDLERS **/

    handleSearch = async (searchText: string) => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'searching' });
            await this.list(0, this.state.medicalSpeciality, searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleLoadMore = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'loading' });
            await this.list(this.state.page + 1, this.state.medicalSpeciality, this.state.searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDownloadReport = async (document: Document): Promise<void> => {
        try {
            this.setState({ actionStatus: `report-${document.id}` });
            await this.downloadReport(document);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.downloaded' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDownloadFile = async (document: Document): Promise<void> => {
        try {
            this.setState({ actionStatus: `files-${document.id}` });
            await this.downloadFile(document.files[0]);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.downloaded' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDownloadFiles = async (document: Document): Promise<void> => {
        try {
            this.setState({ actionStatus: `files-${document.id}` });
            await this.downloadFiles(document);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.downloaded' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.search}>{this.renderDocumentsSearch()}</div>
                <div className={styles.add}>{this.renderCreateButton()}</div>
            </div>
        );
    };

    renderDocumentsSearch = (): JSX.Element => {
        let placeholder = this.props.intl.formatMessage({ id: 'documents.search.placeholder' });
        if (this.state.medicalSpeciality) {
            const medicalSpeciality = settingsService.getMedicalSpecialityByValue(this.state.medicalSpeciality);
            const speciality = medicalSpeciality
                ? medicalSpeciality.label
                : this.props.intl.formatMessage({ id: 'document.medicalSpeciality.unclassified' });
            placeholder = this.props.intl.formatMessage(
                { id: 'documents.search.specialities.placeholder' },
                { speciality },
            );
        }

        return (
            <Search
                placeholder={placeholder}
                onSearch={this.handleSearch}
                loading={this.state.actionStatus === 'searching'}
                size="large"
            />
        );
    };

    renderCreateButton = (): JSX.Element => {
        const { medicalSpeciality } = this.state;
        let url = `/persons/${this.props.person.id}/documents/new`;
        if (this.state.medicalSpeciality) {
            url = `${url}?speciality=${medicalSpeciality}`;
        }

        return (
            <Link to={url} data-test="add">
                <Button type="primary" icon="plus" size="large">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                </Button>
            </Link>
        );
    };

    renderDocumentsLoadMore = (): JSX.Element => {
        if (this.state.last) {
            return <></>;
        } else {
            return (
                <div className={styles.load}>
                    <Button onClick={this.handleLoadMore} loading={this.state.actionStatus === 'loading'}>
                        <FormattedMessage id="common.loadMore" />
                    </Button>
                </div>
            );
        }
    };

    renderDocumentMedicalSpeciality = (): JSX.Element => {
        if (this.state.medicalSpeciality) {
            const medicalSpeciality: CustomType = documentService.getVirtualMedicalSpeciality(
                this.state.medicalSpeciality,
            );
            const backgroundColor: string = nameService.getBackgroundColor(medicalSpeciality.label);
            return (
                <Tag color={backgroundColor} className={styles.tag}>
                    {medicalSpeciality.label}
                </Tag>
            );
        } else {
            return <></>;
        }
    };

    renderDocumentTitle = (document: Document): JSX.Element => {
        return <Link to={`/persons/${this.props.person.id}/documents/${document.id}`}>{document.name}</Link>;
    };

    renderDocumentAvatar = (document: Document): JSX.Element => {
        const backgroundColor = documentService.getDocumentTypeColor(document.documentType!);
        return (
            <Link to={`/persons/${this.props.person.id}/documents/${document.id}`}>
                <AvatarComponent name={document.name} backgroundColor={backgroundColor} />
            </Link>
        );
    };

    renderDocumentDescription = (document: Document): JSX.Element => {
        const format = dateService.getDateFormat(this.context.user);
        const documentTypeName = documentService.getDocumentTypeName(document.documentType!);
        return (
            <Link to={`/persons/${this.props.person.id}/documents/${document.id}`} className={styles.description}>
                <>
                    {documentTypeName}
                    {document.documentDate && ` - ${document.documentDate.format(format)}`}
                </>
            </Link>
        );
    };

    renderDocumentButtons = (document: Document): React.ReactNode[] => {
        const buttons: React.ReactNode[] = [];
        if (document.documentType === 'MANUAL') {
            buttons.push(
                <Tooltip title={<FormattedMessage id="documents.download.report" />}>
                    <Button
                        icon="file"
                        onClick={() => this.handleDownloadReport(document)}
                        loading={this.state.actionStatus === `report-${document.id}`}
                        disabled={!!this.state.actionStatus && this.state.actionStatus !== `report-${document.id}`}
                    ></Button>
                </Tooltip>,
            );
        }
        if (document.files && document.files.length === 1) {
            buttons.push(
                <Tooltip title={<FormattedMessage id="documents.download.file" />}>
                    <Button
                        icon="download"
                        onClick={() => this.handleDownloadFile(document)}
                        loading={this.state.actionStatus === `files-${document.id}`}
                        disabled={!!this.state.actionStatus && this.state.actionStatus !== `files-${document.id}`}
                    ></Button>
                </Tooltip>,
            );
        } else if (document.files && document.files.length > 1) {
            buttons.push(
                <Tooltip title={<FormattedMessage id="documents.download.files" />}>
                    <Badge count={document.files.length} style={{ backgroundColor: '#1aaa55' }}>
                        <Button
                            icon="download"
                            onClick={() => this.handleDownloadFiles(document)}
                            loading={this.state.actionStatus === `files-${document.id}`}
                            disabled={!!this.state.actionStatus && this.state.actionStatus !== `files-${document.id}`}
                        ></Button>
                    </Badge>
                </Tooltip>,
            );
        }

        return buttons;
    };

    renderDocumentsList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.documents}
                data-test="list"
                locale={{
                    emptyText: (
                        <div data-test="empty">
                            <Empty description={<FormattedMessage id="documents.empty" />} />
                        </div>
                    ),
                }}
                loadMore={this.renderDocumentsLoadMore()}
                renderItem={document => (
                    <List.Item actions={this.renderDocumentButtons(document)} data-test="item">
                        <List.Item.Meta
                            avatar={this.renderDocumentAvatar(document)}
                            title={this.renderDocumentTitle(document)}
                            description={this.renderDocumentDescription(document)}
                        />
                    </List.Item>
                )}
            />
        );
    };

    render() {
        return (
            <Layout.Content>
                {this.renderToolbar()}
                {this.renderDocumentsList()}
            </Layout.Content>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(DocumentsPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
    person: Person;
}

interface State {
    documents: Document[];
    medicalSpeciality?: string;
    searchText?: string;
    page: number;
    last: boolean;
    status?: Status;
    actionStatus?: ActionStatus | string;
}
