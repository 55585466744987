import { Person, Locale } from '../model/model';
import personApi from '../api/PersonApi';
import logService from './LogService';
import userApi from '../api/UserApi';

class UserService {
    readonly selectedPersonIdKey: string = 'selectedPersonId';
    readonly selectedChartTypeKey: string = 'selectedChartType';
    readonly selectedLocaleKey: string = 'selectedLocale';
    selectedPersonId: number | undefined;

    getSelectedPerson = async (): Promise<Person | undefined> => {
        let person: Person | undefined;
        const personId = this.selectedPersonId || sessionStorage.getItem(this.selectedPersonIdKey);
        if (personId) {
            try {
                person = await personApi.get(personId as number);
            } catch (error) {
                logService.warn('person id belongs to different user');
            }
        }
        if (!person) {
            const persons = await personApi.list(0, 1);
            return persons.empty ? undefined : persons.content[0];
        }

        return person;
    };

    setSelectedPersonId = (personId: number) => {
        this.selectedPersonId = personId;
        sessionStorage.setItem(this.selectedPersonIdKey, personId.toString());
    };

    removeSelectedPersonId = () => {
        sessionStorage.removeItem(this.selectedPersonIdKey);
    };

    getSelectedLocale = (): Locale | undefined => {
        const selectedLocale = localStorage.getItem(this.selectedLocaleKey);
        return selectedLocale === null ? undefined : (selectedLocale as Locale);
    };

    setSelectedLocale = async (locale: Locale) => {
        localStorage.setItem(this.selectedLocaleKey, locale);
        try {
            const user = await userApi.getCurrentUser();
            user.language = locale.toUpperCase();
            user.admin = undefined;
            user.associate = undefined;
            await userApi.update(user);
        } catch (error) {
            logService.warn('error updating user language');
        }
    };

    getSelectedChartType = (): string | undefined => {
        const selectedChartType = localStorage.getItem(this.selectedChartTypeKey);
        return selectedChartType === null ? undefined : selectedChartType;
    };

    setSelectedChartType = (selectedChartType: string) => {
        localStorage.setItem(this.selectedChartTypeKey, selectedChartType);
    };
}

const userService = new UserService();
export default userService;
