import React, { Component } from 'react';
import styles from './PostCommentComponent.module.scss';
import withSizes from 'react-sizes';
import { List, Tag, Icon } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import CustomContext from '../../../../../../service/CustomContext';
import { Post, User, CustomType, ScreenSizeProps } from '../../../../../../model/model';
import dateService from '../../../../../../service/DateService';
import settingsService from '../../../../../../service/SettingsService';
import Linkify from 'linkifyjs/react';
import PostReactionButtonComponent from '../../PostReactionButtonComponent/PostReactionButtonComponent';
import AvatarComponent from '../../../../../Shared/AvatarComponent/AvatarComponent';
import responsiveService from '../../../../../../service/ResponsiveService';
import PostCommentEditComponent from '../PostCommentEditComponent/PostCommentEditComponent';

class PostCommentComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            edit: false,
        };
    }

    /** HANDLERS **/

    handleReadMore = () => {
        this.setState({ readMore: true });
    };

    handleTogglePostEdit = () => {
        const edit: boolean = !this.state.edit;
        this.setState({ edit });
    };

    handleHidePostEdit = async () => {
        this.setState({ edit: false });
        this.props.handlePostUpdate();
    };

    /** COMPONENTS **/

    renderPostTitle = (): JSX.Element => {
        const post: Post = this.props.post;
        return (
            <>
                <span className={styles.name}>{post.entityName}</span> {this.renderPostStatus()}
            </>
        );
    };

    renderPostDescription = (): JSX.Element => {
        const post: Post = this.props.post;
        const format: string = dateService.getDateFormat(this.context.user);
        return <span className={styles.date}>{post.postDate && post.postDate.format(format)}</span>;
    };

    renderPostStatus = (): JSX.Element => {
        const user: User = this.context.user as User;
        if (user && user.admin) {
            const post: Post = this.props.post;
            const status: CustomType = settingsService.getPostStatusByValue(post.status as string);
            let color: string;
            switch (post.status) {
                case 'APPROVED':
                    color = 'blue';
                    break;
                case 'BLOCKED':
                    color = 'red';
                    break;
                default:
                    color = 'orange';
                    break;
            }

            return (
                <Tag color={color} className={styles.status}>
                    {status.label}
                </Tag>
            );
        } else {
            return <></>;
        }
    };

    renderPostMessage = (): JSX.Element => {
        const post: Post = this.props.post;
        if (this.state.edit) {
            return (
                <div className={styles.message}>
                    <PostCommentEditComponent comment={post} handlePostCreate={this.handleHidePostEdit} />
                </div>
            );
        } else if (post.message && post.message.length > 250 && !this.state.readMore) {
            let truncatedMessage: string = post.message.substring(0, 247) + '...';
            return (
                <div className={styles.message}>
                    <Linkify options={{ target: '_blank', nl2br: true }}>{truncatedMessage}</Linkify>{' '}
                    <span onClick={this.handleReadMore} className={styles.more}>
                        <FormattedMessage id="common.readMore" />
                    </span>
                </div>
            );
        } else {
            return (
                <div className={styles.message}>
                    <Linkify options={{ target: '_blank', nl2br: true }}>{post.message}</Linkify>
                </div>
            );
        }
    };

    renderPostEdit = (): JSX.Element => {
        return (
            <span className={styles.action} onClick={this.handleTogglePostEdit}>
                <Icon type="edit" />
                <FormattedMessage id="common.modify" />
            </span>
        );
    };

    renderPostActions = (): React.ReactNode[] => {
        const post: Post = this.props.post;
        const user: User = this.context.user as User;

        const actions: React.ReactNode[] = [<PostReactionButtonComponent key="1" post={post} />];
        if (user && (user.entity!.id === this.props.post.entityId || user.admin)) {
            actions.push(this.renderPostEdit());
        }

        return actions;
    };

    render() {
        const post: Post = this.props.post;

        return (
            <div>
                <List.Item key={post.id} className={styles.post} actions={this.renderPostActions()}>
                    <List.Item.Meta
                        avatar={<AvatarComponent name={post.entityName} />}
                        title={this.renderPostTitle()}
                        description={this.renderPostDescription()}
                    />
                    {this.renderPostMessage()}
                </List.Item>
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(PostCommentComponent));

interface Props extends WrappedComponentProps, ScreenSizeProps {
    post: Post;
    handlePostUpdate: () => void;
}

interface State {
    readMore?: boolean;
    commentsVisible?: boolean;
    edit: boolean;
}
