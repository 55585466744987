import React, { Component } from 'react';
import PostsComponent from '../../DashaboardPage/Posts/PostsComponent';
import { MedicalConditionType } from '../../../model/model';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

class CommunityQuestionsPage extends Component<Props> {
    render() {
        const title: string = this.props.intl.formatMessage(
            { id: 'community.questions.meta.title' },
            { community: this.props.condition.label },
        );
        const description: string = this.props.intl.formatMessage(
            { id: 'community.questions.meta.description' },
            { community: this.props.condition.label },
        );
        return (
            <>
                <HeadMetadata title={title} description={description} />
                <PostsComponent postType="QUESTION" condition={this.props.condition} />
            </>
        );
    }
}
export default injectIntl(CommunityQuestionsPage);

interface Props extends WrappedComponentProps {
    condition: MedicalConditionType;
}
