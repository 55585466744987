import React, { Component, FormEvent } from 'react';
import styles from './BiometricsMidparentalHeight.module.scss';
import { Form, Button, message, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Parent, Status, ActionStatus, UnitType, Person, Measure } from '../../../../model/model';
import parentApi from '../../../../api/ParentApi';
import MeasureValueField from '../MeasureValueField/MeasureValueField';
import unitService from '../../../../service/UnitService';
import { FormattedNumber, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import errorService from '../../../../service/ErrorService';

class BiometricsMidparentalHeight extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            father: {},
            mother: {},
            midparentalHeight: {},
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const unit: UnitType = unitService.getUnitTypeByChartType('HEIGHT', this.props.measurementSystem);
        const parents: Parent[] = await parentApi.list(this.props.person.id as number);
        parents.forEach(p => {
            if (p.height && p.height.value && p.height.unit) {
                p.height = unitService.convertMeasure(p.height, unit);
            } else {
                p.height = {
                    unit: unit.value,
                };
            }
        });
        const father: Parent = parents.find(p => p.gender === 'MALE') as Parent;
        const mother: Parent = parents.find(p => p.gender === 'FEMALE') as Parent;
        this.setState({ father, mother });

        this.props.form.setFieldsValue({
            fatherHeightValue: father.height && father.height.value,
            fatherHeightSecondaryValue: father.height && father.height.secondaryValue,
            motherHeightValue: mother.height && mother.height.value,
            motherHeightSecondaryValue: mother.height && mother.height.secondaryValue,
        });
        this.calculate(father, mother);
    };

    save = async (values: any) => {
        const father: Parent = Object.assign({}, this.state.father);
        const mother: Parent = Object.assign({}, this.state.mother);
        father.height = Object.assign({}, this.state.father.height, {
            value: values.fatherHeightValue,
            secondaryValue: values.fatherHeightSecondaryValue,
        });
        mother.height = Object.assign({}, this.state.mother.height, {
            value: values.motherHeightValue,
            secondaryValue: values.motherHeightSecondaryValue,
        });

        await parentApi.update(father);
        await parentApi.update(mother);
        this.setState({ father, mother });

        this.calculate(father, mother);
    };

    calculate = (father: Parent, mother: Parent): void => {
        let midparentalHeightValue: number | undefined;
        if (father.height && father.height.value && mother.height && mother.height.value) {
            let fatherHeight: number = unitService.getTotalMeasure(father.height);
            let motherHeight: number = unitService.getTotalMeasure(mother.height);

            if (
                this.props.person.gender === 'MALE' &&
                this.props.measurementSystem === 'INTERNATIONAL_SYSTEM_OF_UNITS'
            ) {
                midparentalHeightValue = (fatherHeight + motherHeight + 13) / 2;
            } else if (this.props.person.gender === 'MALE' && this.props.measurementSystem === 'ENGLISH_SYSTEM') {
                midparentalHeightValue = (fatherHeight + motherHeight + 5 / 12) / 2;
            } else if (
                this.props.person.gender === 'FEMALE' &&
                this.props.measurementSystem === 'INTERNATIONAL_SYSTEM_OF_UNITS'
            ) {
                midparentalHeightValue = (fatherHeight + motherHeight - 13) / 2;
            } else if (this.props.person.gender === 'FEMALE' && this.props.measurementSystem === 'ENGLISH_SYSTEM') {
                midparentalHeightValue = (fatherHeight + motherHeight - 5 / 12) / 2;
            }
        }

        const midparentalHeight: Measure = {
            unit: unitService.getUnitTypeByChartType('HEIGHT', this.props.measurementSystem).value,
            value: midparentalHeightValue,
        };
        this.setState({ midparentalHeight });
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.save(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.calculated' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    /** COMPONENTS **/

    renderMidparentalForm = (): JSX.Element => {
        const unitType: UnitType = unitService.getUnitTypeByChartType('HEIGHT', this.props.measurementSystem);
        const secondaryUnitType: UnitType | undefined = unitService.getSecondaryUnitTypeByUnitType(unitType);

        return (
            <div className={styles.layout}>
                <Form onSubmit={this.handleSave}>
                    <MeasureValueField
                        form={this.props.form}
                        unit={unitType}
                        secondaryUnit={secondaryUnitType}
                        fieldName="fatherHeightValue"
                        secondaryFieldName="fatherHeightSecondaryValue"
                        label={<FormattedMessage id="biometrics.midparental.fatherHeight" />}
                        required={true}
                        size="large"
                    />
                    <MeasureValueField
                        form={this.props.form}
                        unit={unitType}
                        secondaryUnit={secondaryUnitType}
                        fieldName="motherHeightValue"
                        secondaryFieldName="motherHeightSecondaryValue"
                        label={<FormattedMessage id="biometrics.midparental.motherHeight" />}
                        required={true}
                        size="large"
                    />
                    <div className={styles.buttons}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="common.calculate" />
                        </Button>
                    </div>
                </Form>
            </div>
        );
    };

    renderMidparentalHeigh = (): JSX.Element => {
        if (this.state.midparentalHeight.value) {
            const unitType: UnitType = unitService.getUnitTypeByValue(this.state.midparentalHeight.unit as string);
            return (
                <Descriptions>
                    <Descriptions.Item label={<FormattedMessage id="biometrics.midparental.midparentalHeight" />}>
                        <span data-test="result">
                            <FormattedNumber
                                value={this.state.midparentalHeight.value}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />{' '}
                            {unitType && unitType.label.toLowerCase()}
                        </span>
                    </Descriptions.Item>
                </Descriptions>
            );
        }
        return <></>;
    };
    render() {
        return (
            <>
                {this.renderMidparentalForm()}
                {this.renderMidparentalHeigh()}
            </>
        );
    }
}

export default injectIntl(Form.create<Props>()(BiometricsMidparentalHeight));

interface Props extends FormComponentProps, WrappedComponentProps {
    measurementSystem: string;
    person: Person;
}

interface State {
    father: Parent;
    mother: Parent;
    midparentalHeight: Measure;
    status?: Status;
    actionStatus?: ActionStatus;
}
