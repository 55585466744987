import { LocalePath } from './paths';

const paths: LocalePath[] = [
    {
        key: 'professionals',
        path: 'profesionales',
    },
    {
        key: 'patients',
        path: 'pacientes',
    },
    {
        key: 'prices',
        path: 'precios',
    },
    {
        key: 'video-consultation',
        path: 'teleconsulta',
    },
    {
        key: 'map',
        path: 'mapa',
    },
    {
        key: 'contact-us',
        path: 'contacto',
    },
    {
        key: 'legal-notice',
        path: 'aviso-legal',
    },
    {
        key: 'privacy-policy',
        path: 'politica-de-privacidad',
    },
    {
        key: 'cookies-policy',
        path: 'politica-de-cookies',
    },
    {
        key: 'terms-of-use',
        path: 'condiciones-de-uso',
    },
    {
        key: 'terms-of-contract',
        path: 'condiciones-de-contratacion',
    },
    {
        key: 'credits',
        path: 'creditos',
    },
    {
        key: 'communities',
        path: 'comunidades',
    },
    {
        key: 'posts',
        path: 'articulos',
    },
    {
        key: 'questions',
        path: 'preguntas',
    },
    {
        key: 'members',
        path: 'miembros',
    },
    {
        key: 'doctors',
        path: 'medicos',
    },
    {
        key: 'researchers',
        path: 'investigadores',
    },
    {
        key: 'tools',
        path: 'herramientas',
    },
    {
        key: 'growth-chart',
        path: 'curvas-de-crecimiento',
    },
    {
        key: 'bmi-tracker',
        path: 'diario-imc',
    },
    {
        key: 'bmi-calculator',
        path: 'calculadora-imc',
    },
    {
        key: 'bmi-calculator-children',
        path: 'calculadora-imc-niños',
    },
    {
        key: 'calorie-intake-calculator',
        path: 'calculadora-ingesta-calorias',
    },
    {
        key: 'age-calculator',
        path: 'calcular-edad',
    },
    {
        key: 'coronavirus-test',
        path: 'test-de-coronavirus',
    },
    {
        key: 'midparental-height-calculator',
        path: 'calculadora-de-talla-diana',
    },
];
paths.forEach(path => (path.locale = 'es'));
export default paths;
