import React, { Component, FormEvent } from 'react';
import styles from './VideoComponent.module.scss';
import { Button, Row, Col, Modal, Form, Select, message, Icon } from 'antd';
import CustomContext from '../../../../service/CustomContext';
import { ActionStatus, Person, Chat, ChatMember, VideoCall, User } from '../../../../model/model';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import videoService from '../../../../service/VideoService';
import { FormComponentProps } from 'antd/lib/form';
import { MeetingSessionStatus } from 'amazon-chime-sdk-js';

class VideoComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            microphoneStoped: false,
            audioInputDevices: [],
            audioOutputDevices: [],
            videoInputDevices: [],
            logs: [],
        };
    }

    async componentDidMount() {
        try {
            this.addLogs('#### componentDidMount');
            const status = this.props.videoCall ? 'connecting' : 'calling';
            this.setState({ status });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error, [[404, 'video.rejected']]);
            this.addLogs(error);
        }
    }

    componentWillUnmount() {
        videoService.stop();
    }

    /** METHODS **/

    init = async () => {
        const { chat, videoCall } = this.props;

        // set-up video service
        videoService.audioElement = document.getElementById('audio') as HTMLAudioElement;
        videoService.videoLocalElement = document.getElementById('video-local') as HTMLVideoElement;
        videoService.videoRemoteElement = document.getElementById('video-remote') as HTMLVideoElement;
        videoService.callbackMicrophoneUpdated = this.onMicrophoneUpdated;
        videoService.callbackVideoUpdated = this.onVideoUpdated;
        videoService.callbackVideoDidStop = this.onVideoDidStop;
        videoService.callbackAttendeePresent = this.onAttendeePresent;
        videoService.callbackLogs = this.addLogs;

        // start video call
        videoCall ? await videoService.join(videoCall) : await videoService.connect(chat.id);

        // listen to video to resize dimensions on video quality
        const videoLocalElement = videoService.videoLocalElement;
        videoLocalElement.addEventListener(
            'loadedmetadata',
            () => {
                console.log(videoLocalElement.videoWidth, videoLocalElement.videoHeight);
                if (videoLocalElement.videoWidth < videoLocalElement.videoHeight) {
                    this.setState({ localeVideoClassName: styles.portrait });
                } else {
                    this.setState({ localeVideoClassName: styles.landscape });
                }
            },
            false,
        );

        // listen to video to resize dimensions on video quality
        const videoRemoteElement = videoService.videoRemoteElement;
        videoRemoteElement.addEventListener(
            'loadedmetadata',
            () => {
                console.log(videoRemoteElement.videoWidth, videoRemoteElement.videoHeight);
                if (videoRemoteElement.videoWidth < videoRemoteElement.videoHeight) {
                    this.setState({ remoteVideoClassName: styles.portrait });
                } else {
                    this.setState({ remoteVideoClassName: styles.landscape });
                }
            },
            false,
        );
    };

    stop = () => {
        videoService.stop();
    };

    toggleMicrophone = () => {
        videoService.toggleMicrophone();
    };

    toggleVideo = async () => {
        videoService.toggleVideo();
    };

    toggleSettings = async () => {
        if (this.state.seetingsVisible) {
            this.setState({ seetingsVisible: false });
        } else if (videoService.meetingSession) {
            // load devices
            const audioVideo = videoService.meetingSession.audioVideo;
            const audioInputDevices = await audioVideo.listAudioInputDevices();
            const audioInputDevice =
                this.state.audioInputDevice || audioInputDevices.length > 0 ? audioInputDevices[0].deviceId : undefined;
            const audioOutputDevices = await audioVideo.listAudioOutputDevices();
            const audioOutputDevice =
                this.state.audioOutputDevice || audioOutputDevices.length > 0
                    ? audioOutputDevices[0].deviceId
                    : undefined;
            const videoInputDevices = await audioVideo.listVideoInputDevices();
            const videoInputDevice =
                this.state.videoInputDevices || videoInputDevices.length > 0
                    ? videoInputDevices[0].deviceId
                    : undefined;

            // set devices
            await videoService.setDevices(audioInputDevice, audioOutputDevice, videoInputDevice);
            this.setState({
                seetingsVisible: true,
                audioInputDevices,
                audioInputDevice,
                audioOutputDevices,
                audioOutputDevice,
                videoInputDevices,
                videoInputDevice,
            });
        } else {
            this.setState({ seetingsVisible: true });
        }
    };

    saveSettings = async (values: any): Promise<void> => {
        if (videoService.meetingSession) {
            const audioInputDevice = values.audioInputDevice;
            const audioOutputDevice = values.audioOutputDevice;
            const videoInputDevice = values.videoInputDevice;
            await videoService.setDevices(audioInputDevice, audioOutputDevice, videoInputDevice);
            this.setState({ audioInputDevice, audioOutputDevice, videoInputDevice });

            this.toggleSettings();
        }
    };

    onMicrophoneUpdated = (microphoneStoped: boolean) => {
        this.setState({ microphoneStoped });
    };

    onVideoUpdated = (videoStopped: boolean) => {
        this.setState({ videoStopped });
    };

    onVideoDidStop = (sessionStatus: MeetingSessionStatus) => {
        this.setState({ status: 'rejected' });
    };

    onAttendeePresent = (externalUserId?: string) => {
        const user = this.context.user as User;
        const userId = user.id as number;
        if (externalUserId !== userId.toString()) {
            this.setState({ status: undefined });
        }
    };

    addLogs = (log: string) => {
        const logs = [...this.state.logs];
        logs.push(log);
        this.setState({ logs });
    };

    /** HANDLERS **/

    handleStop = async (): Promise<void> => {
        try {
            this.stop();
            this.props.onStop();
        } catch (error) {
            console.log(error);
            errorService.displayMessage(error);
        }
    };

    handleToggleMicrophone = () => {
        try {
            this.toggleMicrophone();
        } catch (error) {
            console.log(error);
            errorService.displayMessage(error);
        }
    };

    handleToggleVideo = async () => {
        try {
            await this.toggleVideo();
        } catch (error) {
            console.log(error);
            errorService.displayMessage(error);
        }
    };

    handleToggleSettings = async () => {
        try {
            await this.toggleSettings();
        } catch (error) {
            console.log(error);
            errorService.displayMessage(error);
        }
    };

    handleSaveSettings = async (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.saveSettings(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleShowLogs = () => {
        Modal.info({
            title: 'Logs',
            content: (
                <div style={{ fontSize: '10px' }}>
                    {this.state.logs.map(log => (
                        <>
                            {log}
                            <br />
                        </>
                    ))}
                </div>
            ),
        });
    };

    /** COMPONENTS **/

    renderCalling() {
        if (this.state.status === 'calling') {
            return (
                <div className={`${styles.notification} ${styles.calling}`}>
                    <Icon type="phone" rotate={90} spin />
                    <FormattedMessage id="video.calling" />
                </div>
            );
        } else if (this.state.status === 'connecting') {
            return (
                <div className={`${styles.notification} ${styles.calling}`}>
                    <Icon type="phone" rotate={90} spin />
                    <FormattedMessage id="common.connecting" />
                </div>
            );
        } else if (this.state.status === 'rejected') {
            return (
                <div className={`${styles.notification} ${styles.rejected}`}>
                    <Icon type="phone" rotate={90} />
                    <FormattedMessage id="video.rejected" />
                </div>
            );
        } else {
            return <></>;
        }
    }

    renderSettings = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const videoInputDeviceOptions = this.state.videoInputDevices.map(videoInputDevice => (
            <Select.Option value={videoInputDevice.deviceId} key={videoInputDevice.deviceId}>
                {videoInputDevice.label}
            </Select.Option>
        ));
        const audioInputDeviceOptions = this.state.audioInputDevices.map(audioInputDevice => (
            <Select.Option value={audioInputDevice.deviceId} key={audioInputDevice.deviceId}>
                {audioInputDevice.label}
            </Select.Option>
        ));
        const audioOutputDeviceOptions = this.state.audioOutputDevices.map(audioOutputDevice => (
            <Select.Option value={audioOutputDevice.deviceId} key={audioOutputDevice.deviceId}>
                {audioOutputDevice.label}
            </Select.Option>
        ));

        return (
            <Modal
                title={<FormattedMessage id="video.settings.title" />}
                visible={this.state.seetingsVisible}
                className={styles.modal}
                onCancel={this.toggleSettings}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ form: 'settingsForm', htmlType: 'submit' }}
            >
                <Form id="settingsForm" onSubmit={this.handleSaveSettings}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item label={<FormattedMessage id="video.settings.videoInputDevice" />}>
                                {getFieldDecorator('videoInputDevice', {
                                    initialValue: this.state.videoInputDevice,
                                })(
                                    <Select size="large" data-test="videoInputDevice">
                                        {videoInputDeviceOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={<FormattedMessage id="video.settings.audioInputDevice" />}>
                                {getFieldDecorator('audioInputDevice', {
                                    initialValue: this.state.audioInputDevice,
                                })(
                                    <Select size="large" data-test="audioInputDevice">
                                        {audioInputDeviceOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label={<FormattedMessage id="video.settings.audioOutputDevice" />}>
                                {getFieldDecorator('audioOutputDevice', {
                                    initialValue: this.state.audioOutputDevice,
                                })(
                                    <Select size="large" data-test="audioOutputDevice">
                                        {audioOutputDeviceOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };

    renderToolbar() {
        const user = this.context.user as User;
        return (
            <Row className={styles.toolbar} type="flex" align="middle">
                <Col xs={20} className={styles.controls}>
                    <Button icon="tool" type="link" onClick={this.handleToggleSettings} className={styles.disabled} />
                    <Button
                        icon="audio"
                        type={this.state.microphoneStoped ? 'link' : 'primary'}
                        onClick={this.handleToggleMicrophone}
                        className={this.state.microphoneStoped ? styles.disabled : styles.enabled}
                    />
                    <Button
                        icon="video-camera"
                        type={this.state.videoStopped ? 'link' : 'primary'}
                        onClick={this.handleToggleVideo}
                        className={this.state.videoStopped ? styles.disabled : styles.enabled}
                    />
                    <Button
                        icon="info"
                        type="link"
                        onClick={this.handleShowLogs}
                        className={styles.disabled}
                        hidden={!user.admin}
                    />
                </Col>

                <Col xs={4} className={styles.stop}>
                    <Button
                        icon="logout"
                        ghost
                        type="danger"
                        onClick={this.handleStop}
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={!!this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                    />
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <div className={styles.video}>
                <audio id="audio" className={styles.audio}></audio>
                <video id="video-local" className={`${styles.local} ${this.state.localeVideoClassName}`}></video>
                <div className={styles.main}>
                    <div className={`${styles.remote} ${this.state.remoteVideoClassName}`}>
                        <video id="video-remote"></video>
                        {this.renderCalling()}
                    </div>
                </div>

                {this.renderToolbar()}
                {this.renderSettings()}
            </div>
        );
    }
}

export default injectIntl(Form.create<Props>()(VideoComponent));

interface Props extends WrappedComponentProps, FormComponentProps {
    person: Person;
    chat: Chat;
    sender: ChatMember;
    receiver: ChatMember;
    videoCall?: VideoCall;
    onStop: () => void;
}

interface State {
    seetingsVisible?: boolean;
    microphoneStoped?: boolean;
    videoStopped?: boolean;

    audioInputDevices: MediaDeviceInfo[];
    audioOutputDevices: MediaDeviceInfo[];
    videoInputDevices: MediaDeviceInfo[];
    audioInputDevice?: string;
    audioOutputDevice?: string;
    videoInputDevice?: string;

    status?: 'calling' | 'connecting' | 'rejected';
    actionStatus?: ActionStatus;

    localeVideoClassName?: string;
    remoteVideoClassName?: string;
    logs: string[];
}
