import React, { Component, FormEvent } from 'react';
import styles from './AgeCalculatorPage.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { ScreenSizeProps, ActionStatus, Person } from '../../../model/model';
import errorService from '../../../service/ErrorService';
import { Row, Col, Form, Button, Descriptions, DatePicker } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import dateService from '../../../service/DateService';
import moment from 'moment';
import CampaignComponent from '../CampaignComponent/CampaignComponent';
import GoogleAdsComponent from '../../Shared/GoogleAdsComponent/GoogleAdsComponent';

class AgeCalculatorPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            person: {},
        };
    }

    componentDidMount() {
        try {
            ga.createDefaultEvent('age calculator', 'age calculator - init');
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    /** METHODS **/

    calculate = (values: any) => {
        const person = Object.assign({}, this.state.person);
        person.birthdate = values.birthdate;

        this.setState({ person });
    };

    /** HANDLERS **/

    handleCalculate = (e: FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    ga.createDefaultEvent('age calculator', 'age calculator - save');
                    this.calculate(values);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="ageCalculator.title" />
                    </h1>
                </div>
            </div>
        );
    };

    renderBirthdateField = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const format: string = dateService.getDateFormat();

        return (
            <Form.Item label={<FormattedMessage id="ageCalculator.birthdate" />}>
                {getFieldDecorator('birthdate', {
                    rules: [
                        {
                            type: 'object',
                            required: true,
                            message: <FormattedMessage id="ageCalculator.birthdate.error.required" />,
                        },
                    ],
                })(
                    <DatePicker
                        format={format}
                        disabledDate={dateService.isFutureDate}
                        allowClear={false}
                        size="large"
                        onFocus={(e: any) => (e.target.readOnly = true)}
                    />,
                )}
            </Form.Item>
        );
    };

    renderForm = (): JSX.Element => {
        return (
            <div className={styles.layout}>
                <Form onSubmit={this.handleCalculate} className={styles.form}>
                    <Row gutter={[24, 0]} align="middle" type="flex">
                        <Col xs={24} sm={16}>
                            {this.renderBirthdateField()}
                        </Col>
                        <Col xs={24} sm={0} className={styles.buttons}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                block
                                loading={this.state.actionStatus === 'saving'}
                            >
                                <FormattedMessage id="common.calculate" />
                            </Button>
                        </Col>
                        <Col xs={0} sm={8} className={styles.button}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                loading={this.state.actionStatus === 'saving'}
                            >
                                <FormattedMessage id="common.calculate" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    renderResult = (): JSX.Element => {
        if (this.state.person.birthdate) {
            const years: number | undefined = dateService.getPeriodInYears(this.state.person.birthdate, moment());
            const age: string = dateService.getPeriodFromBirthdate(moment(), this.state.person);
            return (
                <Descriptions className={styles.result} bordered layout="vertical" column={1}>
                    <Descriptions.Item label={<FormattedMessage id="ageCalculator.age" />}>
                        <p className={styles.bmi}>{years}</p>
                        <p className={styles.description}>{age}</p>
                    </Descriptions.Item>
                </Descriptions>
            );
        } else {
            return <></>;
        }
    };

    renderCampaign = (): JSX.Element => {
        return <CampaignComponent tool="ageCalculator" />;
    };

    renderInfo = (): JSX.Element => {
        return (
            <div className={styles.info}>
                <h3>
                    <FormattedMessage id="ageCalculator.info.title" />
                </h3>
                <p>
                    <FormattedMessage id="ageCalculator.info.desc" />
                </p>
                <ul>
                    <li>
                        <FormattedMessage id="ageCalculator.info.1" />
                    </li>
                    <li>
                        <FormattedMessage id="ageCalculator.info.2" />
                    </li>
                    <li>
                        <FormattedMessage id="ageCalculator.info.3" />
                    </li>
                </ul>
            </div>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="ageCalculator.meta.title" descriptionKey="ageCalculator.meta.description" />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]}>
                        <Col xs={24} xl={19} className={styles.calculator}>
                            <div className="panel">
                                {this.renderHeader()}
                                {this.renderForm()}
                                {this.renderResult()}
                                {this.renderCampaign()}
                                <GoogleAdsComponent />
                                {this.renderInfo()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(
    injectIntl(withRouter(Form.create<Props>()(AgeCalculatorPage))),
);

interface Props extends WrappedComponentProps, RouteComponentProps, FormComponentProps, ScreenSizeProps {}

interface State {
    person: Person;
    actionStatus?: ActionStatus;
}
