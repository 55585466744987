import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { Chat } from '../model/model';
import moment from 'moment';

class ChatApi {
    list = async (personId?: number): Promise<Chat[]> => {
        const requestConfig: AxiosRequestConfig = Object.assign({}, this.requestConfig, {
            params: { personId },
        });
        const response: AxiosResponse<Chat[]> = await apiAxios.get<Chat[]>('/chats', requestConfig);
        return response.data;
    };

    get = async (id: number): Promise<Chat> => {
        const response: AxiosResponse<Chat> = await apiAxios.get<Chat>(`/chats/${id}`);
        return response.data;
    };

    read = async (id: number): Promise<void> => {
        const response: AxiosResponse<void> = await apiAxios.put(`/chats/${id}/read`);
        return response.data;
    };

    private transformResponse = (chats: Chat[]): Chat[] => {
        chats.forEach(chat => {
            if (chat.lastMessage) {
                chat.lastMessage = Object.assign({}, chat.lastMessage, {
                    sent: moment.utc(chat.lastMessage.sent).local(),
                });
            }
        });

        return chats;
    };

    private requestConfig: AxiosRequestConfig = {
        transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(this.transformResponse),
    };
}

const chatApi: ChatApi = new ChatApi();
export default chatApi;
