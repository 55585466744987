import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const ScrollToTop: React.FC<RouteComponentProps> = props => {
    React.useEffect((): any => {
        const unlisten = props.history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    });

    return <></>;
};
export default withRouter(ScrollToTop);
