import React, { Component } from 'react';
import styles from './Loader.module.scss';
import { Icon } from 'antd';

export default class Loader extends Component<Props> {
    render() {
        if (this.props.type === 'complete') {
            return (
                <div className={styles.full}>
                    <div>
                        <div>
                            <div>Loading...</div>
                            <Icon type="loading" />
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.type === 'icon') {
            return (
                <div className={styles.basic}>
                    <Icon type="loading" />
                </div>
            );
        } else {
            return (
                <div className={styles.basic}>
                    <div>{this.props.message || 'Loading...'}</div>
                    <Icon type="loading" />
                </div>
            );
        }
    }
}

interface Props {
    type?: 'complete' | 'basic' | 'icon';
    message?: string;
}
