import apiAxios, { apiNoAuthAxios } from './CustomAxios';
import { Plan } from '../model/model';
import { AxiosResponse } from 'axios';

class PlanApi {
    list = async (): Promise<Plan[]> => {
        const response: AxiosResponse<Plan[]> = await apiNoAuthAxios.get<Plan[]>('/plans');
        return response.data;
    };

    getCurrent = async (): Promise<Plan> => {
        const response: AxiosResponse<Plan> = await apiAxios.get<Plan>('/plans/current');
        return response.data;
    };
}

const planApi: PlanApi = new PlanApi();
export default planApi;
