import { Button, Col, Form, Row, Select, Switch, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { ActionStatus, CustomType, Measure } from '../../../model/model';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import settingsService from '../../../service/SettingsService';
import unitService from '../../../service/UnitService';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import MeasureValueField from '../../PersonPage/BiometricsPage/MeasureValueField/MeasureValueField';
import styles from './MidParentalHeightCalculatorPage.module.scss';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import GoogleAdsComponent from '../../Shared/GoogleAdsComponent/GoogleAdsComponent';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

/**
 * This component displays the midparental height calculator page (for external users).
 */
const MidParentalHeightCalculatorPage: React.FC<FormComponentProps> = props => {
    const { form } = props;
    const measurementSystemTypes: CustomType[] = settingsService.settings.measurementSystemTypes;

    /*** HOOKS ***/

    const context = React.useContext(CustomContext);
    const [measurementSystem, setMeasurementSystem] = useState<string>(measurementSystemTypes[0].value);
    const [actionStatus, setActionStatus] = useState<ActionStatus>();
    const [midparentalHeight, setMidParentalHeight] = useState<Measure | undefined>();

    useEffect(() => {
        const init = () => {};
        init();
    }, []);

    /*** METHODS ***/

    const handleChangeMeasurementSystem = (checked: boolean): void => {
        try {
            ga.createDefaultEvent(
                'midparental height calculator',
                'midparental height calculator - change measurement system',
            );
            const measurementSystemIndex = checked ? 1 : 0;
            const measurementSystem = settingsService.settings.measurementSystemTypes[measurementSystemIndex].value;

            form.resetFields();
            // this.init(measurementSystem);
            setMeasurementSystem(measurementSystem);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    const handleCalculate = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    setActionStatus('saving');

                    ga.createDefaultEvent('midparental height calculator', 'midparental height calculator - save');
                    calculate(values);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                setActionStatus(undefined);
            }
        });
    };

    const calculate = (values: any): void => {
        const gender = values.gender;
        const measurementSystem = values.measurementSystem ? 'ENGLISH_SYSTEM' : 'INTERNATIONAL_SYSTEM_OF_UNITS';
        const fatherMeasure: Measure = {
            value: values.fatherHeightValue,
            secondaryValue: values.fatherHeightSecondaryValue,
        };
        const motherMeasure: Measure = {
            value: values.motherHeightValue,
            secondaryValue: values.motherHeightSecondaryValue,
        };

        let fatherHeight: number = unitService.getTotalMeasure(fatherMeasure);
        let motherHeight: number = unitService.getTotalMeasure(motherMeasure);

        let midparentalHeightValue: number | undefined;
        if (gender === 'MALE' && measurementSystem === 'INTERNATIONAL_SYSTEM_OF_UNITS') {
            midparentalHeightValue = (fatherHeight + motherHeight + 13) / 2;
        } else if (gender === 'MALE' && measurementSystem === 'ENGLISH_SYSTEM') {
            midparentalHeightValue = (fatherHeight + motherHeight + 5 / 12) / 2;
        } else if (gender === 'FEMALE' && measurementSystem === 'INTERNATIONAL_SYSTEM_OF_UNITS') {
            midparentalHeightValue = (fatherHeight + motherHeight - 13) / 2;
        } else if (gender === 'FEMALE' && measurementSystem === 'ENGLISH_SYSTEM') {
            midparentalHeightValue = (fatherHeight + motherHeight - 5 / 12) / 2;
        }

        if (midparentalHeightValue) {
            const midparentalHeight: Measure = {
                unit: unitService.getUnitTypeByChartType('HEIGHT', measurementSystem).value,
                value: midparentalHeightValue,
            };
            setMidParentalHeight(midparentalHeight);
        }
    };

    /** COMPONENTS **/

    const heightUnit = unitService.getUnitTypeByChartType('HEIGHT', measurementSystem);
    const heightSecondaryUnit = unitService.getSecondaryUnitTypeByUnitType(heightUnit);
    const fieldSizeXs = heightSecondaryUnit ? 24 : 12;

    const genderOptions = context.settings.genderTypes.map(genderType => (
        <Select.Option value={genderType.value} key={genderType.value}>
            {genderType.label}
        </Select.Option>
    ));

    return (
        <>
            <HeadMetadata
                titleKey="midParentalHeightCalculator.meta.title"
                descriptionKey="midParentalHeightCalculator.meta.description"
            />
            <div className="panel">
                <div className="panel-header">
                    <div>
                        <h1>
                            <FormattedMessage id="midParentalHeightCalculator.title" />
                        </h1>
                    </div>
                </div>
                <div className={styles.layout}>
                    <Row gutter={[28, 24]}>
                        <Col xs={24} xl={19} className={styles.calculator}>
                            <div className="panel">
                                <Form onSubmit={handleCalculate} className={styles.form}>
                                    <Row gutter={[24, 0]} align="top" type="flex">
                                        <Col xs={24} sm={24} md={14} lg={14}>
                                            <Form.Item label={<FormattedMessage id="person.gender" />}>
                                                {form.getFieldDecorator('gender', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: (
                                                                <FormattedMessage id="midParentalHeightCalculator.gender.error.required" />
                                                            ),
                                                        },
                                                    ],
                                                })(<Select size="large">{genderOptions}</Select>)}
                                            </Form.Item>
                                        </Col>

                                        <Col xs={fieldSizeXs} sm={24} md={24} lg={24}>
                                            <MeasureValueField
                                                form={form}
                                                unit={heightUnit}
                                                secondaryUnit={heightSecondaryUnit}
                                                fieldName="fatherHeightValue"
                                                secondaryFieldName="fatherHeightSecondaryValue"
                                                label={<FormattedMessage id="biometrics.midparental.fatherHeight" />}
                                                required={true}
                                                size="large"
                                                metadata={false}
                                            />
                                        </Col>
                                        <Col xs={fieldSizeXs} sm={24} lg={24}>
                                            <MeasureValueField
                                                form={form}
                                                unit={heightUnit}
                                                secondaryUnit={heightSecondaryUnit}
                                                fieldName="motherHeightValue"
                                                secondaryFieldName="motherHeightSecondaryValue"
                                                label={<FormattedMessage id="biometrics.midparental.motherHeight" />}
                                                required={true}
                                                size="large"
                                                metadata={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 0]} align="middle" type="flex">
                                        <Col xs={24} sm={0} className={styles.buttons}>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                size="large"
                                                block={true}
                                                loading={actionStatus === 'saving'}
                                            >
                                                <FormattedMessage id="common.calculate" />
                                            </Button>
                                        </Col>
                                        <Col xs={0} sm={4} className={styles.button}>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                size="large"
                                                loading={actionStatus === 'saving'}
                                            >
                                                <FormattedMessage id="common.calculate" />
                                            </Button>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item>
                                                {form.getFieldDecorator('measurementSystem', {
                                                    valuePropName: 'checked',
                                                })(
                                                    <Switch
                                                        unCheckedChildren={
                                                            <FormattedMessage id="measurementSystem.metric" />
                                                        }
                                                        checkedChildren={
                                                            <FormattedMessage id="measurementSystem.english" />
                                                        }
                                                        onChange={handleChangeMeasurementSystem}
                                                        className={styles.units}
                                                    />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <ResultComponent measure={midparentalHeight} />
                                <GoogleAdsComponent />
                                <InfoComponent />
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default Form.create<FormComponentProps>()(MidParentalHeightCalculatorPage);

const ResultComponent: React.FC<ResultProps> = props => {
    const { measure } = props;

    if (measure && measure.value && measure.unit) {
        return (
            <Descriptions className={styles.result} bordered layout="vertical" column={1}>
                <Descriptions.Item
                    className={styles.info}
                    label={<FormattedMessage id="biometrics.midparental.midparentalHeight" />}
                >
                    <p className={styles.midparental}>
                        <span className={styles.midparental}>
                            <FormattedNumber
                                value={measure.value}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />{' '}
                            {measure.unit.toLowerCase()}
                        </span>
                    </p>
                    <p className={styles.description}>
                        <FormattedMessage id="biometrics.midparental.midparentalHeight.desc" />
                    </p>
                </Descriptions.Item>
            </Descriptions>
        );
    } else {
        return <></>;
    }
};

const InfoComponent: React.FC = () => {
    return (
        <div className={styles.info}>
            <h3>
                <FormattedMessage id="midparentalHeightCalculator.info.title" />
            </h3>
            <p>
                <FormattedMessage id="midparentalHeightCalculator.info.desc" />
            </p>
            <ul>
                <li>
                    <FormattedMessage id="midparentalHeightCalculator.info.1" />
                </li>
                <li>
                    <FormattedMessage id="midparentalHeightCalculator.info.2" />
                </li>
                <li>
                    <FormattedMessage id="midparentalHeightCalculator.info.3" />
                </li>
            </ul>
            <p>
                <FormattedMessage id="midparentalHeightCalculator.info.4" />
            </p>
            <p>
                <FormattedMessage id="midparentalHeightCalculator.info.5" />
            </p>
            <p>
                <FormattedMessage id="midparentalHeightCalculator.info.6" />
            </p>
        </div>
    );
};
interface ResultProps {
    measure: Measure | undefined;
}
