import React, { Component } from 'react';
import styles from './ChatPayment.module.scss';
import { Form, Input, Checkbox, Modal } from 'antd';
import { ActionStatus, ScreenSizeProps, Chat, ChatPaymentIntent, User } from '../../../../model/model';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import { FormComponentProps } from 'antd/lib/form';
import withSizes from 'react-sizes';
import responsiveService from '../../../../service/ResponsiveService';
import { Link } from 'react-router-dom';
import pathService from '../../../../service/PathService';
import StripePayment from '../../../Shared/StripePayment/StripePayment';
import paymentIntentApi from '../../../../api/PaymentIntentApi';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElement, Stripe, StripeElements } from '@stripe/stripe-js';
import CustomContext from '../../../../service/CustomContext';

class ChatPayment extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        let chatPaymentIntent: ChatPaymentIntent = {
            chatId: this.props.chat.id,
        };
        chatPaymentIntent = await paymentIntentApi.createChatPaymentIntent(chatPaymentIntent);
        this.setState({ chatPaymentIntent });
    };

    save = async (): Promise<boolean> => {
        let success: boolean = false;
        if (this.props.stripe && this.props.elements && this.state.chatPaymentIntent) {
            const user = this.context.user as User;
            // get payment method from stripe
            const result = await this.props.stripe.confirmCardPayment(
                this.state.chatPaymentIntent.clientSecret as string,
                {
                    payment_method: {
                        card: this.props.elements.getElement(CardElement) as StripeCardElement,
                        billing_details: {
                            name: user.fullName,
                            email: user.email,
                        },
                    },
                },
            );

            // process subscription
            if (result.error) {
                // show error message
            } else {
                success = true;
                // show success message
                // add webhook payment_intent.succeeded event that handles any business critical
            }
        }

        return success;
    };

    /** HANDLERS **/

    handleSave = async (): Promise<void> => {
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const success: boolean = await this.save();
                    this.setState({ actionStatus: undefined });
                    if (success) {
                        await this.props.handlePayment();
                    }
                }
            } catch (error) {
                this.setState({ actionStatus: undefined });
                errorService.displayMessage(error, [[402, 'common.notification.paymentError']]);
            }
        });
    };

    /** COMPONENTS **/

    renderPaymentMethodForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form>
                <h2>
                    <FormattedMessage id={'subscription.payment.title'} />
                </h2>
                <Form.Item label={<FormattedMessage id="subscription.payment.card.name" />}>
                    {getFieldDecorator('cardholderName', {
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="subscription.payment.card.name.required" />,
                            },
                        ],
                    })(<Input size="large" maxLength={50} />)}
                </Form.Item>

                <StripePayment
                    handleSave={this.handleSave}
                    legalCheckbox={
                        <Form.Item>
                            {getFieldDecorator('legal', {
                                valuePropName: 'checked',
                                initialValue: false,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.consent.error.required" />,
                                        transform: value => value || undefined,
                                        type: 'boolean',
                                    },
                                ],
                            })(
                                <Checkbox>
                                    <FormattedMessage id="subscription.consent" />{' '}
                                    <Link to={pathService.getPath('terms-of-contract')} className={styles.legal}>
                                        <FormattedMessage id="subscription.termsOfContract" />
                                    </Link>
                                </Checkbox>,
                            )}
                        </Form.Item>
                    }
                    buttonLabel="subscription.payment.button"
                    actionStatus={this.state.actionStatus}
                />
            </Form>
        );
    };

    render() {
        return (
            <Modal visible={true} onCancel={this.props.handleClose} footer={null}>
                {this.renderPaymentMethodForm()}
            </Modal>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(Form.create<Props>()(ChatPayment)));

interface Props extends FormComponentProps, WrappedComponentProps, ScreenSizeProps {
    chat: Chat;
    stripe: Stripe;
    elements: StripeElements;
    handlePayment: () => Promise<void>;
    handleClose: () => void;
}

interface State {
    chatPaymentIntent?: ChatPaymentIntent;
    actionStatus?: ActionStatus;
}
