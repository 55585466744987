import { Avatar, Button, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import withSizes from 'react-sizes';
import errorService from '../../../service/ErrorService';
import responsiveService from '../../../service/ResponsiveService';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import styles from './DoctorComponent.module.scss';
import nutritionist from './images/nutritionist.jpg';
import { ScreenSizeProps } from '../../../model/model';

const DoctorComponent: React.FC<ScreenSizeProps> = props => {
    const handleLink = () => {
        try {
            ga.createDefaultEvent('bmi calculator children', 'nutritionist - click button');
            const tab = window.open('https://www.mimamamemimanutricion.com/pide-cita', '_blank', 'noreferrer');
            tab && tab.focus();
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('bmi calculator children', 'nutritionist - click button');
        }
    };

    return (
        <div className={styles.container}>
            <Row type="flex">
                <Col xs={24} lg={5} className={styles.image}>
                    <Avatar src={nutritionist} size="large" className={styles.avatar} />
                </Col>
                <Col xs={24} lg={19} className={styles.information}>
                    <h3>
                        <FormattedMessage id={'bmiCalculatorChildren.info.title.nutritionist'} />
                    </h3>
                    <strong>
                        <FormattedMessage id={'bmiCalculatorChildren.info.nutritionist.1'} />
                    </strong>
                    <p>
                        <FormattedMessage id={'bmiCalculatorChildren.info.nutritionist.2'} />
                    </p>
                    <div className={styles.button}>
                        <Button
                            type="primary"
                            size="large"
                            icon="mail"
                            onClick={handleLink}
                            block={props.isXs || props.isSm}
                        >
                            <FormattedMessage id={'bmiCalculatorChildren.contact.button'} />
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withSizes(responsiveService.mapSizesToProps)(DoctorComponent);
