import React, { Component } from 'react';
import styles from './InvoicesPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { Invoice, Status } from '../../model/model';
import invoiceApi from '../../api/InvoiceApi';
import { injectIntl, FormattedMessage, WrappedComponentProps, FormattedNumber } from 'react-intl';
import CustomContext from '../../service/CustomContext';
import dateService from '../../service/DateService';
import errorService from '../../service/ErrorService';
import { List, Icon, Avatar, Row, Col, Empty } from 'antd';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';

class InvoicesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            invoices: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const invoices: Invoice[] = await invoiceApi.list();
        this.setState({ invoices });
    };

    /** HANDLERS **/

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="invoices.title" />
                    </h1>
                    <p> </p>
                </div>
            </div>
        );
    };

    renderInvoicesList = (): JSX.Element => {
        const format: string = dateService.getDateFormat(this.context.user);

        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.invoices}
                locale={{
                    emptyText: <Empty className="empty" description={<FormattedMessage id="invoices.empty" />} />,
                }}
                renderItem={invoice => (
                    <List.Item
                        actions={[
                            <a href={invoice.invoicePdf} key="download">
                                <Icon type="download" />
                            </a>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Avatar icon="audit" />}
                            title={<FormattedMessage id={`invoices.${invoice.plan}.title`} />}
                            description={`${invoice.periodStart &&
                                invoice.periodStart.format(format)} - ${invoice.periodEnd &&
                                invoice.periodEnd.format(format)}`}
                        />
                        <div hidden={!invoice.amount || !invoice.currency}>
                            <FormattedNumber
                                value={invoice.amount ? invoice.amount / 100 : 0}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />{' '}
                            {invoice.currency && invoice.currency.toUpperCase()}
                        </div>
                    </List.Item>
                )}
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19}>
                            <div className="panel">
                                {this.renderHeader()}
                                {this.renderInvoicesList()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default injectIntl(withRouter(InvoicesPage));

interface Props extends RouteComponentProps, WrappedComponentProps {}

interface State {
    invoices: Invoice[];
    status?: Status;
}
