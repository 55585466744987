import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Post, PostType, Page } from '../model/model';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';

class PostApi {
    list = async (
        page: number,
        size: number,
        language: string,
        postType: PostType | undefined,
        condition: string | undefined,
        parentId?: number,
    ): Promise<Page<Post>> => {
        const response: AxiosResponse<Page<Post>> = await apiAxios.get<Page<Post>>('/posts', {
            params: { page, size, language, postType, condition, parentId },
        });
        response.data.content.forEach(p => (p.postDate = moment(p.postDate)));
        return response.data;
    };

    count = async (language: string, condition: string | undefined, parentId?: number): Promise<number> => {
        const response: AxiosResponse<number> = await apiAxios.get<number>(`/posts/count`, {
            params: { language, condition, parentId },
        });
        return response.data;
    };

    get = async (id: number): Promise<Post> => {
        const response: AxiosResponse<Post> = await apiAxios.get<Post>(`/posts/${id}`);
        response.data.postDate = moment(response.data.postDate).utc();
        return response.data;
    };

    create = async (post: Post, attachment?: RcFile): Promise<Post> => {
        const formData: FormData = new FormData();
        if (attachment) {
            formData.append('attachment', attachment);
        }
        formData.append('entityId', (post.entityId as number).toString());
        if (post.parentId) {
            formData.append('parentId', (post.parentId as number).toString());
        }
        if (post.title) {
            formData.append('title', post.title as string);
        }
        formData.append('message', post.message as string);
        formData.append('shared', post.shared as string);
        if (post.hashtags) {
            formData.append('hashtags', post.hashtags as string);
        }
        formData.append('commentsAllowed', (post.commentsAllowed as boolean).toString());
        if (post.conditions) {
            formData.append('conditions', post.conditions.join(','));
        }
        formData.append('language', post.language as string);
        formData.append('postType', post.postType as string);
        formData.append('status', post.status as string);

        const response: AxiosResponse<Post> = await apiAxios.post<Post>('/posts', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        response.data.postDate = moment(response.data.postDate).utc();
        return response.data;
    };

    update = async (post: Post): Promise<Post> => {
        const response: AxiosResponse<Post> = await apiAxios.put<Post>(`/posts/${post.id}`, post);
        response.data.postDate = moment(response.data.postDate).utc();
        return response.data;
    };

    delete = async (post: Post): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/posts/${post.id}`);
        return response.data;
    };
}

const postApi: PostApi = new PostApi();
export default postApi;
