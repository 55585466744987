import React, { Component } from 'react';
import styles from './CookieConsentPanel.module.scss';
import { FormattedMessage } from 'react-intl';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import pathService from '../../../service/PathService';

export default class CookieConsentPanel extends Component {
    render() {
        return (
            <CookieConsent buttonText={<FormattedMessage id="cookies.gdpr.allow" />} buttonClasses={styles.allow}>
                <FormattedMessage id="cookies.gdpr.message" />
                <Link to={pathService.getPath('cookies-policy')} className={styles.settings}>
                    <FormattedMessage id="cookies.gdpr.changeSettings" />
                </Link>
            </CookieConsent>
        );
    }
}
