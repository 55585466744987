import React, { Component } from 'react';
import { Button } from 'antd';
import { Person, ScreenSizeProps } from '../../../model/model';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import CustomContext from '../../../service/CustomContext';
import FormattedMessageComponent from '../../Shared/FormattedMessageComponent';

class PersonHeader extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /** METHODS **/

    getPersonPath = (person: Person): string => {
        let path = '/persons';
        if (person.id) {
            path = `${path}/${person.id}`;
        }

        return path;
    };

    getPath = (person: Person): string => {
        let path = '/persons';
        if (person.id) {
            path = `${path}/${person.id}`;
        }

        return path;
    };

    /** COMPONENTS **/

    renderName = (): JSX.Element => {
        const path = this.getPath(this.props.person);
        if (this.props.person.id) {
            return <Link to={path}>{this.props.person.fullName}</Link>;
        } else {
            return <FormattedMessageComponent id="person.new" />;
        }
    };

    renderDescription = (): JSX.Element => {
        if (this.props.description) {
            return <p>{this.props.description}</p>;
        } else {
            return <></>;
        }
    };

    renderBackButton = (): JSX.Element => {
        const path = this.getPath(this.props.person);
        if (this.props.backUrl) {
            return (
                <Link to={this.props.backUrl} data-test="back">
                    <Button icon="arrow-left" ghost type="primary">
                        {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.back" />}
                    </Button>
                </Link>
            );
        } else if (this.props.location.pathname !== '/dashboard' && this.props.location.pathname !== path) {
            return (
                <Link to={path} data-test="back">
                    <Button icon="arrow-left" ghost type="primary">
                        {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.back" />}
                    </Button>
                </Link>
            );
        } else if (this.props.personsCount > 1) {
            return (
                <Link to="/persons" data-test="back">
                    <Button icon="swap" ghost type="primary">
                        {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.switch" />}
                    </Button>
                </Link>
            );
        } else {
            return <></>;
        }
    };

    renderActionButtons = (): JSX.Element => {
        return this.props.actionButtons || <></>;
    };

    render() {
        return (
            <div className="panel-header">
                <div className="panel-header-title">
                    <h1 data-test="person-name">{this.renderName()}</h1>
                    {this.renderDescription()}
                </div>
                <div className="panel-header-back">
                    {this.renderActionButtons()}
                    {this.renderBackButton()}
                </div>
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(withRouter(PersonHeader));

interface Props extends RouteComponentProps, ScreenSizeProps {
    person: Person;
    personsCount: number;
    description?: React.ReactElement;
    backUrl?: string;
    divider?: boolean;
    actionButtons?: React.ReactElement;
}
