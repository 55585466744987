import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class SignUpModal extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** HANDLERS **/

    handleHide = () => {
        this.props.handleClose();
    };

    /** COMPONENTS **/

    render() {
        return (
            <Modal
                title={<FormattedMessage id="signUp.modal.title" />}
                visible={this.props.visible}
                onCancel={this.handleHide}
                okText={<FormattedMessage id="signUp.modal.button" />}
                onOk={() => this.props.history.push('/signup')}
            >
                <p>
                    <FormattedMessage id="signUp.modal.desc" />
                </p>
            </Modal>
        );
    }
}
export default withRouter(SignUpModal);

interface Props extends RouteComponentProps {
    visible?: boolean;
    handleClose: () => void;
}
