import React, { Component } from 'react';
import styles from './BiometricsTable.module.scss';
import { Person, UserChart, UserMeasure, Status, UserMeasureMetadata } from '../../../../model/model';
import unitService from '../../../../service/UnitService';
import { Moment } from 'moment';
import { List } from 'antd';
import { FormattedMessage } from 'react-intl';
import dateService from '../../../../service/DateService';
import MeasureMetadata from '../MeasureMetadata/MeasureMetadata';
import FormattedDateTimeComponent from '../../../Shared/FormattedDateTimeComponent/FormattedDateTimeComponent';
import chartService from '../../../../service/ChartService';

export default class BiometricsTable extends Component<Props> {
    render() {
        let userMeasures: UserMeasure[] = this.props.userChart ? this.props.userChart.series[0].measures : [];
        userMeasures = chartService.sort(userMeasures);
        const birthdate: Moment = this.props.person.birthdate as Moment;
        const unit: string = unitService.getUnitLabelByUserChart(this.props.userChart, this.props.measurementSystem);

        if (this.props.userChart) {
            return (
                <List
                    loading={this.props.status === 'loading'}
                    itemLayout="horizontal"
                    dataSource={userMeasures}
                    locale={{ emptyText: <FormattedMessage id="biometrics.measures.empty" /> }}
                    pagination={{ pageSize: 20, hideOnSinglePage: true }}
                    className={styles.list}
                    data-test="measures"
                    renderItem={userMeasure => {
                        const age: string | undefined = dateService.getPeriod(birthdate, userMeasure.date);
                        const value = unitService.getFormattedMeasure(userMeasure, unit);
                        return (
                            <List.Item
                                actions={[
                                    <span key="measure" className={styles.measure} data-test="measureValue">
                                        {value}
                                    </span>,
                                ]}
                                onClick={() => this.props.showUserMeasure(userMeasure)}
                                className={styles.item}
                                data-test="measure"
                            >
                                <List.Item.Meta
                                    title={
                                        <FormattedDateTimeComponent date={userMeasure.date} time={userMeasure.time} />
                                    }
                                    description={
                                        <span className={styles.age} data-test="measureAge">
                                            {age}
                                        </span>
                                    }
                                    data-test="measureDate"
                                />
                                <span className={styles.metadata} data-test="measureMetadata">
                                    <MeasureMetadata
                                        userMeasure={userMeasure}
                                        userMeasureMetadatas={this.props.userMeasureMetadatas}
                                        type="short"
                                    />
                                </span>
                            </List.Item>
                        );
                    }}
                />
            );
        } else {
            return <></>;
        }
    }
}

interface Props {
    measurementSystem: string;
    dateFormat: string;
    person: Person;
    userChart: UserChart;
    userMeasureMetadatas: UserMeasureMetadata[];
    showUserMeasure: (userMeasure: UserMeasure) => void;
    status?: Status;
}
