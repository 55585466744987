import apiAxios, { apiNoAuthAxios } from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { ExternalMeasure, UserChart, ExternalUser } from '../model/model';

class ExternalMeasureApi {
    create = async (externalMeasure: ExternalMeasure): Promise<ExternalMeasure> => {
        const response: AxiosResponse<ExternalMeasure> = await apiNoAuthAxios.post(
            '/external-measures',
            externalMeasure,
            this.requestConfig,
        );
        return response.data;
    };

    init = async (externalUser: ExternalUser): Promise<UserChart> => {
        const response: AxiosResponse<UserChart> = await apiAxios.post('/external-measures/init', externalUser);
        return response.data;
    };

    private transformRequest = (externalMeasure: ExternalMeasure): any => {
        const birthdate = externalMeasure.birthdate
            ? externalMeasure.birthdate.format('YYYY-MM-DDT00:00:00.000') + 'Z'
            : undefined;
        const date = externalMeasure.date ? externalMeasure.date.format('YYYY-MM-DDT00:00:00.000') + 'Z' : undefined;
        const time = externalMeasure.time ? externalMeasure.time.format('HH:mm') : undefined;
        const transformRequest: any = Object.assign({}, externalMeasure, {
            birthdate,
            date,
            time,
        });

        return transformRequest;
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
    };
}

const externalMeasureApi: ExternalMeasureApi = new ExternalMeasureApi();
export default externalMeasureApi;
