import React, { Component } from 'react';
import styles from './SubscriptionPayment.module.scss';
import { Form, Input, Row, Col, Select, Checkbox } from 'antd';
import { ActionStatus, Subscription, Plan, ScreenSizeProps, Customer } from '../../../../model/model';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import { FormComponentProps } from 'antd/lib/form';
import StripePayment from '../../../Shared/StripePayment/StripePayment';
import subscriptionApi from '../../../../api/SubscriptionApi';
import withSizes from 'react-sizes';
import responsiveService from '../../../../service/ResponsiveService';
import i18nService from '../../../../service/I18nService';
import customerApi from '../../../../api/CustomerApi';
import paymentMethodApi from '../../../../api/PaymentMethodApi';
import { Link } from 'react-router-dom';
import pathService from '../../../../service/PathService';
import { CardElement } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js';

class SubscriptionPayment extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            customer: { address: {} },
        };
    }

    async componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = (): void => {
        const customer: Customer = Object.assign({}, this.props.customer);
        this.setState({ customer });
    };

    save = async (values: any): Promise<boolean> => {
        let success: boolean = false;
        if (this.props.stripe && this.props.elements) {
            // get payment method from stripe
            const cardElement = this.props.elements.getElement(CardElement) as StripeCardElement;
            const response = await this.props.stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: values.cardholderName,
                },
            });

            // process subscription
            if (response.paymentMethod) {
                // save customer
                const paymentMethod = response.paymentMethod;
                let customer: Customer = Object.assign({}, this.state.customer, {
                    name: values.name,
                    taxId: values.taxId,
                    address: {
                        address: values.address,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                        postalCode: values.postalCode,
                    },
                    taxExempt: undefined,
                });
                if (customer.id) {
                    customer = await customerApi.update(customer);
                    await paymentMethodApi.create({ id: paymentMethod.id });
                } else {
                    customer.paymentMethodId = paymentMethod.id;
                    customer = await customerApi.create(customer);
                }
                this.setState({ customer });

                // save subscription
                let subscription: Subscription = {
                    planId: this.props.plan.id,
                };
                subscription = await subscriptionApi.create(subscription);

                // if payment requires authentication, show stripe confirmation panel
                if (subscription.lastInvoicePaymentIntentStatus === 'requires_action') {
                    const result = await this.props.stripe.confirmCardPayment(
                        subscription.lastInvoicePaymentClientSecret as string,
                    );
                    if (result.error) {
                        errorService.displayCustomMessage('subscription.error.paymentDeclined');
                    } else {
                        success = true;
                    }
                } // if payment still requires a payment method, show error message
                else if (subscription.lastInvoicePaymentIntentStatus === 'requires_payment_method') {
                    errorService.displayCustomMessage('common.notification.paymentError');
                } // otherwise payment was successful
                else {
                    success = true;
                }
            }
        }

        return success;
    };

    /** HANDLERS **/

    handleSave = async (): Promise<void> => {
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const success = await this.save(values);
                    this.setState({ actionStatus: undefined });
                    if (success) {
                        await this.props.handlePayment();
                    }
                }
            } catch (error) {
                this.setState({ actionStatus: undefined });
                errorService.displayMessage(error, [[402, 'common.notification.paymentError']]);
            }
        });
    };

    /** COMPONENTS **/

    renderPaymentMethodForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const size: number = this.props.isLg ? 12 : 24;
        const countryOptions = i18nService.getCountries().map(country => (
            <Select.Option value={country.code} key={country.code}>
                {country.name}
            </Select.Option>
        ));

        return (
            <Form>
                <h2>
                    <FormattedMessage id={'subscription.billing.title'} />
                </h2>
                <Row gutter={24}>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.name" />}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.billing.name.required" />,
                                    },
                                ],
                            })(<Input size="large" maxLength={100} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.taxId" />}>
                            {getFieldDecorator('taxId')(<Input size="large" maxLength={50} />)}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.address" />}>
                            {getFieldDecorator('address', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.billing.address.required" />,
                                    },
                                ],
                            })(<Input size="large" maxLength={100} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.city" />}>
                            {getFieldDecorator('city')(<Input size="large" maxLength={50} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.state" />}>
                            {getFieldDecorator('state')(<Input size="large" maxLength={50} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.country" />}>
                            {getFieldDecorator('country', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.billing.country.required" />,
                                    },
                                ],
                            })(<Select size="large">{countryOptions}</Select>)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.postalCode" />}>
                            {getFieldDecorator('postalCode')(<Input size="large" maxLength={10} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <h2>
                    <FormattedMessage id={'subscription.payment.title'} />
                </h2>
                <Form.Item label={<FormattedMessage id="subscription.payment.card.name" />}>
                    {getFieldDecorator('cardholderName', {
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="subscription.payment.card.name.required" />,
                            },
                        ],
                    })(<Input size="large" maxLength={50} />)}
                </Form.Item>

                <StripePayment
                    handleSave={this.handleSave}
                    legalCheckbox={
                        <Form.Item>
                            {getFieldDecorator('legal', {
                                valuePropName: 'checked',
                                initialValue: false,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.consent.error.required" />,
                                        transform: value => value || undefined,
                                        type: 'boolean',
                                    },
                                ],
                            })(
                                <Checkbox>
                                    <FormattedMessage id="subscription.consent" />{' '}
                                    <Link to={pathService.getPath('terms-of-contract')} className={styles.legal}>
                                        <FormattedMessage id="subscription.termsOfContract" />
                                    </Link>
                                </Checkbox>,
                            )}
                        </Form.Item>
                    }
                    buttonLabel="subscription.payment.button"
                    actionStatus={this.state.actionStatus}
                />
            </Form>
        );
    };

    render() {
        return <>{this.renderPaymentMethodForm()}</>;
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(Form.create<Props>()(SubscriptionPayment)));

interface Props extends FormComponentProps, WrappedComponentProps, ScreenSizeProps {
    plan: Plan;
    customer?: Customer;
    stripe: Stripe;
    elements: StripeElements;
    handlePayment: () => Promise<void>;
}

interface State {
    customer: Customer;
    actionStatus?: ActionStatus;
}
