import apiAxios from './CustomAxios';
import { Log } from '../model/model';

class LogApi {
    create = async (log: Log): Promise<void> => {
        await apiAxios.post('/logs', log);
    };
}

const logApi: LogApi = new LogApi();
export default logApi;
