import { Sizes } from 'react-sizes';
import { ScreenSizeProps } from '../model/model';

class ResponsiveService {
    screenSizeProps: ScreenSizeProps;
    readonly xs: number = 480;
    readonly sm: number = 576;
    readonly md: number = 768;
    readonly lg: number = 992;
    readonly xl: number = 1200;
    readonly xxl: number = 1600;

    constructor() {
        this.screenSizeProps = {
            isXs: true,
            isSm: false,
            isMd: false,
            isLg: false,
            isXlg: false,
        };
    }

    mapSizesToProps = (sizes: Sizes): any => {
        return {
            isXs: sizes.width < this.xs,
            isSm: sizes.width >= this.xs && sizes.width < this.sm,
            isMd: sizes.width >= this.sm && sizes.width < this.md,
            isLg: sizes.width >= this.md,
            isXlg: sizes.width >= this.xl,
        };
    };
}

const responsiveService = new ResponsiveService();
export default responsiveService;
