import React, { Component } from 'react';
import styles from './HomeVideoConsultationPage.module.scss';
import doctors from '../images/doctors.png';
import patients from '../images/patients.png';
import chat from '../images/chat.png';
import charts1 from '../images/charts-1.png';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import CustomContext from '../../../service/CustomContext';
import { Link } from 'react-router-dom';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import i18nService from '../../../service/I18nService';
import HomeDemoComponent from '../HomeDemoComponent/HomeDemoComponent';

class HomeVideoConsultationPage extends Component {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /** COMPONENTS **/

    renderRightPanel = (index: number, image: string): JSX.Element => {
        return (
            <Row className={`${styles.section} ${styles.right}`} type="flex" justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image}>
                    <img src={image} alt={image} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className={styles.message}>
                        <h3>
                            <FormattedMessage id={`homeVideoConsultation.${index}.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`homeVideoConsultation.${index}.subtitle`} />
                        </p>
                    </div>
                </Col>
            </Row>
        );
    };

    renderLeftPanel = (index: number, image: string): JSX.Element => {
        return (
            <Row className={`${styles.section} ${styles.left}`} type="flex" justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className={styles.message}>
                        <h3>
                            <FormattedMessage id={`homeVideoConsultation.${index}.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`homeVideoConsultation.${index}.subtitle`} />
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image}>
                    <img src={image} alt={image} />
                </Col>
            </Row>
        );
    };

    renderFullPanel = (index: number, image?: string): JSX.Element => {
        return (
            <Row
                className={`${styles.section} ${styles.full} ${image ? '' : styles.text}`}
                type="flex"
                justify="center"
                align="middle"
            >
                <Col xs={24}>
                    <div className={styles.message}>
                        <h3>
                            <FormattedMessage id={`homeVideoConsultation.${index}.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`homeVideoConsultation.${index}.subtitle`} />
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image} hidden={!image}>
                    <img src={image} alt={image} />
                </Col>
            </Row>
        );
    };

    renderVideoPanel = (): JSX.Element => {
        const video = i18nService.intl.locale === 'es' ? '/videos/es.presentation.mp4' : '/videos/en.presentation.mp4';

        return (
            <Row className={`${styles.section} ${styles.services}`} type="flex" justify="center" align="middle">
                <Col xs={24} className={styles.message}>
                    <div>
                        <h3>
                            <FormattedMessage id="home.video.title" />
                        </h3>
                        <p>
                            <FormattedMessage id="home.video.subtitle.1" />
                        </p>
                        <p>
                            <FormattedMessage id="home.video.subtitle.2" />
                        </p>
                    </div>
                </Col>
                <Col xs={24} className={styles.video}>
                    <video controls width="100%">
                        <source src={video} type="video/mp4" />
                    </video>
                </Col>
            </Row>
        );
    };

    renderDemoPanel = (): JSX.Element => {
        return <HomeDemoComponent />;
    };

    render() {
        return (
            <>
                <HeadMetadata
                    titleKey="homeVideoConsultation.meta.title"
                    descriptionKey="homeVideoConsultation.meta.description"
                />
                <div>
                    <div className={styles.top}>
                        <div className={styles.back}></div>
                        <div className={styles.headline}>
                            <h1>
                                <FormattedMessage id="homeVideoConsultation.title" />
                            </h1>
                            <h2>
                                <FormattedMessage id="homeVideoConsultation.subtitle" />
                            </h2>
                            <div className={styles.buttons}>
                                <Link to="/signup">
                                    <Button type="primary" className={styles.button}>
                                        {this.context.user ? (
                                            <FormattedMessage id="home.dashboard" />
                                        ) : (
                                            <FormattedMessage id="home.signUp" />
                                        )}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {this.renderRightPanel(5, chat)}
                    {this.renderFullPanel(1, doctors)}
                    {this.renderLeftPanel(2, charts1)}
                    {this.renderFullPanel(4)}
                    {this.renderRightPanel(3, patients)}
                    {this.renderVideoPanel()}
                    {this.renderDemoPanel()}
                </div>
            </>
        );
    }
}
export default HomeVideoConsultationPage;
