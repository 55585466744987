import * as React from 'react';
import styles from './CampaignComponent.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ToolKey, ScreenSizeProps } from '../../../model/model';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { Row, Col, Button, Modal, Icon } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class CampaignComponent extends React.Component<Props> {
    /**METHODS */

    showSavingModal = () => {
        const modal = Modal.success({
            icon: <Icon type="loading" />,
            title: this.props.intl.formatMessage({ id: `${this.props.tool}.campaign.save.modal.title` }),
            content: this.props.intl.formatMessage({ id: `${this.props.tool}.campaign.save.modal.description` }),
            okButtonProps: { hidden: true },
        });
        setTimeout(() => {
            modal.destroy();
            this.props.history.push('/signup');
        }, 4000);
    };

    /** HANDLERS **/

    handleSave = () => {
        if (this.props.handleSave) {
            this.props.handleSave();
            this.showSavingModal();
        } else {
            this.props.history.push('/signup');
        }
    };

    /** COMPONENTS **/

    renderButton = (): JSX.Element => {
        const label = this.props.handleSave ? `${this.props.tool}.campaign.save` : 'signUp.signUp';
        return (
            <div className={styles.signup}>
                <Button type="danger" size="large" block={true} className={styles.save} onClick={this.handleSave}>
                    <FormattedMessage id={label} />
                </Button>
            </div>
        );
    };

    render() {
        if (this.props.tool === 'bmiCalculatorChildren') {
            return (
                <Row gutter={[24, 0]} className={styles.campaign}>
                    <p>
                        <FormattedMessage id={`${this.props.tool}.campaign.description`} />
                    </p>
                    <Col span={24}>{this.renderButton()}</Col>
                </Row>
            );
        } else {
            return (
                <Row gutter={[24, 0]} className={styles.campaign}>
                    <Col span={16}>
                        <h3>
                            <FormattedMessage id={`${this.props.tool}.campaign.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`${this.props.tool}.campaign.description`} />
                        </p>
                    </Col>
                    <Col span={8} className={styles.ads}>
                        <div>{this.renderButton()}</div>
                    </Col>
                </Row>
            );
        }
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(CampaignComponent)));

interface Props extends WrappedComponentProps, RouteComponentProps, ScreenSizeProps {
    tool: ToolKey;
    handleSave?: () => void;
}
