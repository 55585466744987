import React, { Component } from 'react';
import styles from './PostReactionButtonComponent.module.scss';
import { Icon } from 'antd';
import { Post, PostReaction, User, ActionStatus } from '../../../../../model/model';
import errorService from '../../../../../service/ErrorService';
import postReactionApi from '../../../../../api/PostReactionApi';
import CustomContext from '../../../../../service/CustomContext';
import SignUpModal from '../../../../Shared/SignUpModal/SignUpModal';

class PostReactionButtonComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            count: 0,
        };
    }

    async componentDidMount() {
        try {
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        this.count();
        this.isSelected();
    };

    count = async () => {
        const count: number = await postReactionApi.count(this.props.post.id as number);
        this.setState({ count });
    };

    isSelected = async (): Promise<boolean> => {
        const user: User = this.context.user as User;
        const count: number = await postReactionApi.count(this.props.post.id as number, user.entity!.id);
        const selected: boolean = count > 0;
        this.setState({ selected });

        return selected;
    };

    select = async (): Promise<void> => {
        const isSelected: boolean = await this.isSelected();
        if (isSelected) {
            await postReactionApi.delete(this.props.post.id as number);
        } else {
            const user: User = this.context.user as User;
            let postReaction: PostReaction = {
                entityId: user.entity!.id,
                postId: this.props.post.id,
                reaction: 'LIKE',
            };
            await postReactionApi.create(postReaction);
        }

        this.init();
    };

    /** HANDLERS **/

    handleSelect = async (): Promise<void> => {
        const user: User = this.context.user as User;
        if (user && this.state.actionStatus !== 'saving') {
            try {
                this.setState({ actionStatus: 'saving' });
                await this.select();
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        } else if (!user) {
            this.setState({ signUpVisible: true });
        }
    };

    handleHideSignUp = () => {
        this.setState({ signUpVisible: false });
    };

    /** COMPONENTS **/

    render() {
        const theme: 'twoTone' | 'outlined' = this.state.selected ? 'twoTone' : 'outlined';
        return (
            <>
                <span className={styles.action} onClick={this.handleSelect}>
                    <Icon type="like" theme={theme} /> {this.state.count}
                </span>
                <SignUpModal visible={this.state.signUpVisible} handleClose={this.handleHideSignUp} />
            </>
        );
    }
}
export default PostReactionButtonComponent;

interface Props {
    post: Post;
}

interface State {
    count: number;
    selected?: boolean;
    signUpVisible?: boolean;
    actionStatus?: ActionStatus;
}
