import React, { Component } from 'react';
import { UserMeasure, UserMeasureMetadata } from '../../../../model/model';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export default class MeasureMetadata extends Component<Props> {
    /** METHODS **/

    isValidUserMeasure = (): boolean => {
        return (
            !!this.props.userMeasure.date && (!!this.props.userMeasure.value || !!this.props.userMeasure.secondaryValue)
        );
    };

    isValidUserMetadata = (userMeasureMetadata?: UserMeasureMetadata): boolean => {
        return (
            !!userMeasureMetadata &&
            userMeasureMetadata.standardDeviation !== undefined &&
            userMeasureMetadata.standardDeviation !== null
        );
    };

    getUserMetadata = (): UserMeasureMetadata | undefined => {
        const userMeasureMetadata: UserMeasureMetadata | undefined = this.props.userMeasureMetadatas.find(umm =>
            moment(umm.userMeasure.date).isSame(moment(this.props.userMeasure.date), 'date'),
        );

        return this.isValidUserMeasure() && this.isValidUserMetadata(userMeasureMetadata)
            ? userMeasureMetadata
            : undefined;
    };

    /** COMPONENTS **/

    render() {
        const userMeasureMetadata: UserMeasureMetadata | undefined = this.getUserMetadata();
        if (userMeasureMetadata && this.props.type === 'short') {
            return (
                <>
                    {userMeasureMetadata.standardDeviation}
                    <FormattedMessage id="measureMetadata.standardDeviation.short" />, {userMeasureMetadata.percentile}
                </>
            );
        } else if (userMeasureMetadata) {
            return (
                <>
                    <FormattedMessage id="measureMetadata.standardDeviation" />: {userMeasureMetadata.standardDeviation}
                    , <FormattedMessage id="measureMetadata.percentile" />: {userMeasureMetadata.percentile}
                </>
            );
        } else {
            return <></>;
        }
    }
}

interface Props {
    userMeasure: UserMeasure;
    userMeasureMetadatas: UserMeasureMetadata[];
    type?: 'normal' | 'short';
}
