import React, { Component } from 'react';
import { Status, Person } from '../../../../model/model';
import errorService from '../../../../service/ErrorService';
import { Layout } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment, { Moment } from 'moment';
import CustomContext from '../../../../service/CustomContext';
import CalendarRecordComponent from '../../../Shared/CalendarComponent/CalendarRecordComponent/CalendarRecordComponent';

class DiaryRecordPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = () => {
        const date = this.props.match.params.date ? moment.utc(this.props.match.params.date) : moment().utc();
        this.setState({ date });
    };

    renderCalendarRecord = (): JSX.Element => {
        return this.state.date ? <CalendarRecordComponent date={this.state.date} person={this.props.person} /> : <></>;
    };

    render() {
        return <Layout.Content>{this.renderCalendarRecord()}</Layout.Content>;
    }
}
export default withRouter(DiaryRecordPage);

interface Props extends RouteComponentProps {
    match: any;
    person: Person;
}

interface State {
    date?: Moment;
    status?: Status;
}
