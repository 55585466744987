import React, { Component } from 'react';
import styles from './PostComponent.module.scss';
import withSizes from 'react-sizes';
import { List, Icon, Tag } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import CustomContext from '../../../../service/CustomContext';
import { ScreenSizeProps, Post, User, CustomType, MedicalConditionType } from '../../../../model/model';
import dateService from '../../../../service/DateService';
import responsiveService from '../../../../service/ResponsiveService';
import PostReactionButtonComponent from './PostReactionButtonComponent/PostReactionButtonComponent';
import settingsService from '../../../../service/SettingsService';
import AvatarComponent from '../../../Shared/AvatarComponent/AvatarComponent';
import Linkify from 'linkifyjs/react';
import { Link } from 'react-router-dom';
import PostCommentsComponent from './PostCommentsComponent/PostCommentsComponent';
import PostCommentButtonComponent from './PostCommentButtonComponent/PostCommentButtonComponent';
import postApi from '../../../../api/PostApi';
import pathService from '../../../../service/PathService';

class PostComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { countMessages: 0, commentsVisible: !props.summary };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = (): void => {
        this.countMessages();
    };

    countMessages = async () => {
        const language: string = this.props.post.language as string;
        const countMessages: number = await postApi.count(language, undefined, this.props.post.id);
        this.setState({ countMessages });
    };

    /** HANDLERS **/

    handleShowPostEdit = () => {
        this.props.handleShowPostEdit && this.props.handleShowPostEdit(this.props.post.id);
    };

    handleReadMore = () => {
        this.setState({ readMore: true });
    };

    handleToggleComments = () => {
        const commentsVisible: boolean = !this.state.commentsVisible;
        this.setState({ commentsVisible });
    };

    handleCommentsUpdate = () => {
        this.countMessages();
    };

    /** COMPONENTS **/

    renderPostHeader = (): JSX.Element => {
        const post: Post = this.props.post;
        return (
            <>
                {post.entityName} {this.renderPostStatus()}
            </>
        );
    };

    renderPostDescription = (): JSX.Element => {
        const post: Post = this.props.post;
        const format: string = dateService.getDateFormat(this.context.user);
        return <span className={styles.date}>{post.postDate && post.postDate.format(format)}</span>;
    };

    renderPostStatus = (): JSX.Element => {
        const user: User = this.context.user as User;
        if (user && user.admin) {
            const post: Post = this.props.post;
            const status: CustomType = settingsService.getPostStatusByValue(post.status as string);
            let color: string;
            switch (post.status) {
                case 'APPROVED':
                    color = 'blue';
                    break;
                case 'BLOCKED':
                    color = 'red';
                    break;
                default:
                    color = 'orange';
                    break;
            }

            return (
                <Tag color={color} className={styles.status}>
                    {status.label}
                </Tag>
            );
        } else {
            return <></>;
        }
    };

    renderPostTitle = (): JSX.Element => {
        const post: Post = this.props.post;
        if (post.title && this.props.summary && this.props.condition) {
            const condition: MedicalConditionType = this.props.condition as MedicalConditionType;
            return (
                <div className={styles.title}>
                    <Link to={`${pathService.getPath('posts')}/${condition.path}/${this.props.post.id}`}>
                        {post.title}
                    </Link>
                </div>
            );
        } else if (post.title && this.props.summary) {
            return <div className={styles.title}>{post.title}</div>;
        } else if (post.title) {
            return <h1 className={styles.title}>{post.title}</h1>;
        } else {
            return <></>;
        }
    };

    renderPostMessage = (): JSX.Element => {
        const post: Post = this.props.post;
        const postLength: number = this.props.postLength || 300;
        if (post.message && post.message.length > postLength && !this.state.readMore) {
            let truncatedMessage: string = post.message.substring(0, postLength - 3) + '...';
            return (
                <div className={styles.message}>
                    <Linkify options={{ target: '_blank', nl2br: true }}>{truncatedMessage}</Linkify>{' '}
                    <span onClick={this.handleReadMore} className={styles.more}>
                        <FormattedMessage id="common.readMore" />
                    </span>
                </div>
            );
        } else {
            return (
                <div className={styles.message}>
                    <Linkify options={{ target: '_blank', nl2br: true }}>{post.message}</Linkify>
                </div>
            );
        }
    };

    renderPostImage = (): JSX.Element => {
        const post: Post = this.props.post;
        if (post.fileName) {
            return (
                <div className={styles.image} hidden={!post.fileName}>
                    <img src={`${process.env.REACT_APP_API_URL}/posts/${post.id}/file`} alt="post" />
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderPostConditions = (): JSX.Element => {
        const post: Post = this.props.post;
        if (post.conditions && !this.props.condition) {
            const conditionTags = post.conditions
                .map(condition => settingsService.getMedicalConditionByValue(condition))
                .map(condition => {
                    return (
                        <Link key={condition.value} to={`${pathService.getPath('posts')}/${condition.path}`}>
                            <Tag>{condition.label}</Tag>
                        </Link>
                    );
                });
            return <div className={styles.conditions}>{conditionTags}</div>;
        } else {
            return <></>;
        }
    };

    renderPostEdit = (): JSX.Element => {
        return (
            <span className={styles.action} onClick={this.handleShowPostEdit}>
                <Icon type="edit" />
                <FormattedMessage id="common.modify" />
            </span>
        );
    };

    PostActions = (): React.ReactNode[] => {
        const post: Post = this.props.post;
        const user: User = this.context.user as User;

        const actions: React.ReactNode[] = [];
        actions.push(<PostReactionButtonComponent key="reaction" post={post} />);
        actions.push(
            <PostCommentButtonComponent
                key="comment"
                count={this.state.countMessages}
                handleSelect={this.handleToggleComments}
            />,
        );
        if (user && (user.entity!.id === this.props.post.entityId || user.admin) && this.props.handleShowPostEdit) {
            actions.push(this.renderPostEdit());
        }

        return actions;
    };

    render() {
        const post: Post = this.props.post;

        return (
            <div>
                <List.Item key={post.id} className={styles.post} actions={this.PostActions()}>
                    <List.Item.Meta
                        avatar={<AvatarComponent name={post.entityName} />}
                        title={this.renderPostHeader()}
                        description={this.renderPostDescription()}
                    />
                    {this.renderPostTitle()}
                    {this.renderPostMessage()}
                    {this.renderPostImage()}
                    {this.renderPostConditions()}
                </List.Item>
                <PostCommentsComponent
                    post={post}
                    visible={this.state.commentsVisible}
                    handleCommentsUpdate={this.handleCommentsUpdate}
                />
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(PostComponent));

interface Props extends WrappedComponentProps, ScreenSizeProps {
    post: Post;
    condition?: MedicalConditionType;
    postLength?: number;
    summary?: boolean;
    handleShowPostEdit?: (postEditId?: number) => void;
}

interface State {
    readMore?: boolean;
    countMessages: number;
    commentsVisible?: boolean;
}
