import React, { Component, FormEvent } from 'react';
import styles from './CalendarRecordComponent.module.scss';
import {
    DiaryRecord,
    DiaryAnswer,
    Status,
    ActionStatus,
    Person,
    DiaryTemplate,
    User,
    DiaryTemplateType,
    Locale,
} from '../../../../model/model';
import Form, { FormComponentProps } from 'antd/lib/form';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import { Row, Col, message, Button, Popconfirm, TimePicker, Switch, Slider, Select } from 'antd';
import diaryRecordApi from '../../../../api/DiaryRecordApi';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DiaryTemplatesComponent from './CalendarTemplatesComponent/CalendarTemplatesComponent';
import i18nService from '../../../../service/I18nService';
import moment, { Moment } from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import unitService from '../../../../service/UnitService';
import CustomContext from '../../../../service/CustomContext';
import settingsService from '../../../../service/SettingsService';
import Loader from '../../../Loader/Loader';
import diaryTemplateApi from '../../../../api/DiaryTemplateApi';
import MedicationField from '../../MedicationField/MedicationField';
import personApi from '../../../../api/PersonApi';
import MeasureValueField from '../../../PersonPage/BiometricsPage/MeasureValueField/MeasureValueField';
import FormattedMessageComponent from '../../FormattedMessageComponent';

class CalendarRecordComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            persons: [],
            diaryRecord: {
                answers: [],
            },
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    async componentDidUpdate(prevProps: Props) {
        const diaryTemplateType: DiaryTemplateType | undefined = this.getDiaryTemplateType();
        if (this.state.diaryTemplateType !== diaryTemplateType) {
            const diaryRecord: DiaryRecord = Object.assign({}, this.state.diaryRecord, {
                name: undefined,
                answers: [],
            });
            this.setState({ diaryTemplateType, diaryRecord });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        let diaryRecord: DiaryRecord;
        let diaryTemplateType: DiaryTemplateType | undefined;
        const personId = this.props.person ? this.props.person.id : undefined;
        if (this.props.match.params.id && this.props.match.params.id === 'new') {
            const diaryTemplate = await this.getDiaryTemplate();
            diaryRecord = this.createDiaryRecord(diaryTemplate);
            diaryTemplateType = this.getDiaryTemplateType();
        } else {
            diaryRecord = await diaryRecordApi.get(this.props.match.params.id);
            diaryRecord.answers = diaryRecord.answers.sort(
                (a: DiaryAnswer, b: DiaryAnswer) => (a.position as number) - (b.position as number),
            );
        }

        // list persons
        let persons: Person[] = [];
        if (!personId) {
            const personsPage = await personApi.list(0, 1000);
            persons = personsPage.content;
        }

        this.setState({ diaryRecord, diaryTemplateType, persons });
    };

    getDate = (): Moment => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const date = params.get('date') as string;
        const time = moment();
        return moment
            .utc(date)
            .set('hours', time.get('hours'))
            .set('minutes', time.get('minutes'))
            .set('seconds', time.get('seconds'));
    };

    getDiaryTemplateType = (): DiaryTemplateType | undefined => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        return params.get('diaryTemplateType') === null
            ? undefined
            : (params.get('diaryTemplateType') as DiaryTemplateType);
    };

    getDiaryTemplateId = (): number | undefined => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        return params.get('diaryTemplateId') === null ? undefined : (params.get('diaryTemplateId') as any);
    };

    getDiaryTemplate = async (): Promise<DiaryTemplate | undefined> => {
        const diaryTemplateId = this.getDiaryTemplateId();
        let diaryTemplate: DiaryTemplate | undefined;
        if (diaryTemplateId) {
            diaryTemplate = await diaryTemplateApi.get(diaryTemplateId);
        }
        return diaryTemplate;
    };

    createDiaryRecord = (diaryTemplate?: DiaryTemplate): DiaryRecord => {
        const name = diaryTemplate ? diaryTemplate.name : undefined;
        const description = diaryTemplate ? diaryTemplate.description : undefined;
        const questions = diaryTemplate ? diaryTemplate.questions : [];
        const answers: DiaryAnswer[] = questions
            .map(answer => {
                return {
                    question: answer.question,
                    format: answer.format,
                    position: answer.position,
                };
            })
            .sort((a: DiaryAnswer, b: DiaryAnswer) => (a.position as number) - (b.position as number));
        const diaryRecord: DiaryRecord = Object.assign({}, this.state.diaryRecord, {
            personId: this.props.person ? this.props.person.id : undefined,
            name,
            description,
            language: i18nService.intl.locale.toUpperCase() as Locale,
            date: this.getDate(),
            answers,
        });

        return diaryRecord;
    };

    selectDiaryTemplate = (diaryTemplate?: DiaryTemplate) => {
        const diaryRecord = this.createDiaryRecord(diaryTemplate);
        this.setState({ diaryRecord });
    };

    save = async (values: any) => {
        let diaryRecord: DiaryRecord = Object.assign({}, this.state.diaryRecord, {
            personId: this.props.person ? this.props.person.id : values.personId,
        });
        diaryRecord.answers.forEach(answer => {
            answer.value = values.answers[answer.position as number].value;
            answer.secondaryValue = values.answers[answer.position as number].secondaryValue;
            answer.text = values.answers[answer.position as number].text;
            if (answer.format === 'MEDICATION') {
                answer.unit = values.answers[answer.position as number].unit;
            }
        });
        diaryRecord.id ? await diaryRecordApi.update(diaryRecord) : await diaryRecordApi.create(diaryRecord);
    };

    delete = async () => {
        await diaryRecordApi.delete(this.state.diaryRecord);
    };

    getDatePath = (date: string): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/dates/${date}` : `/calendar/dates/${date}`;
    };

    /** HANDLERS **/

    handleChangeTime = (date: Moment) => {
        const diaryRecord: DiaryRecord = Object.assign({}, this.state.diaryRecord, {
            date,
        });
        this.setState({ diaryRecord });
    };

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.save(values);
                    const date = (this.state.diaryRecord.date as Moment).format('YYYY-MM-DD');
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7, () =>
                        this.props.history.push(this.getDatePath(date)),
                    );
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'diaryRecord.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            const date = (this.state.diaryRecord.date as Moment).format('YYYY-MM-DD');
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7, () =>
                this.props.history.push(this.getDatePath(date)),
            );
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderDatePicker = (): JSX.Element => {
        const format = 'HH:mm';
        return (
            <div className={styles.date}>
                <span className={styles.day}>
                    {this.state.diaryRecord.date && this.state.diaryRecord.date.format('DD MMMM YYYY')}
                </span>
                <TimePicker
                    value={this.state.diaryRecord.date}
                    format={format}
                    size="large"
                    allowClear={false}
                    onChange={this.handleChangeTime}
                />
            </div>
        );
    };

    renderDiaryTemplates = (): JSX.Element => {
        if (this.state.diaryRecord.name) {
            return (
                <div className={styles.template}>
                    <h2>{this.state.diaryRecord.name}</h2>
                    <p>{this.state.diaryRecord.description}</p>
                </div>
            );
        } else {
            return (
                <div className={styles.templates}>
                    <DiaryTemplatesComponent
                        person={this.props.person}
                        date={this.getDate().format('YYYY-MM-DD')}
                        diaryTemplateType={this.state.diaryTemplateType}
                        onSelectDiaryTemplate={this.selectDiaryTemplate}
                    />
                </div>
            );
        }
    };

    renderPersonSelect = (diaryRecord: DiaryRecord): JSX.Element => {
        if (this.props.person) {
            return <></>;
        } else {
            const { getFieldDecorator } = this.props.form;
            const personOptions = this.state.persons.map(person => (
                <Select.Option value={person.id} key={person.id}>
                    {person.fullName}
                </Select.Option>
            ));

            return (
                <Row gutter={[24, 0]}>
                    <Col xs={24}>
                        <Form.Item label={<FormattedMessageComponent id="diaryRecord.personId" />}>
                            {getFieldDecorator('personId', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="diaryRecord.personId.error.required" />,
                                    },
                                ],
                            })(
                                <Select size="large" data-test="personId" disabled={diaryRecord.readonly}>
                                    {personOptions}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            );
        }
    };

    renderAnswer = (answer: DiaryAnswer): JSX.Element => {
        let field;
        switch (answer.format) {
            case 'TEXT':
                field = this.renderAnswerText(answer);
                break;
            case 'BOOLEAN':
                field = this.renderAnswerBoolean(answer);
                break;
            case 'LEVEL':
                field = this.renderAnswerLevel(answer);
                break;
            case 'FREQUENCY':
                field = this.renderAnswerFrequency(answer);
                break;
            case 'SCALE':
                field = this.renderAnswerScale(answer);
                break;
            case 'GRADE':
                field = this.renderAnswerGrade(answer);
                break;
            case 'MEDICATION':
                field = this.renderAnswerMedication(answer);
                break;
            default:
                field = this.renderAnswerMeasure(answer);
                break;
        }

        return (
            <Row
                gutter={[24, 0]}
                className={styles.answer}
                key={answer.position}
                data-test={`answer-${answer.position}`}
            >
                <Col span={24}>{field}</Col>
            </Row>
        );
    };

    renderAnswerBoolean = (answer: DiaryAnswer): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={answer.question} key={answer.position} colon={false}>
                {getFieldDecorator(`answers[${answer.position}].value`, {
                    initialValue: answer.value,
                    valuePropName: 'checked',
                    normalize: (value: boolean) => (value ? 1 : 0),
                })(
                    <Switch
                        checkedChildren={<FormattedMessage id="common.yes" />}
                        unCheckedChildren={<FormattedMessage id="common.no" />}
                        disabled={this.state.diaryRecord.readonly}
                    />,
                )}
            </Form.Item>
        );
    };

    renderAnswerLevel = (answer: DiaryAnswer): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={answer.question} key={answer.position} colon={false}>
                {getFieldDecorator(`answers[${answer.position}].value`, {
                    initialValue: answer.value || 0,
                })(
                    <Slider
                        tooltipVisible={false}
                        max={3}
                        marks={{
                            0: <FormattedMessage id="diaryRecord.answer.level.none" />,
                            1: <FormattedMessage id="diaryRecord.answer.level.low" />,
                            2: <FormattedMessage id="diaryRecord.answer.level.medium" />,
                            3: <FormattedMessage id="diaryRecord.answer.level.high" />,
                        }}
                        disabled={this.state.diaryRecord.readonly}
                    />,
                )}
            </Form.Item>
        );
    };

    renderAnswerFrequency = (answer: DiaryAnswer): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={answer.question} key={answer.position} colon={false}>
                {getFieldDecorator(`answers[${answer.position}].value`, {
                    initialValue: answer.value || 0,
                })(
                    <Slider
                        tooltipVisible={false}
                        max={3}
                        marks={{
                            0: <FormattedMessage id="diaryRecord.answer.frequency.never" />,
                            1: <FormattedMessage id="diaryRecord.answer.frequency.rarely" />,
                            2: <FormattedMessage id="diaryRecord.answer.frequency.often" />,
                            3: <FormattedMessage id="diaryRecord.answer.frequency.always" />,
                        }}
                        disabled={this.state.diaryRecord.readonly}
                    />,
                )}
            </Form.Item>
        );
    };

    renderAnswerScale = (answer: DiaryAnswer): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={answer.question} key={answer.position} colon={false}>
                {getFieldDecorator(`answers[${answer.position}].value`, {
                    initialValue: answer.value || 0,
                })(
                    <Slider
                        tooltipVisible={false}
                        max={5}
                        marks={{
                            0: 0,
                            1: 1,
                            2: 2,
                            3: 3,
                            4: 4,
                            5: 5,
                        }}
                        disabled={this.state.diaryRecord.readonly}
                    />,
                )}
            </Form.Item>
        );
    };

    renderAnswerGrade = (answer: DiaryAnswer): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={answer.question} key={answer.position} colon={false}>
                {getFieldDecorator(`answers[${answer.position}].value`, {
                    initialValue: answer.value || 0,
                })(
                    <Slider
                        tooltipVisible={false}
                        max={3}
                        marks={{
                            0: <FormattedMessage id="diaryRecord.answer.grade.none" />,
                            1: <FormattedMessage id="diaryRecord.answer.grade.little" />,
                            2: <FormattedMessage id="diaryRecord.answer.grade.moderate" />,
                            3: <FormattedMessage id="diaryRecord.answer.grade.lot" />,
                        }}
                        disabled={this.state.diaryRecord.readonly}
                    />,
                )}
            </Form.Item>
        );
    };

    renderAnswerMeasure = (answer: DiaryAnswer): JSX.Element => {
        const format = settingsService.getDiaryQuestionFormatByValue(answer.format as string);
        const measurementSystem = (this.context.user as User).measurementSystem as string;
        const unit = unitService.getUnitTypeByChartType(format.chartType as string, measurementSystem);
        const secondaryUnit = unitService.getSecondaryUnitTypeByUnitType(unit);

        return (
            <MeasureValueField
                form={this.props.form}
                fieldName={`answers[${answer.position}].value`}
                secondaryFieldName={`answers[${answer.position}].secondaryValue`}
                initialValue={answer.value}
                initialSecondaryValue={answer.secondaryValue}
                unit={unit}
                secondaryUnit={secondaryUnit}
                size="large"
                metadata={false}
                label={<>{answer.question}</>}
                key={answer.position}
                disabled={this.state.diaryRecord.readonly}
                colon={false}
            />
        );
    };

    renderAnswerMedication = (answer: DiaryAnswer): JSX.Element => {
        return (
            <MedicationField
                form={this.props.form}
                fieldName={`answers[${answer.position}].text`}
                fieldMeasureName={`answers[${answer.position}].value`}
                fieldUnitName={`answers[${answer.position}].unit`}
                initialValue={answer.text}
                initialMeasureValue={answer.value}
                initialMeasureUnit={answer.unit}
                size="large"
                label={<>{answer.question}</>}
                key={answer.position}
                disabled={this.state.diaryRecord.readonly}
                colon={false}
            />
        );
    };

    renderAnswerText = (answer: DiaryAnswer): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={answer.question} key={answer.position} colon={false}>
                {getFieldDecorator(`answers[${answer.position}].text`, {
                    initialValue: answer.text,
                })(<TextArea maxLength={500} rows={3} disabled={this.state.diaryRecord.readonly} />)}
            </Form.Item>
        );
    };

    renderAnswers = (): any => {
        const formItems = this.state.diaryRecord.answers.map((answer: any) => this.renderAnswer(answer));
        return <div className={styles.questions}>{formItems}</div>;
    };

    renderForm = (): JSX.Element => {
        if (this.state.diaryRecord.name) {
            return (
                <Form onSubmit={this.handleSave}>
                    {this.renderPersonSelect(this.state.diaryRecord)}
                    {this.renderAnswers()}
                    <Row gutter={[24, 0]} className={styles.buttons}>
                        <Col xs={12} md={8}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                                loading={this.state.actionStatus === 'saving'}
                                disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                                hidden={this.state.diaryRecord.readonly}
                                data-test="submit"
                            >
                                <FormattedMessage id="common.save" />
                            </Button>
                        </Col>
                        <Col xs={12} md={16}>
                            <Popconfirm
                                title={<FormattedMessage id="diaryTemplate.confirm.delete" />}
                                onConfirm={this.handleDelete}
                                okText={<FormattedMessage id="common.ok" />}
                                cancelText={<FormattedMessage id="common.cancel" />}
                            >
                                <Button
                                    type="link"
                                    size="large"
                                    loading={this.state.actionStatus === 'deleting'}
                                    disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                    className={styles.delete}
                                    hidden={!this.state.diaryRecord.id || this.state.diaryRecord.readonly}
                                    data-test="delete"
                                >
                                    <FormattedMessage id="common.delete" />
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Form>
            );
        } else {
            return <></>;
        }
    };

    renderContent = (): JSX.Element => {
        if (this.state.status === 'loading') {
            return <Loader />;
        } else {
            return (
                <>
                    {this.renderDiaryTemplates()}
                    {this.renderForm()}
                </>
            );
        }
    };

    render() {
        return (
            <div className={styles.diary}>
                {this.renderDatePicker()}
                {this.renderContent()}
            </div>
        );
    }
}
export default withRouter(injectIntl(Form.create<Props>()(CalendarRecordComponent)));

interface Props extends FormComponentProps, WrappedComponentProps, RouteComponentProps {
    match: any;
    date: Moment;
    person?: Person;
}

interface State {
    diaryRecord: DiaryRecord;
    persons: Person[];
    diaryTemplateType?: DiaryTemplateType;
    status?: Status;
    actionStatus?: ActionStatus;
}
