import {
    Settings,
    ChartType,
    CustomType,
    Locale,
    MedicalConditionType,
    DiaryQuestionFormatType,
    MeasurementType,
} from '../model/model';
import settingsEn from './../model/en.settings.json';
import settingsEs from './../model/es.settings.json';
import settingsIt from './../model/it.settings.json';
import settingsPl from './../model/pl.settings.json';
import i18nService from './I18nService';

class SettingsService {
    settings: Settings;

    constructor() {
        const locale: Locale = i18nService.getLocaleFromPath();
        if (locale === 'es') {
            this.settings = settingsEs as Settings;
        } else if (locale === 'it') {
            this.settings = settingsIt as Settings;
        } else if (locale === 'pl') {
            this.settings = settingsPl as Settings;
        } else {
            this.settings = settingsEn as Settings;
        }
    }

    init = (locale: Locale) => {
        if (locale === 'es') {
            this.settings = settingsEs as Settings;
        } else if (locale === 'it') {
            this.settings = settingsIt as Settings;
        } else if (locale === 'pl') {
            this.settings = settingsPl as Settings;
        } else {
            this.settings = settingsEn as Settings;
        }
    };

    getTypeByValue = (types: CustomType[], value: string): CustomType => {
        return types.find(t => t.value === value) as CustomType;
    };

    getGenderByValue = (value: string): CustomType => {
        return this.getTypeByValue(this.settings.genderTypes, value);
    };

    getChartTypeByValue = (value: string): ChartType => {
        return this.getTypeByValue(this.settings.chartTypes, value) as ChartType;
    };

    getUserTypeByValue = (value: string): CustomType => {
        return this.getTypeByValue(this.settings.userTypes, value);
    };

    getProfessionByValue = (value: string): CustomType => {
        return this.getTypeByValue(this.settings.professionTypes, value);
    };

    getMedicalSpecialityByValue = (value: string): CustomType => {
        return this.getTypeByValue(this.settings.medicalSpecialityTypes, value);
    };

    getMedicalConditionByValue = (value: string): MedicalConditionType => {
        return this.getTypeByValue(this.settings.medicalConditionTypes, value) as MedicalConditionType;
    };

    getPostStatusByValue = (value: string): CustomType => {
        return this.getTypeByValue(this.settings.postStatusTypes, value);
    };

    getDiaryQuestionFormatByValue = (value: string): DiaryQuestionFormatType => {
        return this.getTypeByValue(this.settings.diaryQuestionFormatTypes, value) as DiaryQuestionFormatType;
    };

    getTypesByValues = (types: CustomType[], values: string[]): CustomType[] => {
        return types.filter(t => values.includes(t.value));
    };

    listChartTypesByMeasurement = (): Map<MeasurementType, ChartType[]> => {
        const chartTypesMap: Map<MeasurementType, ChartType[]> = new Map();
        this.settings.chartTypes
            .filter(ct => ['HEIGHT', 'WEIGHT', 'BMI', 'HEAD_CIRCUMFERENCE', 'ARM_CIRCUMFERENCE'].includes(ct.value))
            .sort((a, b) => {
                let result = (a.position || 10000) - (b.position || 10000); // undefined at the end
                if (result === 0) {
                    result = a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
                }
                return result;
            })
            .forEach(c => {
                if (!chartTypesMap.has(c.measurement)) {
                    chartTypesMap.set(c.measurement, []);
                }
                (chartTypesMap.get(c.measurement) as ChartType[]).push(c);
            });

        return chartTypesMap;
    };
}

const settingsService = new SettingsService();
export default settingsService;
