import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Customer } from '../model/model';

class CustomerApi {
    get = async (id: string): Promise<Customer> => {
        const response: AxiosResponse<Customer> = await apiAxios.get<Customer>(`/customers/${id}`);
        return response.data;
    };

    create = async (customer: Customer): Promise<Customer> => {
        const response: AxiosResponse<Customer> = await apiAxios.post<Customer>('/customers', customer);
        return response.data;
    };

    update = async (customer: Customer): Promise<Customer> => {
        const response: AxiosResponse<Customer> = await apiAxios.put<Customer>(`/customers/${customer.id}`, customer);
        return response.data;
    };
}

const customerApi: CustomerApi = new CustomerApi();
export default customerApi;
