import React, { Component } from 'react';
import { PublicUser, CustomType } from '../../../../model/model';
import { List } from 'antd';
import AvatarComponent from '../../../Shared/AvatarComponent/AvatarComponent';
import settingsService from '../../../../service/SettingsService';
import { FormattedMessage } from 'react-intl';

class CommunityMemberComponent extends Component<Props> {
    renderHealthProfessional = (): JSX.Element => {
        const member: PublicUser = this.props.member;
        const profession: CustomType | undefined = member.profession
            ? settingsService.getProfessionByValue(member.profession)
            : undefined;
        return (
            <List.Item>
                <List.Item.Meta
                    avatar={<AvatarComponent name={member.username} />}
                    title={member.username}
                    description={profession && profession.label}
                />
            </List.Item>
        );
    };

    renderResearcher = (): JSX.Element => {
        const member: PublicUser = this.props.member;
        const type: string = member.userType ? settingsService.getUserTypeByValue(member.userType).label : '';
        return (
            <List.Item>
                <List.Item.Meta
                    avatar={<AvatarComponent name={this.props.member.username} />}
                    title={this.props.member.username}
                    description={type}
                />
            </List.Item>
        );
    };

    renderPatient = (): JSX.Element => {
        return (
            <List.Item>
                <List.Item.Meta
                    avatar={<AvatarComponent name={this.props.member.username} />}
                    title={this.props.member.username}
                    description={<FormattedMessage id="members.member" />}
                />
            </List.Item>
        );
    };

    render() {
        switch (this.props.member.userType) {
            case 'HEALTH_PROFESSIONAL':
                return this.renderHealthProfessional();
            case 'RESEARCHER':
                return this.renderResearcher();
            default:
                return this.renderPatient();
        }
    }
}
export default CommunityMemberComponent;

interface Props {
    member: PublicUser;
}
