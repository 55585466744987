import messagesEn from './../i18n/en.json';
import messagesEs from './../i18n/es.json';
import messagesIt from './../i18n/it.json';
import messagesPl from './../i18n/pl.json';
import countrieEn from './../i18n/en.countries.json';
import countriesEs from './../i18n/es.countries.json';
import countriesIt from './../i18n/it.countries.json';
import countriesPl from './../i18n/pl.countries.json';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import itIt from 'antd/es/locale/it_IT';
import plPl from 'antd/es/locale/pl_PL';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/pl';
import { Locale, Country, AuthUser } from '../model/model.js';
import { IntlShape, createIntlCache, createIntl } from 'react-intl';
import moment from 'moment';
import userService from './UserService';

class I18nService {
    readonly defaultLocale: Locale = 'en';
    readonly messages: any = {
        en: messagesEn,
        es: messagesEs,
        it: messagesIt,
        pl: messagesPl,
    };
    readonly countries: any = {
        en: countrieEn,
        es: countriesEs,
        it: countriesIt,
        pl: countriesPl,
    };
    intl: IntlShape;

    constructor() {
        this.intl = this.buildIntl(this.defaultLocale);
    }

    init = (): Locale => {
        const userSelectedLocale = userService.getSelectedLocale();
        const pathLocale = this.getLocaleFromPath();
        const navigatorLocale = this.getLocaleFromNavigator();
        const isRootPath = !window.location.pathname || window.location.pathname === '/';

        let locale: Locale;
        if (isRootPath && userSelectedLocale && userSelectedLocale !== this.defaultLocale) {
            locale = userSelectedLocale;
            window.history.replaceState('', '', this.getLocalePath(locale));
        } else if (isRootPath && !userSelectedLocale && navigatorLocale !== this.defaultLocale) {
            locale = navigatorLocale;
            window.history.replaceState('', '', this.getLocalePath(locale));
        } else {
            locale = pathLocale;
        }
        this.intl = this.buildIntl(locale);

        return locale;
    };

    getLocaleFromPath = (): Locale => {
        const path: string = window.location.pathname;
        let locale: Locale = 'en';
        if (path === '/es' || path.startsWith('/es/')) {
            locale = 'es';
            // } else if (path === '/it' || path.startsWith('/it/')) {
            //     locale = 'it';
            // } else if (path === '/pl' || path.startsWith('/pl/')) {
            //     locale = 'pl';
        }

        return locale;
    };

    getLocaleFromNavigator = (): Locale => {
        let locale: Locale = 'en';
        if (navigator.language && navigator.language.startsWith('es')) {
            locale = 'es';
            // } else if (navigator.language && navigator.language.startsWith('it')) {
            //     locale = 'it';
            // } else if (navigator.language && navigator.language.startsWith('pl')) {
            //     locale = 'pl';
        }

        return locale;
    };

    getLocalePath = (locale: Locale): string => {
        return locale === this.defaultLocale ? '/' : `/${locale}/`;
    };

    getComponentsLocaleFromPath = (): any => {
        const path: string = window.location.pathname;
        let locale: any = enUS;
        moment.locale('en');
        if (path === '/es' || path.startsWith('/es/')) {
            moment.locale('es');
            locale = esES;
        } else if (path === '/it' || path.startsWith('/it/')) {
            moment.locale('it');
            locale = itIt;
        } else if (path === '/pl' || path.startsWith('/pl/')) {
            moment.locale('pl');
            locale = plPl;
        }

        return locale;
    };

    getMessages = (locale: Locale): any => {
        return this.messages[locale];
    };

    getCountries = (): Country[] => {
        return this.countries[this.intl.locale];
    };

    getCountry = (code: string): Country | undefined => {
        return this.getCountries().find(c => c.code === code);
    };

    getCustomMessage = (key: string, user: AuthUser): string => {
        return user && user.userType === 'PATIENT' ? key : `${key}.doctor`;
    };

    normalize = (text: string): string => {
        let normalizedText: string = text.toLowerCase() || '';
        if (text) {
            normalizedText = normalizedText.replace(/[àáâãäåæ]/, 'a');
            normalizedText = normalizedText.replace(/[ç]/, 'c');
            normalizedText = normalizedText.replace(/[èéêëæ]/, 'e');
            normalizedText = normalizedText.replace(/[ìíîï]/, 'i');
            normalizedText = normalizedText.replace(/[ñ]/, 'n');
            normalizedText = normalizedText.replace(/[òóôõöø]/, 'o');
            normalizedText = normalizedText.replace(/[ß]/, 's');
            normalizedText = normalizedText.replace(/[ùúûü]/, 'u');
            normalizedText = normalizedText.replace(/[ÿ]/, 'y');
        }

        return normalizedText;
    };

    private buildIntl = (locale: Locale): IntlShape => {
        const cache = createIntlCache();
        const intl: IntlShape = createIntl(
            {
                locale: locale,
                messages: this.getMessages(locale),
            },
            cache,
        );
        return intl;
    };
}

const i18nService = new I18nService();
export default i18nService;
