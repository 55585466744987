import React, { Component } from 'react';
import styles from './DiaryTemplatesPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { DiaryTemplate, Status, ScreenSizeProps, Locale } from '../../model/model';
import diaryTemplateApi from '../../api/DiaryTemplateApi';
import { Button, message, List, Row, Col, Icon } from 'antd';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { PaginationConfig } from 'antd/lib/table';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import Search from 'antd/lib/input/Search';
import { Link } from 'react-router-dom';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import i18nService from '../../service/I18nService';

class DiaryTemplatesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            diaryTemplates: [],
            diaryTemplatesPagination: {
                current: 1,
                pageSize: 20,
                hideOnSinglePage: true,
                onChange: this.handleTableChange,
            },
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
    };

    list = async (page: number = 1, pageSize: number = 20, searchText?: string): Promise<void> => {
        const locale = i18nService.intl.locale.toUpperCase() as Locale;
        const diaryTemplatesPage = await diaryTemplateApi.list(page - 1, pageSize, locale, searchText);
        const diaryTemplates: DiaryTemplate[] = diaryTemplatesPage.content;
        const diaryTemplatesPagination: PaginationConfig = Object.assign({}, this.state.diaryTemplatesPagination, {
            current: page,
            total: diaryTemplatesPage.totalElements,
            showTotal: (total: number) => <FormattedMessage id="diaryTemplates.total" values={{ total }} />,
        });
        this.setState({ diaryTemplates, diaryTemplatesPagination, searchText });
    };

    handleSearch = async (searchText: string) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(1, 20, searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    handleTableChange = async (page: number, pageSize?: number) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(page, pageSize);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    /** COMPONENTS */

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.diaryTemplates" />
                    </h1>
                    <p>
                        <FormattedMessage id="navigation.diaryTemplates.desc" />
                    </p>
                </div>
                <div className="panel-header-back">
                    <Link to="/dashboard" data-test="back">
                        <Button icon="arrow-left" ghost type="primary">
                            {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.back" />}
                        </Button>
                    </Link>
                </div>
            </div>
        );
    };

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.search}>{this.renderSearch()}</div>
                <div className={styles.add}>{this.renderCreateButton()}</div>
            </div>
        );
    };

    renderSearch = (): JSX.Element => {
        const placeholder: string = this.props.intl.formatMessage({ id: 'diaryTemplates.search.placeholder' });
        return <Search placeholder={placeholder} onSearch={this.handleSearch} size="large" className={styles.search} />;
    };

    renderCreateButton = (): JSX.Element => {
        return (
            <Link to="/diary-templates/new" data-test="add">
                <Button type="primary" size="large" icon="plus">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                </Button>
            </Link>
        );
    };

    renderTemplateType = (diaryTemplate: DiaryTemplate): JSX.Element => {
        if (diaryTemplate.entityId) {
            return <Icon type="user" />;
        } else {
            return <></>;
        }
    };

    renderList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.diaryTemplates}
                locale={{
                    emptyText: (
                        <div data-test="empty">
                            <p>
                                <FormattedMessage id="diaryTemplates.empty" />
                            </p>
                            <Link to="/diary-templates/new">
                                <Button size="large" type="primary" ghost>
                                    <FormattedMessage id="diaryTemplates.empty.add" />
                                </Button>
                            </Link>
                        </div>
                    ),
                }}
                pagination={this.state.diaryTemplatesPagination}
                renderItem={diaryTemplate => (
                    <Link to={`/diary-templates/${diaryTemplate.id}`}>
                        <List.Item
                            className={styles.item}
                            actions={[this.renderTemplateType(diaryTemplate)]}
                            data-test="item"
                        >
                            <List.Item.Meta
                                avatar={<AvatarComponent name={diaryTemplate.name} />}
                                title={diaryTemplate.name}
                                description={diaryTemplate.description}
                            />
                        </List.Item>
                    </Link>
                )}
                data-test="list"
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex">
                    <Col xs={24} xl={19} className={styles.diaries}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderToolbar()}
                            {this.renderList()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(DiaryTemplatesPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {}

interface TemplateProps {
    templates: DiaryTemplate[];
    pagination: PaginationConfig;
}

interface State {
    searchText?: string;
    diaryTemplates: DiaryTemplate[];
    diaryTemplatesPagination: PaginationConfig;
    status?: Status;
}
