import React, { Component } from 'react';
import styles from './PostCommentButtonComponent.module.scss';
import { Icon } from 'antd';

class PostCommentButtonComponent extends Component<Props> {
    render() {
        return (
            <span className={styles.action} onClick={this.props.handleSelect}>
                <Icon type="message" /> {this.props.count}
            </span>
        );
    }
}
export default PostCommentButtonComponent;

interface Props {
    count: number;
    handleSelect: () => void;
}
