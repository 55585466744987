import React, { Component } from 'react';
import styles from './MeasureValueField.module.scss';
import { Form, InputNumber, Input } from 'antd';
import { Measure, UnitType } from '../../../../model/model';
import { FormComponentProps } from 'antd/lib/form';
import unitService from '../../../../service/UnitService';
import { FormattedMessage } from 'react-intl';

class MeasureValueField extends Component<Props> {
    /** COMPONENTS **/
    renderLabel = (): JSX.Element => {
        return this.props.label ? this.props.label : <FormattedMessage id="measure.measure" />;
    };

    renderCurrentTotalMeasure = (): JSX.Element => {
        const metadata = this.props.metadata;
        const measure: Measure = {
            unit: this.props.unit.value,
        };
        if (this.props.form.getFieldValue(this.props.fieldName)) {
            measure.value = this.props.form.getFieldValue(this.props.fieldName);
        }
        if (this.props.form.getFieldValue(this.props.secondaryFieldName)) {
            measure.secondaryValue = this.props.form.getFieldValue(this.props.secondaryFieldName) || 0;
        }

        return metadata !== false ? (
            <p data-test="totalMeasure">{unitService.getFormattedMeasure(measure, this.props.unit.label)}</p>
        ) : (
            <></>
        );
    };

    renderMeasureValueFieldForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        if (this.props.secondaryUnit) {
            return (
                <>
                    <Form.Item
                        className={styles.label}
                        label={this.renderLabel()}
                        required={this.props.required}
                        colon={this.props.colon}
                    />
                    <Form.Item className={styles.value}>
                        <Input.Group compact>
                            {getFieldDecorator(this.props.fieldName, {
                                initialValue: this.props.initialValue,
                                rules: [
                                    {
                                        required: this.props.required,
                                        type: 'number',
                                        message: (
                                            <span className={styles.error}>
                                                <FormattedMessage id="measure.measure.error.required" />
                                            </span>
                                        ),
                                    },
                                ],
                            })(
                                <InputNumber
                                    min={0}
                                    max={999.99}
                                    maxLength={6}
                                    formatter={value => unitService.formatNumber(value, 2)}
                                    size={this.props.size}
                                    style={{ width: '80px' }}
                                    disabled={this.props.disabled}
                                    data-test="measureValue"
                                />,
                            )}
                            <Input
                                size={this.props.size}
                                value={this.props.unit.label}
                                disabled={true}
                                style={{
                                    width: '55px',
                                    fontSize: '11px',
                                    paddingLeft: '7px',
                                    paddingRight: '7px',
                                    textAlign: 'center',
                                }}
                                data-test="measureUnit"
                            />
                        </Input.Group>
                    </Form.Item>{' '}
                    <Form.Item className={styles.value}>
                        <Input.Group compact>
                            {getFieldDecorator(this.props.secondaryFieldName, {
                                initialValue: this.props.initialSecondaryValue,
                                rules: [
                                    {
                                        message: (
                                            <span className={styles.error}>
                                                <FormattedMessage id="measure.measure.error.format" />
                                            </span>
                                        ),
                                        type: 'number',
                                    },
                                ],
                            })(
                                <InputNumber
                                    min={0}
                                    max={999.99}
                                    maxLength={6}
                                    formatter={value => unitService.formatNumber(value, 2)}
                                    size={this.props.size}
                                    style={{ width: '80px' }}
                                    disabled={this.props.disabled}
                                    data-test="measureSecondaryValue"
                                />,
                            )}
                            <Input
                                size={this.props.size}
                                value={this.props.secondaryUnit.label}
                                disabled={true}
                                style={{
                                    width: '55px',
                                    fontSize: '11px',
                                    paddingLeft: '7px',
                                    paddingRight: '7px',
                                    textAlign: 'center',
                                }}
                                data-test="measureSecondaryUnit"
                            />
                        </Input.Group>
                    </Form.Item>
                    {this.renderCurrentTotalMeasure()}
                </>
            );
        } else {
            return (
                <Form.Item label={this.renderLabel()} colon={this.props.colon}>
                    <Input.Group compact>
                        {getFieldDecorator(this.props.fieldName, {
                            initialValue: this.props.initialValue,
                            rules: [
                                {
                                    required: this.props.required,
                                    type: 'number',
                                    message: <FormattedMessage id="measure.measure.error.required" />,
                                },
                            ],
                        })(
                            <InputNumber
                                min={0}
                                max={999.99}
                                maxLength={6}
                                formatter={value => unitService.formatNumber(value, 2)}
                                size={this.props.size}
                                style={{ width: '80px' }}
                                disabled={this.props.disabled}
                                data-test="measureValue"
                            />,
                        )}
                        <Input
                            size={this.props.size}
                            value={this.props.unit.label}
                            disabled={true}
                            style={{
                                width: '55px',
                                fontSize: '11px',
                                paddingLeft: '7px',
                                paddingRight: '7px',
                                textAlign: 'center',
                            }}
                            data-test="measureUnit"
                        />
                    </Input.Group>
                </Form.Item>
            );
        }
    };

    render() {
        return this.renderMeasureValueFieldForm();
    }
}
export default MeasureValueField;

interface Props extends FormComponentProps {
    unit: UnitType;
    secondaryUnit?: UnitType;
    initialValue?: number; // required for initialization on modals
    initialSecondaryValue?: number; // required for initialization on modals
    fieldName: string;
    secondaryFieldName: string;
    label?: React.ReactElement | null;
    required?: boolean;
    colon?: boolean;
    size?: 'large' | 'small' | 'default';
    metadata?: boolean;
    disabled?: boolean;
}
