import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Subscription, ActionStatus } from '../../../../model/model';
import errorService from '../../../../service/ErrorService';
import subscriptionApi from '../../../../api/SubscriptionApi';
import { Button, message, Popconfirm } from 'antd';

class SubscriptionCancel extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    cancel = async (): Promise<Subscription> => {
        const subscription: Subscription = await subscriptionApi.cancel(this.props.subscription);
        return subscription;
    };

    isSubscriptionActive = () => this.props.subscription && !this.props.subscription.cancelAt;

    /** HANDLERS **/

    handleCancel = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            const subscription: Subscription = await this.cancel();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.cancelled' }), 4);

            this.props.handleCancellation(subscription);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    render() {
        if (this.isSubscriptionActive()) {
            return (
                <>
                    <h2>
                        <FormattedMessage id="subscription.cancel.title" />
                    </h2>
                    <p>
                        <FormattedMessage id="subscription.cancel.subtitle" />
                    </p>
                    <Popconfirm
                        title={<FormattedMessage id="subscription.cancel.confirm" />}
                        onConfirm={this.handleCancel}
                        okText={<FormattedMessage id="common.ok" />}
                        cancelText={<FormattedMessage id="common.cancel" />}
                    >
                        <Button type="danger" loading={this.state.actionStatus === 'deleting'}>
                            <FormattedMessage id="subscription.cancel.button" />
                        </Button>
                    </Popconfirm>
                </>
            );
        } else {
            return <></>;
        }
    }
}
export default injectIntl(SubscriptionCancel);

interface Props extends WrappedComponentProps {
    subscription: Subscription;
    handleCancellation: (subscription: Subscription) => void;
}

interface State {
    actionStatus?: ActionStatus;
}
