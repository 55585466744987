import React, { Component, FormEvent } from 'react';
import styles from './ChatPage.module.scss';
import { Layout, List, Button, Comment, Empty, message, Modal, Icon } from 'antd';
import CustomContext from '../../../service/CustomContext';
import {
    Status,
    ActionStatus,
    Person,
    Chat,
    ScreenSizeProps,
    ChatMessage,
    ChatMember,
    VideoCall,
    User,
} from '../../../model/model';
import { WrappedComponentProps, injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import errorService from '../../../service/ErrorService';
import chatApi from '../../../api/ChatApi';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AvatarComponent from '../../Shared/AvatarComponent/AvatarComponent';
import Form, { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import i18nService from '../../../service/I18nService';
import Loader from '../../Loader/Loader';
import { SpinProps } from 'antd/lib/spin';
import { Scrollbars } from 'react-custom-scrollbars';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import chatMessageApi from '../../../api/ChatMessageApi';
import eventService from '../../../service/EventService';
import Linkify from 'linkifyjs/react';
import VideoComponent from './VideoComponent/VideoComponent';
import PersonHeader from '../PersonHeader/PersonHeader';
import ChatPayment from './ChatPayment/ChatPayment';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';

class ChatPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    scrollbars: React.RefObject<Scrollbars>;

    constructor(props: Props) {
        super(props);
        this.state = {
            messages: [],
        };
        this.scrollbars = React.createRef();
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });

            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    componentDidUpdate(prevProps: Props) {
        this.state.status === 'loading' && this.state.messages.length > 0 && this.scrollToBottom();

        if (
            !this.state.videoVisible &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.location.state.videoCall
        ) {
            this.showVideo();
        }
    }

    componentWillUnmount() {
        eventService.unsubscribe('chatMessageAdded');
    }

    /** METHODS **/

    init = async () => {
        const chat = await chatApi.get(this.props.match.params.id as number);
        const sender = chat.members.find(m => m.personId === this.props.person.id) as ChatMember;
        const receiver = chat.members.find(m => m.personId !== this.props.person.id) as ChatMember;
        this.setState({ chat, sender, receiver });

        await this.list(chat.id);
        eventService.subscribeToChatMessageAdded(chat.id, this.add);

        this.showVideo();
    };

    showVideo = () => {
        if (this.props.history.location && this.props.history.location.state && this.props.location.state.videoCall) {
            const videoCall: VideoCall = Object.assign({}, this.props.history.location.state.videoCall);
            this.setState({ videoCall, videoVisible: true });

            let state = { ...this.props.history.location.state };
            delete state.videoCall;
            this.props.history.replace({ ...this.props.history.location.state, state });
        }
    };

    list = async (chatId: number, lastPosition?: number) => {
        const messagesPage = await chatMessageApi.list(chatId, 40, lastPosition);
        const messages = lastPosition ? [...this.state.messages].concat(messagesPage.content) : messagesPage.content;
        const positions = messages.map(m => m.position as number);
        const position = positions.length > 0 ? Math.min(...positions) : undefined;

        this.setState({ messages, position }, lastPosition ? undefined : this.scrollToBottom);

        // set messages as read
        chatApi.read(chatId);
    };

    add = async (message: ChatMessage) => {
        const positions = this.state.messages.map(m => m.position) as number[];
        // messages are synchronized (add message to list)
        if (message.position === Math.max(...positions) + 1) {
            const messages = [...this.state.messages, message];
            this.setState({ messages }, this.scrollToBottom);
            chatApi.read(message.chatId);
        }
        // messages are not synchronized (reload)
        else {
            const chat = this.state.chat as Chat;
            this.list(chat.id);
        }
    };

    send = async (values: any) => {
        const chat = this.state.chat as Chat;
        const sender = this.state.sender as ChatMember;
        const message: ChatMessage = {
            chatId: chat.id,
            memberId: sender.id,
            body: values.message,
        };
        chatMessageApi.create(message);

        this.props.form.resetFields();
    };

    requestPayment = async () => {
        const chat = this.state.chat as Chat;
        const sender = this.state.sender as ChatMember;
        const message: ChatMessage = {
            chatId: chat.id,
            memberId: sender.id,
            body: 'PAYMENT_REQUEST',
        };
        chatMessageApi.create(message);
    };

    successPayment = async () => {
        const chat = this.state.chat as Chat;
        const sender = this.state.sender as ChatMember;
        const message: ChatMessage = {
            chatId: chat.id,
            memberId: sender.id,
            body: 'PAYMENT_SUCCESS',
        };
        chatMessageApi.create(message);
    };

    scrollToBottom = () => {
        return this.scrollbars.current && this.scrollbars.current.scrollToBottom();
    };

    format = (messages: ChatMessage[]): Array<ChatMessage[]> => {
        const groupedMessages: Array<ChatMessage[]> = [];

        // remove duplicates and sort by position
        const sortedMessages = messages
            .filter((m, i) => messages.indexOf(m) === i)
            .sort((a, b) => (a.position as number) - (b.position as number));

        // group by author
        for (let index = 0; index < sortedMessages.length; index++) {
            const message = sortedMessages[index];
            if (
                index > 0 &&
                message.memberId === sortedMessages[index - 1].memberId &&
                message.body !== 'PAYMENT_REQUEST'
            ) {
                groupedMessages[groupedMessages.length - 1].push(message);
            } else {
                groupedMessages.push([message]);
            }
        }

        return groupedMessages;
    };

    /** HANDLERS **/

    handleSend = async (e?: FormEvent) => {
        e && e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.send(values);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleMessagePressEnter = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (!e.shiftKey) {
            e.preventDefault();
            await this.handleSend();
            !this.props.isLg && document && document.activeElement && (document.activeElement as any).blur();
        }
    };

    handleLoadMore = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'loading' });
            const chat = this.state.chat as Chat;
            await this.list(chat.id, this.state.position);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleRequestPayment = async () => {
        try {
            this.setState({ actionStatus: 'requestingPayment' });
            await this.requestPayment();
            this.handleHideRequestPayment();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleSuccessPayment = async () => {
        try {
            this.handleHidePayment();
            await this.successPayment();
            // TODO: payer first text don't work
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleStartVideo = () => {
        this.setState({ videoVisible: true });
    };

    handleStopVideo = () => {
        this.setState({ videoVisible: false });
    };

    handleShowRequestPayment = () => {
        this.setState({ requestPaymentVisible: true });
    };

    handleHideRequestPayment = () => {
        this.setState({ requestPaymentVisible: false });
    };

    handleShowPayment = () => {
        this.setState({ paymentVisible: true });
    };

    handleHidePayment = () => {
        this.setState({ paymentVisible: false });
    };

    /** COMPONENTS **/

    renderLoadMore = (): JSX.Element => {
        if (this.state.position && this.state.position > 0) {
            return (
                <div className={styles.previous}>
                    <Button onClick={this.handleLoadMore}>
                        <FormattedMessage id="chat.messages.load" />
                    </Button>
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderMessage = (groupedMessage: ChatMessage[]): JSX.Element => {
        const body = groupedMessage[0].body;

        if (body === 'PAYMENT_REQUEST') {
            return this.renderPaymentRequestMessage(groupedMessage);
        } else if (body === 'PAYMENT_SUCCESS') {
            return this.renderPaymentSuccessMessage(groupedMessage);
        } else {
            return this.renderTextMessage(groupedMessage);
        }
    };

    renderTextMessage = (groupedMessage: ChatMessage[]): JSX.Element => {
        const sender = this.state.sender as ChatMember;
        const receiver = this.state.receiver as ChatMember;
        const author = groupedMessage[0].memberId === sender.id ? sender : receiver;
        const date = moment.utc(groupedMessage[0].sent).fromNow();
        const color = author.id === sender.id ? 'rgba(26, 170, 85, 0.65)' : 'rgba(146, 102, 138, 0.65)';
        const size = this.props.isXs || this.props.isSm ? 'small' : 'large';
        const body = groupedMessage.map(groupedMessage => <p key={groupedMessage.position}>{groupedMessage.body}</p>);

        return (
            <li>
                <Comment
                    author={<span className={styles.sender}>{author.currentName}</span>}
                    avatar={
                        <AvatarComponent name={author.currentName} size={size} shape="square" backgroundColor={color} />
                    }
                    content={
                        <div className={styles.body}>
                            <Linkify options={{ target: '_blank', nl2br: true }}>{body}</Linkify>
                        </div>
                    }
                    datetime={date}
                    className={styles.message}
                />
            </li>
        );
    };

    renderPaymentRequestMessage = (groupedMessage: ChatMessage[]): JSX.Element => {
        const size = this.props.isXs || this.props.isSm ? 'small' : 'large';
        const author = 'SecuHealth';
        const date = moment.utc(groupedMessage[0].sent).fromNow();

        // TODO: disable button if more recent payment request or not receiver
        return (
            <li>
                <Comment
                    author={<span className={styles.sender}>{author}</span>}
                    avatar={<AvatarComponent name={author} size={size} shape="square" backgroundColor="#000" />}
                    content={
                        <div className={styles.body}>
                            <p>
                                <FormattedHTMLMessage
                                    id="chat.requestPayment.message"
                                    values={{ amount: 20, duration: 15 }}
                                />
                            </p>
                            <Button
                                icon="credit-card"
                                size="large"
                                className="alternative"
                                onClick={this.handleShowPayment}
                            >
                                <FormattedMessage id="chat.requestPayment.showPayment" />
                            </Button>
                        </div>
                    }
                    datetime={date}
                    className={styles.message}
                />
            </li>
        );
    };

    renderPaymentSuccessMessage = (groupedMessage: ChatMessage[]): JSX.Element => {
        const size = this.props.isXs || this.props.isSm ? 'small' : 'large';
        const author = 'SecuHealth';
        const date = moment.utc(groupedMessage[0].sent).fromNow();
        const startDate = moment
            .utc(groupedMessage[0].sent)
            .local()
            .format('DD MMMM YYYY HH:mm');

        return (
            <li>
                <Comment
                    author={<span className={styles.sender}>{author}</span>}
                    avatar={<AvatarComponent name={author} size={size} shape="square" backgroundColor="#000" />}
                    content={
                        <div className={styles.body}>
                            <p className={styles.success}>
                                <Icon type="check-circle" theme="filled" />
                                <FormattedHTMLMessage id="chat.payment.message" values={{ startDate }} />
                            </p>
                        </div>
                    }
                    datetime={date}
                    className={styles.message}
                />
            </li>
        );
    };

    renderMessages = (): JSX.Element => {
        const receiverName = this.state.receiver ? this.state.receiver.currentName : '';
        const loading: SpinProps = {
            indicator: <Loader type="basic" />,
            size: 'large',
        };
        const emptyText =
            this.state.status === 'loading' ? (
                <></>
            ) : (
                <Empty description={<FormattedMessage id="chat.messages.empty" values={{ user: receiverName }} />} />
            );
        const groupedMessages = this.format(this.state.messages);

        return (
            <div className={styles.messages}>
                <Scrollbars ref={this.scrollbars}>
                    {this.renderLoadMore()}
                    <List
                        loading={this.state.status === 'loading' ? loading : undefined}
                        itemLayout="vertical"
                        dataSource={groupedMessages}
                        locale={{
                            emptyText,
                        }}
                        renderItem={groupedMessage => this.renderMessage(groupedMessage)}
                        className={styles.list}
                    />
                </Scrollbars>
            </div>
        );
    };

    renderNew = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        const placeholder = i18nService.intl.formatMessage({ id: 'chat.message.placeholder' });
        return (
            <Form onSubmit={this.handleSend} className={styles.new}>
                <Form.Item className={styles.text}>
                    {getFieldDecorator('message')(
                        <TextArea
                            maxLength={1000}
                            rows={this.props.isXs || this.props.isSm ? 2 : 3}
                            placeholder={placeholder}
                            onPressEnter={this.handleMessagePressEnter}
                        />,
                    )}
                </Form.Item>
                <Button
                    type="primary"
                    icon="caret-right"
                    htmlType="submit"
                    size="large"
                    loading={this.state.actionStatus === 'saving'}
                    disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                >
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.send" />}
                </Button>
            </Form>
        );
    };

    renderVideoButton = (): JSX.Element => {
        const user = this.context.user as User;
        if (this.state.chat && user.userType !== 'PATIENT') {
            return (
                <Button icon="video-camera" ghost onClick={this.handleStartVideo} className="alternative">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="chat.videoCall" />}
                </Button>
            );
        } else {
            return <></>;
        }
    };

    renderVideo = (): JSX.Element => {
        if (this.state.videoVisible && this.state.chat) {
            return (
                <>
                    <VideoComponent
                        person={this.props.person}
                        chat={this.state.chat as Chat}
                        sender={this.state.sender as ChatMember}
                        receiver={this.state.receiver as ChatMember}
                        onStop={this.handleStopVideo}
                        videoCall={this.state.videoCall}
                    />
                </>
            );
        } else {
            return <></>;
        }
    };

    renderRequestPaymentButton = (): JSX.Element => {
        const user = this.context.user as User;
        if (this.state.chat && user.associate) {
            return (
                <Button type="danger" icon="credit-card" ghost onClick={this.handleShowRequestPayment}>
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="chat.requestPayment" />}
                </Button>
            );
        } else {
            return <></>;
        }
    };

    renderRequestPaymentModal = (): JSX.Element => {
        return (
            <Modal
                title={<FormattedMessage id="chat.requestPayment.modal.title" />}
                visible={this.state.requestPaymentVisible}
                okText={<FormattedMessage id="common.ok" />}
                cancelText={<FormattedMessage id="common.cancel" />}
                onCancel={this.handleHideRequestPayment}
                onOk={this.handleRequestPayment}
                className="root-modal"
            >
                <div className={styles.requestPaymentModal}>
                    <p>
                        <FormattedMessage id="chat.requestPayment.modal.description.1" /> <strong>20€</strong>
                    </p>
                    <p>
                        <FormattedMessage id="chat.requestPayment.modal.description.2" /> <strong>2€</strong>
                    </p>
                    <p>
                        <FormattedMessage id="chat.requestPayment.modal.description.3" /> <strong>18€</strong>
                    </p>
                </div>
            </Modal>
        );
    };

    renderPaymentModal = (): JSX.Element => {
        if (this.state.paymentVisible) {
            return (
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <ChatPayment
                            chat={this.state.chat}
                            handlePayment={this.handleSuccessPayment}
                            handleClose={this.handleHidePayment}
                            stripe={stripe as Stripe}
                            elements={elements as StripeElements}
                        />
                    )}
                </ElementsConsumer>
            );
        } else {
            return <></>;
        }
    };

    render() {
        return (
            <>
                <PersonHeader
                    person={this.props.person}
                    backUrl={`/persons/${this.props.person.id}/chats`}
                    actionButtons={
                        <>
                            {this.renderRequestPaymentButton()}
                            {this.renderVideoButton()}
                        </>
                    }
                    divider={true}
                />
                <Layout.Content className={styles.chat}>
                    {this.renderMessages()}
                    {this.renderNew()}
                    {this.renderVideo()}
                    {this.renderRequestPaymentModal()}
                    {this.renderPaymentModal()}
                </Layout.Content>
            </>
        );
    }
}

export default withSizes(responsiveService.mapSizesToProps)(withRouter(injectIntl(Form.create<Props>()(ChatPage))));

interface Props extends RouteComponentProps, WrappedComponentProps, FormComponentProps, ScreenSizeProps {
    match: any;
    person: Person;
}

interface State {
    chat?: Chat;
    sender?: ChatMember;
    receiver?: ChatMember;
    position?: number;
    messages: ChatMessage[];
    videoVisible?: boolean;
    videoCall?: VideoCall;
    requestPaymentVisible?: boolean;
    paymentVisible?: boolean;
    status?: Status;
    actionStatus?: ActionStatus | 'requestingPayment';
}
