import React from 'react';
import { Context } from '../model/model';
import i18nService from './I18nService';

// export default custom context
export const defaultContext: Context = {
    user: undefined,
    settings: {
        userTypes: [],
        languageTypes: [],
        genderTypes: [],
        measurementSystemTypes: [],
        unitTypes: [],
        chartTypes: [],
        dateFormatTypes: [],
        medicalConditionTypes: [],
        professionTypes: [],
        medicalSpecialityTypes: [],
        sharedTypes: [],
        postStatusTypes: [],
        diaryQuestionFormatTypes: [],
    },
    locale: i18nService.defaultLocale,
};

// export react custom context
const CustomContext: React.Context<Context> = React.createContext(defaultContext);
export default CustomContext;
