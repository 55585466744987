import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { DiaryMedication } from '../model/model';
import moment, { Moment } from 'moment';

class DiaryMedicationApi {
    list = async (from: Moment, to: Moment, personId?: number): Promise<DiaryMedication[]> => {
        const response: AxiosResponse<DiaryMedication[]> = await apiAxios.get<DiaryMedication[]>('/diary-medications', {
            params: {
                from: from.toJSON(),
                to: to.toJSON(),
                personId,
            },
        });
        response.data.forEach(diaryMedication => {
            diaryMedication.startDate = moment(diaryMedication.startDate).utc();
            diaryMedication.endDate = moment(diaryMedication.endDate).utc();
        });

        return response.data;
    };

    get = async (id: number): Promise<DiaryMedication> => {
        const response: AxiosResponse<DiaryMedication> = await apiAxios.get<DiaryMedication>(
            `/diary-medications/${id}`,
            this.requestConfig,
        );

        return response.data;
    };

    create = async (diaryMedication: DiaryMedication): Promise<DiaryMedication> => {
        const response: AxiosResponse<DiaryMedication> = await apiAxios.post<DiaryMedication>(
            '/diary-medications',
            diaryMedication,
            this.requestConfig,
        );
        return response.data;
    };

    update = async (diaryMedication: DiaryMedication): Promise<DiaryMedication> => {
        const response: AxiosResponse<DiaryMedication> = await apiAxios.put<DiaryMedication>(
            `/diary-medications/${diaryMedication.id}`,
            diaryMedication,
            this.requestConfig,
        );
        return response.data;
    };

    delete = async (diaryMedication: DiaryMedication): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/diary-medications/${diaryMedication.id}`);
        return response.data;
    };

    private transformRequest = (diaryMedication: DiaryMedication): DiaryMedication | undefined => {
        let transformRequest: DiaryMedication | undefined;
        if (diaryMedication) {
            transformRequest = Object.assign({}, diaryMedication, {
                startDate: diaryMedication.startDate
                    ? moment(diaryMedication.startDate)
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:00.000') + 'Z'
                    : undefined,
                endDate: diaryMedication.endDate
                    ? moment(diaryMedication.endDate)
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:00.000') + 'Z'
                    : undefined,
            });
        }

        return transformRequest;
    };

    private transformResponse = (diaryMedication: DiaryMedication): DiaryMedication | undefined => {
        let transformResponse: DiaryMedication | undefined;
        if (diaryMedication) {
            transformResponse = Object.assign({}, diaryMedication, {
                startDate: diaryMedication.startDate ? moment(diaryMedication.startDate).utc() : undefined,
                endDate: diaryMedication.endDate ? moment(diaryMedication.endDate).utc() : undefined,
            });
        }

        return transformResponse;
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
        transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(this.transformResponse),
    };
}

const diaryMedicationApi: DiaryMedicationApi = new DiaryMedicationApi();
export default diaryMedicationApi;
