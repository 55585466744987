import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default class NotFoundPage extends Component {
    render() {
        return (
            <Result
                status="warning"
                title={<FormattedMessage id="error.notFound.title" />}
                subTitle={<FormattedMessage id="error.notFound.subtitle" />}
                extra={
                    <Link to="/">
                        <Button type="primary" size="large">
                            <FormattedMessage id="common.backToHome" />
                        </Button>
                    </Link>
                }
            />
        );
    }
}
