import React, { Component } from 'react';
import PostComponent from '../../../DashaboardPage/Posts/PostComponent/PostComponent';
import { Post, Status, MedicalConditionType } from '../../../../model/model';
import postApi from '../../../../api/PostApi';
import Loader from '../../../Loader/Loader';
import NotFoundPage from '../../../../errors/NotFoundPage/NotFoundPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { List } from 'antd';
import HeadMetadata from '../../../Helper/HeadMetadata/HeadMetadata';

class CommunityPostPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            status: 'loading',
        };
    }

    async componentDidMount() {
        try {
            await this.init();
            this.setState({ status: undefined });
        } catch (error) {
            this.setState({ status: 'error' });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const post: Post = await postApi.get(this.props.match.params.id);
        this.setState({ post });
    };

    /** COMPONENTS **/

    renderPost = (): JSX.Element => {
        const post: Post = this.state.post as Post;
        const posts: Post[] = this.state.post ? [this.state.post] : [];
        return (
            <>
                <HeadMetadata title={post.title} description={post.message} />
                <List
                    itemLayout="vertical"
                    dataSource={posts}
                    renderItem={post => <PostComponent post={post} postLength={5000} />}
                />
            </>
        );
    };

    render() {
        if (this.state.status === 'loading') {
            return <Loader />;
        } else if (this.state.status === 'error') {
            return <NotFoundPage />;
        } else {
            return this.renderPost();
        }
    }
}
export default withRouter(CommunityPostPage);

interface Props extends RouteComponentProps {
    match: any;
    condition: MedicalConditionType;
}

interface State {
    post?: Post;
    status?: Status;
}
