import { LocalePath } from './paths';

const paths: LocalePath[] = [
    {
        key: 'communities',
        path: 'społeczności',
    },
    {
        key: 'tools',
        path: 'narzędzia',
    },
    {
        key: 'growth-chart',
        path: 'tabele-wzrostu',
    },
    {
        key: 'bmi-calculator',
        path: 'kalkulator-bmi',
    },
    {
        key: 'bmi-calculator-children',
        path: 'kalkulator-bmi-dzieci',
    },
    {
        key: 'age-calculator',
        path: 'kalkulator-wieku',
    },
    {
        key: 'coronavirus-test',
        path: 'test-na-koronawirusa',
    },
];
paths.forEach(path => (path.locale = 'pl'));
export default paths;
