import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Page, ChatMessage } from '../model/model';

class ChatMessageApi {
    list = async (chatId: number, size: number, position?: number, searchText?: string): Promise<Page<ChatMessage>> => {
        const response: AxiosResponse<Page<ChatMessage>> = await apiAxios.get<Page<ChatMessage>>('/chat-messages', {
            params: { chatId, size, position, searchText },
        });

        return response.data;
    };

    count = async (): Promise<number> => {
        const response: AxiosResponse<number> = await apiAxios.get<number>(`/chat-messages/count`);
        return response.data;
    };

    create = async (chatMessage: ChatMessage): Promise<ChatMessage> => {
        const response: AxiosResponse<ChatMessage> = await apiAxios.post<ChatMessage>('/chat-messages', chatMessage);
        return response.data;
    };

    delete = async (chatMessage: ChatMessage): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/chat-messages/${chatMessage.id}`);
        return response.data;
    };
}

const chatMessageApi: ChatMessageApi = new ChatMessageApi();
export default chatMessageApi;
