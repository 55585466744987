import React, { Component } from 'react';
import styles from './SignUpComponent.module.scss';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

export default class SignUpComponent extends Component {
    /** COMPONENTS **/

    render() {
        return (
            <div className={styles.signUp}>
                <h2>
                    <FormattedMessage id="sidebar.signUp.title" />
                </h2>
                <p>
                    <FormattedMessage id="sidebar.signUp.desc" />
                </p>
                <Link to="/signup">
                    <Button type="primary" size="large" block>
                        <FormattedMessage id="sidebar.signUp.button" />
                    </Button>
                </Link>
            </div>
        );
    }
}
