import React, { Component } from 'react';
import styles from './Footer.module.scss';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CustomContext from '../../service/CustomContext';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { ScreenSizeProps } from '../../model/model';
import pathService from '../../service/PathService';

class Footer extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /** COMPONENTS **/

    renderMobileFooter = (): JSX.Element => {
        return (
            <div className={styles.footer}>
                <div className={styles.links}>
                    <Link to={pathService.getPath('map')}>
                        <FormattedMessage id="footer.map" />
                    </Link>
                    <Link to={pathService.getPath('legal-notice')}>
                        <FormattedMessage id="footer.legalNotice" />
                    </Link>
                    <Link to={pathService.getPath('privacy-policy')}>
                        <FormattedMessage id="footer.privacyPolicy" />
                    </Link>

                    <Link to={pathService.getPath('cookies-policy')}>
                        <FormattedMessage id="footer.cookiesPolicy" />
                    </Link>
                </div>
                <div className={styles.links}>
                    <Link to={pathService.getPath('terms-of-use')}>
                        <FormattedMessage id="footer.termsOfUse" />
                    </Link>
                    <Link to={pathService.getPath('terms-of-contract')}>
                        <FormattedMessage id="footer.termsOfContract" />
                    </Link>
                    <Link to={pathService.getPath('contact-us')}>
                        <FormattedMessage id="footer.contactUs" />
                    </Link>
                </div>
                <div className={styles.links}>
                    <a href="https://walfen.com" className={styles.copyright}>
                        © Walfen Technologies S.L.U.
                    </a>
                </div>
            </div>
        );
    };

    renderDesktopFooter = (): JSX.Element => {
        return (
            <div className={styles.desktop}>
                <div className={styles.footer}>
                    <div className={styles.links}>
                        <Link to={pathService.getPath('map')}>
                            <FormattedMessage id="footer.map" />
                        </Link>
                        <Link to={pathService.getPath('legal-notice')}>
                            <FormattedMessage id="footer.legalNotice" />
                        </Link>
                        <Link to={pathService.getPath('privacy-policy')}>
                            <FormattedMessage id="footer.privacyPolicy" />
                        </Link>
                        <Link to={pathService.getPath('cookies-policy')}>
                            <FormattedMessage id="footer.cookiesPolicy" />
                        </Link>
                        <Link to={pathService.getPath('terms-of-use')}>
                            <FormattedMessage id="footer.termsOfUse" />
                        </Link>
                        <Link to={pathService.getPath('terms-of-contract')}>
                            <FormattedMessage id="footer.termsOfContract" />
                        </Link>
                        <Link to={pathService.getPath('contact-us')}>
                            <FormattedMessage id="footer.contactUs" />
                        </Link>
                    </div>
                    <a href="https://walfen.com" className={styles.copyright}>
                        © Walfen Technologies S.L.U.
                    </a>
                </div>
            </div>
        );
    };

    render() {
        return this.props.isLg ? this.renderDesktopFooter() : this.renderMobileFooter();
    }
}
export default withSizes(responsiveService.mapSizesToProps)(withRouter(Footer));

interface Props extends RouteComponentProps, ScreenSizeProps {}
