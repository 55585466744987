import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { Page, PersonLink } from '../model/model';
import moment from 'moment';

class PersonLinkApi {
    list = async (personId: number, page: number, size: number, searchText?: string): Promise<Page<PersonLink>> => {
        const response: AxiosResponse<Page<PersonLink>> = await apiAxios.get<Page<PersonLink>>('/person-links', {
            params: { personId, page, size, searchText },
        });
        return response.data;
    };

    get = async (id: number): Promise<PersonLink> => {
        const response: AxiosResponse<PersonLink> = await apiAxios.get<PersonLink>(
            `/person-links/${id}`,
            this.requestConfig,
        );
        return response.data;
    };

    create = async (personLink: PersonLink): Promise<PersonLink> => {
        const response: AxiosResponse<PersonLink> = await apiAxios.post<PersonLink>(
            '/person-links',
            personLink,
            this.requestConfig,
        );
        return response.data;
    };

    update = async (personLink: PersonLink): Promise<PersonLink> => {
        const response: AxiosResponse<PersonLink> = await apiAxios.put<PersonLink>(
            `/person-links/${personLink.id}`,
            personLink,
            this.requestConfig,
        );
        return response.data;
    };

    delete = async (personLink: PersonLink): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/person-links/${personLink.id}`);
        return response.data;
    };

    private transformResponse = (personLink: PersonLink): PersonLink | undefined => {
        let transformResponse: PersonLink | undefined;
        if (personLink) {
            transformResponse = Object.assign({}, personLink, {
                sent: moment(personLink.sent),
            });
        }

        return transformResponse;
    };

    private requestConfig: AxiosRequestConfig = {
        transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(this.transformResponse),
    };
}

const personLinkApi: PersonLinkApi = new PersonLinkApi();
export default personLinkApi;
