import apiAxios from './CustomAxios';
import { VideoMeeting, VideoAttendee } from '../model/model';

class VideoApi {
    createMeeting = async (chatId: number): Promise<VideoMeeting> => {
        const response = await apiAxios.post<any>(
            '/videos/meeting',
            {},
            {
                params: { chatId },
            },
        );
        return this.formatVideoMeeting(response.data);
    };

    deleteMeeting = async (meetingId: string) => {
        await apiAxios.delete(`/videos/meeting/${meetingId}`);
    };

    createAttendee = async (meetingId: string): Promise<VideoAttendee> => {
        const response = await apiAxios.post<any>(
            '/videos/attendee',
            {},
            {
                params: { meetingId },
            },
        );
        return this.formatVideoAttendee(response.data);
    };

    formatVideoMeeting = (rawVideoMeeting: any): VideoMeeting => {
        return {
            Meeting: {
                MeetingId: rawVideoMeeting.meeting.meetingId,
                ExternalMeetingId: rawVideoMeeting.meeting.externalMeetingId.replace('chat-', ''), // aws chime prefix
                MediaRegion: rawVideoMeeting.meeting.mediaRegion,
                MediaPlacement: {
                    AudioFallbackUrl: rawVideoMeeting.meeting.mediaPlacement.audioFallbackUrl,
                    AudioHostUrl: rawVideoMeeting.meeting.mediaPlacement.audioHostUrl,
                    ScreenDataUrl: rawVideoMeeting.meeting.mediaPlacement.screenDataUrl,
                    ScreenSharingUrl: rawVideoMeeting.meeting.mediaPlacement.screenSharingUrl,
                    ScreenViewingUrl: rawVideoMeeting.meeting.mediaPlacement.screenViewingUrl,
                    SignalingUrl: rawVideoMeeting.meeting.mediaPlacement.signalingUrl,
                    TurnControlUrl: rawVideoMeeting.meeting.mediaPlacement.turnControlUrl,
                },
            },
        };
    };

    formatVideoAttendee = (rawVideoAttendee: any): VideoAttendee => {
        return {
            Attendee: {
                AttendeeId: rawVideoAttendee.attendee.attendeeId,
                ExternalUserId: rawVideoAttendee.attendee.externalUserId.replace('user-', ''), // aws chime prefix
                JoinToken: rawVideoAttendee.attendee.joinToken,
            },
        };
    };
}

const videoApi: VideoApi = new VideoApi();
export default videoApi;
