import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { DiaryAppointment } from '../model/model';
import moment, { Moment } from 'moment';

class DiaryAppointmentApi {
    list = async (from: Moment, to: Moment, personId?: number): Promise<DiaryAppointment[]> => {
        const response: AxiosResponse<DiaryAppointment[]> = await apiAxios.get<DiaryAppointment[]>(
            '/diary-appointments',
            {
                params: {
                    from: from.toJSON(),
                    to: to.toJSON(),
                    personId,
                },
            },
        );
        response.data.forEach(diaryAppointment => {
            diaryAppointment.startDate = moment(diaryAppointment.startDate).utc();
            diaryAppointment.endDate = moment(diaryAppointment.endDate).utc();
            diaryAppointment.alarmDate = moment(diaryAppointment.alarmDate).utc();
        });

        return response.data;
    };

    get = async (id: number): Promise<DiaryAppointment> => {
        const response: AxiosResponse<DiaryAppointment> = await apiAxios.get<DiaryAppointment>(
            `/diary-appointments/${id}`,
            this.requestConfig,
        );

        return response.data;
    };

    create = async (diaryAppointment: DiaryAppointment): Promise<DiaryAppointment> => {
        const response: AxiosResponse<DiaryAppointment> = await apiAxios.post<DiaryAppointment>(
            '/diary-appointments',
            diaryAppointment,
            this.requestConfig,
        );
        return response.data;
    };

    update = async (diaryAppointment: DiaryAppointment): Promise<DiaryAppointment> => {
        const response: AxiosResponse<DiaryAppointment> = await apiAxios.put<DiaryAppointment>(
            `/diary-appointments/${diaryAppointment.id}`,
            diaryAppointment,
            this.requestConfig,
        );
        return response.data;
    };

    delete = async (diaryAppointment: DiaryAppointment): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/diary-appointments/${diaryAppointment.id}`);
        return response.data;
    };

    private transformRequest = (diaryAppointment: DiaryAppointment): DiaryAppointment | undefined => {
        let transformRequest: DiaryAppointment | undefined;
        if (diaryAppointment) {
            transformRequest = Object.assign({}, diaryAppointment, {
                startDate: diaryAppointment.startDate
                    ? moment(diaryAppointment.startDate)
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:00.000') + 'Z'
                    : undefined,
                endDate: diaryAppointment.endDate
                    ? moment(diaryAppointment.endDate)
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:00.000') + 'Z'
                    : undefined,
                alarmDate: diaryAppointment.alarmDate
                    ? moment(diaryAppointment.alarmDate)
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:00.000') + 'Z'
                    : undefined,
            });
        }

        return transformRequest;
    };

    private transformResponse = (diaryAppointment: DiaryAppointment): DiaryAppointment | undefined => {
        let transformResponse: DiaryAppointment | undefined;
        if (diaryAppointment) {
            transformResponse = Object.assign({}, diaryAppointment, {
                startDate: diaryAppointment.startDate ? moment(diaryAppointment.startDate).utc() : undefined,
                endDate: diaryAppointment.endDate ? moment(diaryAppointment.endDate).utc() : undefined,
                alarmDate: diaryAppointment.alarmDate ? moment(diaryAppointment.alarmDate).utc() : undefined,
            });
        }

        return transformResponse;
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
        transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(this.transformResponse),
    };
}

const diaryAppointmentApi: DiaryAppointmentApi = new DiaryAppointmentApi();
export default diaryAppointmentApi;
