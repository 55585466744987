import React, { Component } from 'react';
import { Avatar } from 'antd';
import nameService from '../../../service/NameService';

export default class AvatarComponent extends Component<Props> {
    /** COMPONENTS **/

    render() {
        const shape = this.props.shape || 'square';
        if (this.props.name && this.props.name.length > 0) {
            const initial: string = nameService.getInitial(this.props.name);
            const backgroundColor = this.props.backgroundColor || nameService.getBackgroundColor(this.props.name);
            return (
                <Avatar size={this.props.size} style={{ backgroundColor }} shape={shape}>
                    {initial}
                </Avatar>
            );
        } else {
            const backgroundColor: string = this.props.backgroundColor || '#290b39';
            return <Avatar size={this.props.size} icon="user" style={{ backgroundColor }} shape={shape} />;
        }
    }
}

interface Props {
    name?: string;
    size?: 'large' | 'small' | 'default' | number;
    shape?: 'circle' | 'square';
    backgroundColor?: string;
}
