import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { Person, Page } from '../model/model';
import moment from 'moment';

class PersonApi {
    list = async (page: number, size: number, searchText?: string): Promise<Page<Person>> => {
        const response: AxiosResponse<Page<Person>> = await apiAxios.get<Page<Person>>('/persons', {
            params: { page, size, searchText },
        });
        response.data.content.forEach(p => (p.birthdate = moment(p.birthdate).utc()));
        return response.data;
    };

    count = async (): Promise<number> => {
        const response: AxiosResponse<number> = await apiAxios.get<number>(`/persons/count`);
        return response.data;
    };

    get = async (id: number): Promise<Person> => {
        const response: AxiosResponse<Person> = await apiAxios.get<Person>(`/persons/${id}`);
        response.data.birthdate = moment(response.data.birthdate).utc();
        return response.data;
    };

    create = async (person: Person): Promise<Person> => {
        const response: AxiosResponse<Person> = await apiAxios.post<Person>('/persons', person, this.requestConfig);
        response.data.birthdate = moment(response.data.birthdate).utc();
        return response.data;
    };

    update = async (person: Person): Promise<Person> => {
        const response: AxiosResponse<Person> = await apiAxios.put<Person>(
            `/persons/${person.id}`,
            person,
            this.requestConfig,
        );
        response.data.birthdate = moment(response.data.birthdate).utc();
        return response.data;
    };

    delete = async (person: Person): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/persons/${person.id}`);
        return response.data;
    };

    private transformRequest = (person: Person): any => {
        const birthdate = person.birthdate ? person.birthdate.format('YYYY-MM-DDT00:00:00.000') + 'Z' : undefined;
        const transformRequest: any = Object.assign({}, person, {
            birthdate,
        });

        return transformRequest;
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
    };
}

const personApi: PersonApi = new PersonApi();
export default personApi;
