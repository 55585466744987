import React, { Component } from 'react';
import styles from './UserMeasureModal.module.scss';
import {
    UserMeasure,
    UserChart,
    Person,
    StandardChart,
    UserMeasureMetadata,
    ActionStatus,
    BmiMeasure,
    Measure,
    UnitType,
} from '../../../../model/model';
import { Modal, Popconfirm, Button, Form, DatePicker, Row, Col, TimePicker } from 'antd';
import { WrappedComponentProps, injectIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import MeasureMetadata from '../MeasureMetadata/MeasureMetadata';
import { FormComponentProps } from 'antd/lib/form';
import unitService from '../../../../service/UnitService';
import measureMetadataService from '../../../../service/MeasureMetadataService';
import moment, { Moment } from 'moment';
import errorService from '../../../../service/ErrorService';
import dateService from '../../../../service/DateService';
import MeasureValueField from '../MeasureValueField/MeasureValueField';
import bmiService from '../../../../service/BmiService';
import { BmiLevelComponent } from '../../../Shared/BmiLevelComponent';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class UserMeasureModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userMeasure: {},
        };

        this.loadMeasureMetadataDebounced = _.debounce(this.loadMeasureMetadataDebounced, 1500);
    }

    componentDidUpdate(prevProps: Props) {
        try {
            if (this.props.visible && this.props.visible !== prevProps.visible) {
                this.init(this.props.userMeasure);
            }
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    /** METHODS */

    init = (selectedUserMeasure?: UserMeasure) => {
        this.props.form.resetFields();
        this.props.userChart.chartType === 'BMI'
            ? this.initBmiMeasure(selectedUserMeasure)
            : this.initCommonMeasure(selectedUserMeasure);
    };

    initCommonMeasure = (selectedUserMeasure?: UserMeasure) => {
        let userMeasure: UserMeasure;
        if (selectedUserMeasure) {
            userMeasure = Object.assign({}, selectedUserMeasure);
        } else {
            const seriesId = this.props.userChart.series[0].id;
            const unit = unitService.getUnitValueByUserChart(this.props.userChart, this.props.measurementSystem);
            const date = moment().startOf('day');
            userMeasure = { seriesId, unit, date };
        }

        this.setState({ userMeasure });
        this.loadMeasureMetadata(userMeasure);
    };

    initBmiMeasure = (selectedUserMeasure?: BmiMeasure) => {
        let userMeasure: BmiMeasure;
        if (selectedUserMeasure) {
            const id = selectedUserMeasure.id || -1;
            userMeasure = Object.assign({}, selectedUserMeasure, { id });
        } else {
            const seriesId = this.props.userChart.series[0].id;
            const unit = unitService.getUnitValueByUserChart(this.props.userChart, this.props.measurementSystem);
            const heightUnit = unitService.getUnitTypeByChartType('HEIGHT', this.props.measurementSystem).value;
            const weightUnit = unitService.getUnitTypeByChartType('WEIGHT', this.props.measurementSystem).value;
            const date = moment().startOf('day');
            userMeasure = {
                seriesId,
                unit,
                date,
                height: { unit: heightUnit, date },
                weight: { unit: weightUnit, date },
            };
        }

        this.setState({ userMeasure });
        this.loadMeasureMetadata(userMeasure);
    };

    loadMeasureMetadata = async (userMeasure: UserMeasure): Promise<void> => {
        if (userMeasure.date && userMeasure.date.diff(this.props.person.birthdate, 'years') < 18) {
            const measureMetadata = await measureMetadataService.getUserMeasureMetadata(
                userMeasure,
                this.props.standardChart,
                this.props.person.birthdate,
            );
            this.setState({ measureMetadata });
        }
    };

    loadMeasureMetadataDebounced = async (userMeasure: UserMeasure): Promise<void> => {
        await this.loadMeasureMetadata(userMeasure);
    };

    /** HANDLERS */

    handleSave = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'saving' });
            this.props.form.validateFields(async (error: any) => {
                if (!error) {
                    await this.props.saveUserMeasure(this.state.userMeasure);
                }
            });
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.props.deleteUserMeasure(this.state.userMeasure);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleClose = (): void => {
        this.props.closeUserMeasure();
    };

    handleChangeDate = (value: Moment | null) => {
        const date: Moment | undefined = value ? value : undefined;
        this.props.userChart.chartType === 'BMI' ? this.handleChangeBmiDate(date) : this.handleChangeCommonDate(date);
    };

    handleChangeCommonDate = (date: Moment | undefined) => {
        const userMeasure: UserMeasure = Object.assign({}, this.state.userMeasure);
        userMeasure.date = date;

        this.setState({ userMeasure, measureMetadata: undefined });
        this.loadMeasureMetadataDebounced(userMeasure);
    };

    handleChangeBmiDate = (date: Moment | undefined) => {
        const userMeasure: BmiMeasure = Object.assign({}, this.state.userMeasure);
        userMeasure.date = date;
        (userMeasure.height as UserMeasure).date = date;
        (userMeasure.weight as UserMeasure).date = date;

        this.setState({ userMeasure, measureMetadata: undefined });
        this.loadMeasureMetadataDebounced(userMeasure);
    };

    handleChangeTime = (value: Moment | null) => {
        const time: Moment | undefined = value ? value : undefined;
        this.props.userChart.chartType === 'BMI' ? this.handleChangeBmiTime(time) : this.handleChangeCommonTime(time);
    };

    handleChangeCommonTime = (time: Moment | undefined) => {
        const userMeasure: UserMeasure = Object.assign({}, this.state.userMeasure);
        userMeasure.time = time;

        this.setState({ userMeasure });
    };

    handleChangeBmiTime = (time: Moment | undefined) => {
        const userMeasure: BmiMeasure = Object.assign({}, this.state.userMeasure);
        userMeasure.time = time;
        (userMeasure.height as UserMeasure).time = time;
        (userMeasure.weight as UserMeasure).time = time;

        this.setState({ userMeasure });
    };

    handleChangeValue = () => {
        this.props.userChart.chartType === 'BMI' ? this.handleChangeBmiValue() : this.handleChangeCommonValue();
    };

    handleChangeCommonValue = () => {
        const userMeasure: UserMeasure = Object.assign({}, this.state.userMeasure);
        userMeasure.value = this.props.form.getFieldValue('value');
        userMeasure.secondaryValue = this.props.form.getFieldValue('secondaryValue');

        this.setState({ userMeasure, measureMetadata: undefined });
        this.loadMeasureMetadataDebounced(userMeasure);
    };

    handleChangeBmiValue = () => {
        const userMeasure: BmiMeasure = Object.assign({}, this.state.userMeasure);
        (userMeasure.height as UserMeasure).value = this.props.form.getFieldValue('heightValue');
        (userMeasure.height as UserMeasure).secondaryValue = this.props.form.getFieldValue('heightSecondaryValue');
        (userMeasure.weight as UserMeasure).value = this.props.form.getFieldValue('weightValue');
        (userMeasure.weight as UserMeasure).secondaryValue = this.props.form.getFieldValue('weightSecondaryValue');
        userMeasure.value = bmiService.calculateBmi(userMeasure.height as Measure, userMeasure.weight as Measure).value;

        this.setState({ userMeasure, measureMetadata: undefined });
        this.loadMeasureMetadataDebounced(userMeasure);
    };

    /**COMPONENTS **/

    renderForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form id="userMeasureForm" onChange={this.handleChangeValue}>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <>
                                    <FormattedMessage id="biometrics.measure.date" /> (
                                    {this.props.dateFormat.toLowerCase()})
                                </>
                            }
                            extra={
                                <span data-test="age">
                                    {dateService.getPeriodFromBirthdate(
                                        this.props.form.getFieldValue('date') || this.state.userMeasure.date,
                                        this.props.person,
                                    )}
                                </span>
                            }
                            className={styles.date}
                        >
                            {getFieldDecorator('date', {
                                initialValue: this.state.userMeasure.date,
                                rules: [
                                    {
                                        type: 'object',
                                        required: true,
                                        message: <FormattedMessage id="biometrics.measure.date.error.required" />,
                                    },
                                ],
                            })(
                                <DatePicker
                                    format={this.props.dateFormat}
                                    disabledDate={(current?: Moment | null) => {
                                        return !dateService.isAllowedMeasureDate(
                                            this.props.person,
                                            current || undefined,
                                        );
                                    }}
                                    allowClear={false}
                                    onChange={this.handleChangeDate}
                                    onFocus={(e: any) => (e.target.readOnly = true)}
                                    disabled={this.props.readonly}
                                    size="large"
                                    data-test="measureDate"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="biometrics.measure.date" />} className={styles.time}>
                            {getFieldDecorator('time', {
                                initialValue: this.state.userMeasure.time,
                            })(
                                <TimePicker
                                    format="HH:mm"
                                    size="large"
                                    allowClear={true}
                                    onChange={this.handleChangeTime}
                                    disabled={this.props.readonly}
                                    placeholder=""
                                    data-test="measureTime"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                {this.props.userChart.chartType === 'BMI'
                    ? this.renderBmiMeasureFields()
                    : this.renderCommonMeasureFields()}
            </Form>
        );
    };

    renderCommonMeasureFields = (): JSX.Element => {
        const unitType: UnitType = unitService.getUnitTypeByChartType(
            this.props.userChart.chartType,
            this.props.measurementSystem,
        );
        const secondaryUnitType: UnitType | undefined = unitService.getSecondaryUnitTypeByUnitType(unitType);

        return (
            <MeasureValueField
                form={this.props.form}
                unit={unitType}
                secondaryUnit={secondaryUnitType}
                initialValue={this.state.userMeasure.value}
                initialSecondaryValue={this.state.userMeasure.secondaryValue}
                fieldName="value"
                secondaryFieldName="secondaryValue"
                required={true}
                disabled={this.props.readonly}
                size="large"
                data-test="measure"
            />
        );
    };

    renderBmiMeasureFields = (): JSX.Element => {
        const bmiMeasure = this.state.userMeasure as BmiMeasure;
        const heightUnitType: UnitType = unitService.getUnitTypeByChartType('HEIGHT', this.props.measurementSystem);
        const heightSecondaryUnitType: UnitType | undefined = unitService.getSecondaryUnitTypeByUnitType(
            heightUnitType,
        );
        const weightUnitType: UnitType = unitService.getUnitTypeByChartType('WEIGHT', this.props.measurementSystem);
        const weightSecondaryUnitType: UnitType | undefined = unitService.getSecondaryUnitTypeByUnitType(
            weightUnitType,
        );

        return (
            <Row>
                <Col span={12}>
                    <MeasureValueField
                        form={this.props.form}
                        unit={heightUnitType}
                        secondaryUnit={heightSecondaryUnitType}
                        initialValue={bmiMeasure.height && bmiMeasure.height.value}
                        initialSecondaryValue={bmiMeasure.height && bmiMeasure.height.secondaryValue}
                        fieldName="heightValue"
                        secondaryFieldName="heightSecondaryValue"
                        label={<FormattedMessage id="biometrics.measure.bmi.height" />}
                        required={true}
                        disabled={this.props.readonly}
                        size="large"
                        data-test="measureHeight"
                    />
                </Col>
                <Col span={12}>
                    <MeasureValueField
                        form={this.props.form}
                        unit={weightUnitType}
                        secondaryUnit={weightSecondaryUnitType}
                        initialValue={bmiMeasure.weight && bmiMeasure.weight.value}
                        initialSecondaryValue={bmiMeasure.weight && bmiMeasure.weight.secondaryValue}
                        fieldName="weightValue"
                        secondaryFieldName="weightSecondaryValue"
                        label={<FormattedMessage id="biometrics.measure.bmi.weight" />}
                        required={true}
                        disabled={this.props.readonly}
                        size="large"
                        data-test="measureWeight"
                    />
                </Col>
            </Row>
        );
    };

    renderMetadata = (): JSX.Element => {
        if (this.state.measureMetadata) {
            return (
                <p className={styles.metadata} data-test="measureMetadata">
                    <MeasureMetadata
                        userMeasure={this.state.userMeasure}
                        userMeasureMetadatas={[this.state.measureMetadata]}
                    />
                </p>
            );
        } else {
            return <></>;
        }
    };

    renderBmi = (): JSX.Element => {
        if (this.props.userChart.chartType === 'BMI' && this.state.userMeasure.date && this.state.userMeasure.value) {
            const isChild = this.state.userMeasure.date.diff(this.props.person.birthdate, 'years') < 18;

            let bmiLevelStyles;
            if (isChild && this.state.measureMetadata && this.state.measureMetadata.percentile) {
                bmiLevelStyles =
                    bmiService.getBmiChildrenLevel(this.state.measureMetadata.percentile) === 'normal'
                        ? styles.normal
                        : undefined;
            } else if (!isChild) {
                bmiLevelStyles =
                    bmiService.getBmiLevel(this.state.userMeasure.value) === 'normal' ? styles.normal : undefined;
            }
            const unitType: UnitType = unitService.getUnitTypeByChartType(
                this.props.userChart.chartType,
                this.props.measurementSystem,
            );

            return (
                <>
                    <p className={`${styles.bmi} ${bmiLevelStyles}`} data-test="bmi">
                        <label>
                            <FormattedMessage id="biometrics.measure.bmi.bmi" />:
                        </label>
                        {this.state.userMeasure.value ? (
                            <>
                                {' '}
                                <FormattedNumber
                                    value={this.state.userMeasure.value}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                />{' '}
                                {unitType && unitType.label.toLowerCase()}
                            </>
                        ) : (
                            <></>
                        )}
                    </p>
                </>
            );
        } else {
            return <></>;
        }
    };

    renderBmiDescription = (): JSX.Element => {
        if (this.props.userChart.chartType === 'BMI' && this.state.userMeasure.date && this.state.userMeasure.value) {
            const isChild = this.state.userMeasure.date.diff(this.props.person.birthdate, 'years') < 18;
            if (isChild && this.state.measureMetadata && this.state.measureMetadata.percentile) {
                return (
                    <p className={styles.weight} data-test="bmiLevelHeight">
                        <BmiLevelComponent percentile={this.state.measureMetadata.percentile} />
                    </p>
                );
            } else if (!isChild) {
                return (
                    <p className={styles.weight} data-test="bmiLevelWeight">
                        <BmiLevelComponent bmi={this.state.userMeasure.value} />
                    </p>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    };

    renderDiaryRecord = (): JSX.Element => {
        if (this.state.userMeasure.diaryRecordId) {
            const date = (this.state.userMeasure.date as Moment).format('YYYY-MM-DD');
            return (
                <p className={styles.diary} data-test="diaryRecord">
                    <FormattedMessage id="biometrics.measure.diaryRecord" />{' '}
                    <Link
                        to={`/persons/${this.props.person.id}/diary/records/${this.state.userMeasure.diaryRecordId}?date=${date}`}
                    >
                        <FormattedMessage id="biometrics.measure.diaryRecord.link" />
                    </Link>
                </p>
            );
        } else return <></>;
    };

    renderFooter = (): JSX.Element => {
        return (
            <div className={styles.footer} key="userMeasureFooter">
                <Popconfirm
                    title={
                        !this.props.external && this.props.userChart.chartType === 'BMI' ? (
                            <FormattedMessage id="biometrics.measure.confirm.delete.bmi" />
                        ) : (
                            <FormattedMessage id="biometrics.measure.confirm.delete" />
                        )
                    }
                    onConfirm={
                        !this.props.external && this.props.userChart.chartType === 'BMI' ? undefined : this.handleDelete
                    }
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                    okButtonProps={
                        !this.props.external && this.props.userChart.chartType === 'BMI' ? { hidden: true } : undefined
                    }
                >
                    <Button
                        type="link"
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                        hidden={!this.state.userMeasure.id || this.props.readonly}
                        data-test="delete"
                    >
                        <FormattedMessage id="common.delete" />
                    </Button>
                </Popconfirm>
                <Button className={styles.back} onClick={this.handleClose} data-test="cancel">
                    <FormattedMessage id="common.cancel" />
                </Button>
                <Button
                    type="primary"
                    onClick={this.handleSave}
                    loading={this.state.actionStatus === 'saving'}
                    disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                    hidden={this.props.readonly}
                    form="userMeasureForm"
                    key="submit"
                    htmlType="submit"
                    data-test="submit"
                >
                    <FormattedMessage id="common.save" />
                </Button>
            </div>
        );
    };

    render() {
        const title: string = this.state.userMeasure.id
            ? this.props.intl.formatMessage({ id: 'biometrics.measure.title.edit' })
            : this.props.intl.formatMessage({ id: 'biometrics.measure.title.add' });

        return (
            <Modal
                title={title}
                visible={this.props.visible}
                okText="Save"
                onCancel={this.handleClose}
                className="root-modal"
                footer={[this.renderFooter()]}
                data-test="measureModal"
            >
                {this.renderForm()}
                {this.renderMetadata()}
                {this.renderBmi()}
                {this.renderBmiDescription()}
                {this.renderDiaryRecord()}
            </Modal>
        );
    }
}
export default injectIntl(Form.create<Props>()(UserMeasureModal));

interface Props extends FormComponentProps, WrappedComponentProps {
    visible?: boolean;
    external?: boolean;
    measurementSystem: string;
    dateFormat: string;
    person: Person;
    readonly: boolean;
    userChart: UserChart;
    userMeasure?: UserMeasure;
    standardChart?: StandardChart;
    saveUserMeasure: (userMeasure: UserMeasure) => Promise<void>;
    deleteUserMeasure: (userMeasure: UserMeasure) => Promise<void>;
    closeUserMeasure: () => void;
}

interface State {
    userMeasure: UserMeasure | BmiMeasure;
    measureMetadata?: UserMeasureMetadata;
    actionStatus?: ActionStatus;
}
