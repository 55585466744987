import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Page, DiaryTemplate, Locale } from '../model/model';

class DiaryTemplateApi {
    list = async (page: number, size: number, locale: Locale, searchText?: string): Promise<Page<DiaryTemplate>> => {
        const language = locale.toUpperCase();
        const response: AxiosResponse<Page<DiaryTemplate>> = await apiAxios.get<Page<DiaryTemplate>>(
            '/diary-templates',
            {
                params: { page, size, language, searchText },
            },
        );
        return response.data;
    };

    listStandards = async (
        page: number,
        size: number,
        locale: Locale,
        searchText?: string,
    ): Promise<Page<DiaryTemplate>> => {
        const language = locale.toUpperCase();
        const response: AxiosResponse<Page<DiaryTemplate>> = await apiAxios.get<Page<DiaryTemplate>>(
            '/diary-templates/standards',
            {
                params: { page, size, language, searchText },
            },
        );
        return response.data;
    };

    listUsers = async (page: number, size: number, searchText?: string): Promise<Page<DiaryTemplate>> => {
        const response: AxiosResponse<Page<DiaryTemplate>> = await apiAxios.get<Page<DiaryTemplate>>(
            '/diary-templates/users',
            {
                params: { page, size, searchText },
            },
        );
        return response.data;
    };

    listPersons = async (
        page: number,
        size: number,
        personId: number,
        searchText?: string,
    ): Promise<Page<DiaryTemplate>> => {
        const response: AxiosResponse<Page<DiaryTemplate>> = await apiAxios.get<Page<DiaryTemplate>>(
            '/diary-templates/persons',
            {
                params: { page, size, personId, searchText },
            },
        );
        return response.data;
    };

    get = async (id: number): Promise<DiaryTemplate> => {
        const response: AxiosResponse<DiaryTemplate> = await apiAxios.get<DiaryTemplate>(`/diary-templates/${id}`);
        return response.data;
    };

    create = async (diaryTemplate: DiaryTemplate): Promise<DiaryTemplate> => {
        const response: AxiosResponse<DiaryTemplate> = await apiAxios.post<DiaryTemplate>(
            '/diary-templates',
            diaryTemplate,
        );
        return response.data;
    };

    update = async (diaryTemplate: DiaryTemplate): Promise<DiaryTemplate> => {
        const response: AxiosResponse<DiaryTemplate> = await apiAxios.put<DiaryTemplate>(
            `/diary-templates/${diaryTemplate.id}`,
            diaryTemplate,
        );
        return response.data;
    };

    delete = async (diaryTemplate: DiaryTemplate): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/diary-templates/${diaryTemplate.id}`);
        return response.data;
    };
}

const diaryTemplateApi: DiaryTemplateApi = new DiaryTemplateApi();
export default diaryTemplateApi;
