import EventSource from 'eventsource';
import authService from './AuthService';
import { EventType, ChatMessage, Notification, Chat, VideoCall } from '../model/model';
import videoApi from '../api/VideoApi';

class EventService {
    eventSource?: EventSource;
    connectionRetries: number = 0;
    chatId: number | undefined;

    notificationAddedListener?: EventListener;
    notificationsReadListener?: EventListener;

    chatMessageAddedListener?: EventListener;
    chatMessageReceivedListener?: EventListener;
    chatReadListener?: EventListener;

    videoCallListener?: EventListener;

    init = () => {
        this.close();
        this.connect();
    };

    close = () => {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
        }
    };

    resume = () => {
        if (!this.eventSource || (this.eventSource && this.eventSource.readyState === EventSource.CLOSED)) {
            this.connect();
        }
    };

    unsubscribe = (eventType: EventType) => {
        if (this.eventSource) {
            switch (eventType) {
                case 'notificationAdded':
                    this.eventSource.removeEventListener('notificationAdded', this.notificationAddedListener);
                    this.notificationAddedListener = undefined;
                    break;
                case 'notificationsRead':
                    this.eventSource.removeEventListener('notificationsRead', this.notificationsReadListener);
                    this.notificationsReadListener = undefined;
                    break;
                case 'chatMessageAdded':
                    this.chatId = undefined;
                    this.eventSource.removeEventListener('chatMessageAdded', this.chatMessageAddedListener);
                    this.chatMessageAddedListener = undefined;
                    break;
                case 'chatMessageReceived':
                    this.eventSource.removeEventListener('chatMessageAdded', this.chatMessageReceivedListener); // event shared with chatMessageAdded
                    this.chatMessageReceivedListener = undefined;
                    break;
                case 'chatRead':
                    this.eventSource.removeEventListener('chatRead', this.chatReadListener);
                    this.chatReadListener = undefined;
                    break;
                case 'videoCall':
                    this.eventSource.removeEventListener('videoCall', this.videoCallListener);
                    this.videoCallListener = undefined;
                    break;
                default:
                    break;
            }
        }
    };

    subscribeToNotificationAdded = (callback: (notification: Notification) => void) => {
        if (this.eventSource) {
            const eventListener = (event: any) => {
                const notification: Notification = JSON.parse(event.data);
                callback(notification);
            };

            this.notificationAddedListener = eventListener;
            this.eventSource.addEventListener('notificationAdded', this.notificationAddedListener);
        }
    };

    subscribeToNotificationsRead = (callback: () => void) => {
        if (this.eventSource) {
            const eventListener = () => callback();
            this.notificationsReadListener = eventListener;
            this.eventSource.addEventListener('notificationsRead', this.notificationsReadListener);
        }
    };

    subscribeToChatMessageAdded = (chatId: number, callback: (message: ChatMessage) => void) => {
        this.chatId = chatId;
        if (this.eventSource) {
            const eventListener = (event: any) => {
                const message: ChatMessage = JSON.parse(event.data);
                if (this.chatId && this.chatId === message.chatId) {
                    callback(message);
                }
            };

            this.chatMessageAddedListener = eventListener;
            this.eventSource.addEventListener('chatMessageAdded', this.chatMessageAddedListener);
        }
    };

    subscribeToChatMessageReceived = (callback: (message: ChatMessage) => void) => {
        if (this.eventSource) {
            const eventListener = (event: any) => {
                const message: ChatMessage = JSON.parse(event.data);
                if (!this.chatId || this.chatId !== message.chatId) {
                    callback(message);
                }
            };

            this.chatMessageReceivedListener = eventListener;
            this.eventSource.addEventListener('chatMessageAdded', this.chatMessageReceivedListener);
        }
    };

    subscribeToChatRead = (callback: (chat: Chat) => void) => {
        if (this.eventSource) {
            const eventListener = (event: any) => {
                const chat: Chat = JSON.parse(event.data);
                callback(chat);
            };

            this.chatReadListener = eventListener;
            this.eventSource.addEventListener('chatRead', this.chatReadListener);
        }
    };

    subscribeToVideoCall = (callback: (videoCall: VideoCall) => void) => {
        if (this.eventSource) {
            const eventListener = (event: any) => {
                const result: any = JSON.parse(event.data);
                const videoCall: VideoCall = Object.assign({}, result, {
                    videoMeeting: videoApi.formatVideoMeeting(result.videoMeeting),
                });
                callback(videoCall);
            };

            this.videoCallListener = eventListener;
            this.eventSource.addEventListener('videoCall', this.videoCallListener);
        }
    };

    private connect = () => {
        const url = `${process.env.REACT_APP_API_URL}/events`;
        const accessToken = authService.getAccessTokenFromCookie();
        if (accessToken) {
            this.eventSource = new EventSource(url, { headers: { Authorization: `Bearer ${accessToken}` } });
            this.eventSource.onerror = this.onError;
            this.eventSource.onopen = this.onOpen;
            this.notificationAddedListener &&
                this.eventSource.addEventListener('notificationAdded', this.notificationAddedListener);
            this.notificationsReadListener &&
                this.eventSource.addEventListener('notificationsRead', this.notificationsReadListener);
            this.chatMessageAddedListener &&
                this.eventSource.addEventListener('chatMessageAdded', this.chatMessageAddedListener);
            this.chatMessageReceivedListener &&
                this.eventSource.addEventListener('chatMessageReceived', this.chatMessageReceivedListener);
            this.chatReadListener && this.eventSource.addEventListener('chatRead', this.chatReadListener);
            this.videoCallListener && this.eventSource.addEventListener('videoCall', this.videoCallListener);
        }
    };

    private onOpen = () => {
        this.connectionRetries = 0;
    };

    private onError = async (event: any) => {
        this.connectionRetries = this.connectionRetries + 1;
        if (this.connectionRetries < 5) {
            this.close();

            // http 406 error is linked to invalid access token
            if (event.type === 'error' && event.status === 406) {
                authService.initOauthImplicitFlowSilentRefresh();
                await new Promise(resolve => setTimeout(resolve, 3000));
            }
            this.connect();
        } else {
            this.close();

            // http 406 error is linked to invalid access token
            if (event.type === 'error' && event.status === 406) {
                authService.signOut('unauthorized');
            }
        }
    };
}

const eventService = new EventService();
export default eventService;
