import apiAxios, { apiNoAuthAxios } from './CustomAxios';
import { UserToken, Alert } from '../model/model';
import { AxiosResponse } from 'axios';

class AlertApi {
    list = async (): Promise<Alert[]> => {
        const response: AxiosResponse<Alert[]> = await apiAxios.get<Alert[]>('/alerts');
        return response.data;
    };

    cancel = async (userId: number, userToken: UserToken): Promise<void> => {
        await apiNoAuthAxios.post<void>(`/alerts/cancels/${userId}`, userToken);
    };
}

const alertApi: AlertApi = new AlertApi();
export default alertApi;
