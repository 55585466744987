import React, { Component, FormEvent } from 'react';
import styles from './PostCommentEditComponent.module.scss';
import { Post, ActionStatus, User } from '../../../../../../model/model';
import CustomContext from '../../../../../../service/CustomContext';
import { Form, message, Button, Select, Popconfirm } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import errorService from '../../../../../../service/ErrorService';
import postApi from '../../../../../../api/PostApi';

class PostCommentEditComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.context.user) {
            return;
        }

        this.init(this.props.comment);
    }

    init = (post: Post): void => {
        this.props.form.setFieldsValue({
            message: post.message,
            status: post.status,
        });
    };

    /** METHODS **/

    save = async (values: any): Promise<Post> => {
        let post: Post = Object.assign({}, this.props.comment, {
            message: values.message,
            status: values.status,
            entityName: undefined,
            fileName: undefined,
            fileSize: undefined,
            fileContentType: undefined,
            postDate: undefined,
        });
        return post.id ? await postApi.update(post) : await postApi.create(post);
    };

    delete = async (): Promise<void> => {
        await postApi.delete(this.props.comment);
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.save(values);
                    await message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);

                    this.props.form.setFieldsValue({
                        message: '',
                    });
                    this.props.handlePostCreate();
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            await message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);

            this.props.handlePostCreate();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderSaveButton = (): JSX.Element => {
        if (this.props.comment.id) {
            return (
                <Button type="primary" htmlType="submit">
                    <FormattedMessage id="common.save" />
                </Button>
            );
        } else if (this.props.form.isFieldsTouched()) {
            return (
                <Button type="primary" htmlType="submit">
                    <FormattedMessage id="common.post" />
                </Button>
            );
        } else {
            return <></>;
        }
    };

    renderDeleteButton = (): JSX.Element => {
        const user: User = this.context.user as User;
        if (this.props.comment.id && user && user.entity!.id === this.props.comment.entityId) {
            return (
                <Popconfirm
                    title={
                        <FormattedMessage
                            id={
                                this.props.comment.postType === 'QUESTION'
                                    ? 'question.comment.confirm.delete'
                                    : 'post.comment.confirm.delete'
                            }
                        />
                    }
                    onConfirm={this.handleDelete}
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                >
                    <Button
                        type="link"
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                    >
                        <FormattedMessage id="common.delete" />
                    </Button>
                </Popconfirm>
            );
        } else {
            return <></>;
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const user: User = this.context.user as User;
        const messagePlaceholder = this.props.intl.formatMessage({
            id:
                this.props.comment.postType === 'QUESTION'
                    ? 'question.comment.message.placeholder'
                    : 'post.comment.message.placeholder',
        });
        const postStatusOptions = this.context.settings.postStatusTypes.map(statusType => (
            <Select.Option value={statusType.value} key={statusType.value}>
                {statusType.label}
            </Select.Option>
        ));

        return (
            <Form onSubmit={this.handleSave} className={styles.comment}>
                <Form.Item className={styles.message}>
                    {getFieldDecorator('message', {
                        rules: [
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id={
                                            this.props.comment.postType === 'QUESTION'
                                                ? 'question.comment.message.error.required'
                                                : 'post.comment.message.error.required'
                                        }
                                    />
                                ),
                            },
                        ],
                    })(
                        <TextArea
                            maxLength={1300}
                            rows={this.props.form.isFieldsTouched() ? 3 : 1}
                            placeholder={messagePlaceholder}
                        />,
                    )}
                </Form.Item>
                <div hidden={!user || !user.admin}>
                    <Form.Item>
                        {getFieldDecorator('status', {
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id={
                                                this.props.comment.postType === 'QUESTION'
                                                    ? 'question.comment.status.error.required'
                                                    : 'post.comment.status.error.required'
                                            }
                                        />
                                    ),
                                },
                            ],
                        })(<Select>{postStatusOptions}</Select>)}
                    </Form.Item>
                </div>
                {this.renderSaveButton()}
                {this.renderDeleteButton()}
            </Form>
        );
    }
}
export default injectIntl(Form.create<Props>()(PostCommentEditComponent));

interface Props extends FormComponentProps, WrappedComponentProps {
    comment: Post;
    handlePostCreate: () => void;
}

interface State {
    actionStatus?: ActionStatus;
}
