import { MedicalConditionType } from '../model/model';
import i18nService from './I18nService';
import settingsService from './SettingsService';

class MedicalConditionService {
    filter = (
        medicalConditions: MedicalConditionType[],
        selectedMedicalConditions?: string[],
    ): MedicalConditionType[] => {
        return medicalConditions.filter(
            mc => selectedMedicalConditions && selectedMedicalConditions.includes(mc.value),
        );
    };

    search = (medicalConditions: MedicalConditionType[], searchText?: string): MedicalConditionType[] => {
        let filteredMedicalConditions: MedicalConditionType[] = medicalConditions.sort((a, b) =>
            a.label.localeCompare(b.label),
        );

        if (searchText) {
            const normalizedSearchText: string = i18nService.normalize(searchText);

            // filter by starting search text
            filteredMedicalConditions = medicalConditions.filter(c =>
                i18nService.normalize(c.label).startsWith(normalizedSearchText),
            );
            // filter by containg search text
            if (filteredMedicalConditions.length < 20) {
                let medicalConditionTypesContaining: MedicalConditionType[] = medicalConditions.filter(
                    c =>
                        !filteredMedicalConditions.map(ct => ct.value).includes(c.value) &&
                        i18nService.normalize(c.label).includes(normalizedSearchText),
                );
                filteredMedicalConditions = filteredMedicalConditions.concat(medicalConditionTypesContaining);
            }
        }

        return filteredMedicalConditions;
    };

    getMedicalConditionByPath = (path: string): MedicalConditionType | undefined => {
        return settingsService.settings.medicalConditionTypes.find(mc => mc.path === path);
    };
}

const medicalConditionService = new MedicalConditionService();
export default medicalConditionService;
