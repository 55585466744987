import React, { Component } from 'react';
import styles from './PersonPage.module.scss';
import { ScreenSizeProps } from '../../model/model';
import withSizes from 'react-sizes';
import responsiveService from '../../service/ResponsiveService';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import PersonComponent from './PersonComponent';
import { Row, Col } from 'antd';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';

class PersonPage extends Component<Props> {
    render() {
        let id: number | undefined;
        if (this.props.match.params.id && this.props.match.params.id !== 'new') {
            id = this.props.match.params.id;
        }

        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex" className={styles.layout}>
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            <PersonComponent id={id} key={id} />
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(PersonPage);

interface Props extends ScreenSizeProps {
    match: any;
}
