import React, { Component } from 'react';
import styles from './CommunityMembersPage.module.scss';
import { MedicalConditionType, PublicUser, Status, Page } from '../../../model/model';
import { PaginationConfig } from 'antd/lib/table';
import errorService from '../../../service/ErrorService';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { message, List } from 'antd';
import Search from 'antd/lib/input/Search';
import userApi from '../../../api/UserApi';
import CommunityMemberComponent from './CommunityMemberComponent/CommunityMemberComponent';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

class CommunityMembersPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            members: [],
            pagination: { current: 1, pageSize: 20, hideOnSinglePage: true, onChange: this.handleTableChange },
            keyLabel: 'members',
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        this.loadKeyLabel();
        await this.list();
    };

    list = async (page: number = 1, pageSize: number = 20, searchText?: string) => {
        const userType: string | undefined = this.props.userType === 'PATIENT' ? undefined : this.props.userType;
        const membersPage: Page<PublicUser> = await userApi.list(
            page - 1,
            pageSize,
            searchText,
            userType,
            this.props.condition.value,
        );
        const members: PublicUser[] = membersPage.content;
        const pagination: PaginationConfig = Object.assign({}, this.state.pagination, {
            current: page,
            total: membersPage.totalElements,
            showTotal: (total: number) => <FormattedMessage id="members.total" values={{ total }} />,
        });
        this.setState({ members, pagination, searchText });
    };

    loadKeyLabel = (): void => {
        let keyLabel: string;
        switch (this.props.userType) {
            case 'HEALTH_PROFESSIONAL':
                keyLabel = 'doctors';
                break;
            case 'RESEARCHER':
                keyLabel = 'researchers';
                break;
            default:
                keyLabel = 'members';
                break;
        }
        this.setState({ keyLabel });
    };

    /** HANDLERS **/

    handleSearch = async (searchText: string) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(1, 20, searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    handleTableChange = async (page: number, pageSize?: number) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(page, pageSize);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    /** COMPONENTS **/

    renderSearch = (): JSX.Element => {
        const placeholder: string = this.props.intl.formatMessage({ id: `${this.state.keyLabel}.search.placeholder` });
        return <Search placeholder={placeholder} onSearch={this.handleSearch} className={styles.search} />;
    };

    renderList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.members}
                locale={{ emptyText: <FormattedMessage id={`${this.state.keyLabel}.empty`} /> }}
                pagination={this.state.pagination}
                renderItem={member => <CommunityMemberComponent member={member} />}
            />
        );
    };

    render() {
        const title: string = this.props.intl.formatMessage(
            { id: `community.${this.state.keyLabel}.meta.title` },
            { community: this.props.condition.label },
        );
        const description: string = this.props.intl.formatMessage(
            { id: `community.${this.state.keyLabel}.meta.description` },
            { community: this.props.condition.label },
        );
        return (
            <>
                <HeadMetadata title={title} description={description} />
                {this.renderSearch()}
                {this.renderList()}
            </>
        );
    }
}
export default injectIntl(CommunityMembersPage);

interface Props extends WrappedComponentProps {
    condition: MedicalConditionType;
    userType: string;
}

interface State {
    members: PublicUser[];
    searchText?: string;
    pagination: PaginationConfig;
    keyLabel: string;
    status?: Status;
}
