import React, { Component } from 'react';
import styles from './UserLinksPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserLink, Status, Page, ScreenSizeProps } from '../../model/model';
import userLinkApi from '../../api/UserLinkApi';
import { Button, message, List, Row, Col, Empty } from 'antd';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { PaginationConfig } from 'antd/lib/table';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import Search from 'antd/lib/input/Search';
import { Link } from 'react-router-dom';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';

class UserLinksPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            userLinks: [],
            pagination: { current: 1, pageSize: 20, hideOnSinglePage: true, onChange: this.handleTableChange },
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
    };

    list = async (page: number = 1, pageSize: number = 20, searchText?: string) => {
        const userLinksPage: Page<UserLink> = await userLinkApi.list(page - 1, pageSize, searchText);
        const userLinks: UserLink[] = userLinksPage.content;
        const pagination: PaginationConfig = Object.assign({}, this.state.pagination, {
            current: page,
            total: userLinksPage.totalElements,
            showTotal: (total: number) => <FormattedMessage id="userLinks.total" values={{ total }} />,
        });
        this.setState({ userLinks, pagination, searchText });
    };

    handleSearch = async (searchText: string) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(1, 20, searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    handleTableChange = async (page: number, pageSize?: number) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(page, pageSize);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    /** COMPONENTS */

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.userLinks" />
                    </h1>
                    <p>
                        <FormattedMessage id="navigation.userLinks.desc" />
                    </p>
                </div>
            </div>
        );
    };

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.search}>{this.renderSearch()}</div>
                <div className={styles.add}>{this.renderCreateButton()}</div>
            </div>
        );
    };

    renderSearch = (): JSX.Element => {
        const placeholder: string = this.props.intl.formatMessage({
            id: 'userLinks.search.placeholder',
        });
        return <Search placeholder={placeholder} onSearch={this.handleSearch} size="large" className={styles.search} />;
    };

    renderCreateButton = (): JSX.Element => {
        return (
            <Link to="/employees/new" data-test="add">
                <Button type="primary" size="large" icon="plus">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                </Button>
            </Link>
        );
    };

    renderItem = (userLink: UserLink): JSX.Element => {
        let name = userLink.email;
        if (userLink.approver) {
            name = userLink.approver.fullName;
        }

        return (
            <Link to={`/employees/${userLink.id}`} className={styles.description}>
                <List.Item data-test="item">
                    <List.Item.Meta
                        avatar={<AvatarComponent name={name} />}
                        title={name}
                        description={this.renderDescription(userLink)}
                    />
                </List.Item>
            </Link>
        );
    };

    renderDescription = (userLink: UserLink): JSX.Element => {
        if (userLink.linkedUserId) {
            return <>{userLink.email}</>;
        } else {
            return <FormattedMessage id="userLink.status.pending" />;
        }
    };

    renderList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.userLinks}
                locale={{
                    emptyText: (
                        <Empty
                            className="empty"
                            data-test="empty"
                            description={
                                <>
                                    <span>
                                        <FormattedMessage id="userLinks.empty" />
                                    </span>
                                    <Link to="/employees/new">
                                        <Button size="large" type="primary" ghost>
                                            <FormattedMessage id="userLinks.empty.add" />
                                        </Button>
                                    </Link>
                                </>
                            }
                        />
                    ),
                }}
                pagination={this.state.pagination}
                renderItem={userLink => this.renderItem(userLink)}
                data-test="list"
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex">
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderToolbar()}
                            {this.renderList()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(UserLinksPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {}

interface State {
    userLinks: UserLink[];
    searchText?: string;
    pagination: PaginationConfig;
    status?: Status;
}
