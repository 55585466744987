import React, { Component, FormEvent } from 'react';
import styles from './ProfilePage.module.scss';
import {
    Layout,
    Form,
    Input,
    Button,
    message,
    DatePicker,
    Popconfirm,
    Row,
    Col,
    notification,
    Icon,
    Select,
    Tabs,
} from 'antd';
import CustomContext from '../../../service/CustomContext';
import { Status, ActionStatus, Person } from '../../../model/model';
import { FormComponentProps } from 'antd/lib/form';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router';
import dateService from '../../../service/DateService';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../../service/ErrorService';
import i18nService from '../../../service/I18nService';
import TextArea from 'antd/lib/input/TextArea';

class ProfilePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    componentWillUnmount() {
        notification.destroy();
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        if (this.props.person.id) {
            this.props.form.setFieldsValue({
                firstName: this.props.person.firstName,
                lastName: this.props.person.lastName,
                birthdate: moment.utc(this.props.person.birthdate),
                gender: this.props.person.gender,
                email: this.props.person.email,
                phone: this.props.person.phone,
                idCard: this.props.person.idCard,
                healthCard: this.props.person.healthCard,
                profession: this.props.person.profession,
                address: this.props.person.address && this.props.person.address.address,
                city: this.props.person.address && this.props.person.address.city,
                state: this.props.person.address && this.props.person.address.state,
                country: this.props.person.address && this.props.person.address.country,
                postalCode: this.props.person.address && this.props.person.address.postalCode,
                identifier: this.props.person.identifier,
                redirected: this.props.person.redirected,
                background: this.props.person.background,
                remarks: this.props.person.remarks,
                tutorFirstName: this.props.person.tutorFirstName,
                tutorLastName: this.props.person.tutorLastName,
                tutorRelationship: this.props.person.tutorRelationship,
            });
        } else if (this.props.personsCount === 0 && this.context.user && this.context.user.userType === 'PATIENT') {
            this.props.form.setFieldsValue({
                firstName: this.context.user && this.context.user.firstName,
                lastName: this.context.user && this.context.user.lastName,
            });
        }
    };

    openNotification = (person: Person): void => {
        notification.open({
            message: (
                <>
                    <Icon type="check-circle" theme="twoTone" twoToneColor="#1aaa55" />
                    {this.props.intl.formatMessage({ id: 'person.notification.title' })}
                </>
            ),
            description: (
                <>
                    {this.props.intl.formatMessage({ id: 'person.notification.desc' }, { name: person.fullName })}
                    <Button
                        type="primary"
                        ghost
                        block
                        onClick={() => {
                            this.props.history.push(`/persons/${this.props.person.id}/biometrics`);
                        }}
                    >
                        {this.props.intl.formatMessage({ id: 'person.notification.biometrics' })}
                    </Button>
                    <Button
                        type="danger"
                        ghost
                        block
                        onClick={() => {
                            this.props.history.push(`/persons/${this.props.person.id}/documents/specialities`);
                        }}
                    >
                        {this.props.intl.formatMessage({ id: 'person.notification.documents' })}
                    </Button>
                </>
            ),
            duration: 0,
            className: styles.notification,
        });
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const person: Person = await this.props.save(values);

                    // this.openNotification(person);
                    await message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 1);
                    this.props.history.push(`/persons/${person.id}`);
                }
            } catch (error) {
                errorService.displayMessage(error, [[402, 'person.error.totalCount']], 10);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.props.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderProfileForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const format: string = dateService.getDateFormat(this.context.user);
        const genderOptions = this.context.settings.genderTypes.map(genderType => (
            <Select.Option value={genderType.value} key={genderType.value}>
                {genderType.label}
            </Select.Option>
        ));
        const countryOptions = i18nService.getCountries().map(country => (
            <Select.Option value={country.code} key={country.code}>
                {country.name}
            </Select.Option>
        ));

        return (
            <div className={styles.layout}>
                <Form onSubmit={this.handleSave}>
                    <Row gutter={[24, 12]} type="flex">
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="person.firstName" />}>
                                {getFieldDecorator('firstName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: <FormattedMessage id="person.firstName.error.required" />,
                                        },
                                    ],
                                })(<Input maxLength={50} size="large" data-test="firstName" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="person.lastName" />}>
                                {getFieldDecorator('lastName')(
                                    <Input maxLength={50} size="large" data-test="lastName" />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]} type="flex">
                        <Col span={12}>
                            <Form.Item
                                label={<FormattedMessage id="person.birthdate" />}
                                extra={dateService.getPeriod(this.props.form.getFieldValue('birthdate'), moment())}
                            >
                                {getFieldDecorator('birthdate', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: <FormattedMessage id="person.birthdate.error.required" />,
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format={format}
                                        disabledDate={dateService.isFutureDate}
                                        allowClear={false}
                                        onFocus={(e: any) => (e.target.readOnly = true)}
                                        size="large"
                                        placeholder=""
                                        data-test="birthdate"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="person.gender" />}>
                                {getFieldDecorator('gender', {
                                    rules: [
                                        {
                                            required: true,
                                            message: <FormattedMessage id="person.gender.error.required" />,
                                        },
                                    ],
                                })(
                                    <Select size="large" data-test="gender">
                                        {genderOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Tabs defaultActiveKey="1" className={styles.tabs}>
                        <Tabs.TabPane tab={<FormattedMessage id="person.contact" />} key="1" data-test="contactTab">
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.email" />}>
                                        {getFieldDecorator('email', {
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: <FormattedMessage id="person.email.error.invalid" />,
                                                },
                                            ],
                                        })(<Input maxLength={100} size="large" type="email" data-test="email" />)}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.phone" />}>
                                        {getFieldDecorator('phone')(
                                            <Input maxLength={25} size="large" data-test="phone" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.idCard" />}>
                                        {getFieldDecorator('idCard')(
                                            <Input maxLength={25} size="large" data-test="idCard" />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.healthCard" />}>
                                        {getFieldDecorator('healthCard')(
                                            <Input maxLength={25} size="large" data-test="healthCard" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={24}>
                                    <Form.Item label={<FormattedMessage id="person.profession" />}>
                                        {getFieldDecorator('profession')(
                                            <Input maxLength={100} size="large" data-test="profession" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id="person.address" />} key="2" data-test="addressTab">
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={24}>
                                    <Form.Item label={<FormattedMessage id="person.address.address" />}>
                                        {getFieldDecorator('address')(
                                            <Input size="large" maxLength={100} data-test="address" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.address.city" />}>
                                        {getFieldDecorator('city')(
                                            <Input size="large" maxLength={50} data-test="city" />,
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.address.state" />}>
                                        {getFieldDecorator('state')(
                                            <Input size="large" maxLength={50} data-test="state" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={12}>
                                    <Form.Item
                                        label={<FormattedMessage id="person.address.country" data-test="country" />}
                                    >
                                        {getFieldDecorator('country')(
                                            <Select size="large" allowClear={true}>
                                                {countryOptions}
                                            </Select>,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={
                                            <FormattedMessage id="person.address.postalCode" data-test="postalCode" />
                                        }
                                    >
                                        {getFieldDecorator('postalCode')(<Input size="large" maxLength={10} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id="person.medical" />} key="3" data-test="medicalTab">
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.identifier" />}>
                                        {getFieldDecorator('identifier')(
                                            <Input size="large" maxLength={10} data-test="identifier" />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.redirected" />}>
                                        {getFieldDecorator('redirected')(
                                            <Input size="large" maxLength={100} data-test="redirected" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={24}>
                                    <Form.Item label={<FormattedMessage id="person.background" />}>
                                        {getFieldDecorator('background')(
                                            <TextArea maxLength={50000} rows={5} data-test="background" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={24}>
                                    <Form.Item label={<FormattedMessage id="person.remarks" />}>
                                        {getFieldDecorator('remarks')(
                                            <TextArea maxLength={50000} rows={5} data-test="remarks" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<FormattedMessage id="person.tutor" />} key="4" data-test="tutorTab">
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.tutorFirstName" />}>
                                        {getFieldDecorator('tutorFirstName')(
                                            <Input size="large" maxLength={50} data-test="tutorFirstName" />,
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={<FormattedMessage id="person.tutorLastName" />}>
                                        {getFieldDecorator('tutorLastName')(
                                            <Input size="large" maxLength={50} data-test="tutorLastName" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]} type="flex">
                                <Col span={24}>
                                    <Form.Item label={<FormattedMessage id="person.tutorRelationship" />}>
                                        {getFieldDecorator('tutorRelationship')(
                                            <Input size="large" maxLength={100} data-test="tutorRelationship" />,
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Tabs.TabPane>
                    </Tabs>

                    <Row gutter={[24, 0]} className={styles.buttons} type="flex">
                        <Col xs={12} md={8}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                                loading={this.state.actionStatus === 'saving'}
                                disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                                data-test="submit"
                            >
                                <FormattedMessage id="common.save" />
                            </Button>
                        </Col>
                        <Col xs={12} md={16}>
                            <Popconfirm
                                title={
                                    <FormattedMessage
                                        id="person.confirm.delete"
                                        values={{
                                            name: this.props.person.fullName,
                                        }}
                                    />
                                }
                                onConfirm={this.handleDelete}
                                okText={<FormattedMessage id="common.ok" />}
                                cancelText={<FormattedMessage id="common.cancel" />}
                            >
                                <Button
                                    type="link"
                                    size="large"
                                    loading={this.state.actionStatus === 'deleting'}
                                    disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                    hidden={this.props.person.id === undefined}
                                    className={styles.delete}
                                    data-test="delete"
                                >
                                    <FormattedMessage id="common.delete" />
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    render() {
        return <Layout.Content>{this.renderProfileForm()}</Layout.Content>;
    }
}
export default injectIntl(withRouter(Form.create<Props>()(ProfilePage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps {
    person: Person;
    personsCount?: number;
    save: (values: any) => Promise<Person>;
    delete: () => Promise<void>;
}

interface State {
    status?: Status;
    actionStatus?: ActionStatus;
}
