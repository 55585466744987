import React, { Component } from 'react';
import styles from './PostsComponent.module.scss';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import {
    ScreenSizeProps,
    Status,
    ActionStatus,
    Post,
    Page,
    MedicalConditionType,
    PostType,
} from '../../../model/model';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import errorService from '../../../service/ErrorService';
import postApi from '../../../api/PostApi';
import { message, Button, List, Form, Alert } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form';
import PostComponent from './PostComponent/PostComponent';
import PostEditComponent from './PostEditComponent/PostEditComponent';
import CustomContext from '../../../service/CustomContext';
import SignUpModal from '../../Shared/SignUpModal/SignUpModal';

class PostsComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            posts: [],
            page: 0,
            last: true,
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list(this.state.page);
    };

    list = async (page: number) => {
        const size: number = this.props.size || 20;
        const language: string = this.context.locale.toUpperCase();
        const condition: string | undefined = this.props.condition && this.props.condition.value;

        const postsPage: Page<Post> = await postApi.list(page, size, language, this.props.postType, condition);
        const posts: Post[] = page === 0 ? postsPage.content : this.state.posts.concat(postsPage.content);
        const last: boolean = postsPage.last;
        this.setState({ posts, page, last });
    };

    showPost = async (postEditId?: number) => {
        if (this.context.user) {
            this.setState({
                postEditVisible: true,
                postEditId: postEditId,
            });
        } else {
            this.setState({
                signUpVisible: true,
            });
        }
    };

    hidePost = async (refresh?: boolean) => {
        this.setState({
            postEditVisible: false,
            postEditId: undefined,
        });

        // refresh posts
        if (refresh) {
            await this.list(0);
        }
    };

    /** HANDLERS **/

    handleLoadMore = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'loading' });
            await this.list(this.state.page + 1);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleShowPost = async (postEditId?: number) => {
        try {
            message.destroy();
            await this.showPost(postEditId);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleHidePost = async (refresh?: boolean) => {
        try {
            message.destroy();
            await this.hidePost(refresh);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleHideSignUp = () => {
        this.setState({ signUpVisible: false });
    };

    /** COMPONENTS **/

    renderCreateButton = (): JSX.Element => {
        return (
            <Button icon="edit" type="primary" onClick={() => this.handleShowPost()} className={styles.new}>
                {this.props.isXs || this.props.isSm ? (
                    ''
                ) : (
                    <FormattedMessage id={this.props.postType === 'QUESTION' ? 'questions.create' : 'posts.create'} />
                )}
            </Button>
        );
    };

    renderLoadMoreButton = (): JSX.Element => {
        if (this.state.last) {
            return <></>;
        } else {
            return (
                <div className={styles.load}>
                    <Button
                        type="primary"
                        onClick={this.handleLoadMore}
                        loading={this.state.actionStatus === 'loading'}
                    >
                        <FormattedMessage id="common.loadMore" />
                    </Button>
                </div>
            );
        }
    };

    renderListPanel = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="vertical"
                dataSource={this.state.posts}
                locale={{
                    emptyText: (
                        <FormattedMessage id={this.props.postType === 'QUESTION' ? 'questions.empty' : 'posts.empty'} />
                    ),
                }}
                loadMore={this.renderLoadMoreButton()}
                renderItem={post => (
                    <PostComponent
                        post={post}
                        condition={this.props.condition}
                        handleShowPostEdit={this.handleShowPost}
                        summary={true}
                    />
                )}
            />
        );
    };

    renderAds = (): JSX.Element => {
        if (this.props.condition) {
            return (
                <Alert
                    message={<FormattedMessage id="ads.communityManagers" />}
                    type="info"
                    showIcon
                    className={styles.ads}
                />
            );
        } else {
            return <></>;
        }
    };

    render() {
        return (
            <>
                {this.renderCreateButton()}
                <SignUpModal visible={this.state.signUpVisible} handleClose={this.handleHideSignUp} />
                {this.renderAds()}

                {this.renderListPanel()}
                <PostEditComponent
                    visible={this.state.postEditVisible}
                    handleClose={this.handleHidePost}
                    postType={this.props.postType}
                    postId={this.state.postEditId}
                    condition={this.props.condition}
                />
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(
    withRouter(injectIntl(Form.create<Props>()(PostsComponent))),
);

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    size?: number;
    postType?: PostType;
    condition?: MedicalConditionType;
}

interface State {
    posts: Post[];
    page: number;
    last: boolean;
    postEditVisible?: boolean;
    postEditId?: number;
    signUpVisible?: boolean;
    status?: Status;
    actionStatus?: ActionStatus | number;
}
