import React, { Component } from 'react';
import styles from './HomePricesPage.module.scss';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import { Row, Col, Icon, Button } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Plan, Status } from '../../../model/model';
import planApi from '../../../api/PlanApi';
import errorService from '../../../service/ErrorService';

class HomePricesPage extends Component<any, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            plans: [],
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const plans = await planApi.list();
        this.setState({ plans });
    };

    /** COMPONENTS **/

    render() {
        const standardPlan = this.state.plans.find(plan => plan.nickname === 'standard');
        const premiumPlan = this.state.plans.find(plan => plan.nickname === 'premium');

        return (
            <>
                <HeadMetadata titleKey="homePrices.meta.title" descriptionKey="homePrices.meta.description" />
                <div className={styles.plans}>
                    <div className={styles.header}>
                        <FormattedMessage id="homePrices.title" />
                    </div>
                    <Row gutter={[48, 48]} type="flex">
                        <Col xs={24} lg={8}>
                            <div className={`${styles.plan} ${styles.basic}`}>
                                <h2>
                                    <FormattedMessage id="homePrices.plans.basic.title" />
                                </h2>
                                <p className={styles.description}>
                                    <FormattedMessage id="homePrices.plans.basic.description" />
                                </p>
                                <h3>
                                    <FormattedHTMLMessage id="homePrices.plans.basic.price" />
                                </h3>
                                <Link to="/signup">
                                    <Button size="large" block>
                                        <FormattedMessage id="home.signUp" />
                                    </Button>
                                </Link>
                                <div className={styles.functionalities}>
                                    <h4>
                                        <FormattedMessage id="homePrices.plans.basic.functionalities" />
                                    </h4>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.1" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.2" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.3" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.4" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.5" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.6" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.7" />
                                    </p>
                                    <p>
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.basic.functionalities.8" />
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div className={`${styles.plan} ${styles.standard}`} data-test="standardPlan">
                                <h2 data-test="planName">
                                    <FormattedMessage id="homePrices.plans.standard.title" />
                                </h2>
                                <p className={styles.description}>
                                    <FormattedMessage id="homePrices.plans.standard.description" />
                                </p>
                                <h3 data-test="planPrice">
                                    <FormattedHTMLMessage
                                        id="homePrices.plans.standard.price"
                                        values={{
                                            amount:
                                                standardPlan && standardPlan.amount ? standardPlan.amount / 100 : '',
                                            currency:
                                                standardPlan && standardPlan.currency
                                                    ? standardPlan.currency.toUpperCase()
                                                    : '',
                                        }}
                                    />
                                </h3>
                                <Link to="/signup">
                                    <Button size="large" block>
                                        <FormattedMessage id="home.signUp" />
                                    </Button>
                                </Link>
                                <div className={styles.functionalities}>
                                    <h4>
                                        <FormattedMessage id="homePrices.plans.standard.functionalities" />
                                    </h4>
                                    <p data-test="planVideoconsultation">
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage id="homePrices.plans.standard.functionalities.1" />
                                    </p>
                                    <p data-test="planStorage">
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage
                                            id="homePrices.plans.standard.functionalities.2"
                                            values={{ maxStorage: standardPlan ? standardPlan.maxStorage : '' }}
                                        />
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            <div className={`${styles.plan} ${styles.premium}`} data-test="premiumPlan">
                                <h2 data-test="planName">
                                    <FormattedMessage id="homePrices.plans.premium.title" />
                                </h2>
                                <p className={styles.description}>
                                    <FormattedMessage id="homePrices.plans.premium.description" />
                                </p>
                                <h3 data-test="planPrice">
                                    <FormattedHTMLMessage
                                        id="homePrices.plans.premium.price"
                                        values={{
                                            amount: premiumPlan && premiumPlan.amount ? premiumPlan.amount / 100 : '',
                                            currency:
                                                premiumPlan && premiumPlan.currency
                                                    ? premiumPlan.currency.toUpperCase()
                                                    : '',
                                        }}
                                    />
                                </h3>
                                <Link to="/signup">
                                    <Button size="large" block>
                                        <FormattedMessage id="home.signUp" />
                                    </Button>
                                </Link>
                                <div className={styles.functionalities}>
                                    <h4>
                                        <FormattedMessage id="homePrices.plans.premium.functionalities" />
                                    </h4>
                                    <p data-test="planStorage">
                                        <Icon type="check-circle" theme="filled" />
                                        <FormattedMessage
                                            id="homePrices.plans.premium.functionalities.2"
                                            values={{ maxStorage: premiumPlan ? premiumPlan.maxStorage : '' }}
                                        />
                                    </p>
                                    <p className={styles.extra} data-test="planExtra1">
                                        <FormattedMessage id="homePrices.plans.premium.functionalities.extra.1" />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default HomePricesPage;

interface Props {}

interface State {
    plans: Plan[];
    status?: Status;
}
