import React, { Component } from 'react';
import styles from './BankDetailsPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { Status, UserToken, User, Seller, ActionStatus } from '../../model/model';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import { Row, Col, Button, message, Icon } from 'antd';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import uuid from 'uuid/v4';
import sellerApi from '../../api/SellerApi';
import logService from '../../service/LogService';
import userApi from '../../api/UserApi';
import Loader from '../Loader/Loader';

class BankDetailsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);

        if (params.get('error') !== null) {
            const error = params.get('error') === null ? undefined : (params.get('error') as string);
            const errorDescription =
                params.get('error_description') === null ? undefined : (params.get('error_description') as string);
            errorService.displayCustomMessage('bankDetails.add.error.creation', undefined, 15);
            logService.error(
                `bank details - error setting bank details - error: ${error}, errorDescription: ${errorDescription}`,
            );
        } else if (params.get('code') !== null) {
            const authorizationCode = params.get('code') === null ? undefined : (params.get('code') as string);
            const userToken: UserToken = { token: authorizationCode };
            await sellerApi.create(userToken);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 2);
        }

        // load bank details
        const user = await userApi.getCurrentUser();
        if (user.stripeSellerEnabled) {
            const seller = await sellerApi.get();
            this.setState({ seller });
        }
    };

    showBillingDashboard = async (): Promise<void> => {
        const stripeDashboardUrl = await sellerApi.getDashboardUrl();
        window.open(stripeDashboardUrl, '_blank');
    };

    /** HANDLERS **/

    handleShowBillingDashboard = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'loading' });
            await this.showBillingDashboard();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="bankDetails.add.title" />
                    </h1>
                </div>
            </div>
        );
    };

    renderAddBankDetails = (): JSX.Element => {
        const state = uuid();
        const user = this.context.user as User;

        const stripeUrl = new URL('https://connect.stripe.com/express/oauth/authorize');
        stripeUrl.searchParams.append('response_type', 'code');
        stripeUrl.searchParams.append('client_id', process.env.REACT_APP_STRIPE_CLIENT_ID as string);
        stripeUrl.searchParams.append('state', state);
        stripeUrl.searchParams.append('redirect_uri', window.location.href);
        stripeUrl.searchParams.append('stripe_user[email]', user.email as string);
        if (user.userType === 'MEDICAL_CENTER') {
            stripeUrl.searchParams.append('stripe_user[business_type]', 'company');
            stripeUrl.searchParams.append('stripe_user[business_name]', user.firstName as string);
        } else {
            stripeUrl.searchParams.append('stripe_user[business_type]', 'individual');
            stripeUrl.searchParams.append('stripe_user[first_name]', user.firstName as string);
            stripeUrl.searchParams.append('stripe_user[last_name]', user.lastName as string);
        }

        return (
            <>
                <p className={styles.description}>
                    <FormattedMessage id={'bankDetails.add.description'} />
                </p>
                <p className={styles.hint}>
                    <FormattedMessage id={'bankDetails.add.hint'} />
                </p>
                <p></p>
                <a href={stripeUrl.href}>
                    <Button type="primary" size="large" icon="bank">
                        <FormattedMessage id={'bankDetails.add.button'} />
                    </Button>
                </a>
            </>
        );
    };

    renderBankDetails = (): JSX.Element => {
        const seller = this.state.seller as Seller;
        return (
            <>
                <p className={styles.description} hidden={seller.restricted}>
                    <Icon type="check-circle" theme="filled" /> <FormattedMessage id={'bankDetails.success'} />
                </p>
                <p className={styles.warning} hidden={!seller.restricted}>
                    <Icon type="warning" theme="filled" /> <FormattedMessage id={'bankDetails.warning'} />
                </p>
                <p className={styles.hint}>
                    <FormattedMessage id={'bankDetails.hint'} />
                </p>
                <Button
                    type="primary"
                    size="large"
                    icon="bank"
                    onClick={this.handleShowBillingDashboard}
                    loading={this.state.actionStatus === 'loading'}
                >
                    <FormattedMessage id={'bankDetails.button'} />
                </Button>
            </>
        );
    };

    renderContent = (): JSX.Element => {
        if (this.state.status === 'loading') {
            return <Loader />;
        } else if (this.state.seller) {
            return this.renderBankDetails();
        } else {
            return this.renderAddBankDetails();
        }
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex">
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderContent()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default injectIntl(withRouter(BankDetailsPage));

interface Props extends RouteComponentProps, WrappedComponentProps {
    match: any;
}

interface State {
    seller?: Seller;
    status?: Status;
    actionStatus?: ActionStatus;
}
