import React, { Component, FormEvent } from 'react';
import styles from './LinkPage.module.scss';
import { Layout, Form, Button, message, Popconfirm, Checkbox, Row, Col, Input, Icon } from 'antd';
import { Status, ActionStatus, PersonLink, Person, PublicUser, User, ScreenSizeProps } from '../../../../model/model';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedHTMLMessage } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import CustomContext from '../../../../service/CustomContext';
import personLinkApi from '../../../../api/PersonLinkApi';
import nameService from '../../../../service/NameService';
import TextArea from 'antd/lib/input/TextArea';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import FormattedMessageComponent from '../../../Shared/FormattedMessageComponent';
import moment from 'moment';
import Loader from '../../../Loader/Loader';

class LinkPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            personLink: {},
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        let personLink: PersonLink;
        if (this.props.match.params.id && this.props.match.params.id === 'new') {
            personLink = {
                personId: this.props.person.id,
                profile: true,
                documents: false,
                diaryAppointments: false,
                diaryMedications: false,
                diaryRecords: false,
                biometrics: false,
            };
        } else {
            personLink = await personLinkApi.get(this.props.match.params.id as number);
        }
        this.setState({ personLink });
    };

    create = async (values: any): Promise<void> => {
        let personLink: PersonLink = Object.assign({}, this.state.personLink, {
            email: values.email,
            message: values.message,
            profile: values.profile,
            diaryRecords: values.diaryRecords,
            diaryAppointments: values.diaryAppointments,
            diaryMedications: values.diaryMedications,
            biometrics: values.biometrics,
            documents: values.documents,
        });
        personLink = await personLinkApi.create(personLink);
        this.setState({ personLink });
    };

    update = async (values: any): Promise<void> => {
        let personLink: PersonLink = Object.assign({}, this.state.personLink);
        const inviter = personLink.inviter as PublicUser;
        const user = this.context.user as User;
        if (inviter.id === user.entity!.id) {
            personLink.profile = values.profile;
            personLink.diaryRecords = values.diaryRecords;
            personLink.diaryAppointments = values.diaryAppointments;
            personLink.diaryMedications = values.diaryMedications;
            personLink.biometrics = values.biometrics;
            personLink.documents = values.documents;
        } else {
            personLink.linkedProfile = values.profile;
            personLink.linkedDiaryRecords = values.diaryRecords;
            personLink.linkedDiaryAppointments = values.diaryAppointments;
            personLink.linkedDiaryMedications = values.diaryMedications;
            personLink.linkedBiometrics = values.biometrics;
            personLink.linkedDocuments = values.documents;
        }
        personLink.email = undefined;
        personLink.sent = undefined;
        personLink.message = undefined;
        personLink.approver = undefined;
        personLink.inviter = undefined;
        personLink.inviterPerson = undefined;

        personLink = await personLinkApi.update(personLink);
        this.setState({ personLink });
    };

    delete = async (): Promise<void> => {
        await personLinkApi.delete(this.state.personLink);
        this.props.history.push(`/persons/${this.props.person.id}/links`);
    };

    validateEmail = (rule: any, value: any, callback: any): void => {
        const email = value as string;
        const userEmail = (this.context.user as User).email as string;
        if (email.trim().toLowerCase() === userEmail.trim().toLowerCase()) {
            callback(<FormattedMessage id="personLink.email.error.same" />);
        } else {
            callback();
        }
    };

    /** HANDLERS **/

    handleCreate = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.create(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'personLink.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleUpdate = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.update(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'personLink.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderCreationForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const personName = this.props.person.fullName;
        const message = this.props.intl.formatMessage(
            {
                id: 'personLink.message.placeholder',
            },
            { personName: personName },
        );
        const personLink = this.state.personLink;

        return (
            <Form onSubmit={this.handleCreate}>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessageComponent id="personLink.email" />}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="personLink.email.error.required" />,
                                    },
                                    {
                                        type: 'email',
                                        message: <FormattedMessage id="personLink.email.error.invalid" />,
                                    },
                                    { validator: this.validateEmail },
                                ],
                            })(
                                <Input
                                    prefix={<Icon type="user" className={styles.inputIcon} />}
                                    maxLength={100}
                                    size="large"
                                    type="email"
                                    data-test="email"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessageComponent id="personLink.message" />}>
                            {getFieldDecorator('message', {
                                initialValue: message,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="personLink.message.error.required" />,
                                    },
                                ],
                            })(<TextArea maxLength={500} rows={5} data-test="message" />)}
                        </Form.Item>
                    </Col>
                </Row>
                <p className={styles.label}>
                    <FormattedMessage id="personLink.question" />
                </p>
                <Row>
                    <Col xs={12} md={8}>
                        <Form.Item>
                            {getFieldDecorator('profile', {
                                valuePropName: 'checked',
                                initialValue: personLink.profile,
                            })(
                                <Checkbox data-test="profile">
                                    <FormattedMessage id="personLink.profile" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Item>
                            {getFieldDecorator('documents', {
                                valuePropName: 'checked',
                                initialValue: personLink.documents,
                            })(
                                <Checkbox data-test="documents">
                                    <FormattedMessage id="personLink.documents" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Item>
                            {getFieldDecorator('diaryAppointments', {
                                valuePropName: 'checked',
                                initialValue: personLink.diaryAppointments,
                            })(
                                <Checkbox data-test="diaryAppointments">
                                    <FormattedMessage id="personLink.diaryAppointments" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Item>
                            {getFieldDecorator('diaryMedications', {
                                valuePropName: 'checked',
                                initialValue: personLink.diaryMedications,
                            })(
                                <Checkbox data-test="diaryMedications">
                                    <FormattedMessage id="personLink.diaryMedications" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Item>
                            {getFieldDecorator('diaryRecords', {
                                valuePropName: 'checked',
                                initialValue: personLink.diaryRecords,
                            })(
                                <Checkbox data-test="diaryRecords">
                                    <FormattedMessage id="personLink.diaryRecords" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={8}>
                        <Form.Item>
                            {getFieldDecorator('biometrics', {
                                valuePropName: 'checked',
                                initialValue: personLink.biometrics,
                            })(
                                <Checkbox data-test="biometrics">
                                    <FormattedMessage id="personLink.biometrics" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 36]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block={this.props.isXs || this.props.isSm}
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessageComponent id="personLink.invite" />
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    renderPendingApproval = (): JSX.Element => {
        const sent = this.state.personLink.sent
            ? moment(this.state.personLink.sent).format('DD MMMM YYYY')
            : moment().format('DD MMMM YYYY');

        return (
            <div className={styles.invited} data-test="pendingApproval">
                <h2>
                    <Icon type="check-circle" /> <FormattedMessage id="personLink.invited.title" />
                </h2>
                <p data-test="email">
                    <FormattedHTMLMessage
                        id="personLink.invited.desc"
                        values={{ email: this.state.personLink.email, sent: sent }}
                    />
                </p>
                <Popconfirm
                    title={
                        <FormattedMessage
                            id="personLink.invited.confirm.delete"
                            values={{
                                user: this.state.personLink.email,
                            }}
                        />
                    }
                    onConfirm={this.handleDelete}
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                >
                    <Button
                        type="link"
                        className={styles.cancel}
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                        hidden={this.state.personLink.id === undefined}
                        data-test="delete"
                    >
                        <FormattedMessage id="personLink.invited.delete" />
                    </Button>
                </Popconfirm>
            </div>
        );
    };

    renderSharedInformation = (): JSX.Element => {
        const user = this.context.user as User;
        const entity = user.entity!;
        const personLink = this.state.personLink;
        const inviter = personLink.inviter as PublicUser;

        return (
            <Row gutter={[12, 12]}>
                <Col
                    xs={12}
                    md={6}
                    hidden={
                        (inviter.id !== entity.id && !personLink.profile) ||
                        (inviter.id === entity.id && !personLink.linkedProfile)
                    }
                >
                    <Icon type="user" />
                    <FormattedMessage id="personLink.profile" />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    hidden={
                        (inviter.id !== entity.id && !personLink.documents) ||
                        (inviter.id === entity.id && !personLink.linkedDocuments)
                    }
                >
                    <Icon type="history" />
                    <FormattedMessage id="personLink.documents" />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    hidden={
                        (inviter.id !== entity.id && !personLink.diaryAppointments) ||
                        (inviter.id === entity.id && !personLink.linkedDiaryAppointments)
                    }
                >
                    <Icon type="read" />
                    <FormattedMessage id="personLink.diaryAppointments" />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    hidden={
                        (inviter.id !== entity.id && !personLink.diaryMedications) ||
                        (inviter.id === entity.id && !personLink.linkedDiaryMedications)
                    }
                >
                    <Icon type="medicine-box" />
                    <FormattedMessage id="personLink.diaryMedications" />
                </Col>
                <Col
                    xs={12}
                    md={6}
                    hidden={
                        (inviter.id !== entity.id && !personLink.diaryRecords) ||
                        (inviter.id === entity.id && !personLink.linkedDiaryRecords)
                    }
                >
                    <Icon type="read" />
                    <FormattedMessage id="personLink.diaryRecords" />
                </Col>

                <Col
                    xs={12}
                    md={6}
                    hidden={
                        (inviter.id !== entity.id && !personLink.biometrics) ||
                        (inviter.id === entity.id && !personLink.linkedBiometrics)
                    }
                >
                    <Icon type="line-chart" />
                    <FormattedMessage id="personLink.biometrics" />
                </Col>
            </Row>
        );
    };

    renderUpdateForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const personLink = this.state.personLink;
        const userName = nameService.getCorrespondingUserName(personLink, this.context.user as User);
        const personName = this.props.person.fullName;
        const inviter = personLink.inviter as PublicUser;
        const user = this.context.user as User;

        return (
            <div className={styles.layout}>
                <p className={styles.intro} data-test="email">
                    <FormattedHTMLMessage id="personLink.shared" values={{ userName, personName }} />
                </p>
                <div className={styles.shared}>{this.renderSharedInformation()}</div>
                <p className={styles.question}>
                    <FormattedHTMLMessage id="personLink.question" values={{ userName, personName }} />
                </p>
                <Form onSubmit={this.handleUpdate}>
                    <Row>
                        <Col xs={12} md={8}>
                            <Form.Item>
                                {getFieldDecorator('profile', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        inviter.id === user.entity!.id ? personLink.profile : personLink.linkedProfile,
                                })(
                                    <Checkbox data-test="profile">
                                        <FormattedMessage id="personLink.profile" />
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item>
                                {getFieldDecorator('documents', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        inviter.id === user.entity!.id
                                            ? personLink.documents
                                            : personLink.linkedDocuments,
                                })(
                                    <Checkbox data-test="documents">
                                        <FormattedMessage id="personLink.documents" />
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={12} md={8}>
                            <Form.Item>
                                {getFieldDecorator('diaryAppointments', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        inviter.id === user.entity!.id
                                            ? personLink.diaryAppointments
                                            : personLink.linkedDiaryAppointments,
                                })(
                                    <Checkbox data-test="diaryAppointments">
                                        <FormattedMessage id="personLink.diaryAppointments" />
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item>
                                {getFieldDecorator('diaryMedications', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        inviter.id === user.entity!.id
                                            ? personLink.diaryMedications
                                            : personLink.linkedDiaryMedications,
                                })(
                                    <Checkbox data-test="diaryMedications">
                                        <FormattedMessage id="personLink.diaryMedications" />
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item>
                                {getFieldDecorator('diaryRecords', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        inviter.id === user.entity!.id
                                            ? personLink.diaryRecords
                                            : personLink.linkedDiaryRecords,
                                })(
                                    <Checkbox data-test="diaryRecords">
                                        <FormattedMessage id="personLink.diaryRecords" />
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Item>
                                {getFieldDecorator('biometrics', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        inviter.id === user.entity!.id
                                            ? personLink.biometrics
                                            : personLink.linkedBiometrics,
                                })(
                                    <Checkbox data-test="biometrics">
                                        <FormattedMessage id="personLink.biometrics" />
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[24, 0]} className={styles.buttons}>
                        <Col xs={12} md={8}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                block
                                loading={this.state.actionStatus === 'saving'}
                                disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                                data-test="submit"
                            >
                                <FormattedMessage id="common.save" />
                            </Button>
                        </Col>
                        <Col xs={12} md={16}>
                            <Popconfirm
                                title={
                                    <FormattedMessage
                                        id="personLink.confirm.delete"
                                        values={{
                                            person: this.props.person.fullName,
                                            user: this.state.personLink.email,
                                        }}
                                    />
                                }
                                onConfirm={this.handleDelete}
                                okText={<FormattedMessage id="common.ok" />}
                                cancelText={<FormattedMessage id="common.cancel" />}
                            >
                                <Button
                                    type="link"
                                    size="large"
                                    loading={this.state.actionStatus === 'deleting'}
                                    disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                    hidden={this.state.personLink.id === undefined}
                                    className={styles.delete}
                                    data-test="delete"
                                >
                                    <FormattedMessage id="personLink.delete" />
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    renderForm = (): JSX.Element => {
        if (this.state.status === 'loading') {
            return <Loader />;
        }
        if (this.state.personLink.id && this.state.personLink.linkedPersonId) {
            return this.renderUpdateForm();
        } else if (this.state.personLink.id) {
            return this.renderPendingApproval();
        } else {
            return this.renderCreationForm();
        }
    };

    render() {
        return <Layout.Content>{this.renderForm()}</Layout.Content>;
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(Form.create<Props>()(LinkPage))));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
    person: Person;
}

interface State {
    personLink: PersonLink;
    status?: Status;
    actionStatus?: ActionStatus;
}
