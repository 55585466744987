import { Moment } from 'moment';
import i18nService from './I18nService';

class AxisService {
    getXScaleMax = (start: Moment, end: Moment): Moment => {
        let xScaleMax: Moment = end;
        if (start && end) {
            if (end.diff(start, 'months') < 6) {
                xScaleMax = start.clone().add(6, 'months');
            } else if (end.diff(start, 'years') < 2) {
                xScaleMax = start.clone().add(2, 'years');
            } else if (end.diff(start, 'years') < 5) {
                xScaleMax = start.clone().add(5, 'years');
            } else if (end.diff(start, 'years') < 18) {
                xScaleMax = start.clone().add(18, 'years');
            } else {
                xScaleMax = end.clone();
            }
        }

        return xScaleMax;
    };

    getXAxisTicks(birthdate: Moment, start: Moment | undefined, end: Moment, full?: boolean): Array<Moment> {
        let xAxisTicks: Array<Moment> = [];

        if (birthdate && end) {
            if (end.diff(birthdate, 'months') < 6) {
                xAxisTicks.push(birthdate.clone());
                [4, 8].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'weeks')));
                [3, 4, 5, 6].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                if (full) {
                    [1, 2, 3, 5, 6, 7, 9, 10, 11, 12].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'weeks')));
                }
            } else if (end.diff(birthdate, 'years') < 2) {
                xAxisTicks.push(birthdate);
                [3, 6, 9].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                [1].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                [15, 18, 21].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                [2].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                if (full) {
                    [1, 2, 4, 5, 7, 8, 10, 11].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                    [13, 14, 16, 17, 19, 20, 22, 23].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                }
            } else if (end.diff(birthdate, 'years') < 5) {
                xAxisTicks.push(birthdate.clone());
                [1].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                [2].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                [3].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                [4].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                [5].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                if (full) {
                    [2, 4, 6, 8, 10].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                    [14, 16, 18, 20, 22].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                    [26, 28, 30, 32, 34].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                    [38, 40, 42, 44, 46].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                    [50, 52, 54, 56, 58].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'months')));
                }
            } else if (end.diff(birthdate, 'years') < 16) {
                xAxisTicks.push(birthdate.clone());
                [5, 10, 15, 18].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                if (full) {
                    [1, 2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17].forEach(i =>
                        xAxisTicks.push(birthdate.clone().add(i, 'years')),
                    );
                }
            } else if (end.diff(birthdate, 'years') < 18) {
                xAxisTicks.push(birthdate.clone());
                [5, 10, 15, 18, 20].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                if (full) {
                    [1, 2, 3, 4, 6, 7, 8, 9, 11, 12, 13, 14, 16, 17, 19].forEach(i =>
                        xAxisTicks.push(birthdate.clone().add(i, 'years')),
                    );
                }
            } else if (end.diff(birthdate, 'years') < 38) {
                xAxisTicks.push(birthdate.clone());
                [10, 20, 30, 40].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                if (full) {
                    [5, 15, 25, 35, 45].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                }
            } else {
                xAxisTicks.push(birthdate.clone());
                [20, 40, 60, 80].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                if (full) {
                    [10, 30, 50, 70, 90].forEach(i => xAxisTicks.push(birthdate.clone().add(i, 'years')));
                }
            }
        }
        xAxisTicks.sort((a, b) => a.valueOf() - b.valueOf());

        return xAxisTicks;
    }

    getXAxisTickLabel = (birthdate: Moment, start: Moment, end: Moment, tick: Moment, isEmpty: boolean): string => {
        let label: string = '';
        if (birthdate && end) {
            let years: number = tick.diff(birthdate, 'years');
            let months: number = tick.diff(birthdate, 'months');

            if (end.diff(birthdate, 'months') <= 6) {
                if (birthdate.isSame(tick)) {
                    label = '';
                } else if (months < 3) {
                    label = tick.diff(birthdate, 'weeks').toString();
                } else {
                    label =
                        tick.diff(birthdate, 'months') +
                        ' ' +
                        (months === 1
                            ? i18nService.intl.formatMessage({ id: 'time.month' })
                            : i18nService.intl.formatMessage({ id: 'time.months' }));
                }
            } else if (end.diff(birthdate, 'years') <= 2) {
                if (birthdate.isSame(tick)) {
                    label = '';
                } else if (months % 12 !== 0) {
                    label = String(tick.diff(birthdate, 'months') % 12);
                } else {
                    label =
                        tick.diff(birthdate, 'years') +
                        ' ' +
                        (years === 1
                            ? i18nService.intl.formatMessage({ id: 'time.year' })
                            : i18nService.intl.formatMessage({ id: 'time.years' }));
                }
            } else if (end.diff(birthdate, 'years') <= 5) {
                if (birthdate.isSame(tick)) {
                    label = '';
                } else if (months % 12 !== 0) {
                    label = String(tick.diff(birthdate, 'months') % 12);
                } else {
                    label =
                        tick.diff(birthdate, 'years') +
                        ' ' +
                        (years === 1
                            ? i18nService.intl.formatMessage({ id: 'time.year' })
                            : i18nService.intl.formatMessage({ id: 'time.years' }));
                }
            } else if (end.diff(birthdate, 'years') <= 18) {
                if (birthdate.isSame(tick)) {
                    label = '';
                } else if (years % 5 !== 0) {
                    label = String(tick.diff(birthdate, 'years'));
                } else {
                    label =
                        tick.diff(birthdate, 'years') +
                        ' ' +
                        (years === 1
                            ? i18nService.intl.formatMessage({ id: 'time.year' })
                            : i18nService.intl.formatMessage({ id: 'time.years' }));
                }
            } else if (end.diff(birthdate, 'years') <= 18) {
                if (birthdate.isSame(tick)) {
                    label = '';
                } else if (years % 10 !== 0) {
                    label = String(tick.diff(birthdate, 'years'));
                } else if (years % 10 === 0) {
                    label =
                        tick.diff(birthdate, 'years') +
                        ' ' +
                        (years === 1
                            ? i18nService.intl.formatMessage({ id: 'time.year' })
                            : i18nService.intl.formatMessage({ id: 'time.years' }));
                }
            } else {
                if (birthdate.isSame(tick)) {
                    label = '';
                } else if (years % 20 !== 0) {
                    label = String(tick.diff(birthdate, 'years'));
                } else if (years % 20 === 0) {
                    label =
                        tick.diff(birthdate, 'years') +
                        ' ' +
                        (years === 1
                            ? i18nService.intl.formatMessage({ id: 'time.year' })
                            : i18nService.intl.formatMessage({ id: 'time.years' }));
                }
            }
        }
        return label;
    };
}

const axisService: AxisService = new AxisService();
export default axisService;
