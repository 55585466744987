import { ExternalUser, UserMeasure, BmiMeasure, ExternalMeasure, ToolType } from '../model/model';
import externalMeasureApi from '../api/ExternalMeasureApi';
import logService from './LogService';
import { Moment } from 'moment';
import locationServiceService from './LocationService';

class ExternalMeasureService {
    saveExternalUserMeasure = async (
        tool: ToolType,
        chartType: string,
        userMeasure: UserMeasure | BmiMeasure,
        externalUser: ExternalUser,
        gender?: string,
        birthdate?: Moment,
    ): Promise<void> => {
        try {
            if (chartType === 'BMI') {
                const bmiMeasure: BmiMeasure = userMeasure as BmiMeasure;
                const heightMeasure: BmiMeasure = bmiMeasure.height as UserMeasure;
                const weightMeasure: BmiMeasure = bmiMeasure.weight as UserMeasure;
                this.saveSingleExternalUserMeasure(tool, 'HEIGHT', heightMeasure, externalUser, gender, birthdate);
                this.saveSingleExternalUserMeasure(tool, 'WEIGHT', weightMeasure, externalUser, gender, birthdate);
            } else {
                this.saveSingleExternalUserMeasure(tool, chartType, userMeasure, externalUser, gender, birthdate);
            }
        } catch (error) {
            logService.error('external measure service - error saving external measure');
        }
    };

    private saveSingleExternalUserMeasure = async (
        tool: ToolType,
        chartType: string,
        userMeasure: UserMeasure,
        externalUser: ExternalUser,
        gender?: string,
        birthdate?: Moment,
    ): Promise<void> => {
        try {
            const externalMeasure: ExternalMeasure = {
                tool,
                chartType,
                value: userMeasure.value,
                time: userMeasure.time,
                secondaryValue: userMeasure.secondaryValue,
                unit: userMeasure.unit,
                date: userMeasure.date,
                gender,
                birthdate,
            };
            externalMeasure.country = await locationServiceService.getCountry();
            externalMeasure.externalUser = externalUser;
            await externalMeasureApi.create(externalMeasure);
        } catch (error) {
            logService.error('external measure service - error saving external measure default');
        }
    };
}

const externalMeasureService = new ExternalMeasureService();
export default externalMeasureService;
