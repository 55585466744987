import React, { Component } from 'react';
import styles from './NotificationsPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { Notification, Status, ScreenSizeProps, PersonLink, PublicUser, User, UserLink } from '../../model/model';
import notificationApi from '../../api/NotificationApi';
import { List, Row, Col, Button, Empty } from 'antd';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import personLinkApi from '../../api/PersonLinkApi';
import { Link } from 'react-router-dom';
import nameService from '../../service/NameService';
import userLinkApi from '../../api/UserLinkApi';

class NotificationsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            notifications: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
    };

    list = async () => {
        const notifications: Notification[] = await notificationApi.list();
        for (const notification of notifications) {
            await this.loadNotificationTarget(notification);
        }

        this.setState({ notifications });

        // set notifications as read
        notificationApi.read();
    };

    loadNotificationTarget = async (notification: Notification) => {
        switch (notification.notificationType) {
            case 'PERSON_LINK_INVITED':
            case 'PERSON_LINK_APPROVED':
                notification.target = await personLinkApi.get(notification.targetId as number);
                break;
            case 'USER_LINK_INVITED':
            case 'USER_LINK_APPROVED':
                notification.target = await userLinkApi.get(notification.targetId as number);
                break;
            default:
                break;
        }
    };

    /** COMPONENTS */

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.notifications" />
                    </h1>
                    <p>
                        <FormattedMessage id="navigation.notifications.desc" />
                    </p>
                </div>
            </div>
        );
    };

    renderPersonLinkInvitedNotification = (notification: Notification): JSX.Element => {
        const personLink = notification.target as PersonLink;
        const inviter = personLink.inviter as PublicUser;

        return (
            <Link to={`/links/approvals/${personLink.id}`}>
                <List.Item data-test="item">
                    <List.Item.Meta
                        avatar={<AvatarComponent name={inviter.firstName} />}
                        title={
                            <FormattedMessage
                                id="notifications.personLink.invited"
                                values={{ user: inviter.fullName }}
                            />
                        }
                        description={personLink.sent && personLink.sent.format('DD MMMM YYYY')}
                    />
                    <div className={styles.description}>
                        <Button type="primary" ghost icon="eye">
                            {!this.props.isXs && !this.props.isSm && (
                                <FormattedMessage id="notifications.personLink.invited.button" />
                            )}
                        </Button>
                    </div>
                </List.Item>
            </Link>
        );
    };

    renderPersonLinkApprovedNotification = (notification: Notification): JSX.Element => {
        const personLink = notification.target as PersonLink;
        const correspondingUser = nameService.getCorrespondingUserName(personLink, this.context.user as User);

        return (
            <Link to={`/persons/${personLink.personId}/links/${personLink.id}`}>
                <List.Item data-test="item">
                    <List.Item.Meta
                        avatar={<AvatarComponent name={correspondingUser} />}
                        title={
                            <FormattedMessage
                                id="notifications.personLink.approved"
                                values={{ user: correspondingUser }}
                            />
                        }
                        description={personLink.sent && personLink.sent.format('DD MMMM YYYY')}
                    />
                    <div className={styles.description}>
                        <Button type="primary" ghost icon="eye">
                            {!this.props.isXs && !this.props.isSm && (
                                <FormattedMessage id="notifications.personLink.approved.button" />
                            )}
                        </Button>
                    </div>
                </List.Item>
            </Link>
        );
    };

    renderUserLinkInvitedNotification = (notification: Notification): JSX.Element => {
        const userLink = notification.target as PersonLink;
        const inviter = userLink.inviter as PublicUser;

        return (
            <Link to={`/employees/approvals/${userLink.id}`}>
                <List.Item data-test="item">
                    <List.Item.Meta
                        avatar={<AvatarComponent name={inviter.firstName} />}
                        title={
                            <FormattedMessage id="notifications.userLink.invited" values={{ user: inviter.fullName }} />
                        }
                        description={userLink.sent && userLink.sent.format('DD MMMM YYYY')}
                    />
                    <div className={styles.description}>
                        <Button type="primary" ghost icon="eye">
                            {!this.props.isXs && !this.props.isSm && (
                                <FormattedMessage id="notifications.userLink.invited.button" />
                            )}
                        </Button>
                    </div>
                </List.Item>
            </Link>
        );
    };

    renderUserLinkApprovedNotification = (notification: Notification): JSX.Element => {
        const userLink = notification.target as UserLink;
        let user = userLink.email;
        if (userLink.approver) {
            user = userLink.approver.fullName;
        }

        return (
            <Link to={`/employees/${userLink.id}`}>
                <List.Item data-test="item">
                    <List.Item.Meta
                        avatar={<AvatarComponent name={user} />}
                        title={<FormattedMessage id="notifications.userLink.approved" values={{ user }} />}
                        description={userLink.sent && userLink.sent.format('DD MMMM YYYY')}
                    />
                    <div className={styles.description}>
                        <Button type="primary" ghost icon="eye">
                            {!this.props.isXs && !this.props.isSm && (
                                <FormattedMessage id="notifications.userLink.approved.button" />
                            )}
                        </Button>
                    </div>
                </List.Item>
            </Link>
        );
    };

    renderNotification = (notification: Notification): JSX.Element => {
        switch (notification.notificationType) {
            case 'PERSON_LINK_INVITED':
                return this.renderPersonLinkInvitedNotification(notification);
            case 'PERSON_LINK_APPROVED':
                return this.renderPersonLinkApprovedNotification(notification);
            case 'USER_LINK_INVITED':
                return this.renderUserLinkInvitedNotification(notification);
            case 'USER_LINK_APPROVED':
                return this.renderUserLinkApprovedNotification(notification);
            default:
                return <></>;
        }
    };

    renderNotificationsList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.notifications}
                locale={{
                    emptyText: (
                        <Empty
                            className="empty"
                            description={
                                <div data-test="empty">
                                    <FormattedMessage id="notifications.empty" />
                                </div>
                            }
                        />
                    ),
                }}
                renderItem={notification => this.renderNotification(notification)}
                data-test="list"
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex">
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderNotificationsList()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(NotificationsPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {}

interface State {
    notifications: Notification[];
    status?: Status;
}
