import { LocalePath } from './paths';

const paths: LocalePath[] = [
    {
        key: 'coronavirus-test',
        path: 'coronavirus',
    },
];
paths.forEach(path => (path.locale = 'it'));
export default paths;
