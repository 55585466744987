import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Invoice } from '../model/model';
import moment from 'moment';

class InvoiceApi {
    list = async (): Promise<Invoice[]> => {
        const response: AxiosResponse<Invoice[]> = await apiAxios.get<Invoice[]>('/invoices');
        response.data.forEach(i => {
            i.periodStart = moment(i.periodStart);
            i.periodEnd = moment(i.periodEnd);
        });
        return response.data;
    };
}

const invoiceApi: InvoiceApi = new InvoiceApi();
export default invoiceApi;
