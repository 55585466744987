import React, { Component } from 'react';
import styles from './HomeDoctorsPage.module.scss';
import doctors from '../images/doctors.png';
import imc from '../images/forms-1.png';
import charts1 from '../images/charts-1.png';
import charts3 from '../images/charts-3.png';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import CustomContext from '../../../service/CustomContext';
import { Link } from 'react-router-dom';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

class HomeDoctorsPage extends Component {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    componentDidMount() {}

    /** COMPONENTS **/

    renderRightPanel = (index: number, image: string): JSX.Element => {
        return (
            <Row className={`${styles.section} ${styles.right}`} type="flex" justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image}>
                    <img src={image} alt={image} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className={styles.message}>
                        <h3>
                            <FormattedMessage id={`homeDoctors.${index}.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`homeDoctors.${index}.subtitle`} />
                        </p>
                    </div>
                </Col>
            </Row>
        );
    };

    renderLeftPanel = (index: number, image: string): JSX.Element => {
        return (
            <Row className={`${styles.section} ${styles.left}`} type="flex" justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className={styles.message}>
                        <h3>
                            <FormattedMessage id={`homeDoctors.${index}.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`homeDoctors.${index}.subtitle`} />
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image}>
                    <img src={image} alt={image} />
                </Col>
            </Row>
        );
    };

    renderFullPanel = (index: number, image?: string): JSX.Element => {
        return (
            <Row
                className={`${styles.section} ${styles.full} ${image ? '' : styles.text}`}
                type="flex"
                justify="center"
                align="middle"
            >
                <Col xs={24}>
                    <div className={styles.message}>
                        <h3>
                            <FormattedMessage id={`homeDoctors.${index}.title`} />
                        </h3>
                        <p>
                            <FormattedMessage id={`homeDoctors.${index}.subtitle`} />
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image} hidden={!image}>
                    <img src={image} alt={image} />
                </Col>
            </Row>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="homeDoctors.meta.title" descriptionKey="homeDoctors.meta.description" />
                <div>
                    <div className={styles.top}>
                        <div className={styles.back}></div>
                        <div className={styles.headline}>
                            <h1>
                                <FormattedMessage id="homeDoctors.title" />
                            </h1>
                            <div className={styles.buttons}>
                                <Link to="/signup">
                                    <Button type="primary" className={styles.button}>
                                        {this.context.user ? (
                                            <FormattedMessage id="home.dashboard" />
                                        ) : (
                                            <FormattedMessage id="home.signUp" />
                                        )}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {this.renderRightPanel(5, charts3)}
                    {this.renderFullPanel(1, doctors)}
                    {this.renderLeftPanel(2, charts1)}
                    {this.renderFullPanel(4)}
                    {this.renderRightPanel(3, imc)}
                </div>
            </>
        );
    }
}

export default HomeDoctorsPage;
