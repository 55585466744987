import React, { Component } from 'react';
import styles from './CommunitiesPage.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../service/ErrorService';
import { Row, Col, List, Icon } from 'antd';
import ga from '../Helper/GoogleAnalytics/GoogleAnalytics';
import { Status, MedicalConditionType, User } from '../../model/model';
import settingsService from '../../service/SettingsService';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import { Link } from 'react-router-dom';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import CustomContext from '../../service/CustomContext';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import pathService from '../../service/PathService';

class CommunitiesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            availableConditions: [],
        };
    }

    async componentDidMount() {
        try {
            ga.createDefaultEvent('communities', 'communities - init');
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.listConditions();
    };

    listConditions = async (): Promise<void> => {
        const availableConditions: MedicalConditionType[] = await this.listAvailableConditions();
        this.setState({ availableConditions });
    };

    listUserConditions = (): MedicalConditionType[] => {
        let userConditions: MedicalConditionType[] = [];
        if (this.context.user) {
            const user: User = this.context.user as User;
            userConditions = settingsService.settings.medicalConditionTypes.filter(
                mc => user.conditions && user.conditions.includes(mc.value),
            );
            userConditions = userConditions.sort((a, b) => a.label.localeCompare(b.label));
        }
        return userConditions;
    };

    listAvailableConditions = async (): Promise<MedicalConditionType[]> => {
        let availableConditions: MedicalConditionType[] = settingsService.settings.medicalConditionTypes;
        availableConditions = availableConditions.sort((a, b) => a.label.localeCompare(b.label));

        return availableConditions;
    };

    /** HANDLERS **/

    /** COMPONENTS **/

    renderConditionAction = (condition: MedicalConditionType): JSX.Element => {
        if (
            this.context.user &&
            this.context.user.conditions &&
            this.context.user.conditions.includes(condition.value)
        ) {
            return <Icon type="heart" theme="filled" />;
        } else {
            return <></>;
        }
    };

    renderConditions = (): JSX.Element => {
        let conditions: MedicalConditionType[] = this.listUserConditions();
        conditions = conditions.concat(this.state.availableConditions.filter(ac => !conditions.includes(ac)));

        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={conditions}
                locale={{ emptyText: <FormattedMessage id="communities.empty" /> }}
                pagination={{ pageSize: 20, hideOnSinglePage: true }}
                className={styles.list}
                renderItem={condition => {
                    return (
                        <Link to={`${pathService.getPath('posts')}/${condition.path}`}>
                            <List.Item className={styles.item} actions={[this.renderConditionAction(condition)]}>
                                <List.Item.Meta
                                    avatar={<AvatarComponent name={condition.value} />}
                                    title={<span className={styles.condition}>{condition.label}</span>}
                                />
                            </List.Item>
                        </Link>
                    );
                }}
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="communities.meta.title" descriptionKey="communities.meta.description" />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]}>
                        <Col xs={24} xl={19}>
                            <div className="panel">
                                <div className="panel-header">
                                    <div>
                                        <h1>
                                            <FormattedMessage id="communities.title" />
                                        </h1>
                                    </div>
                                </div>
                                {this.renderConditions()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default injectIntl(CommunitiesPage);

interface Props extends WrappedComponentProps {}

interface State {
    availableConditions: MedicalConditionType[];
    status?: Status;
}
