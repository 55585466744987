import { Address } from '../model/model';

class AddressService {
    getSimpleAddress = (address?: Address, phone?: string): string => {
        let simpleAddress = '';
        if (address && address.address) {
            simpleAddress = address.address;
            if (address.city) {
                simpleAddress = simpleAddress + ', ' + address.city;
            }
            if (address.state) {
                simpleAddress = simpleAddress + ' (' + address.state + ')';
            }
            if (phone) {
                simpleAddress = simpleAddress + ' - ' + phone;
            }
        }
        return simpleAddress;
    };
}

const addressService: AddressService = new AddressService();
export default addressService;
