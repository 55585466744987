import React, { Component } from 'react';
import styles from './SubscriptionEnd.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Moment } from 'moment';
import { Subscription } from '../../../../model/model';
import dateService from '../../../../service/DateService';
import CustomContext from '../../../../service/CustomContext';

class SubscriptionEnd extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /** METHODS **/

    isSubscriptionActive = () => this.props.subscription && !this.props.subscription.cancelAt;

    /** COMPONENTS **/

    render() {
        if (this.isSubscriptionActive()) {
            const subscription: Subscription = this.props.subscription;
            const currentPeriodEnd: Moment = subscription.currentPeriodEnd as Moment;
            const format: string = dateService.getDateFormat(this.context.user);
            const currentPeriodEndDate: string = currentPeriodEnd.format(format);

            return (
                <p className={styles.info}>
                    <FormattedMessage id="subscription.renewal" values={{ date: currentPeriodEndDate }} />
                </p>
            );
        } else {
            return <></>;
        }
    }
}
export default injectIntl(SubscriptionEnd);

interface Props extends WrappedComponentProps {
    subscription: Subscription;
}
