import React, { Component, FormEvent } from 'react';
import styles from './PasswordChangePage.module.scss';
import { Form, Input, Icon, Button, Result } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import userApi from '../../../api/UserApi';
import { UserTokenPassword, ActionStatus } from '../../../model/model';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../../service/ErrorService';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';

class PasswordChangePage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userTokenPassword: {},
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const token: string | undefined = params.get('token') === null ? undefined : (params.get('token') as string);
        const userTokenPassword: UserTokenPassword = Object.assign({}, this.state.userTokenPassword, {
            token,
        });
        this.setState({ userTokenPassword });
    };

    changePassword = async (values: any) => {
        const user: UserTokenPassword = Object.assign({}, this.state.userTokenPassword, {
            password: values.password,
        });

        await userApi.changePassword(user);
        this.setState({ status: 'success' });
    };

    validateConfirmPassword = (rule: any, value: any, callback: any): void => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback(<FormattedMessage id="passwordChange.confirmPassword.error.notEquals" />);
        } else {
            callback();
        }
    };

    /** HANDLERS **/

    handleChangePassword = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error, values) => {
            try {
                this.setState({ actionStatus: 'saving' });
                !error && (await this.changePassword(values));
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    /** COMPONENTS **/

    renderPasswordChangeForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.layout}>
                <h1>
                    <FormattedMessage id="passwordChange.title" />
                </h1>
                <Form onSubmit={this.handleChangePassword}>
                    <Form.Item
                        label={<FormattedMessage id="passwordChange.password" />}
                        extra={<FormattedMessage id="passwordChange.password.extra" />}
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: <FormattedMessage id="passwordChange.password.error.required" />,
                                },
                                {
                                    pattern: new RegExp('^(?=.*[0-9])(?=.*[^0-9]).{8,20}$'),
                                    message: <FormattedMessage id="passwordChange.password.error.invalid" />,
                                },
                            ],
                        })(
                            <Input.Password
                                prefix={<Icon type="lock" className={styles.inputIcon} />}
                                type="password"
                                maxLength={20}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="passwordChange.confirmPassword" />}>
                        {getFieldDecorator('confirmPassword', {
                            rules: [
                                {
                                    required: true,
                                    message: <FormattedMessage id="passwordChange.confirmPassword.error.required" />,
                                },
                                { validator: this.validateConfirmPassword },
                            ],
                        })(
                            <Input.Password
                                prefix={<Icon type="lock" className={styles.inputIcon} />}
                                type="password"
                                maxLength={20}
                            />,
                        )}
                    </Form.Item>
                    <div className={styles.buttons}>
                        <Button type="primary" htmlType="submit" loading={this.state.actionStatus === 'saving'}>
                            <FormattedMessage id="passwordChange.resetPassword" />
                        </Button>
                        <Link to="/signin">
                            <Button type="link">
                                <FormattedMessage id="common.back" />
                            </Button>
                        </Link>
                    </div>
                </Form>
            </div>
        );
    };

    renderPasswordChangeSuccess = (): JSX.Element => {
        return (
            <Result
                status="success"
                title={<FormattedMessage id="passwordChange.success.title" />}
                subTitle={<FormattedMessage id="passwordChange.success.subtitle" />}
                extra={[
                    <Link to="/signin" key="signin">
                        <Button type="primary" size="large">
                            <FormattedMessage id="passwordChange.success.button" />
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    renderPasswordChange = (): JSX.Element => {
        return this.state.status ? this.renderPasswordChangeSuccess() : this.renderPasswordChangeForm();
    };

    render() {
        return (
            <>
                <HeadMetadata />
                {this.renderPasswordChange()}
            </>
        );
    }
}
export default injectIntl(Form.create()(withRouter(PasswordChangePage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps {}

interface State {
    userTokenPassword: UserTokenPassword;
    status?: 'success';
    actionStatus?: ActionStatus;
}
