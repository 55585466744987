import React, { Component } from 'react';
import styles from './MapPage.module.scss';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import { FormattedMessage } from 'react-intl';
import pathService from '../../../service/PathService';

export default class MapPage extends Component {
    render() {
        return (
            <>
                <HeadMetadata titleKey="footer.map" />
                <div className={styles.layout}>
                    <h1>
                        <FormattedMessage id="map.title" />
                    </h1>
                    <div>
                        <h3>English</h3>
                        <a href="/growth-chart">Growth Charts</a>
                        <a href="/communities">Communities</a>
                        <a href="/tools">Tools</a>
                    </div>
                    <div>
                        <h3>Español</h3>
                        <a href="/es">Inicio</a>
                        <a href={`/es${pathService.getPath('communities', 'es')}`}>Comunidades</a>
                        <a href={`/es${pathService.getPath('tools', 'es')}`}>Herramientas</a>
                    </div>
                    {/* <div>
                        <h3>Polski</h3>
                        <a href="/pl">Home</a>
                        <a href={`/pl${pathService.getPath('communities', 'pl')}`} hidden={true}>
                            Społeczności
                        </a>
                        <a href={`/pl${pathService.getPath('tools', 'pl')}`}>Narzędzia</a>
                    </div> */}
                </div>
            </>
        );
    }
}
