import React, { Component, FormEvent } from 'react';
import styles from './PostEditComponent.module.scss';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { ScreenSizeProps, ActionStatus, Post, User, MedicalConditionType, PostType } from '../../../../model/model';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CustomContext from '../../../../service/CustomContext';
import errorService from '../../../../service/ErrorService';
import postApi from '../../../../api/PostApi';
import { message, Button, Drawer, Form, Avatar, Upload, Icon, Checkbox, Popconfirm, Select, Input } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { RcFile } from 'antd/lib/upload';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import medicalConditionService from '../../../../service/MedicalConditionService';

class PostEditComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly maxFileSize: number = 2;

    constructor(props: Props) {
        super(props);
        this.state = {
            post: {
                status: 'PENDING',
            },
            medicalConditionTypes: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        await this.init(this.props.postId);
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.postId !== prevProps.postId) {
            await this.init(this.props.postId);
        }
    }

    /** METHODS **/

    init = async (postId?: number): Promise<void> => {
        const user: User = this.context.user as User;
        let post: Post;
        const medicalConditionTypes: MedicalConditionType[] = medicalConditionService.search(
            this.context.settings.medicalConditionTypes,
        );
        if (postId) {
            post = await postApi.get(postId);
            this.props.form.setFieldsValue({
                title: post.title,
                message: post.message,
                commentsAllowed: post.commentsAllowed,
                status: post.status,
                conditions: post.conditions,
                language: post.language,
                files: [],
            });
        } else {
            post = {
                entityId: user.entity!.id,
                shared: 'ANYONE',
                commentsAllowed: true,
                conditions: this.props.condition ? [this.props.condition.value] : [],
                language: this.context.locale.toUpperCase(),
                postType: this.props.postType || 'POST',
                status: 'PENDING',
            };
            this.props.form.setFieldsValue({
                title: post.title,
                message: post.message,
                commentsAllowed: post.commentsAllowed,
                conditions: post.conditions,
                status: post.status,
                language: post.language,
                files: [],
            });
        }
        this.setState({ post, medicalConditionTypes });
    };

    save = async (values: any, file?: RcFile): Promise<Post> => {
        let post: Post;
        if (this.state.post.id) {
            post = Object.assign({}, this.state.post, {
                title: values.title,
                message: values.message,
                commentsAllowed: values.commentsAllowed,
                conditions: values.conditions,
                status: values.status,
                language: values.language,
                entityName: undefined,
                fileName: undefined,
                fileSize: undefined,
                fileContentType: undefined,
                postDate: undefined,
            });
            post = await postApi.update(post);
        } else {
            post = Object.assign({}, this.state.post, {
                title: values.title,
                message: values.message,
                commentsAllowed: values.commentsAllowed,
                conditions: values.conditions,
                status: values.status,
                language: values.language,
            });
            post = await postApi.create(post, file);
        }

        return post;
    };

    delete = async (): Promise<void> => {
        await postApi.delete(this.state.post);
    };

    changeFile = (uploadFiles: UploadFile[]): UploadFile[] => {
        let uploadFile: UploadFile | undefined;
        if (uploadFiles && uploadFiles.length > 0) {
            uploadFile = uploadFiles.length > 1 ? uploadFiles[1] : uploadFiles[0];
        }

        const files: UploadFile[] = [];
        if (uploadFile && uploadFile.size > this.maxFileSize * 1024 * 1024) {
            errorService.displayCustomMessage('post.file.error.size', { size: this.maxFileSize });
        } else if (uploadFile && !uploadFile.type.startsWith('image/')) {
            errorService.displayCustomMessage('post.file.error.contentType');
        } else if (uploadFile) {
            files.push(uploadFile);
        }

        return files;
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const file: RcFile | undefined =
                        values.files && values.files.length > 0 ? values.files[0].originFileObj : undefined;
                    await this.save(values, file);
                    await message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);

                    this.init();
                    this.props.handleClose(true);
                }
            } catch (error) {
                errorService.displayMessage(error, [[413, 'post.file.error.size', { size: this.maxFileSize }]]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            await message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);

            this.init();
            this.props.handleClose(true);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleClose = () => {
        this.init();
        this.props.handleClose();
    };

    handleFileChange = (event: UploadChangeParam): any => {
        return this.changeFile(event.fileList);
    };

    handleFileBeforeUpload = (uploadFile: RcFile, uploadFiles: RcFile[]): boolean | PromiseLike<void> => {
        return false;
    };

    handleConditionsSearch = (searchText: string): void => {
        const medicalConditionTypes: MedicalConditionType[] = medicalConditionService.search(
            this.context.settings.medicalConditionTypes,
            searchText,
        );
        this.setState({ medicalConditionTypes });
    };

    /** COMPONENTS **/

    renderPostFileUpload = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        if (this.state.post.id || this.props.postType === 'QUESTION') {
            return <></>;
        } else {
            return (
                <>
                    <Form.Item
                        label={<FormattedMessage id="post.file" />}
                        extra={<FormattedMessage id="post.file.extra" values={{ size: this.maxFileSize }} />}
                    >
                        {getFieldDecorator('files', {
                            valuePropName: 'fileList',
                            getValueFromEvent: this.handleFileChange,
                        })(
                            <Upload
                                listType="picture-card"
                                showUploadList={{
                                    showRemoveIcon: true,
                                    showPreviewIcon: false,
                                    showDownloadIcon: true,
                                }}
                                accept="image/*"
                                beforeUpload={this.handleFileBeforeUpload}
                            >
                                <Button>
                                    <Icon type="upload" /> <FormattedMessage id="post.file.upload" />
                                </Button>
                            </Upload>,
                        )}
                    </Form.Item>
                </>
            );
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const user: User = this.context.user as User;
        const width: string = this.props.isLg ? '720' : '100vw';
        const titlePlaceholder = this.props.intl.formatMessage({
            id: this.props.postType === 'QUESTION' ? 'question.title.placeholder' : 'post.title.placeholder',
        });
        const medicalConditionOptions = this.state.medicalConditionTypes.map(medicalConditionType => (
            <Select.Option value={medicalConditionType.value} key={medicalConditionType.value}>
                {medicalConditionType.label}
            </Select.Option>
        ));
        const postStatusOptions = this.context.settings.postStatusTypes.map(statusType => (
            <Select.Option value={statusType.value} key={statusType.value}>
                {statusType.label}
            </Select.Option>
        ));
        const postLanguageOptions = this.context.settings.languageTypes.map(languageType => (
            <Select.Option value={languageType.value} key={languageType.value}>
                {languageType.label}
            </Select.Option>
        ));

        return (
            <Drawer
                title={
                    <FormattedMessage
                        id={this.props.postType === 'QUESTION' ? 'question.edit.title' : 'post.edit.title'}
                    />
                }
                placement="right"
                width={width}
                onClose={this.handleClose}
                visible={this.props.visible}
                className="root-drawer"
                bodyStyle={{ paddingBottom: 80 }}
            >
                <p className={styles.user}>
                    <Avatar icon="user" /> <span className={styles.name}>{user && user.username}</span>
                </p>

                <Form>
                    <Form.Item
                        label={
                            <FormattedMessage
                                id={this.props.postType === 'QUESTION' ? 'question.title' : 'post.title'}
                            />
                        }
                    >
                        {getFieldDecorator('title', {
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id={
                                                this.props.postType === 'QUESTION'
                                                    ? 'question.title.error.required'
                                                    : 'post.title.error.required'
                                            }
                                        />
                                    ),
                                },
                            ],
                        })(<Input maxLength={70} placeholder={titlePlaceholder} />)}
                    </Form.Item>

                    <Form.Item
                        label={
                            <FormattedMessage
                                id={this.props.postType === 'QUESTION' ? 'question.message' : 'post.message'}
                            />
                        }
                    >
                        {getFieldDecorator('message', {
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id={
                                                this.props.postType === 'QUESTION'
                                                    ? 'question.message.error.required'
                                                    : 'post.message.error.required'
                                            }
                                        />
                                    ),
                                },
                            ],
                        })(<TextArea maxLength={1300} rows={6} />)}
                    </Form.Item>

                    <Form.Item label={<FormattedMessage id="post.conditions" />}>
                        {getFieldDecorator('conditions')(
                            <Select mode="multiple" onSearch={this.handleConditionsSearch} filterOption={false}>
                                {medicalConditionOptions}
                            </Select>,
                        )}
                    </Form.Item>

                    {this.renderPostFileUpload()}

                    <div hidden={!user || !user.admin}>
                        <Form.Item>
                            {getFieldDecorator('commentsAllowed', {
                                valuePropName: 'checked',
                                initialValue: true,
                            })(
                                <Checkbox>
                                    <FormattedMessage id="post.commentsAllowed" />
                                </Checkbox>,
                            )}
                        </Form.Item>
                    </div>

                    <div hidden={!user || !user.admin}>
                        <Form.Item label={<FormattedMessage id="post.status" />}>
                            {getFieldDecorator('status', {
                                rules: [
                                    { required: true, message: <FormattedMessage id="post.status.error.required" /> },
                                ],
                            })(<Select>{postStatusOptions}</Select>)}
                        </Form.Item>

                        <Form.Item label={<FormattedMessage id="post.language" />}>
                            {getFieldDecorator('language', {
                                rules: [
                                    { required: true, message: <FormattedMessage id="post.language.error.required" /> },
                                ],
                            })(<Select>{postLanguageOptions}</Select>)}
                        </Form.Item>
                    </div>
                </Form>

                <div className={styles.buttons}>
                    <Popconfirm
                        title={
                            <FormattedMessage
                                id={
                                    this.props.postType === 'QUESTION'
                                        ? 'question.confirm.delete'
                                        : 'post.confirm.delete'
                                }
                            />
                        }
                        onConfirm={this.handleDelete}
                        okText={<FormattedMessage id="common.ok" />}
                        cancelText={<FormattedMessage id="common.cancel" />}
                    >
                        <Button
                            type="link"
                            loading={this.state.actionStatus === 'deleting'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                            className={styles.delete}
                            hidden={!this.state.post.id || this.state.post.entityId !== user.entity!.id}
                        >
                            <FormattedMessage id="common.delete" />
                        </Button>
                    </Popconfirm>
                    <Button onClick={this.handleClose}>
                        <FormattedMessage id="common.cancel" />
                    </Button>
                    <Button
                        type="primary"
                        onClick={this.handleSave}
                        loading={this.state.actionStatus === 'saving'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                    >
                        <FormattedMessage id="common.post" />
                    </Button>
                </div>
            </Drawer>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(
    withRouter(injectIntl(Form.create<Props>()(PostEditComponent))),
);

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    postType?: PostType;
    postId?: number;
    condition?: MedicalConditionType;
    visible: boolean;
    handleClose: (refresh?: boolean) => void;
}

interface State {
    post: Post;
    medicalConditionTypes: MedicalConditionType[];
    actionStatus?: ActionStatus;
}
