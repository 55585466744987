import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import authService from '../service/AuthService';
import eventService from '../service/EventService';

const axiosOAuthRequestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    return {
        ...config,
        headers: {
            Authorization: authService.getAccessTokenFromCookie()
                ? `Bearer ${authService.getAccessTokenFromCookie()}`
                : null,
            ...config.headers,
        },
    };
};

// retry configuration for failed http requests
const retryConfig: IAxiosRetryConfig = {
    retries: 4,
    retryCondition: (error: AxiosError): boolean => {
        let retry: boolean = false;
        if (
            error &&
            error.config &&
            error.response &&
            error.response.status === 401 &&
            authService.getAccessTokenFromCookie()
        ) {
            const retryCount = (error as any).config['axios-retry'].retryCount;
            if (retryCount === 0) {
                authService.initOauthImplicitFlowSilentRefresh();
                eventService.resume();
                retry = true;
            } else if (retryCount < 4) {
                error.config.headers.Authorization = `Bearer ${authService.getAccessTokenFromCookie()}`;
                retry = true;
            }
        }
        return retry;
    },
    retryDelay: (retryNumber = 0): number => {
        return 500 * retryNumber;
    },
};

// api (includes request retry)
const apiAxiosConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
};
const apiAxios: AxiosInstance = axios.create(apiAxiosConfig);
axiosRetry(apiAxios, retryConfig);
apiAxios.interceptors.request.use(axiosOAuthRequestInterceptor);
export default apiAxios;

// api without authentication
export const apiNoAuthAxios: AxiosInstance = axios.create(apiAxiosConfig);

// auth
const authAxiosConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_AUTH_URL,
    withCredentials: true,
};
export const authAxios: AxiosInstance = axios.create(authAxiosConfig);
