import * as React from 'react';
import { useEffect } from 'react';
import styles from './GoogleAdsComponent.module.scss';

/**
 * This component displays a formatted Google advertisment after a fixed period of time.
 */
const GoogleAdsComponent: React.FC = () => {
    useEffect(() => {
        const init = () => {
            ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
        };
        init();
    }, []);

    return (
        <div className={styles.container}>
            <ins
                className="adsbygoogle"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-6413020711552386"
                data-ad-slot="6843795975"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};
export default GoogleAdsComponent;
