import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Customer, UserToken, Seller } from '../model/model';

class SellerApi {
    create = async (userToken: UserToken): Promise<Customer> => {
        const response: AxiosResponse<Customer> = await apiAxios.post<Customer>('/sellers', userToken);
        return response.data;
    };

    get = async (): Promise<Seller> => {
        const response: AxiosResponse<Seller> = await apiAxios.get<Seller>('/sellers/current');
        return response.data;
    };

    getDashboardUrl = async (): Promise<string> => {
        const response: AxiosResponse<string> = await apiAxios.get<string>(`/sellers/dashboard/url`);
        return response.data;
    };
}

const sellerApi: SellerApi = new SellerApi();
export default sellerApi;
