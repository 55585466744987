import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import bmiService from '../../service/BmiService';
import { BmiMeasure, Measure } from '../../model/model';
import unitService from '../../service/UnitService';

export const HealthyWeightComponent: React.FC<Props> = props => {
    const heightMeasure = props.bmiMeasure.height as Measure;
    const weightMeasure = props.bmiMeasure.weight as Measure;
    const weightUnit = unitService.getUnitTypeByUserMeasure(weightMeasure);
    const weightSecondaryUnit = unitService.getSecondaryUnitTypeByUnitType(weightUnit);
    const minHealthyWeight = bmiService.calculateBmiNormalMinWeight(heightMeasure, weightUnit, props.minBmi);
    const maxHealthyWeight = bmiService.calculateBmiNormalMaxWeight(heightMeasure, weightUnit, props.maxBmi);

    if (weightSecondaryUnit && (minHealthyWeight.secondaryValue || maxHealthyWeight.secondaryValue)) {
        return (
            <FormattedMessage
                id="healthyWeight.composed"
                values={{
                    minWeight: (
                        <FormattedNumber
                            value={minHealthyWeight.value as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                        />
                    ),
                    maxWeight: (
                        <FormattedNumber
                            value={maxHealthyWeight.value as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                        />
                    ),
                    unit: weightUnit.label,
                    minSecondaryWeight: (
                        <FormattedNumber
                            value={minHealthyWeight.secondaryValue as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                        />
                    ),
                    maxSecondaryWeight: (
                        <FormattedNumber
                            value={maxHealthyWeight.secondaryValue as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                        />
                    ),
                    secondaryUnit: weightSecondaryUnit.label,
                }}
            />
        );
    } else {
        return (
            <FormattedMessage
                id="healthyWeight.simple"
                values={{
                    minWeight: (
                        <FormattedNumber
                            value={minHealthyWeight.value as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                        />
                    ),
                    maxWeight: (
                        <FormattedNumber
                            value={maxHealthyWeight.value as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                        />
                    ),
                    unit: weightUnit.label,
                }}
            />
        );
    }
};

interface Props {
    bmiMeasure: BmiMeasure;
    minBmi?: number;
    maxBmi?: number;
}
