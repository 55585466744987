import React, { Component } from 'react';
import styles from './TermsOfUsePage.module.scss';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import { Link } from 'react-router-dom';
import pathService from '../../../service/PathService';

export default class TermsOfUsePage extends Component {
    render() {
        return (
            <>
                <HeadMetadata titleKey="footer.termsOfUse" />
                <div className={styles.layout}>
                    <FormattedHTMLMessage id="termsOfUse" />
                </div>
                <Link to={pathService.getPath('credits')}>
                    <FormattedMessage id="footer.credits" />
                </Link>
            </>
        );
    }
}
