import React, { Component, FormEvent } from 'react';
import styles from './BmiCalculatorPage.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedNumber } from 'react-intl';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { ScreenSizeProps, Status, ActionStatus, UserMeasure, BmiMeasure, Person } from '../../../model/model';
import errorService from '../../../service/ErrorService';
import { Row, Col, Form, Button, Descriptions, Switch, DatePicker, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import CustomContext from '../../../service/CustomContext';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import MeasureValueField from '../../PersonPage/BiometricsPage/MeasureValueField/MeasureValueField';
import unitService from '../../../service/UnitService';
import settingsService from '../../../service/SettingsService';
import bmiService from '../../../service/BmiService';
import pathService from '../../../service/PathService';
import { IdealWeightComponent } from '../../Shared/IdealWeightComponent';
import { HealthyWeightComponent } from '../../Shared/HealthyWeightComponent';
import externalUserService from '../../../service/ExternalUserService';
import externalMeasureService from '../../../service/ExternalMeasureService';
import moment from 'moment';
import { BmiLevelComponent } from '../../Shared/BmiLevelComponent';
import dateService from '../../../service/DateService';
import CampaignComponent from '../CampaignComponent/CampaignComponent';
import GoogleAdsComponent from '../../Shared/GoogleAdsComponent/GoogleAdsComponent';

class BmiCalculatorPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            measurementSystem: settingsService.settings.measurementSystemTypes[0].value,
            person: {},
            bmiMeasure: {
                height: {},
                weight: {},
            },
        };
    }

    componentDidMount() {
        try {
            ga.createDefaultEvent('bmi calculator', 'bmi calculator - init');
            this.init(this.state.measurementSystem);
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    /** METHODS **/

    init = (measurementSystem: string) => {
        const person: Person = Object.assign({}, this.state.person, {
            gender: settingsService.settings.genderTypes[0].value,
            birthdate: moment()
                .startOf('day')
                .subtract(18, 'years'),
        });
        const date = moment().startOf('day');
        const bmiMeasure: BmiMeasure = {
            date,
            unit: unitService.getUnitTypeByChartType('BMI', measurementSystem).value,
            height: { unit: unitService.getUnitTypeByChartType('HEIGHT', measurementSystem).value, date },
            weight: { unit: unitService.getUnitTypeByChartType('WEIGHT', measurementSystem).value, date },
        };

        this.props.form.setFieldsValue({
            gender: person.gender,
            birthdate: person.birthdate,
        });

        this.setState({ person, bmiMeasure });
    };

    getBmiMeasure = (values: any): BmiMeasure => {
        const bmiMeasure = Object.assign({}, this.state.bmiMeasure);
        (bmiMeasure.height as UserMeasure).value = values.heightValue;
        (bmiMeasure.height as UserMeasure).secondaryValue = values.heightSecondaryValue;
        (bmiMeasure.weight as UserMeasure).value = values.weightValue;
        (bmiMeasure.weight as UserMeasure).secondaryValue = values.weightSecondaryValue;

        return bmiMeasure;
    };

    calculate = (values: any): void => {
        const person = Object.assign({}, this.state.person);
        person.gender = values.gender;
        person.birthdate = values.birthdate;
        const bmiMeasure = this.getBmiMeasure(values);
        bmiMeasure.value = bmiService.calculateBmi(
            bmiMeasure.height as UserMeasure,
            bmiMeasure.weight as UserMeasure,
        ).value;
        this.setState({ person, bmiMeasure });

        this.save(bmiMeasure);
    };

    save = (bmiMeasure: BmiMeasure) => {
        externalUserService.setExternalAction('BA');
        const externalUser = externalUserService.createExternalUser(true);
        externalMeasureService.saveExternalUserMeasure(
            'BA',
            'BMI',
            bmiMeasure,
            externalUser,
            this.state.person.gender,
            this.state.person.birthdate,
        );
    };

    changeMeasurementSystem = (checked: boolean): void => {
        const measurementSystemIndex = checked ? 1 : 0;
        const measurementSystem = settingsService.settings.measurementSystemTypes[measurementSystemIndex].value;

        this.props.form.resetFields();
        this.init(measurementSystem);
        this.setState({ measurementSystem });
    };

    /** HANDLERS **/

    handleCalculate = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    ga.createDefaultEvent('bmi calculator', 'bmi calculator - save');
                    this.calculate(values);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleChangeMeasurementSystem = (checked: boolean): void => {
        try {
            ga.createDefaultEvent('bmi calculator', 'bmi calculator - change measurement system');
            this.changeMeasurementSystem(checked);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleSave = () => {
        try {
            ga.createDefaultEvent('bmi calculator', 'bmi calculator - save');
            this.save(this.state.bmiMeasure);
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('bmi calculator', 'bmi calculator - save - error');
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="bmiCalculator.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="bmiCalculator.child" />{' '}
                        <Link to={pathService.getPath('bmi-calculator-children')}>
                            <FormattedMessage id="bmiCalculator.child.link" />
                        </Link>
                    </p>
                </div>
            </div>
        );
    };

    renderGenderField = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const genderOptions = this.context.settings.genderTypes.map(genderType => (
            <Select.Option value={genderType.value} key={genderType.value}>
                {genderType.label}
            </Select.Option>
        ));

        return (
            <Form.Item>
                {getFieldDecorator('gender', {
                    rules: [
                        {
                            required: true,
                            message: <FormattedMessage id="bmiCalculatorChildren.gender.error.required" />,
                        },
                    ],
                })(<Select size="large">{genderOptions}</Select>)}
            </Form.Item>
        );
    };

    renderBirthdateField = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const format: string = dateService.getDateFormat();
        const placeholder: string = this.props.intl.formatMessage({ id: 'bmiCalculatorChildren.birthdate' });
        return (
            <Form.Item>
                {getFieldDecorator('birthdate', {
                    rules: [
                        {
                            type: 'object',
                            required: true,
                            message: <FormattedMessage id="bmiCalculatorChildren.birthdate.error.required" />,
                        },
                    ],
                })(
                    <DatePicker
                        format={format}
                        disabledDate={dateService.isFutureDate}
                        allowClear={false}
                        size="large"
                        placeholder={placeholder}
                        onFocus={(e: any) => (e.target.readOnly = true)}
                    />,
                )}
            </Form.Item>
        );
    };

    renderForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const heightUnit = unitService.getUnitTypeByChartType('HEIGHT', this.state.measurementSystem);
        const heightSecondaryUnit = unitService.getSecondaryUnitTypeByUnitType(heightUnit);
        const weightUnit = unitService.getUnitTypeByChartType('WEIGHT', this.state.measurementSystem);
        const weightSecondaryUnit = unitService.getSecondaryUnitTypeByUnitType(weightUnit);

        const fieldSizeXs = heightSecondaryUnit ? 24 : 12;

        return (
            <div className={styles.layout}>
                <Form onSubmit={this.handleCalculate} className={styles.form}>
                    <Row gutter={[24, 0]} align="top" type="flex">
                        <Col xs={12} sm={10}>
                            {this.renderGenderField()}
                        </Col>
                        <Col xs={12} sm={10}>
                            {this.renderBirthdateField()}
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]} align="middle" type="flex">
                        <Col xs={fieldSizeXs} sm={10}>
                            <MeasureValueField
                                form={this.props.form}
                                unit={heightUnit}
                                secondaryUnit={heightSecondaryUnit}
                                fieldName="heightValue"
                                secondaryFieldName="heightSecondaryValue"
                                label={<FormattedMessage id="bmiCalculator.height" />}
                                required={true}
                                size="large"
                                metadata={false}
                            />
                        </Col>
                        <Col xs={fieldSizeXs} sm={10}>
                            <MeasureValueField
                                form={this.props.form}
                                unit={weightUnit}
                                secondaryUnit={weightSecondaryUnit}
                                fieldName="weightValue"
                                secondaryFieldName="weightSecondaryValue"
                                label={<FormattedMessage id="bmiCalculator.weight" />}
                                required={true}
                                size="large"
                                metadata={false}
                            />
                        </Col>

                        <Col xs={24} sm={0} className={styles.buttons}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                block
                                loading={this.state.actionStatus === 'saving'}
                            >
                                <FormattedMessage id="common.calculate" />
                            </Button>
                        </Col>
                        <Col xs={0} sm={4} className={styles.button}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                loading={this.state.actionStatus === 'saving'}
                            >
                                <FormattedMessage id="common.calculate" />
                            </Button>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                {getFieldDecorator('measurementSystem', { valuePropName: 'checked' })(
                                    <Switch
                                        unCheckedChildren={<FormattedMessage id="measurementSystem.metric" />}
                                        checkedChildren={<FormattedMessage id="measurementSystem.english" />}
                                        onChange={this.handleChangeMeasurementSystem}
                                        className={styles.units}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    renderResult = (): JSX.Element => {
        if (this.state.bmiMeasure.value) {
            const bmiMeasure = this.state.bmiMeasure as BmiMeasure;
            const bmi = bmiMeasure.value as number;
            const bmiLevelStyles = bmiService.getBmiLevel(bmi) === 'normal' ? styles.normal : undefined;

            return (
                <Descriptions className={styles.result} bordered layout="vertical" column={1}>
                    <Descriptions.Item label={<FormattedMessage id="bmiCalculator.bmi" />}>
                        <p className={styles.bmi}>
                            <span className={bmiLevelStyles}>
                                <FormattedNumber
                                    value={this.state.bmiMeasure.value as number}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                />
                            </span>
                        </p>
                        <p className={styles.metadata}>
                            <IdealWeightComponent bmiMeasure={this.state.bmiMeasure} />{' '}
                            <HealthyWeightComponent bmiMeasure={this.state.bmiMeasure} />
                        </p>
                        <p className={styles.description}>
                            <BmiLevelComponent bmi={bmi} />
                        </p>
                    </Descriptions.Item>
                </Descriptions>
            );
        } else {
            return <></>;
        }
    };

    renderCampaign = (): JSX.Element => {
        const handleSave = !this.context.user && this.state.bmiMeasure.value ? this.handleSave : undefined;
        return <CampaignComponent tool="bmiCalculator" handleSave={handleSave} />;
    };

    renderInfo = (): JSX.Element => {
        return (
            <div className={styles.info}>
                <h3>
                    <FormattedMessage id="bmiCalculator.info.title" />
                </h3>
                <p>
                    <FormattedMessage id="bmiCalculator.info.desc" />
                </p>
                <ul>
                    <li>
                        <FormattedMessage id="bmiCalculator.info.1" />
                    </li>
                    <li>
                        <FormattedMessage id="bmiCalculator.info.2" />
                    </li>
                    <li>
                        <FormattedMessage id="bmiCalculator.info.3" />
                    </li>
                    <li>
                        <FormattedMessage id="bmiCalculator.info.4" />
                    </li>
                </ul>
                <p>
                    <FormattedMessage id="tools.measurementSystems" />
                </p>
                <p>
                    <FormattedMessage id="bmiCalculator.info.5" />
                </p>
            </div>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="bmiCalculator.meta.title" descriptionKey="bmiCalculator.meta.description" />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]}>
                        <Col xs={24} xl={19} className={styles.calculator}>
                            <div className="panel">
                                {this.renderHeader()}
                                {this.renderForm()}
                                {this.renderResult()}
                                {this.renderCampaign()}
                                <GoogleAdsComponent />
                                {this.renderInfo()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(
    injectIntl(withRouter(Form.create<Props>()(BmiCalculatorPage))),
);

interface Props extends WrappedComponentProps, RouteComponentProps, FormComponentProps, ScreenSizeProps {}

interface State {
    measurementSystem: string;
    person: Person;
    bmiMeasure: BmiMeasure;
    status?: Status;
    actionStatus?: ActionStatus;
}
