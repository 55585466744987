import React, { Component, FormEvent } from 'react';
import styles from './HomeDemoComponent.module.scss';
import { Row, Col, Icon, Result, Button, Checkbox } from 'antd';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import Search from 'antd/lib/input/Search';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import logService from '../../../service/LogService';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Link } from 'react-router-dom';
import pathService from '../../../service/PathService';

class HomeDemoComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    requestDemo = async (values: any): Promise<void> => {
        const email = values.email;
        logService.notification(`request demo - request - email: ${values.email}`);
        this.setState({ email });
    };

    /** HANDLERS **/

    handleRequestDemo = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        ga.createDefaultEvent('request demo', 'request demo - request');
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    await this.requestDemo(values);
                }
            } catch (error) {
                ga.createDefaultEvent('request demo', 'request demo - request - error');
            }
        });
    };

    /** COMPONENTS **/

    render() {
        const theme = this.props.theme === 'dark' ? styles.dark : '';
        if (this.state.email) {
            return (
                <Row
                    className={`${styles.section} ${styles.demo} ${theme}`}
                    type="flex"
                    justify="center"
                    align="middle"
                >
                    <Col xs={24}>
                        <div className={`${styles.message} ${styles.success}`}>
                            <Result
                                icon={<img src="/images/contact.png" alt="contact" />}
                                title={
                                    <p>
                                        <FormattedMessage id="home.demo.success" />
                                    </p>
                                }
                            />
                        </div>
                    </Col>
                </Row>
            );
        } else {
            const { getFieldDecorator } = this.props.form;

            return (
                <Row
                    className={`${styles.section} ${styles.demo} ${theme}`}
                    type="flex"
                    justify="center"
                    align="middle"
                >
                    <Col xs={24}>
                        <div className={styles.message}>
                            <h3>
                                <FormattedMessage id={`home.demo.title`} />
                            </h3>
                            <p>
                                <FormattedMessage id="home.demo.label" />
                            </p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.form}>
                        <Form id="demoForm" onSubmit={this.handleRequestDemo}>
                            <Form.Item>
                                {getFieldDecorator('email', {
                                    rules: [
                                        {
                                            required: true,
                                            message: <FormattedMessage id="signUp.email.error.required" />,
                                        },
                                        {
                                            type: 'email',
                                            message: <FormattedMessage id="signUp.email.error.invalid" />,
                                        },
                                    ],
                                    validateTrigger: 'onSubmit',
                                })(
                                    <Search
                                        prefix={<Icon type="user" className={styles.inputIcon} />}
                                        placeholder={this.props.intl.formatMessage({
                                            id: 'home.demo.placeholder',
                                        })}
                                        enterButton={
                                            <Button type="primary" form="demoForm" htmlType="submit">
                                                <FormattedMessage id="home.demo.button" />
                                            </Button>
                                        }
                                        maxLength={100}
                                        size="large"
                                        type="email"
                                        className={styles.email}
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item className={styles.field}>
                                {getFieldDecorator('legal', {
                                    valuePropName: 'checked',
                                    initialValue: false,
                                    rules: [
                                        {
                                            required: true,
                                            message: <FormattedMessage id="signUp.consent.error.required" />,
                                            transform: value => value || undefined,
                                            type: 'boolean',
                                        },
                                    ],
                                    validateTrigger: 'onSubmit',
                                })(
                                    <Checkbox className={styles.legal} data-test="legal">
                                        <span className={styles.label}>
                                            <FormattedMessage id="signUp.consent" />{' '}
                                            <Link to={pathService.getPath('legal-notice')}>
                                                <FormattedMessage id="signUp.legalNotice" />
                                            </Link>{' '}
                                            <FormattedMessage id="common.and" />{' '}
                                            <Link to={pathService.getPath('privacy-policy')}>
                                                <FormattedMessage id="signUp.privacyPolicy" />
                                            </Link>
                                        </span>
                                    </Checkbox>,
                                )}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            );
        }
    }
}
export default injectIntl(Form.create<Props>()(HomeDemoComponent));

interface Props extends WrappedComponentProps, FormComponentProps {
    theme?: 'default' | 'dark';
}

interface State {
    email?: string;
}
