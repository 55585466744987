import React, { Component } from 'react';
import styles from './SubscriptionActivate.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Moment } from 'moment';
import { Subscription, ActionStatus } from '../../../../model/model';
import errorService from '../../../../service/ErrorService';
import subscriptionApi from '../../../../api/SubscriptionApi';
import dateService from '../../../../service/DateService';
import { Button, message } from 'antd';

class SubscriptionActivate extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    activate = async (): Promise<Subscription> => {
        const subscription: Subscription = await subscriptionApi.activate(this.props.subscription);
        return subscription;
    };

    isSubscriptionCancelled = () => this.props.subscription && this.props.subscription.cancelAt;

    /** HANDLERS **/

    handleActivate = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'saving' });
            const subscription: Subscription = await this.activate();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.activated' }), 4);

            this.props.handleActivation(subscription);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    render() {
        if (this.isSubscriptionCancelled()) {
            const subscription: Subscription = this.props.subscription as Subscription;
            const cancelAt: Moment = subscription.cancelAt as Moment;
            const format: string = dateService.getDateFormat(this.context.user);
            const cancelAtDate: string = cancelAt.format(format);

            return (
                <>
                    <p className={styles.warning}>
                        <FormattedMessage id="subscription.activate.title" values={{ date: cancelAtDate }} />
                    </p>
                    <p>
                        <FormattedMessage id="subscription.activate.subtitle" />
                    </p>
                    <Button
                        type="primary"
                        size="large"
                        onClick={this.handleActivate}
                        loading={this.state.actionStatus === 'saving'}
                    >
                        <FormattedMessage id="subscription.activate.button" />
                    </Button>
                </>
            );
        } else {
            return <></>;
        }
    }
}
export default injectIntl(SubscriptionActivate);

interface Props extends WrappedComponentProps {
    subscription: Subscription;
    handleActivation: (subscription: Subscription) => void;
}

interface State {
    actionStatus?: ActionStatus;
}
