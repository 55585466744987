import React, { Component } from 'react';
import styles from './CommunityPage.module.scss';
import { Row, Col } from 'antd';
import { MedicalConditionType, CommunityMenuType } from '../../model/model';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import medicalConditionService from '../../service/MedicalConditionService';
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom';
import CommunityHeaderComponent from './CommunityHeaderComponent/CommunityHeaderComponent';
import CommunityMenuComponent from './CommunityMenuComponent/CommunityMenuComponent';
import CommunityPostsPage from './CommunityPostsPage/CommunityPostsPage';
import CommunityQuestionsPage from './CommunityQuestionsPage/CommunityQuestionsPage';
import CommunityMembersPage from './CommunityMembersPage/CommunityMembersPage';
import CommunityPostPage from './CommunityPostsPage/CommunityPostPage/CommunityPostPage';
import pathService from '../../service/PathService';

class CommunityPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.match.params.condition !== prevProps.match.params.condition ||
            this.props.match.params.id !== prevProps.match.params.id
        ) {
            this.init();
        }
    }

    /** METHODS **/

    init = (): void => {
        const condition: MedicalConditionType | undefined = medicalConditionService.getMedicalConditionByPath(
            this.props.match.params.condition,
        );
        const id: number | undefined = this.props.match.params.id;
        this.setState({ condition, id });
    };

    /** COMPONENTS **/

    renderRoutes = (): JSX.Element => {
        const condition: MedicalConditionType = this.state.condition as MedicalConditionType;
        return (
            <Switch key={`${condition.value}-${this.props.selected}`}>
                <Route
                    exact
                    path={`${pathService.getPath('posts')}/:condition`}
                    render={() => <CommunityPostsPage condition={condition} />}
                />
                <Route
                    exact
                    path={`${pathService.getPath('posts')}/:condition/:id`}
                    render={() => <CommunityPostPage condition={condition} />}
                />
                <Route
                    path={pathService.getPath('questions')}
                    render={() => <CommunityQuestionsPage condition={condition} />}
                />
                <Route
                    path={pathService.getPath('members')}
                    render={() => <CommunityMembersPage condition={condition} userType="PATIENT" />}
                />
                <Route
                    path={pathService.getPath('doctors')}
                    render={() => <CommunityMembersPage condition={condition} userType="HEALTH_PROFESSIONAL" />}
                />
                <Route
                    path={pathService.getPath('researchers')}
                    render={() => <CommunityMembersPage condition={condition} userType="RESEARCHER" />}
                />
            </Switch>
        );
    };

    render() {
        if (this.state.condition) {
            const condition: MedicalConditionType = this.state.condition;
            return (
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19}>
                            <div className="panel">
                                <CommunityHeaderComponent condition={condition} />
                                <CommunityMenuComponent condition={condition} selected={this.props.selected} />
                                {this.renderRoutes()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return <></>;
        }
    }
}
export default withRouter(CommunityPage);

interface Props extends RouteComponentProps {
    match: any;
    selected: CommunityMenuType;
}

interface State {
    condition?: MedicalConditionType;
    id?: number;
}
