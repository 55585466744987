import React, { Component, FormEvent } from 'react';
import styles from './CoronavirusPage.module.scss';
import './CoronavirusPage.css';
import { FormattedMessage, FormattedHTMLMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
    Status,
    ActionStatus,
    CoronavirusTest,
    CoronavirusTestProperty,
    ScreenSizeProps,
    CoronavirusTestStatus,
    CoronavirusContact,
    Country,
} from '../../../model/model';
import errorService from '../../../service/ErrorService';
import { Row, Col, Button, Icon, Result, Modal, Descriptions, Radio, InputNumber, Select } from 'antd';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import coronavirusPhones from '../../../model/coronavirus-phones.json';
import i18nService from '../../../service/I18nService';
import coronavirusTestApi from '../../../api/CoronavirusTestApi';
import locationServiceService from '../../../service/LocationService';
import logService from '../../../service/LogService';
import Form, { FormComponentProps } from 'antd/lib/form';
import settingsService from '../../../service/SettingsService';
import externalUserService from '../../../service/ExternalUserService';

class CoronavirusPage extends Component<Props, State> {
    readonly steps: number = 21;
    readonly mainSymptoms: CoronavirusTestProperty[] = ['temperature', 'cough', 'tasteLoss', 'smellLoss'];
    readonly secondarySymptoms: CoronavirusTestProperty[] = [
        'tired',
        'throat',
        'headache',
        'muscular',
        'diarrhea',
        'hearthProblems',
        'vascularProblems',
        'respiratoryProblems',
        'autoimmuneConditions',
        'fluVaccination',
        'pneumococcusVaccination',
        'conjunctivitis',
    ];
    readonly exposure: CoronavirusTestProperty[] = ['contact', 'relatives', 'environment'];

    constructor(props: Props) {
        super(props);
        this.state = {
            coronavirusTest: {},
            step: 0,
        };
    }

    componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            ga.createDefaultEvent('coronavirus test', 'coronavirus test - init');
            this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = (): void => {
        let country: Country | undefined = i18nService.getCountry(i18nService.intl.locale);
        if (!country) {
            country = i18nService.getCountry('gb');
        }

        this.setState({ country });
    };

    save = async (coronavirusTest: CoronavirusTest): Promise<void> => {
        try {
            ga.createDefaultEvent('coronavirus test', 'coronavirus test - save');
            coronavirusTest.country = await locationServiceService.getCountry();
            coronavirusTest.externalUser = externalUserService.createExternalUser();
            await coronavirusTestApi.create(coronavirusTest);
        } catch (error) {
            logService.error('coronavirus test - error saving coronavirus test');
        }
    };

    getResult = (): CoronavirusTestStatus => {
        let result: CoronavirusTestStatus;
        const coronavirusTest: CoronavirusTest = this.state.coronavirusTest;
        const mainSymptoms: CoronavirusTestProperty[] = this.mainSymptoms.filter(
            symptom => coronavirusTest[symptom] === true,
        );
        const secondarySymptoms: CoronavirusTestProperty[] = this.secondarySymptoms.filter(
            symptom => coronavirusTest[symptom] === true,
        );
        const exposure: CoronavirusTestProperty[] = this.exposure.filter(symptom => coronavirusTest[symptom] === true);

        if (coronavirusTest.dyspnoea) {
            result = 'high';
        } else if (mainSymptoms.length > 1 && secondarySymptoms.length > 0) {
            result = 'high';
        } else if (mainSymptoms.length > 1) {
            result = 'medium';
        } else if (mainSymptoms.length > 0 && secondarySymptoms.length > 1) {
            result = 'medium';
        } else if (exposure.length > 0 && (mainSymptoms.length > 0 || secondarySymptoms.length > 1)) {
            result = 'medium';
        } else if (exposure.length > 0 || mainSymptoms.length > 0 || secondarySymptoms.length > 1) {
            result = 'low';
        } else {
            result = 'none';
        }

        return result;
    };

    /** HANDLERS **/

    handleNext = () => {
        const step: number = this.state.step + 1;
        this.setState({ step });
    };

    handlePrevious = () => {
        if (this.state.step > 0) {
            const step: number = this.state.step - 1;
            this.setState({ step });
        }
    };

    handleAnswer = (property: CoronavirusTestProperty, answer: boolean) => {
        this.setState({ stepStatus: answer ? 'yes' : 'no' });

        setTimeout(() => {
            const currentPropertyStatus: boolean | undefined = this.state.coronavirusTest[property];
            const coronavirusTest: CoronavirusTest = Object.assign({}, this.state.coronavirusTest, {
                [property]: answer,
            });
            this.setState({ coronavirusTest, stepStatus: undefined });
            if (currentPropertyStatus === undefined) {
                this.handleNext();
            }
        }, 500);
    };

    handleSavePerson = (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    const coronavirusTest: CoronavirusTest = Object.assign({}, this.state.coronavirusTest, {
                        gender: values.gender,
                        age: values.age,
                    });
                    this.save(coronavirusTest);

                    this.setState({ coronavirusTest });
                    this.handleNext();
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleReset = () => {
        const step: number = 0;
        const coronavirusTest: CoronavirusTest = {};
        this.props.form.resetFields();
        this.setState({ step, coronavirusTest });
    };

    handleShowModal = () => {
        ga.createDefaultEvent('coronavirus test', 'coronavirus test - show contact numbers');
        this.setState({ modalVisible: true });
    };

    handleHideModal = () => {
        this.setState({ modalVisible: false });
    };

    handleChangeContactCountry = (countryCode: string) => {
        const country: Country | undefined = i18nService.getCountry(countryCode);
        this.setState({ country });
    };

    /** COMPONENTS **/

    renderIntro = (step: number): JSX.Element => {
        if (this.state.step === step) {
            return (
                <div className={styles.intro}>
                    <h2>
                        <FormattedMessage id="coronavirus.intro" />
                    </h2>
                    <p>
                        <FormattedMessage id="coronavirus.description" /> <FormattedMessage id="coronavirus.remark" />
                    </p>
                    <Button
                        type={'primary'}
                        size="large"
                        block={this.props.isXs || this.props.isSm}
                        onClick={this.handleNext}
                    >
                        <FormattedMessage id="coronavirus.start" />
                    </Button>
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderQuestion = (field: CoronavirusTestProperty, step: number): JSX.Element => {
        const value: boolean | undefined = this.state.coronavirusTest[field];
        if (this.state.step === step) {
            return (
                <div className={styles.question}>
                    <label>
                        <FormattedMessage id={`coronavirus.${field}`} />
                    </label>
                    <div className={styles.answer}>
                        <Button
                            type={value === false ? 'default' : 'primary'}
                            size="large"
                            icon={value === true || this.state.stepStatus === 'yes' ? 'check' : undefined}
                            ghost={value === undefined && this.state.stepStatus !== 'yes'}
                            onClick={() => this.handleAnswer(field, true)}
                        >
                            <FormattedMessage id="common.yes" />
                        </Button>
                        <Button
                            type={value === true ? 'default' : 'danger'}
                            size="large"
                            icon={value === false || this.state.stepStatus === 'no' ? 'check' : undefined}
                            ghost={value === undefined && this.state.stepStatus !== 'no'}
                            onClick={() => this.handleAnswer(field, false)}
                        >
                            <FormattedMessage id="common.no" />
                        </Button>
                    </div>

                    <div className={styles.navigation}>
                        <Button
                            onClick={this.handlePrevious}
                            type="link"
                            icon="arrow-left"
                            size="large"
                            hidden={this.state.step < 1}
                        />
                        <span className={styles.counter}>
                            {step} / {this.steps}
                        </span>
                        <Button
                            onClick={this.handleNext}
                            type="link"
                            icon="arrow-right"
                            size="large"
                            hidden={value === undefined}
                        />
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderPerson = (step: number): JSX.Element => {
        const { getFieldDecorator } = this.props.form;

        const genderOptions = settingsService.settings.genderTypes.map(genderType => (
            <Radio value={genderType.value} key={genderType.value}>
                {genderType.label}
            </Radio>
        ));

        return (
            <Form onSubmit={this.handleSavePerson} className={styles.person} hidden={this.state.step !== step}>
                <Form.Item
                    label={<FormattedMessage id="coronavirus.gender" />}
                    className={styles.field}
                    required={false}
                >
                    {getFieldDecorator('gender', {
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="coronavirus.gender.error.required" />,
                            },
                        ],
                    })(<Radio.Group>{genderOptions}</Radio.Group>)}
                </Form.Item>
                <Form.Item label={<FormattedMessage id="coronavirus.age" />} className={styles.field} required={false}>
                    {getFieldDecorator('age', {
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="coronavirus.age.error.required" />,
                            },
                            {
                                type: 'number',
                                min: 0,
                                max: 100,
                                message: <FormattedMessage id="coronavirus.age.error.number" />,
                            },
                        ],
                    })(<InputNumber />)}
                </Form.Item>

                <div className={styles.buttons}>
                    <Button type="primary" htmlType="submit" size="large">
                        <FormattedMessage id="coronavirus.save" />
                    </Button>
                </div>

                <div className={styles.navigation}>
                    <Button
                        onClick={this.handlePrevious}
                        type="link"
                        icon="arrow-left"
                        size="large"
                        hidden={this.state.step < 1}
                    />
                    <span className={styles.counter}>
                        {step} / {this.steps}
                    </span>
                </div>
            </Form>
        );
    };

    renderResult = (step: number): JSX.Element => {
        if (this.state.step === step) {
            return (
                <div className={styles.result}>
                    {this.renderResultMessage()}
                    {this.renderModal()}
                    <div className={styles.navigation}>
                        <Row>
                            <Col span={12}>
                                <Button onClick={this.handlePrevious} type="link" block size="large">
                                    <Icon type="arrow-left" />{' '}
                                    {this.props.isLg && <FormattedMessage id="common.previous" />}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button onClick={this.handleReset} type="default" size="large">
                                    <Icon type="reload" /> <FormattedMessage id="coronavirus.reset" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderResultMessage = (): JSX.Element => {
        const result: CoronavirusTestStatus = this.getResult();

        if (result === 'high') {
            return (
                <Result
                    status="error"
                    icon={<Icon type="exclamation-circle" theme="filled" />}
                    title={<FormattedMessage id="coronavirus.high.title" />}
                    subTitle={<FormattedMessage id="coronavirus.high.subtitle" />}
                    extra={[
                        <Button key="action" type="primary" size="large" onClick={this.handleShowModal}>
                            <FormattedMessage id="coronavirus.result.button" />
                        </Button>,
                    ]}
                />
            );
        } else if (result === 'medium') {
            return (
                <Result
                    status="warning"
                    title={<FormattedMessage id="coronavirus.medium.title" />}
                    subTitle={<FormattedMessage id="coronavirus.medium.subtitle" />}
                    extra={[
                        <Button key="action" type="primary" size="large" onClick={this.handleShowModal}>
                            <FormattedMessage id="coronavirus.result.button" />
                        </Button>,
                    ]}
                />
            );
        } else if (result === 'low') {
            return (
                <Result
                    status="warning"
                    title={<FormattedMessage id="coronavirus.low.title" />}
                    subTitle={<FormattedHTMLMessage id="coronavirus.low.subtitle" />}
                    extra={[
                        <Button key="action" type="primary" size="large" onClick={this.handleShowModal}>
                            <FormattedMessage id="coronavirus.result.button" />
                        </Button>,
                    ]}
                />
            );
        } else {
            return (
                <Result
                    status="success"
                    title={<FormattedMessage id="coronavirus.none.title" />}
                    subTitle={<FormattedHTMLMessage id="coronavirus.none.subtitle" />}
                    extra={[
                        <Button key="action" type="primary" size="large" onClick={this.handleShowModal}>
                            <FormattedMessage id="coronavirus.result.button" />
                        </Button>,
                    ]}
                />
            );
        }
    };

    renderModal = (): JSX.Element => {
        return (
            <Modal
                title={<FormattedMessage id="coronavirus.modal.title" />}
                visible={this.state.modalVisible}
                onOk={this.handleHideModal}
                onCancel={this.handleHideModal}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                {this.renderPhones()}
            </Modal>
        );
    };

    renderPhones = (): JSX.Element => {
        const coronavirusNationalPhones: CoronavirusContact[] = coronavirusPhones as any[];
        const coronavirusNationalPhone: CoronavirusContact = coronavirusNationalPhones.find(
            c => this.state.country && c.countryCode === this.state.country.code,
        ) as CoronavirusContact;
        const countryOptions = coronavirusNationalPhones
            .map(coronavirusNationalPhone => i18nService.getCountry(coronavirusNationalPhone.countryCode) as Country)
            .sort((a, b) => (a.name as string).localeCompare(b.name as string))
            .map(country => (
                <Select.Option value={country.code} key={country.code}>
                    {country.name}
                </Select.Option>
            ));

        return (
            <>
                <Descriptions
                    title={
                        <Select
                            defaultValue={this.state.country && this.state.country.code}
                            onChange={this.handleChangeContactCountry}
                            size="large"
                            className={styles.countries}
                        >
                            {countryOptions}
                        </Select>
                    }
                    size="small"
                    bordered={true}
                    className={styles.national}
                >
                    {coronavirusNationalPhone.emergencyPhone && (
                        <Descriptions.Item
                            key="emergencyPhone"
                            label={<FormattedMessage id="coronavirus.modal.emergencyPhone" />}
                            span={3}
                        >
                            {coronavirusNationalPhone.emergencyPhone}
                        </Descriptions.Item>
                    )}
                    {coronavirusNationalPhone.infoPhone && (
                        <Descriptions.Item
                            key="informationPhone"
                            label={<FormattedMessage id="coronavirus.modal.informationPhone" />}
                            span={3}
                        >
                            {coronavirusNationalPhone.infoPhone}
                        </Descriptions.Item>
                    )}
                </Descriptions>
                {coronavirusNationalPhone.states && coronavirusNationalPhone.states.length > 0 && (
                    <Descriptions
                        title={<FormattedMessage id="coronavirus.modal.state" />}
                        size="small"
                        bordered={true}
                    >
                        {coronavirusNationalPhone.states.map(state => (
                            <Descriptions.Item key={state.name} label={state.name} span={3}>
                                {state.phone}
                            </Descriptions.Item>
                        ))}
                        ;
                    </Descriptions>
                )}
            </>
        );
    };

    renderForm = (): JSX.Element => {
        return (
            <div>
                <h1>
                    <FormattedMessage id="coronavirus.title" />
                </h1>
                <div>
                    {this.renderIntro(0)}

                    {this.renderQuestion('dyspnoea', 1)}

                    {this.renderQuestion('temperature', 2)}
                    {this.renderQuestion('cough', 3)}
                    {this.renderQuestion('tasteLoss', 4)}
                    {this.renderQuestion('smellLoss', 5)}

                    {this.renderQuestion('tired', 6)}
                    {this.renderQuestion('throat', 7)}
                    {this.renderQuestion('headache', 8)}
                    {this.renderQuestion('muscular', 9)}
                    {this.renderQuestion('diarrhea', 10)}
                    {this.renderQuestion('hearthProblems', 11)}
                    {this.renderQuestion('vascularProblems', 12)}
                    {this.renderQuestion('respiratoryProblems', 13)}
                    {this.renderQuestion('autoimmuneConditions', 14)}
                    {this.renderQuestion('fluVaccination', 15)}
                    {this.renderQuestion('pneumococcusVaccination', 16)}
                    {this.renderQuestion('conjunctivitis', 17)}

                    {this.renderQuestion('contact', 18)}
                    {this.renderQuestion('relatives', 19)}
                    {this.renderQuestion('environment', 20)}

                    {this.renderPerson(21)}
                    {this.renderResult(22)}
                </div>
            </div>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="coronavirus.meta.title" descriptionKey="coronavirus.meta.description" />
                <div className={styles.layout}>{this.renderForm()}</div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(Form.create<Props>()(CoronavirusPage)));

interface Props extends WrappedComponentProps, ScreenSizeProps, FormComponentProps {}

interface State {
    coronavirusTest: CoronavirusTest;
    step: number;
    result?: CoronavirusTestStatus;
    modalVisible?: boolean;
    country?: Country;
    status?: Status;
    stepStatus?: 'yes' | 'no';
    actionStatus?: ActionStatus;
}
