import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import bmiService from '../../service/BmiService';

export const BmiLevelComponent: React.FC<Props> = props => {
    if (props.percentile) {
        const bmiChildrenLevelLabel = bmiService.getBmiChildrenLevelDescription(props.percentile);
        return <FormattedMessage id={bmiChildrenLevelLabel} />;
    } else if (props.bmi) {
        const bmiLevelLabel = bmiService.getBmiLevelDescription(props.bmi);
        return <FormattedMessage id={bmiLevelLabel} />;
    } else {
        return <></>;
    }
};

interface Props {
    bmi?: number;
    percentile?: string;
}
