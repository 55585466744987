import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { DiaryRecord } from '../model/model';
import moment, { Moment } from 'moment';

class DiaryRecordApi {
    list = async (from: Moment, to: Moment, personId?: number): Promise<DiaryRecord[]> => {
        const response: AxiosResponse<DiaryRecord[]> = await apiAxios.get<DiaryRecord[]>('/diary-records', {
            params: {
                from: from.toJSON(),
                to: to.toJSON(),
                personId,
            },
        });
        response.data.forEach(diaryRecord => (diaryRecord.date = moment(diaryRecord.date).utc()));

        return response.data;
    };

    get = async (id: number): Promise<DiaryRecord> => {
        const response: AxiosResponse<DiaryRecord> = await apiAxios.get<DiaryRecord>(
            `/diary-records/${id}`,
            this.requestConfig,
        );
        return response.data;
    };

    create = async (diaryRecord: DiaryRecord): Promise<DiaryRecord> => {
        const response: AxiosResponse<DiaryRecord> = await apiAxios.post<DiaryRecord>(
            '/diary-records',
            diaryRecord,
            this.requestConfig,
        );
        return response.data;
    };

    update = async (diaryRecord: DiaryRecord): Promise<DiaryRecord> => {
        const response: AxiosResponse<DiaryRecord> = await apiAxios.put<DiaryRecord>(
            `/diary-records/${diaryRecord.id}`,
            diaryRecord,
            this.requestConfig,
        );
        return response.data;
    };

    delete = async (diaryRecord: DiaryRecord): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/diary-records/${diaryRecord.id}`);
        return response.data;
    };

    private transformRequest = (diaryRecord: DiaryRecord): DiaryRecord | undefined => {
        let transformRequest: DiaryRecord | undefined;
        if (diaryRecord) {
            const date =
                moment(diaryRecord.date)
                    .utc()
                    .format('YYYY-MM-DDTHH:mm:00.000') + 'Z';
            transformRequest = Object.assign({}, diaryRecord, {
                date,
            });
        }

        return transformRequest;
    };

    private transformResponse = (diaryRecord: DiaryRecord): DiaryRecord | undefined => {
        let transformResponse: DiaryRecord | undefined;
        if (diaryRecord) {
            const date = moment(diaryRecord.date).utc();
            transformResponse = Object.assign({}, diaryRecord, {
                date,
            });
        }

        return transformResponse;
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
        transformResponse: (axios.defaults.transformResponse as AxiosTransformer[]).concat(this.transformResponse),
    };
}

const diaryRecordApi: DiaryRecordApi = new DiaryRecordApi();
export default diaryRecordApi;
