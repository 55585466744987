import logApi from '../api/LogApi';
import { Log, LogLevel } from '../model/model';

class LogService {
    info = (message: string): void => {
        this.log('INFO', message);
    };

    warn = (message: string): void => {
        this.log('WARN', message);
    };

    error = (error?: any): void => {
        this.log('ERROR', error || 'internal error');
    };

    notification = (message: string): void => {
        this.log('NOTIFICATION', message);
    };

    log = (level: LogLevel, message: string): void => {
        try {
            const page: string = window.location.href;
            const log: Log = { level, page, message };
            logApi.create(log);
        } catch (error) {
            console.log('error posting client log');
        }
    };
}

const logService = new LogService();
export default logService;
