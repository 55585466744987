import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Parent } from '../model/model';

class ParentApi {
    list = async (personId: number): Promise<Parent[]> => {
        const response: AxiosResponse<Parent[]> = await apiAxios.get<Parent[]>('/parents', {
            params: { personId },
        });
        return response.data;
    };

    update = async (parent: Parent): Promise<Parent> => {
        const response: AxiosResponse<Parent> = await apiAxios.put<Parent>(`/parents/${parent.id}`, parent);
        return response.data;
    };
}

const parentApi: ParentApi = new ParentApi();
export default parentApi;
