import React, { Component } from 'react';
import styles from './DocumentsBySpecialityPage.module.scss';
import { Button, List, Progress, Empty } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import { Status, Person, Plan, DocumentsBySpeciality, CustomType, ScreenSizeProps } from '../../../../model/model';
import CustomContext from '../../../../service/CustomContext';
import errorService from '../../../../service/ErrorService';
import documentApi from '../../../../api/DocumentApi';
import dateService from '../../../../service/DateService';
import { Link } from 'react-router-dom';
import planApi from '../../../../api/PlanApi';
import AvatarComponent from '../../../Shared/AvatarComponent/AvatarComponent';
import documentService from '../../../../service/DocumentService';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';

class DocumentsBySpecialityPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            documentsBySpecialities: [],
            maxStorage: 0.5,
            currentStorage: 0,
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
        this.getStorage();
    };

    list = async () => {
        const documentsBySpeciality: DocumentsBySpeciality[] = await documentApi.listDocumentsBySpeciality(this.props
            .person.id as number);
        this.setState({ documentsBySpecialities: documentsBySpeciality });
    };

    getStorage = async () => {
        const plan: Plan = await planApi.getCurrent();
        const maxStorage: number = plan.maxStorage! * 1024 * 1024 * 1024;
        const currentStorage: number = await documentApi.countFileSizes();
        this.setState({ maxStorage, currentStorage });
    };

    /** COMPONENTS **/

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.storage}>{this.renderDocumentsStorage()}</div>
                <div className={styles.add}>{this.renderCreateButton()}</div>
            </div>
        );
    };

    renderDocumentsStorage = (): JSX.Element => {
        const { currentStorage, maxStorage } = this.state;
        const percent: number = Math.round((currentStorage * 100) / maxStorage);
        const formattedCurrentStorage = documentService.getFormattedSize(currentStorage);
        const formattedMaxStorage = documentService.getFormattedSize(maxStorage);

        const strokeColor: string | undefined = percent > 85 ? '#ff4d4f' : undefined;
        return (
            <>
                <Progress percent={percent} status="normal" strokeColor={strokeColor} />
                <p className={styles.level}>
                    {formattedCurrentStorage} / {formattedMaxStorage}
                </p>
            </>
        );
    };

    renderCreateButton = (): JSX.Element => {
        return (
            <Link to={`/persons/${this.props.person.id}/documents/new`} data-test="add">
                <Button type="primary" icon="plus" size="large">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                </Button>
            </Link>
        );
    };

    renderDocumentsBySpecialityAvatar = (documentsBySpeciality: DocumentsBySpeciality): JSX.Element => {
        const medicalSpeciality: CustomType = documentService.getVirtualMedicalSpeciality(
            documentsBySpeciality.medicalSpeciality,
        );
        return (
            <>
                <AvatarComponent name={medicalSpeciality.label} />
            </>
        );
    };

    renderDocumentsBySpecialityTitle = (documentsBySpeciality: DocumentsBySpeciality): JSX.Element => {
        const medicalSpeciality: CustomType = documentService.getVirtualMedicalSpeciality(
            documentsBySpeciality.medicalSpeciality,
        );
        return <>{medicalSpeciality.label}</>;
    };

    renderDocumentsBySpecialityDescription = (documentsBySpeciality: DocumentsBySpeciality): JSX.Element => {
        const format: string = dateService.getDateFormat(this.context.user);
        const lastDate: string | undefined =
            documentsBySpeciality.lastDate && documentsBySpeciality.lastDate.format(format);
        return (
            <>
                <FormattedMessage id="documentsBySpeciality.lastDate" /> {lastDate}
            </>
        );
    };

    renderDocumentsBySpecialityList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.documentsBySpecialities}
                data-test="list"
                locale={{
                    emptyText: (
                        <div data-test="empty">
                            <Empty
                                className="empty"
                                description={
                                    <>
                                        <span>
                                            <FormattedMessage id="documentsBySpeciality.empty" />
                                        </span>
                                        <Link to={`/persons/${this.props.person.id}/documents/new`}>
                                            <Button size="large" type="primary" ghost>
                                                <FormattedMessage id="documentsBySpeciality.empty.add" />
                                            </Button>
                                        </Link>
                                    </>
                                }
                            />
                        </div>
                    ),
                }}
                renderItem={documentsBySpeciality => (
                    <Link
                        to={`/persons/${this.props.person.id}/documents?speciality=${
                            documentService.getVirtualMedicalSpeciality(documentsBySpeciality.medicalSpeciality).value
                        }`}
                        className={styles.description}
                    >
                        <List.Item data-test="item">
                            <List.Item.Meta
                                avatar={this.renderDocumentsBySpecialityAvatar(documentsBySpeciality)}
                                title={this.renderDocumentsBySpecialityTitle(documentsBySpeciality)}
                                description={this.renderDocumentsBySpecialityDescription(documentsBySpeciality)}
                            />

                            <div>{documentsBySpeciality.count}</div>
                        </List.Item>
                    </Link>
                )}
            />
        );
    };

    renderDocumentsBySpecialityShowAll = (): JSX.Element => {
        if (this.state.documentsBySpecialities.length > 1) {
            return (
                <div className={styles.button}>
                    <Link to={`/persons/${this.props.person.id}/documents`}>
                        <Button>
                            <FormattedMessage id="documentsBySpeciality.showAll" />
                        </Button>
                    </Link>
                </div>
            );
        } else {
            return <></>;
        }
    };

    render() {
        return (
            <>
                {this.renderToolbar()}
                {this.renderDocumentsBySpecialityList()}
                {this.renderDocumentsBySpecialityShowAll()}
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(DocumentsBySpecialityPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    person: Person;
}

interface State {
    documentsBySpecialities: DocumentsBySpeciality[];
    maxStorage: number;
    currentStorage: number;
    status?: Status;
}
