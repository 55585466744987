import uuid from 'uuid/v4';
import Cookies from 'js-cookie';
import { SignOutType, User, AuthUser, Locale } from '../model/model';
import userApi from '../api/UserApi';
import i18nService from './I18nService';
import authApi from '../api/AuthApi';
import eventService from './EventService';
import userService from './UserService';

class AuthorizationService {
    user: AuthUser;
    silentSignInCallback?: () => void;
    signOutCallback?: (type?: SignOutType) => void;
    silentRefreshIFrame?: any;

    /**
     * Initializes authentiation service. It must be called during the initialization of the application.
     * @param location - current location
     * @param silientSignInCallback - callback function to trigger on signIn
     * @param signOutCallback - callback function to trigger on signOut
     */
    init = async (
        location: Location,
        silentSignInCallback: () => void,
        signOutCallback: (type?: SignOutType) => void,
    ): Promise<AuthUser> => {
        const accessToken: string | undefined = this.getAccessTokenFromLocation(location.hash);
        const state: string | undefined = this.getStateFromLocation(location.hash);
        this.silentSignInCallback = silentSignInCallback;
        this.signOutCallback = signOutCallback;

        // add refresh oauth access token listener
        window.addEventListener('message', this.refreshOAuthAccessToken);

        // if access token is received in url, authenticate user
        if (accessToken && state && state === Cookies.get('authorization-state')) {
            this.user = await this.signIn(accessToken);
        }
        // else if access token is stored, try to authenticate user with existing access token
        else if (this.getAccessTokenFromCookie()) {
            this.user = await this.signIn();
        }
        // else if route is private, init oauth implicit flow (redirect user to authorization endpoint)
        else if (this.isPrivateRuote(location)) {
            this.initOAuthImplicitFlow();
        } else {
            this.user = null;
        }

        return this.user;
    };

    /**
     * Initializes oauth implict flow. It redirects the user to the authorization endpoint.
     */
    initOAuthImplicitFlow = (): void => {
        const authorizationState: string = uuid();
        Cookies.set('authorization-state', authorizationState);

        const authorizeUrl: URL = this.getOauthAuthorizationUrl(authorizationState, this.getRedirectUri());
        window.location.replace(authorizeUrl.toString());
    };

    /**
     * Initializes oauth implict flow. It redirects the user to the authorization endpoint.
     */
    initOAuthImplicitFlowWithSocialProvider = (provider: string): void => {
        const authorizeUrl: URL = this.getSocialOauthAuthorizationUrl(provider, this.getRedirectUri());
        window.location.replace(authorizeUrl.toString());
    };

    /**
     * Initializes oauth implict flow silent refresh. It redirects the user to the authorization endpoint using an iframe to avoid page reload.
     */
    initOauthImplicitFlowSilentRefresh = () => {
        // remove existing silent refresh iframe
        if (this.silentRefreshIFrame && document.body.contains(this.silentRefreshIFrame)) {
            document.body.removeChild(this.silentRefreshIFrame);
        }

        // create authorization url for silent refresh (include authorization state)
        const authorizationState: string = uuid();
        Cookies.set('authorization-state', authorizationState);
        const authorizeUrl: URL = this.getOauthAuthorizationUrl(
            authorizationState,
            `${this.getLocationOrigin()}/silent-refresh.html`,
            'none',
        );

        // create hidden silent refresh iframe with authorization url
        this.silentRefreshIFrame = document.createElement('iframe');
        this.silentRefreshIFrame.setAttribute('src', authorizeUrl.toString());
        this.silentRefreshIFrame.style.position = 'fixed';
        this.silentRefreshIFrame.style.width = '1px';
        this.silentRefreshIFrame.style.height = '1px';
        document.body.appendChild(this.silentRefreshIFrame);
    };

    /**
     * Refreshes the oauth access token. This method is called by the silent refresh iframe.
     */
    refreshOAuthAccessToken = async (event: any) => {
        if (event.data && event.data.type === 'authorizationUrl') {
            const accessToken: string | undefined = this.getAccessTokenFromLocation(event.data.value);
            const state: string | undefined = this.getStateFromLocation(event.data.value);

            // set cookies
            if (accessToken && state && state === Cookies.get('authorization-state')) {
                Cookies.set('access-token', accessToken);
                Cookies.remove('authorization-state');
            }
        }
    };

    /**
     * Sign out the authenticated user.
     */
    signOut = (type?: SignOutType): void => {
        // get current status (avoid duplicate warning)
        const isSignedOut = !this.user;

        // remove user
        this.user = null;

        // clean cookies
        Cookies.remove('access-token');
        Cookies.remove('authorization-state');

        // close events
        eventService.close();

        // sign out from api
        authApi.signOut();

        // remove default person id
        userService.removeSelectedPersonId();

        // trigger signOut callback
        if (!isSignedOut) {
            this.signOutCallback && this.signOutCallback(type);
        }
    };

    getAccessTokenFromCookie = (): string | undefined => {
        return Cookies.get('access-token');
    };

    getSignInRedirectUri = (): string => {
        const localePath: string = i18nService.getLocalePath(i18nService.intl.locale as Locale);
        return `${this.getLocationOrigin()}${localePath}signin`;
    };

    isUserComplete = (): boolean => {
        return !!this.user && !!this.user.userType && !!this.user.firstName && !!this.user.lastName;
    };

    /**
     * Signs in the user. Sets the user as authenticated and initializes authentication silent refresh.
     */
    private signIn = async (accessToken?: string): Promise<User | null> => {
        // set cookies
        if (accessToken) {
            Cookies.set('access-token', accessToken);
            Cookies.remove('authorization-state');
        }

        let user: AuthUser = null;
        try {
            user = this.user || (await userApi.getCurrentUser());
        } catch (error) {
            console.log('error getting current user');
        }

        return user;
    };

    private getOauthAuthorizationUrl = (authorizationState: string, redirectUri: string, prompt?: string) => {
        const authorizeUrl: URL = new URL(process.env.REACT_APP_AUTH_URL + '/oauth/authorize');
        authorizeUrl.searchParams.set('response_type', 'token');
        authorizeUrl.searchParams.set('client_id', 'health-web');
        authorizeUrl.searchParams.set('state', authorizationState);
        authorizeUrl.searchParams.set('redirect_uri', redirectUri);
        authorizeUrl.searchParams.set('scope', 'read write');
        if (prompt) {
            authorizeUrl.searchParams.set('prompt', prompt);
        }

        return authorizeUrl;
    };

    private getSocialOauthAuthorizationUrl = (provider: string, redirectUri: string) => {
        const authorizeUrl: URL = new URL(process.env.REACT_APP_API_URL + '/oauth2/authorize/' + provider);
        authorizeUrl.searchParams.set('redirect_uri', redirectUri);

        return authorizeUrl;
    };

    private getAccessTokenFromLocation = (locationHash: string): string | undefined => {
        const hash = locationHash.startsWith('#') ? locationHash.substring(1) : locationHash;
        const params = new URLSearchParams(hash);
        return params.get('access_token') === null ? undefined : (params.get('access_token') as string);
    };

    private getStateFromLocation = (locationHash: string): string | undefined => {
        const hash = locationHash.startsWith('#') ? locationHash.substring(1) : locationHash;
        const params = new URLSearchParams(hash);
        return params.get('state') === null ? undefined : (params.get('state') as string);
    };

    private getLocationOrigin = (): string => {
        const location: Location = window.location;
        return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    };

    private isPrivateRuote = (location: Location): boolean => {
        return this.listPrivateRoutes().some(
            privateRoute => location.pathname.startsWith(`${privateRoute}/`) || location.pathname === privateRoute,
        );
    };

    private getRedirectUri = (): string => {
        const localePath: string = i18nService.getLocalePath(i18nService.intl.locale as Locale);
        return `${this.getLocationOrigin()}${localePath}dashboard`;
    };

    private listPrivateRoutes = (): string[] => {
        const privateRoutes: string[] = [
            'dashboard',
            'user',
            'subscriptions',
            'invoices',
            'persons',
            'employees',
            'profile',
            'notifications',
            'diary-templates',
            'diary',
            'biometrics',
            'documents',
            'links',
            'video',
            'chats',
            'contacts',
            'calendar',
        ];
        const localePath: string = i18nService.getLocalePath(i18nService.intl.locale as Locale);
        return privateRoutes.map(privateRoute => `${localePath}${privateRoute}`);
    };
}

const authService = new AuthorizationService();
export default authService;
