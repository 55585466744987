import React, { Component, FormEvent } from 'react';
import styles from './DiaryTemplatePage.module.scss';
import { Form, Input, Button, message, Popconfirm, Select, Row, Col } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import CustomContext from '../../../service/CustomContext';
import diaryTemplateApi from '../../../api/DiaryTemplateApi';
import { DiaryTemplate, Status, ActionStatus, User, DiaryQuestion, ScreenSizeProps } from '../../../model/model';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../../service/ErrorService';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import i18nService from '../../../service/I18nService';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { Link } from 'react-router-dom';
import FormattedMessageComponent from '../../Shared/FormattedMessageComponent';

class DiaryTemplatePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    fieldId: number = 1;

    constructor(props: Props) {
        super(props);
        this.state = {
            diaryTemplate: {
                questions: [],
            },
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        let diaryTemplate: DiaryTemplate;
        if (this.props.match.params.id && this.props.match.params.id !== 'new') {
            diaryTemplate = await diaryTemplateApi.get(this.props.match.params.id);
            diaryTemplate.questions = diaryTemplate.questions.sort(
                (a: DiaryQuestion, b: DiaryQuestion) => (a.position as number) - (b.position as number),
            );
            this.props.form.setFieldsValue({
                name: diaryTemplate.name,
                description: diaryTemplate.description,
                language: diaryTemplate.language,
                keys: diaryTemplate.questions.map((question: DiaryQuestion, index: number) => index),
            });
            this.fieldId = diaryTemplate.questions.length + 1;

            // get readonly status
            const user = this.context.user as User;
            const readonly = !diaryTemplate.entityId && !user.admin;

            this.setState({ diaryTemplate, readonly });
        } else {
            this.props.form.setFieldsValue({
                language: i18nService.intl.locale.toUpperCase(),
                keys: [this.fieldId],
            });
        }
    };

    save = async (values: any) => {
        const { keys, questions, formats } = values;
        const user = this.context.user as User;
        const diaryQuestions: DiaryQuestion[] = keys.map((fieldKey: number, index: number) => {
            return {
                question: questions[fieldKey],
                format: formats[fieldKey],
                position: index,
            };
        });
        let diaryTemplate: DiaryTemplate = Object.assign({}, this.state.diaryTemplate, {
            entityId: user.entity!.id,
            name: values.name,
            description: values.description,
            language: values.language,
            questions: diaryQuestions,
        });

        diaryTemplate = diaryTemplate.id
            ? await diaryTemplateApi.update(diaryTemplate)
            : await diaryTemplateApi.create(diaryTemplate);
        this.setState({ diaryTemplate });
    };

    delete = async () => {
        await diaryTemplateApi.delete(this.state.diaryTemplate);
    };

    /** HANDLERS **/

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.save(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'diaryTemplate.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7, () =>
                this.props.history.push('/diary-templates'),
            );
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleAddQuestion = () => {
        const { form } = this.props;
        this.fieldId = this.fieldId + 1;
        const keys: number[] = form.getFieldValue('keys');
        const nextKeys = keys.concat(this.fieldId);
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    handleRemoveQuestion = (fieldKey: number) => {
        const { form } = this.props;
        const keys: number[] = form.getFieldValue('keys');
        if (keys.length > 1) {
            form.setFieldsValue({
                keys: keys.filter((key: number) => key !== fieldKey),
            });
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="diaryTemplate.title" />
                    </h1>
                    <p>
                        <FormattedMessageComponent id="diaryTemplate.desc" />
                    </p>
                </div>
                <div className="panel-header-back">{this.renderBackButton()}</div>
            </div>
        );
    };

    renderBackButton = (): JSX.Element => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const personId = params.get('personId');
        const date = params.get('date');
        let link: string;
        if (params.get('personId') != null && this.state.diaryTemplate.id) {
            link = `/persons/${personId}/diary/records/new?date=${date}&diaryTemplateType=user`;
        } else if (personId != null) {
            link = `/persons/${personId}/diary/records/new?date=${date}`;
        } else {
            link = '/diary-templates';
        }

        return (
            <Link to={link} data-test="back">
                <Button icon="arrow-left" ghost type="primary">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.back" />}
                </Button>
            </Link>
        );
    };

    renderQuestion = (fieldKey: number, fieldIndex: number): JSX.Element => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const keys: number[] = getFieldValue('keys');
        const formatOptions = this.context.settings.diaryQuestionFormatTypes
            .filter(formatType => !formatType.chartType)
            .map(formatType => (
                <Select.Option value={formatType.value} key={formatType.value}>
                    {formatType.label}
                </Select.Option>
            ));
        const formatMeasureOptions = this.context.settings.diaryQuestionFormatTypes
            .filter(formatType => formatType.chartType)
            .map(formatType => (
                <Select.Option value={formatType.value} key={formatType.value}>
                    {formatType.label}
                </Select.Option>
            ));

        return (
            <Row gutter={[24, 0]} className={styles.question} key={fieldKey} data-test="question">
                <Col span={24}>
                    <Form.Item
                        label={
                            <FormattedMessage
                                id="diaryTemplate.questions.question.question"
                                values={{ position: fieldIndex + 1 }}
                            />
                        }
                        key={fieldKey}
                    >
                        {getFieldDecorator(`questions[${fieldKey}]`, {
                            initialValue:
                                this.state.diaryTemplate.questions &&
                                this.state.diaryTemplate.questions[fieldKey] &&
                                this.state.diaryTemplate.questions[fieldKey].question,
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage id="diaryTemplate.questions.question.question.error.required" />
                                    ),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                maxLength={150}
                                disabled={this.state.readonly}
                                data-test="questionQuestion"
                            />,
                        )}
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item key={fieldKey}>
                        {getFieldDecorator(`formats[${fieldKey}]`, {
                            initialValue:
                                this.state.diaryTemplate.questions &&
                                this.state.diaryTemplate.questions[fieldKey] &&
                                this.state.diaryTemplate.questions[fieldKey].format,
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage id="diaryTemplate.questions.question.format.error.required" />
                                    ),
                                },
                            ],
                        })(
                            <Select
                                size="large"
                                placeholder={
                                    <FormattedMessage id="diaryTemplate.questions.question.format.placeholder" />
                                }
                                disabled={this.state.readonly}
                                data-test="questionFormat"
                            >
                                {formatOptions}

                                <Select.OptGroup
                                    label={<FormattedMessage id="diaryTemplate.questions.question.format.measures" />}
                                >
                                    {formatMeasureOptions}
                                </Select.OptGroup>
                            </Select>,
                        )}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Popconfirm
                        title={<FormattedMessage id="diaryTemplate.questions.question.confirm.delete" />}
                        onConfirm={() => this.handleRemoveQuestion(fieldKey)}
                        okText={<FormattedMessage id="common.ok" />}
                        cancelText={<FormattedMessage id="common.cancel" />}
                    >
                        <Button
                            size="large"
                            type="danger"
                            icon="delete"
                            ghost
                            hidden={keys.length < 2 || this.state.readonly}
                            data-test="questionDelete"
                        ></Button>
                    </Popconfirm>
                </Col>
            </Row>
        );
    };

    renderQuestions = (): any => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((fieldKey: any, fieldIndex: number) => this.renderQuestion(fieldKey, fieldIndex));

        return (
            <div className={styles.questions}>
                <h2>
                    <FormattedMessage id="diaryTemplate.questions" />
                </h2>
                <p>
                    <FormattedMessageComponent id="diaryTemplate.questions.desc" />
                </p>
                {formItems}
                <div className={styles.add}>
                    <Button
                        size="large"
                        type="primary"
                        ghost
                        onClick={this.handleAddQuestion}
                        hidden={this.state.readonly}
                        data-test="questionAdd"
                    >
                        <FormattedMessage id="diaryTemplate.questions.add" />
                    </Button>
                </div>
            </div>
        );
    };

    renderForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const user = this.context.user as User;
        const namePlaceholder = this.props.intl.formatMessage({ id: 'diaryTemplate.name.placeholder' });
        const descriptionPlaceholder = this.props.intl.formatMessage({ id: 'diaryTemplate.description.placeholder' });
        const languageOptions = this.context.settings.languageTypes.map(languageType => (
            <Select.Option value={languageType.value} key={languageType.value}>
                {languageType.label}
            </Select.Option>
        ));

        return (
            <Form onSubmit={this.handleSave}>
                <Row gutter={[24, 0]}>
                    <Col span={user.admin ? 18 : 24}>
                        <Form.Item label={<FormattedMessage id="diaryTemplate.name" />}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="diaryTemplate.name.error.required" />,
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    maxLength={100}
                                    placeholder={namePlaceholder}
                                    disabled={this.state.readonly}
                                    data-test="name"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={user.admin ? 6 : 0}>
                        <Form.Item label={<FormattedMessage id="diaryTemplate.language" />}>
                            {getFieldDecorator('language', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="diaryTemplate.language.error.required" />,
                                    },
                                ],
                            })(
                                <Select size="large" disabled={this.state.readonly}>
                                    {languageOptions}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="diaryTemplate.description" />}>
                            {getFieldDecorator('description')(
                                <Input
                                    size="large"
                                    maxLength={100}
                                    placeholder={descriptionPlaceholder}
                                    disabled={this.state.readonly}
                                    data-test="description"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                {this.renderQuestions()}

                <Row gutter={[24, 0]} className={styles.buttons}>
                    <Col xs={12} md={8}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            hidden={this.state.readonly}
                            data-test="submit"
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Col>
                    <Col xs={12} md={16}>
                        <Popconfirm
                            title={<FormattedMessage id="diaryTemplate.confirm.delete" />}
                            onConfirm={this.handleDelete}
                            okText={<FormattedMessage id="common.ok" />}
                            cancelText={<FormattedMessage id="common.cancel" />}
                        >
                            <Button
                                type="link"
                                size="large"
                                loading={this.state.actionStatus === 'deleting'}
                                disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                hidden={this.state.diaryTemplate.id === undefined || this.state.readonly}
                                className={styles.delete}
                                data-test="delete"
                            >
                                <FormattedMessage id="common.delete" />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19} className={styles.diary}>
                            <div className="panel">
                                {this.renderHeader()}
                                {this.renderForm()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(Form.create()(withRouter(DiaryTemplatePage))));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
}

interface State {
    diaryTemplate: DiaryTemplate;
    readonly?: boolean;
    status?: Status;
    actionStatus?: ActionStatus;
}
