import { ExternalUser, AuthUser, UserChart, ToolType } from '../model/model';
import uuid from 'uuid/v4';
import externalMeasureApi from '../api/ExternalMeasureApi';
import logService from './LogService';

class ExternalUserService {
    readonly userUuidKey: string = 'userUuid';
    readonly sessionUuidKey: string = 'sessionUuid';
    readonly externalActionKey: string = 'externalAction';

    createExternalUser = (generateSessionUUid?: boolean): ExternalUser => {
        // get or create user uuid - permament per user (browser)
        let userUuid: string | undefined = localStorage.getItem(this.userUuidKey) || undefined;
        if (!userUuid) {
            userUuid = uuid();
            localStorage.setItem(this.userUuidKey, userUuid);
        }

        // create session uuid - associated to action (e.g. store external measures, coronavirus test for sign up)
        let sessionUuid: string | undefined;
        if (generateSessionUUid) {
            sessionUuid = uuid();
            localStorage.setItem(this.sessionUuidKey, sessionUuid);
        }

        return {
            sessionUuid,
            userUuid,
        };
    };

    getExternalUser = (): ExternalUser => {
        const externalUser: ExternalUser = {
            sessionUuid: localStorage.getItem(this.sessionUuidKey) || undefined,
            userUuid: localStorage.getItem(this.userUuidKey) || undefined,
        };

        return externalUser;
    };

    initExternalUser = async (user: AuthUser, router: React.RefObject<any>): Promise<void> => {
        if (user) {
            // get external action and external user
            const externalAction: string | undefined = this.getExternalAction();
            const externalUser: ExternalUser = this.getExternalUser();

            // remove external action and external user from local storage
            this.removeExternalSessionUuid();
            this.removeExternalAction();

            // initialize user with external data (external measures)
            if (externalAction && externalUser.userUuid && externalUser.sessionUuid) {
                try {
                    let userChart: UserChart;
                    switch (externalAction as ToolType) {
                        case 'GC':
                        case 'BA':
                            userChart = await externalMeasureApi.init(externalUser);
                            router.current.history.push(`/persons/${userChart.personId}/profile`);
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    logService.warn('external user service - error initializing user');
                }
            }
        }
    };

    removeExternalSessionUuid = (): void => {
        localStorage.removeItem(this.sessionUuidKey);
    };

    getExternalAction = (): ToolType | undefined => {
        return (localStorage.getItem(this.externalActionKey) as ToolType) || undefined;
    };

    setExternalAction = (externalAction: ToolType): void => {
        localStorage.setItem(this.externalActionKey, externalAction);
    };

    removeExternalAction = (): void => {
        localStorage.removeItem(this.externalActionKey);
    };
}

const externalUserService = new ExternalUserService();
export default externalUserService;
