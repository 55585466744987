import React, { Component } from 'react';
import styles from './ChatsPage.module.scss';
import { Layout, Button, List, Badge, Empty } from 'antd';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import { Chat, Status, Person, ActionStatus, ScreenSizeProps, ChatMember, ChatMessage } from '../../../model/model';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import chatApi from '../../../api/ChatApi';
import { Link } from 'react-router-dom';
import AvatarComponent from '../../Shared/AvatarComponent/AvatarComponent';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import FormattedMessageComponent from '../../Shared/FormattedMessageComponent';
import moment, { Moment } from 'moment';
import i18nService from '../../../service/I18nService';

class ChatsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            chats: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
    };

    list = async () => {
        let chats = await chatApi.list(this.props.person.id as number);
        chats = chats.sort((a, b) => {
            let result;
            if (!a.lastMessage || !b.lastMessage) {
                result = a.lastMessage ? -1 : 1;
            } else {
                const lastMessageA = a.lastMessage as ChatMessage;
                const lastMessageB = b.lastMessage as ChatMessage;
                result = (lastMessageA.sent as Moment).isAfter(lastMessageB.sent as Moment) ? -1 : 1;
            }

            return result;
        });
        this.setState({ chats });
    };

    getChatLastMessageDesc = (chat: Chat): string => {
        let lastMessage: string;
        if (chat.lastMessage) {
            const maxLength = this.props.isXs || this.props.isSm ? 26 : 80;
            lastMessage = chat.lastMessage.body;
            if (lastMessage && lastMessage.length > maxLength) {
                lastMessage = lastMessage.substring(0, maxLength - 3) + '...';
            }
        } else {
            const member = chat.members.find(m => m.personId !== this.props.person.id) as ChatMember;
            lastMessage = i18nService.intl.formatMessage({ id: member.personId ? 'chats.desc' : 'chats.desc.unknown' });
        }

        return lastMessage;
    };

    getDate = (lastMessage?: ChatMessage): string => {
        let date = '';
        if (lastMessage && lastMessage.sent && lastMessage.sent.isSame(moment(), 'day')) {
            date = lastMessage.sent.format('HH:mm');
        } else if (lastMessage && lastMessage.sent) {
            date = lastMessage.sent.fromNow();
        }

        return date;
    };

    /** HANDLERS **/

    /** COMPONENTS **/

    renderItem = (chat: Chat): JSX.Element => {
        const member = chat.members.find(m => m.personId !== this.props.person.id) as ChatMember;
        const name = member.currentName;
        const description = this.getChatLastMessageDesc(chat);
        const date = this.getDate(chat.lastMessage);

        return (
            <Link to={`/persons/${this.props.person.id}/chats/${chat.id}`} className={styles.description}>
                <List.Item
                    actions={[
                        <Badge count={chat.unread} overflowCount={9} offset={[-8, 30]} key="date">
                            {date}
                        </Badge>,
                    ]}
                    className={styles.item}
                    data-test="item"
                >
                    <List.Item.Meta
                        avatar={<AvatarComponent name={name} />}
                        title={name}
                        description={<span className={styles.desc}>{description}</span>}
                    />
                </List.Item>
            </Link>
        );
    };

    renderList = (): JSX.Element => {
        return (
            <>
                <List
                    loading={this.state.status === 'loading'}
                    itemLayout="horizontal"
                    locale={{
                        emptyText: (
                            <Empty
                                className="empty"
                                description={
                                    <div data-test="empty">
                                        <span>
                                            <FormattedMessage id="chats.empty" />
                                        </span>
                                        <Link to={`/persons/${this.props.person.id}/links/new`}>
                                            <Button size="large" type="primary" ghost>
                                                <FormattedMessageComponent id="chats.empty.add" />
                                            </Button>
                                        </Link>
                                    </div>
                                }
                            />
                        ),
                    }}
                    dataSource={this.state.chats}
                    renderItem={chat => this.renderItem(chat)}
                />
            </>
        );
    };

    render() {
        return <Layout.Content className={styles.chats}>{this.renderList()}</Layout.Content>;
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(ChatsPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    person: Person;
}

interface State {
    chats: Chat[];
    status?: Status;
    actionStatus?: ActionStatus;
}
