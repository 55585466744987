import React, { Component } from 'react';
import styles from './ToolsPage.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Row, Col, List } from 'antd';
import ga from '../Helper/GoogleAnalytics/GoogleAnalytics';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import { Link } from 'react-router-dom';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import pathService from '../../service/PathService';
import { KeyPath } from '../../model/paths';
import { ToolKey } from '../../model/model';

class ToolsPage extends Component<Props> {
    tools: Tool[] = [
        { key: 'growthChart', path: 'growth-chart' },
        { key: 'bmiTracker', path: 'bmi-tracker' },
        { key: 'bmiCalculator', path: 'bmi-calculator' },
        { key: 'bmiCalculatorChildren', path: 'bmi-calculator-children' },
        { key: 'bmrCalculator', path: 'calorie-intake-calculator' },
        { key: 'ageCalculator', path: 'age-calculator' },
        { key: 'midparentalHeightCalculator', path: 'midparental-height-calculator' },
    ];

    componentDidMount() {
        ga.createDefaultEvent('tools', 'tools - init');
    }

    /** COMPONENTS **/

    renderTools = (): JSX.Element => {
        return (
            <List
                itemLayout="horizontal"
                size="large"
                dataSource={this.tools}
                pagination={false}
                className={styles.list}
                renderItem={tool => {
                    const title = this.props.intl.formatMessage({ id: `tools.${tool.key}` });
                    const desc = this.props.intl.formatMessage({ id: `tools.${tool.key}.desc` });
                    return (
                        <Link to={pathService.getPath(tool.path)}>
                            <List.Item className={styles.item}>
                                <List.Item.Meta
                                    avatar={<AvatarComponent name={title} size="large" />}
                                    title={<span className={styles.title}>{title}</span>}
                                    description={<span className={styles.desc}>{desc}</span>}
                                />
                            </List.Item>
                        </Link>
                    );
                }}
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="tools.meta.title" descriptionKey="tools.meta.description" />
                <Row gutter={[28, 24]}>
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            <div className="panel-header">
                                <div>
                                    <h1>
                                        <FormattedMessage id="tools.title" />
                                    </h1>
                                </div>
                            </div>
                            {this.renderTools()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default injectIntl(ToolsPage);

interface Props extends WrappedComponentProps {}

type Tool = {
    key: ToolKey;
    path: KeyPath;
};
