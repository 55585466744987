import { UserMeasureMetadata, StandardChart, UserMeasure, UnitType } from '../model/model';
import userMeasureMetadataApi from '../api/UserMeasureMetadataApi';
import { Moment } from 'moment';
import unitService from './UnitService';
import settingsService from './SettingsService';

class MeasureMetadataService {
    list = async (
        userMeasures: UserMeasure[],
        standardChart?: StandardChart,
        birthdate?: Moment,
    ): Promise<UserMeasureMetadata[]> => {
        let userMeasureMetadatas: UserMeasureMetadata[] = [];
        const filteredUserMeasures = userMeasures.filter(um => um.date && um.date.diff(birthdate, 'years') < 18);
        if (standardChart && birthdate && filteredUserMeasures.length > 0) {
            const measurementSystem: string = settingsService.settings.measurementSystemTypes[0].value;
            const unit: UnitType = unitService.getUnitTypeByChartType(standardChart.chartType, measurementSystem);
            const userMeasuresIMS: UserMeasure[] = filteredUserMeasures.map(um => unitService.convertMeasure(um, unit));
            userMeasureMetadatas = await userMeasureMetadataApi.list(birthdate, standardChart.id, userMeasuresIMS);
        }

        return userMeasureMetadatas;
    };

    getUserMeasureMetadata = async (
        userMeasure: UserMeasure,
        standardChart?: StandardChart,
        birthdate?: Moment,
    ): Promise<UserMeasureMetadata | undefined> => {
        const userMeasureMetadatas: UserMeasureMetadata[] = await this.list([userMeasure], standardChart, birthdate);

        return userMeasureMetadatas.length > 0 ? userMeasureMetadatas[0] : undefined;
    };
}

const measureMetadataService = new MeasureMetadataService();
export default measureMetadataService;
