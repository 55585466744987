import React, { Component } from 'react';
import styles from './CalendarComponent.module.scss';
import { Link } from 'react-router-dom';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Person, Status, DiaryRecord, ScreenSizeProps, DiaryAppointment, DiaryMedication } from '../../../model/model';
import { Button, Calendar, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import diaryRecordApi from '../../../api/DiaryRecordApi';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import diaryAppointmentApi from '../../../api/DiaryAppointmentApi';
import diaryMedicationApi from '../../../api/DiaryMedicationApi';
import { RenderHeader } from 'antd/lib/calendar/Header';

class CalendarComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            date: moment(),
            diaryAppointments: [],
            diaryRecords: [],
            diaryMedications: [],
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            console.log(error);
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.date !== prevProps.date) {
            try {
                this.setState({ status: 'loading' });
                await this.init();
            } catch (error) {
                console.log(error);
                errorService.displayMessage(error);
            } finally {
                this.setState({ status: undefined });
            }
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list(this.props.date);
    };

    list = async (date: Moment) => {
        const from = date.clone().startOf('month');
        const to = date.clone().endOf('month');
        const personId = this.props.person ? this.props.person.id : undefined;
        const diaryAppointments = await diaryAppointmentApi.list(from, to, personId);
        const diaryRecords = await diaryRecordApi.list(from, to, personId);
        const diaryMedications = await diaryMedicationApi.list(from, to, personId);
        this.setState({ date, diaryAppointments, diaryRecords, diaryMedications });
    };

    listByDate = (date: Moment): Array<DiaryRecord | DiaryAppointment | DiaryMedication> => {
        const appointments = this.state.diaryAppointments.filter(item => date.isSame(item.startDate, 'day'));
        const records = this.state.diaryRecords.filter(item => date.isSame(item.date, 'day'));
        const medications = this.state.diaryMedications.filter(item => date.isSame(item.startDate, 'day'));

        let items: Array<DiaryAppointment | DiaryRecord | DiaryMedication> = appointments
            .concat(records)
            .concat(medications);
        items = items.sort((a, b) => {
            const aDate = ('startDate' in a ? a.startDate : (a as DiaryRecord).date) as Moment;
            const bDate = ('startDate' in b ? b.startDate : (b as DiaryRecord).date) as Moment;
            return aDate.isAfter(bDate) ? 1 : -1;
        });

        return items;
    };

    /** HANDLERS **/

    handleChange = (date?: Moment) => {
        if (date) {
            this.list(date);
        }
    };

    /** COMPONENTS */

    renderHeader = (headerRender: RenderHeader): React.ReactNode => {
        const { status } = this.state;
        const date = status ? '...' : headerRender.value.format('MMMM YYYY');
        const previousMonth = this.state.date.clone().subtract(1, 'month');
        const nextMonth = this.state.date.clone().add(1, 'month');

        return (
            <div className={styles.toolbar}>
                <div className={styles.dates}>
                    <Button icon="left" onClick={() => this.handleChange(previousMonth)} />
                    <span>{date}</span>
                    <Button icon="right" onClick={() => this.handleChange(nextMonth)} />
                </div>
            </div>
        );
    };

    renderItem = (item: DiaryAppointment | DiaryRecord | DiaryMedication) => {
        if ('date' in item) {
            return (
                <p className={styles.appointment} key={item.id}>
                    <Tooltip title={`${item.date && item.date.format('HH:mm')} ${item.name}`}>
                        {item.date && item.date.format('HH:mm')} {item.name}
                    </Tooltip>
                </p>
            );
        } else if ('startDate' in item && 'remarks' in item) {
            return (
                <p className={styles.medication} key={item.id}>
                    <Tooltip title={`${item.startDate && item.startDate.format('HH:mm')} ${item.name}`}>
                        {item.startDate && item.startDate.format('HH:mm')} {item.name}
                    </Tooltip>
                </p>
            );
        } else if ('startDate' in item) {
            return (
                <p className={styles.record} key={item.id}>
                    <Tooltip title={`${item.startDate && item.startDate.format('HH:mm')} ${item.name}`}>
                        {item.startDate && item.startDate.format('HH:mm')} {item.name}
                    </Tooltip>
                </p>
            );
        } else {
            return <></>;
        }
    };

    renderCell = (date: Moment) => {
        const day = date.format('DD');
        const formattedDate = date.format('YYYY-MM-DD');
        const isToday = moment().isSame(date, 'day');
        const personId = this.props.person ? this.props.person.id : undefined;
        const link = personId
            ? `/persons/${personId}/diary/dates/${formattedDate}`
            : `/calendar/dates/${formattedDate}`;

        const items = this.listByDate(date);

        const areItemsVisible = items.length > 0 && !this.props.isXs && !this.props.isSm && !this.props.isMd;
        const isSeeMoreVisible = items.length > 3;

        return (
            <Link to={link}>
                <div
                    className={`ant-fullcalendar-date ${items.length > 0 ? styles.recorded : ''} ${
                        isToday ? styles.today : ''
                    }`}
                >
                    <div className="ant-fullcalendar-value">{day}</div>
                    <div
                        className={`ant-fullcalendar-content ${styles.items}`}
                        hidden={!areItemsVisible}
                        data-test="items"
                    >
                        <div className={styles.item} hidden={!areItemsVisible}>
                            {items.slice(0, 3).map(item => this.renderItem(item))}
                        </div>
                        <div className={styles.more} hidden={!isSeeMoreVisible}>
                            ...
                        </div>
                    </div>
                </div>
            </Link>
        );
    };

    renderCalendar = (): JSX.Element => {
        return (
            <Calendar
                onChange={this.handleChange}
                value={this.state.date}
                fullscreen={!this.props.isXs && !this.props.isSm}
                dateFullCellRender={this.renderCell}
                headerRender={this.renderHeader}
            />
        );
    };

    render() {
        return <div className={styles.diary}>{this.renderCalendar()}</div>;
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(CalendarComponent));

interface Props extends WrappedComponentProps, ScreenSizeProps {
    date: Moment;
    person?: Person;
}

interface State {
    date: Moment;
    diaryAppointments: DiaryAppointment[];
    diaryRecords: DiaryRecord[];
    diaryMedications: DiaryMedication[];
    status?: Status;
}
