import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../../service/AuthService';

class ProtectedRoute extends Component<any> {
    render() {
        const { component: Component, ...props } = this.props;

        if (authService.user && authService.isUserComplete()) {
            return <Route {...props} render={props => <Component {...props} />} />;
        } else if (authService.user) {
            return <Redirect to="/dashboard" />;
        } else {
            return <Redirect to="/signin" />;
        }
    }
}
export default ProtectedRoute;
