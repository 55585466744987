import apiAxios from './CustomAxios';
import { ChatPaymentIntent } from '../model/model';

class PaymentIntentApi {
    createChatPaymentIntent = async (paymentIntent: ChatPaymentIntent): Promise<ChatPaymentIntent> => {
        const response = await apiAxios.post<ChatPaymentIntent>('/payment-intents/chats', paymentIntent);
        return response.data;
    };
}

const paymentIntentApi: PaymentIntentApi = new PaymentIntentApi();
export default paymentIntentApi;
