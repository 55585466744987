import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { PostReaction, Page } from '../model/model';

class PostReactionApi {
    list = async (postId: number, page: number, size: number): Promise<Page<PostReaction>> => {
        const response: AxiosResponse<Page<PostReaction>> = await apiAxios.get<Page<PostReaction>>('/post-reactions', {
            params: { postId, page, size },
        });
        return response.data;
    };

    count = async (postId: number, entityId?: number): Promise<number> => {
        const response: AxiosResponse<number> = await apiAxios.get<number>(`/post-reactions/count`, {
            params: { postId, entityId },
        });
        return response.data;
    };

    create = async (postReaction: PostReaction): Promise<PostReaction> => {
        const response: AxiosResponse<PostReaction> = await apiAxios.post<PostReaction>(
            '/post-reactions',
            postReaction,
        );
        return response.data;
    };

    delete = async (postId: number): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/post-reactions`, {
            params: { postId },
        });
        return response.data;
    };
}

const postReactionApi: PostReactionApi = new PostReactionApi();
export default postReactionApi;
