import React, { Component } from 'react';
import styles from './ToolsComponent.module.scss';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, List, Avatar } from 'antd';
import pathService from '../../../service/PathService';
import { KeyPath } from '../../../model/paths';

class ToolsComponent extends Component<Props> {
    tools: Tool[] = [
        { key: 'growthChart', path: 'growth-chart', icon: 'line-chart' },
        { key: 'bmiCalculatorChildren', path: 'bmi-calculator-children', icon: 'calculator' },
        { key: 'bmiCalculator', path: 'bmi-calculator', icon: 'calculator' },
        { key: 'bmiTracker', path: 'bmi-tracker', icon: 'line-chart' },
        { key: 'bmrCalculator', path: 'calorie-intake-calculator', icon: 'calculator' },
        { key: 'ageCalculator', path: 'age-calculator', icon: 'calculator' },
        { key: 'midparentalHeightCalculator', path: 'midparental-height-calculator', icon: 'calculator' },
    ];

    /** COMPONENTS **/

    renderLoadMore = (): JSX.Element => {
        return (
            <div className={styles.buttons}>
                <Link to={pathService.getPath('tools')}>
                    <Button type="default">
                        <FormattedMessage id="sidebar.showMore" />
                    </Button>
                </Link>
            </div>
        );
    };

    render() {
        return (
            <div className={styles.tools}>
                <h2>
                    <FormattedMessage id="sidebar.tools.title" />
                </h2>
                <List
                    itemLayout="horizontal"
                    dataSource={this.tools}
                    className={styles.list}
                    loadMore={this.renderLoadMore()}
                    renderItem={tool => {
                        const title = this.props.intl.formatMessage({ id: `tools.${tool.key}` });
                        return (
                            <Link to={pathService.getPath(tool.path)}>
                                <List.Item className={styles.item}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                icon={tool.icon}
                                                size="small"
                                                style={{ backgroundColor: 'rgb(92, 106, 194)' }}
                                            />
                                        }
                                        title={<span className={styles.title}>{title}</span>}
                                    />
                                </List.Item>
                            </Link>
                        );
                    }}
                />
            </div>
        );
    }
}
export default injectIntl(ToolsComponent);

interface Props extends WrappedComponentProps {}

type Tool = {
    key: string;
    path: KeyPath;
    icon: string;
};
