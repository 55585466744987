import { AxiosRequestConfig } from 'axios';
import { authAxios } from './CustomAxios';

const signIn = async (username: string, password: string) => {
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    };

    await authAxios.post('/signin', body, config);
};

const signOut = async () => {
    await authAxios.get('/signout');
};

const authApi = {
    signIn,
    signOut,
};
export default authApi;
