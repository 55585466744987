import React, { Component } from 'react';
import styles from './PostCommentsComponent.module.scss';
import { Post, ActionStatus, Page, Status, User } from '../../../../../model/model';
import CustomContext from '../../../../../service/CustomContext';
import postApi from '../../../../../api/PostApi';
import errorService from '../../../../../service/ErrorService';
import { FormattedMessage } from 'react-intl';
import { Button, List, message } from 'antd';
import PostCommentEditComponent from './PostCommentEditComponent/PostCommentEditComponent';
import PostCommentComponent from './PostCommentComponent/PostCommentComponent';

class PostCommentsComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            comment: {},
            comments: [],
            page: 0,
            last: true,
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        this.initComment();
        await this.list(this.state.page);
    };

    initComment = () => {
        if (this.context.user) {
            const user: User = this.context.user as User;
            const comment: Post = {
                entityId: user.entity!.id,
                parentId: this.props.post.id,
                commentsAllowed: true,
                shared: 'ANYONE',
                conditions: [],
                postType: this.props.post.postType,
                status: 'APPROVED',
                language: this.props.post.language,
            };
            this.setState({ comment });
        }
    };

    list = async (page: number) => {
        const size: number = this.props.size || 5;
        const language: string = this.context.locale.toUpperCase();

        const postsPage: Page<Post> = await postApi.list(
            page,
            size,
            language,
            undefined,
            undefined,
            this.props.post.id,
        );
        const comments: Post[] = page === 0 ? postsPage.content : this.state.comments.concat(postsPage.content);
        const last: boolean = postsPage.last;
        this.setState({ comments, page, last });
    };

    refresh = async () => {
        await this.list(0);
    };

    /** HANDLERS **/

    handleLoadMore = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'loading' });
            await this.list(this.state.page + 1);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleCommentUpdate = async () => {
        try {
            message.destroy();
            await this.refresh();
            this.props.handleCommentsUpdate();
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    /** COMPONENTS **/

    renderLoadMore = (): JSX.Element => {
        if (this.state.last) {
            return <></>;
        } else {
            return (
                <div className={styles.load}>
                    <Button
                        type="primary"
                        onClick={this.handleLoadMore}
                        loading={this.state.actionStatus === 'loading'}
                    >
                        <FormattedMessage id="common.loadMore" />
                    </Button>
                </div>
            );
        }
    };

    renderComment = (): JSX.Element => {
        if (this.context.user) {
            return (
                <div className={styles.new}>
                    <PostCommentEditComponent
                        comment={this.state.comment}
                        handlePostCreate={this.handleCommentUpdate}
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderComments = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="vertical"
                dataSource={this.state.comments}
                locale={{
                    emptyText: (
                        <FormattedMessage
                            id={
                                this.props.post.postType === 'QUESTION'
                                    ? 'question.comments.empty'
                                    : 'post.comments.empty'
                            }
                        />
                    ),
                }}
                loadMore={this.renderLoadMore()}
                renderItem={comment => (
                    <PostCommentComponent post={comment} handlePostUpdate={this.handleCommentUpdate} />
                )}
            />
        );
    };

    render() {
        if (this.props.visible) {
            return (
                <div className={styles.comments}>
                    {this.renderComment()}
                    {this.renderComments()}
                </div>
            );
        } else {
            return <></>;
        }
    }
}
export default PostCommentsComponent;

interface Props {
    post: Post;
    size?: number;
    visible?: boolean;
    handleCommentsUpdate: () => void;
}

interface State {
    comment: Post;
    comments: Post[];
    page: number;
    last: boolean;
    status?: Status;
    actionStatus?: ActionStatus;
}
