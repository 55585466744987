import React, { Component } from 'react';
import { Person, UserChart, TemplateChart, ScreenSizeProps } from '../../../../model/model';
import { Line } from 'react-chartjs-2';
import unitService from '../../../../service/UnitService';
import chartService from '../../../../service/ChartService';
import { Moment } from 'moment';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import withSizes from 'react-sizes';
import responsiveService from '../../../../service/ResponsiveService';
import 'chartjs-plugin-annotation';

class BiometricsChart extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            data: {},
            options: {},
        };
    }

    componentDidUpdate(prevProps: Props) {
        try {
            if (this.props.userChart !== prevProps.userChart || this.props.templateChart !== prevProps.templateChart) {
                const unit: string = unitService.getUnitLabelByUserChart(
                    this.props.userChart,
                    this.props.measurementSystem,
                );
                const labels: Moment[] = chartService.getXScaleLabels(
                    this.props.person.birthdate as Moment,
                    this.props.userChart,
                    this.props.isLg,
                );
                const options: any = chartService.getOptions(this.props.person, this.props.userChart, unit, labels);
                const data: any = {
                    labels,
                    datasets: chartService.getDatasets(this.props.person, this.props.userChart),
                };
                if (this.props.templateChart) {
                    data.datasets = data.datasets.concat(
                        chartService.getTemplateDatasets(this.props.templateChart, this.props.person),
                    );
                }
                this.setState({ data, options });
            }
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    /*** METHODS ***/

    getChartHeight = (): number => {
        let height: number;
        if (this.props.height) {
            height = this.props.height;
        } else if (this.props.isMd) {
            height = 150;
        } else if (this.props.isLg) {
            height = 85;
        } else {
            height = 300;
        }

        return height;
    };

    /*** COMPONENTS ***/

    render() {
        return (
            <Line
                data={this.state.data}
                options={this.state.options}
                height={this.getChartHeight()}
                data-test="lineChart"
            />
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(BiometricsChart));

interface Props extends WrappedComponentProps, ScreenSizeProps {
    measurementSystem: string;
    person: Person;
    userChart: UserChart;
    templateChart?: TemplateChart;
    height?: number;
}

interface State {
    data: any;
    options: any;
}
