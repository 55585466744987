import React, { Component } from 'react';
import { Form, Input, Row, Col, Select, message, Button } from 'antd';
import { ActionStatus, ScreenSizeProps, Customer } from '../../../../model/model';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import { FormComponentProps } from 'antd/lib/form';
import withSizes from 'react-sizes';
import responsiveService from '../../../../service/ResponsiveService';
import i18nService from '../../../../service/I18nService';
import customerApi from '../../../../api/CustomerApi';

class SubscriptionBilling extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = (): void => {
        this.props.form.setFieldsValue({
            name: this.props.customer.name,
            taxId: this.props.customer.taxId,
            address: this.props.customer.address.address,
            city: this.props.customer.address.city,
            state: this.props.customer.address.state,
            country: this.props.customer.address.country,
            postalCode: this.props.customer.address.postalCode,
        });
    };

    save = async (values: any): Promise<Customer> => {
        const customer: Customer = Object.assign({}, this.props.customer, {
            name: values.name,
            taxId: values.taxId,
            address: {
                address: values.address,
                city: values.city,
                state: values.state,
                country: values.country,
                postalCode: values.postalCode,
            },
            taxExempt: undefined,
        });

        return await customerApi.update(customer);
    };

    /** HANDLERS **/
    handleSave = async (): Promise<void> => {
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const customer: Customer = await this.save(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 4);

                    this.props.handleSave(customer);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    /** COMPONENTS **/

    renderPaymentBillingForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const size: number = this.props.isLg ? 12 : 24;
        const countryOptions = i18nService.getCountries().map(country => (
            <Select.Option value={country.code} key={country.code}>
                {country.name}
            </Select.Option>
        ));

        return (
            <Form>
                <h2>
                    <FormattedMessage id={'subscription.billing.title'} />
                </h2>
                <Row gutter={24}>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.name" />}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.billing.name.required" />,
                                    },
                                ],
                            })(<Input size="large" maxLength={100} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.taxId" />}>
                            {getFieldDecorator('taxId')(<Input size="large" maxLength={50} />)}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.address" />}>
                            {getFieldDecorator('address', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.billing.address.required" />,
                                    },
                                ],
                            })(<Input size="large" maxLength={100} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.city" />}>
                            {getFieldDecorator('city')(<Input size="large" maxLength={50} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.state" />}>
                            {getFieldDecorator('state')(<Input size="large" maxLength={50} />)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.country" />}>
                            {getFieldDecorator('country', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="subscription.billing.country.required" />,
                                    },
                                ],
                            })(<Select size="large">{countryOptions}</Select>)}
                        </Form.Item>
                    </Col>
                    <Col span={size}>
                        <Form.Item label={<FormattedMessage id="subscription.billing.postalCode" />}>
                            {getFieldDecorator('postalCode')(<Input size="large" maxLength={10} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    type="primary"
                    size="large"
                    onClick={this.handleSave}
                    loading={this.state.actionStatus === 'saving'}
                >
                    <FormattedMessage id="subscription.billing.button" />
                </Button>
            </Form>
        );
    };

    render() {
        return this.renderPaymentBillingForm();
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(Form.create<Props>()(SubscriptionBilling)));

interface Props extends FormComponentProps, WrappedComponentProps, ScreenSizeProps {
    customer: Customer;
    handleSave: (customer: Customer) => void;
}

interface State {
    actionStatus?: ActionStatus;
}
