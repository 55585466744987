import React, { Component, RefObject } from 'react';
import Template from './components/Template/Template';
import authService from './service/AuthService';
import CustomContext, { defaultContext } from './service/CustomContext';
import { Context, Settings, Status, SignOutType, AuthUser, User, Locale } from './model/model';
import { message, Modal, Spin, ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import settingsService from './service/SettingsService';
import { BrowserRouter, Route } from 'react-router-dom';
import i18nService from './service/I18nService';
import CookieConsentPanel from './components/Helper/CookieConsentPanel/CookieConsentPanel';
import Loader from './components/Loader/Loader';
import { withGATracker } from './components/Helper/GoogleAnalytics/GoogleAnalytics';
import externalUserService from './service/ExternalUserService';
import eventService from './service/EventService';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

export default class App extends Component<any, State> {
    router: RefObject<any>;
    constructor(props: any) {
        super(props);
        this.router = React.createRef<BrowserRouter>();
        this.state = {
            context: Object.assign({}, defaultContext, {
                updateUser: this.updateUser,
                selectLocale: this.selectLocale,
            }),
            status: 'loading',
        };
    }
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

    async componentDidMount() {
        try {
            await this.init();
        } catch (error) {
            this.setState({ status: 'error' });
        }
    }

    componentWillUnmount() {
        // close events
        eventService.close();
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        message.config({ maxCount: 1 });
        Spin.setDefaultIndicator(<Loader type="icon" />);

        // set-up i18n
        const locale = i18nService.init();
        settingsService.init(locale);

        // set-up context (settings, user)
        const settings: Settings = settingsService.settings;
        const user: AuthUser = await authService.init(window.location, this.silentSignInCallback, this.signOut);
        const context: Context = Object.assign({}, this.state.context, {
            user,
            settings,
            locale,
        });
        const status: Status | undefined = user !== undefined ? undefined : 'loading';
        this.setState({ context, status });

        // start events
        user && eventService.init();

        // load external user stored data and redirect to corresponding page
        await externalUserService.initExternalUser(user, this.router);
    };

    silentSignInCallback = async (): Promise<void> => {
        if (!this.context.user) {
            const user: AuthUser = authService.user;
            const context: Context = Object.assign({}, this.state.context, {
                user,
            });
            this.setState({ context, status: undefined });
        }
    };

    signOut = (type?: SignOutType): void => {
        const title: string = i18nService.intl.formatMessage({ id: 'error.unauthorized.title' });
        const content: string = i18nService.intl.formatMessage({ id: 'error.unauthorized.subtitle' });
        const okText: string = i18nService.intl.formatMessage({ id: 'error.unauthorized.button' });
        if (type === 'unauthorized') {
            Modal.destroyAll();
            Modal.warning({
                title,
                content,
                okText,
            });
        } else if (type === 'signout') {
            this.router.current && this.router.current.history.push('/');
        }

        // remove user from context
        if (this.state.context.user) {
            const context: Context = Object.assign({}, this.state.context, {
                user: null,
            });
            this.setState({ context });
        }
    };

    updateUser = (user: User, callback?: () => void): void => {
        const context: Context = Object.assign({}, this.state.context, { user });
        authService.user = Object.assign({}, user);
        callback && callback();

        this.setState({ context });
    };

    selectLocale = (locale: Locale): void => {
        const context: Context = Object.assign({}, this.state.context, { locale });
        this.setState({ context });
        this.router.current && this.router.current.history.push(i18nService.getLocalePath(context.locale));
    };

    /** COMPONENTS */

    renderContent = (): JSX.Element => {
        return (
            <CustomContext.Provider value={this.state.context}>
                <IntlProvider
                    locale={this.state.context.locale}
                    defaultLocale={i18nService.defaultLocale}
                    messages={i18nService.getMessages(this.state.context.locale)}
                >
                    <BrowserRouter basename={i18nService.getLocalePath(this.state.context.locale)} ref={this.router}>
                        <Route component={withGATracker(Template, { locale: this.state.context.locale })} />
                        <CookieConsentPanel />
                    </BrowserRouter>
                </IntlProvider>
            </CustomContext.Provider>
        );
    };

    render() {
        return (
            <ConfigProvider locale={i18nService.getComponentsLocaleFromPath()}>
                <Elements stripe={this.stripePromise}>
                    {this.state.status === 'loading' ? <Loader type="complete" /> : this.renderContent()}
                </Elements>
            </ConfigProvider>
        );
    }
}

interface State {
    context: Context;
    status?: Status;
}
