import React, { Component, FormEvent } from 'react';
import styles from './CalendarMedicationComponent.module.scss';
import { DiaryMedication, Status, ActionStatus, Person } from '../../../../model/model';
import Form, { FormComponentProps } from 'antd/lib/form';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import { Row, Col, message, Button, Popconfirm, TimePicker, Select } from 'antd';
import diaryMedicationApi from '../../../../api/DiaryMedicationApi';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import CustomContext from '../../../../service/CustomContext';
import TextArea from 'antd/lib/input/TextArea';
import FormattedMessageComponent from '../../FormattedMessageComponent';
import personApi from '../../../../api/PersonApi';
import MedicationField from '../../MedicationField/MedicationField';

class CalendarMedicationComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            diaryMedication: {},
            persons: [],
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        let diaryMedication: DiaryMedication;
        const personId = this.props.person ? this.props.person.id : undefined;
        if (this.props.match.params.id && this.props.match.params.id === 'new') {
            diaryMedication = Object.assign({}, this.state.diaryMedication, {
                startDate: this.getStartDate(),
                personId: personId,
                timezone: moment.tz.guess(),
            });

            this.props.form.setFieldsValue({
                startDate: diaryMedication.startDate,
            });
        } else {
            diaryMedication = await diaryMedicationApi.get(this.props.match.params.id);
            this.props.form.setFieldsValue({
                startDate: diaryMedication.startDate,
                personId: diaryMedication.personId,
                name: diaryMedication.name,
                values: diaryMedication.value,
                unit: diaryMedication.unit,
                remarks: diaryMedication.remarks,
            });
        }

        // list persons
        let persons: Person[] = [];
        if (!personId) {
            const personsPage = await personApi.list(0, 1000);
            persons = personsPage.content;
        }

        this.setState({ diaryMedication, persons });
    };

    getStartDate = (): Moment => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const date = params.get('date') as string;
        return moment.utc(date).set('hours', 8);
    };

    save = async (values: any) => {
        let diaryMedication: DiaryMedication = Object.assign({}, this.state.diaryMedication, {
            startDate: values.startDate,
            personId: this.props.person ? this.props.person.id : values.personId,
            name: values.name,
            value: values.value,
            unit: values.unit,
            remarks: values.remarks,
        });

        return diaryMedication.id
            ? await diaryMedicationApi.update(diaryMedication)
            : await diaryMedicationApi.create(diaryMedication);
    };

    delete = async () => {
        await diaryMedicationApi.delete(this.state.diaryMedication);
    };

    getDatePath = (date: string): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/dates/${date}` : `/calendar/dates/${date}`;
    };

    /** HANDLERS **/

    handleChangeTime = (startDate: Moment) => {
        const diaryMedication: DiaryMedication = Object.assign({}, this.state.diaryMedication, {
            startDate,
        });
        this.setState({ diaryMedication });
    };

    handleSave = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    const diaryMedication = await this.save(values);
                    const date = (diaryMedication.startDate as Moment).format('YYYY-MM-DD');
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7, () =>
                        this.props.history.push(this.getDatePath(date)),
                    );
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'diaryMedication.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            const date = (this.state.diaryMedication.startDate as Moment).format('YYYY-MM-DD');
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7, () =>
                this.props.history.push(this.getDatePath(date)),
            );
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderDatePicker = (): JSX.Element => {
        if (this.state.diaryMedication.startDate) {
            const startDate = this.state.diaryMedication.startDate as Moment;
            return (
                <div className={styles.date}>
                    <span className={styles.day}>
                        <span>{startDate.format('dddd')}</span>, {startDate.format('DD MMMM YYYY')}
                    </span>
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderPersonSelect = (diaryMedication: DiaryMedication): JSX.Element => {
        if (this.props.person) {
            return <></>;
        } else {
            const { getFieldDecorator } = this.props.form;
            const personOptions = this.state.persons.map(person => (
                <Select.Option value={person.id} key={person.id}>
                    {person.fullName}
                </Select.Option>
            ));

            return (
                <Row gutter={[24, 0]}>
                    <Col xs={24}>
                        <Form.Item label={<FormattedMessageComponent id="diaryMedication.personId" />}>
                            {getFieldDecorator('personId', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="diaryMedication.personId.error.required" />,
                                    },
                                ],
                            })(
                                <Select size="large" data-test="personId" disabled={diaryMedication.readonly}>
                                    {personOptions}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            );
        }
    };

    renderMedicationField = (diaryMedication: DiaryMedication): JSX.Element => {
        return (
            <MedicationField
                form={this.props.form}
                fieldName="name"
                fieldMeasureName="value"
                fieldUnitName="unit"
                initialValue={diaryMedication.name}
                initialMeasureValue={diaryMedication.value}
                initialMeasureUnit={diaryMedication.unit}
                size="large"
                label={<FormattedMessage id="diaryMedication.name" />}
                disabled={diaryMedication.readonly}
                required={true}
                requiredLabel={<FormattedMessage id="diaryMedication.name.error.required" />}
            />
        );
    };

    renderForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const diaryMedication = this.state.diaryMedication;

        return (
            <Form onSubmit={this.handleSave}>
                <Row gutter={[24, 0]}>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <Form.Item label={<FormattedMessage id="diaryMedication.startTime" />} data-test="startDate">
                            {getFieldDecorator('startDate', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="diaryMedication.startTime.error.required" />,
                                    },
                                ],
                            })(
                                <TimePicker
                                    format={'HH:mm'}
                                    size="large"
                                    allowClear={false}
                                    onChange={this.handleChangeTime}
                                    placeholder=""
                                    disabled={diaryMedication.readonly}
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {this.renderPersonSelect(diaryMedication)}
                {this.renderMedicationField(diaryMedication)}
                <Row gutter={[24, 0]}>
                    <Col xs={24}>
                        <Form.Item label={<FormattedMessage id="diaryMedication.remarks" />}>
                            {getFieldDecorator('remarks')(
                                <TextArea
                                    rows={3}
                                    maxLength={1000}
                                    data-test="remarks"
                                    disabled={diaryMedication.readonly}
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]} className={styles.buttons}>
                    <Col xs={12} md={8}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                            hidden={diaryMedication.readonly}
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </Col>
                    <Col xs={12} md={16}>
                        <Popconfirm
                            title={<FormattedMessage id="diaryMedication.confirm.delete" />}
                            onConfirm={this.handleDelete}
                            okText={<FormattedMessage id="common.ok" />}
                            cancelText={<FormattedMessage id="common.cancel" />}
                        >
                            <Button
                                type="link"
                                size="large"
                                loading={this.state.actionStatus === 'deleting'}
                                disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                className={styles.delete}
                                hidden={!this.state.diaryMedication.id || diaryMedication.readonly}
                                data-test="delete"
                            >
                                <FormattedMessage id="common.delete" />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        return (
            <div className={styles.diary}>
                {this.renderDatePicker()}
                {this.renderForm()}
            </div>
        );
    }
}
export default withRouter(injectIntl(Form.create<Props>()(CalendarMedicationComponent)));

interface Props extends FormComponentProps, WrappedComponentProps, RouteComponentProps {
    match: any;
    date: Moment;
    person?: Person;
}

interface State {
    diaryMedication: DiaryMedication;
    persons: Person[];
    status?: Status;
    actionStatus?: ActionStatus;
}
