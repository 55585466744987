import apiAxios from './CustomAxios';
import axios, { AxiosResponse, AxiosRequestConfig, AxiosTransformer } from 'axios';
import { UserMeasure, BmiMeasure } from '../model/model';

class UserMeasureApi {
    create = async (userMeasure: UserMeasure): Promise<UserMeasure> => {
        if ('height' in userMeasure && 'weight' in userMeasure) {
            return await this.createBmiMeasure(userMeasure);
        } else {
            return await this.createDefaultMeasure(userMeasure);
        }
    };

    private createDefaultMeasure = async (userMeasure: UserMeasure): Promise<UserMeasure> => {
        const response: AxiosResponse<UserMeasure> = await apiAxios.post<UserMeasure>(
            '/user-measures',
            userMeasure,
            this.requestConfig,
        );
        return response.data;
    };

    private createBmiMeasure = async (bmiMeasure: BmiMeasure): Promise<BmiMeasure> => {
        const response: AxiosResponse<UserMeasure> = await apiAxios.post<BmiMeasure>(
            '/user-measures/bmi',
            bmiMeasure,
            this.requestConfig,
        );
        return response.data;
    };

    update = async (userMeasure: UserMeasure): Promise<UserMeasure> => {
        if ('height' in userMeasure && 'weight' in userMeasure) {
            return await this.updateBmiMeasure(userMeasure);
        } else {
            return await this.updateDefaultMeasure(userMeasure);
        }
    };

    private updateDefaultMeasure = async (userMeasure: UserMeasure): Promise<UserMeasure> => {
        const response: AxiosResponse<UserMeasure> = await apiAxios.put<UserMeasure>(
            `/user-measures/${userMeasure.id}`,
            userMeasure,
            this.requestConfig,
        );
        return response.data;
    };

    private updateBmiMeasure = async (bmiMeasure: BmiMeasure): Promise<UserMeasure> => {
        const response: AxiosResponse<BmiMeasure> = await apiAxios.put<BmiMeasure>(
            `/user-measures/bmi`,
            bmiMeasure,
            this.requestConfig,
        );
        return response.data;
    };

    delete = async (userMeasure: UserMeasure): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/user-measures/${userMeasure.id}`);
        return response.data;
    };

    private transformRequest = (userMeasure: UserMeasure | BmiMeasure): any => {
        const date = userMeasure.date ? userMeasure.date.format('YYYY-MM-DDT00:00:00.000') + 'Z' : undefined;
        const time = userMeasure.time ? userMeasure.time.format('HH:mm') : undefined;
        const transformRequest: any = Object.assign({}, userMeasure, {
            date,
            time,
        });
        if (
            'height' in transformRequest &&
            'weight' in transformRequest &&
            transformRequest.height &&
            transformRequest.weight
        ) {
            transformRequest.id = transformRequest.height.id && transformRequest.weight.id ? -1 : undefined;
            transformRequest.height.date = date;
            transformRequest.height.time = time;
            transformRequest.height.seriesId = transformRequest.height.seriesId || -1; // to simplify validation in backend
            transformRequest.weight.date = date;
            transformRequest.weight.time = time;
            transformRequest.weight.seriesId = transformRequest.weight.seriesId || -1; // to simplify validation in backend
        }

        return transformRequest;
    };

    private requestConfig: AxiosRequestConfig = {
        transformRequest: [this.transformRequest, ...(axios.defaults.transformRequest as AxiosTransformer[])],
    };
}

const userMeasureApi: UserMeasureApi = new UserMeasureApi();
export default userMeasureApi;
