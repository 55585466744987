import { Plan, Subscription, PlanStatus } from '../model/model.js';

class PlanService {
    getPlanStatus = (plan: Plan, subscription: Subscription | undefined): PlanStatus => {
        let planStatus: PlanStatus;
        // active plan
        if (subscription && subscription.planId === plan.id) {
            planStatus = 'active';
        }
        // no active plan (lower plan)
        else if (subscription && plan.nickname === 'standard') {
            planStatus = 'included';
        }
        // no active plan (higher plan)
        else if (subscription && plan.nickname === 'premium') {
            planStatus = 'upgrade';
        }
        // no active plan and no subscription
        else {
            planStatus = 'subscribe';
        }

        return planStatus;
    };

    getPlanActionIcon = (planStatus: PlanStatus): string => {
        let planActionIcon: string;
        if (planStatus === 'active') {
            planActionIcon = 'modify';
        } else if (planStatus === 'included') {
            planActionIcon = 'check';
        } else {
            planActionIcon = 'credit-card';
        }

        return planActionIcon;
    };

    getPlanActionMessage = (planStatus: PlanStatus): string => {
        let getPlanActionMessage: string;
        if (planStatus === 'active') {
            getPlanActionMessage = 'common.modify';
        } else if (planStatus === 'included') {
            getPlanActionMessage = 'subscriptions.included';
        } else if (planStatus === 'upgrade') {
            getPlanActionMessage = 'common.upgrade';
        } else {
            getPlanActionMessage = 'common.subscribe';
        }

        return getPlanActionMessage;
    };
}

const planService = new PlanService();
export default planService;
