import React, { Component } from 'react';
import styles from './LinkApprovalPage.module.scss';
import { Button, message, Popconfirm, Row, Col, Select, Icon, Modal } from 'antd';
import {
    Status,
    ActionStatus,
    PersonLink,
    ScreenSizeProps,
    Entity,
    Person,
    PublicPerson,
} from '../../../../model/model';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedHTMLMessage } from 'react-intl';
import errorService from '../../../../service/ErrorService';
import CustomContext from '../../../../service/CustomContext';
import personLinkApi from '../../../../api/PersonLinkApi';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../../../Shared/SidebarComponent/SidebarComponent';
import personApi from '../../../../api/PersonApi';
import moment from 'moment';
import FormattedMessageComponent from '../../../Shared/FormattedMessageComponent';

class LinkApprovalPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            personLink: {},
            persons: [],
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async () => {
        if (this.props.match.params.id) {
            const personLink = await personLinkApi.get(this.props.match.params.id as number);
            const persons = await this.listPersons();

            this.setState({ personLink, persons });
        }
    };

    listPersons = async (): Promise<Person[]> => {
        const personsPage = await personApi.list(0, 1000);
        return personsPage.content;
    };

    save = async (): Promise<PersonLink> => {
        let personLink: PersonLink = Object.assign({}, this.state.personLink, {
            email: undefined,
            message: undefined,
            sent: undefined,
            inviter: undefined,
            inviterPerson: undefined,
            approver: undefined,
        });
        return await personLinkApi.update(personLink);
    };

    delete = async (): Promise<void> => {
        await personLinkApi.delete(this.state.personLink);
        this.props.history.push(`/notifications`);
    };

    /** HANDLERS **/

    handleSave = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'saving' });
            const personLink = await this.save();
            const modal = Modal.success({
                icon: <Icon type="loading" />,
                title: this.props.intl.formatMessage({ id: `personLink.approval.saving` }),
                content: this.props.intl.formatMessage({ id: `personLink.approval.redirecting` }),
                okButtonProps: { hidden: true },
            });
            setTimeout(() => {
                modal.destroy();
                this.props.history.push(`/persons/${personLink.linkedPersonId}`);
            }, 3000);
        } catch (error) {
            errorService.displayMessage(error, [[409, 'personLink.error.duplicated']]);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleSelectPerson = (selectPerson: boolean) => {
        let person: Person;
        const personLink = Object.assign({}, this.state.personLink);
        if (selectPerson) {
            person = this.state.person && this.state.person.id ? this.state.person : this.state.persons[0];
            personLink.linkedPersonId = person.id;
        } else {
            person = {};
            personLink.linkedPersonId = undefined;
        }
        this.setState({ person, personLink });
    };

    handleChangePerson = (personId: number) => {
        const personLink = Object.assign({}, this.state.personLink);
        personLink.linkedPersonId = personId;
        this.setState({ personLink });
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        const personLink = this.state.personLink;
        const inviter = personLink.inviter as Entity;
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.links.approvals" />
                    </h1>
                    <p>
                        <FormattedHTMLMessage
                            id="personLink.approval.invitation"
                            values={{ user: inviter && inviter.fullName }}
                        />
                    </p>
                </div>
            </div>
        );
    };

    renderInvitation = (): JSX.Element => {
        const personLink = this.state.personLink;
        return <div className={styles.invitation}>{personLink.message}</div>;
    };

    renderPerson = (): JSX.Element => {
        return (
            <div className={styles.person}>
                {this.state.persons.length > 0 ? this.renderPersonSelector() : this.renderPersonLinked()}
            </div>
        );
    };

    renderPersonSelector = (): JSX.Element => {
        const personLink = this.state.personLink;
        const person = personLink.inviterPerson as PublicPerson;
        const name = person.fullName;
        const birthdate = moment(person.birthdate).format('DD MMMM YYYY');
        const personOptions = this.state.persons.map(person => (
            <Select.Option value={person.id} key={person.id}>
                {person.fullName} - {moment(person.birthdate).format('DD MMMM YYYY')}
            </Select.Option>
        ));

        return (
            <div className={styles.selector}>
                <p>
                    <FormattedHTMLMessage id="personLink.approval.person.selector" values={{ name, birthdate }} />
                </p>

                <div>
                    <Button
                        type="primary"
                        ghost={!this.state.person || !this.state.person.id}
                        size="large"
                        block={this.props.isXs || this.props.isSm}
                        onClick={() => this.handleSelectPerson(true)}
                    >
                        <FormattedMessage id="personLink.approval.person.selector.yes" />
                    </Button>
                    <Button
                        ghost={!this.state.person || !!this.state.person.id}
                        size="large"
                        block={this.props.isXs || this.props.isSm}
                        onClick={() => this.handleSelectPerson(false)}
                        className="alternative"
                    >
                        <FormattedMessage id="personLink.approval.person.selector.no" />
                    </Button>
                </div>

                <div hidden={!this.state.person || !this.state.person.id}>
                    <Select
                        size="large"
                        value={this.state.personLink.linkedPersonId}
                        className={styles.persons}
                        onChange={this.handleChangePerson}
                    >
                        {personOptions}
                    </Select>
                </div>
            </div>
        );
    };

    renderPersonLinked = (): JSX.Element => {
        if (this.state.personLink && this.state.personLink.inviterPerson && this.state.persons.length === 0) {
            const personLink = this.state.personLink;
            const inviter = personLink.inviter as Entity;
            const person = personLink.inviterPerson as PublicPerson;
            const birthdate = moment(person.birthdate).format('DD MMMM YYYY');
            return (
                <p>
                    <FormattedMessageComponent
                        id="personLink.approval.person.title"
                        values={{ user: inviter && inviter.fullName }}
                    />{' '}
                    <span className={styles.linked}>
                        {person.fullName} ({birthdate})
                    </span>
                </p>
            );
        } else {
            return <></>;
        }
    };

    renderButtons = (): JSX.Element => {
        return (
            <div className={styles.buttons}>
                <Button
                    type="primary"
                    size="large"
                    onClick={this.handleSave}
                    loading={this.state.actionStatus === 'saving'}
                    disabled={
                        (this.state.actionStatus && this.state.actionStatus !== 'saving') ||
                        (this.state.persons.length > 0 && !this.state.person)
                    }
                    data-test="submit"
                >
                    <FormattedMessage id="common.accept" />
                </Button>
                <Popconfirm
                    title={
                        <FormattedMessage
                            id="personLink.approval.confirm.delete"
                            values={{
                                user: this.state.personLink.inviter && this.state.personLink.inviter.fullName,
                            }}
                        />
                    }
                    onConfirm={this.handleDelete}
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                >
                    <Button
                        type="link"
                        size="large"
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                        data-test="delete"
                    >
                        <FormattedMessage id="common.reject" />
                    </Button>
                </Popconfirm>
            </div>
        );
    };

    render() {
        return (
            <Row gutter={[28, 24]} type="flex">
                <Col xs={24} xl={19} className={styles.dashboard}>
                    <div className="panel">
                        {this.renderHeader()}
                        {this.renderInvitation()}
                        {this.renderPerson()}
                        {this.renderButtons()}
                    </div>
                </Col>
                <Col xs={0} xl={5}>
                    <SidebarComponent />
                </Col>
            </Row>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(LinkApprovalPage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
}

interface State {
    personLink: PersonLink;
    persons: Person[];
    person?: Person;
    status?: Status;
    actionStatus?: ActionStatus;
}
