import moment, { Moment } from 'moment';
import {
    Person,
    UserMeasure,
    UserChart,
    TemplateChart,
    StandardSeries,
    Unit,
    StandardChart,
    StandardMeasure,
} from '../model/model';
import unitService from './UnitService';
import axisService from './AxisService';
import dateService from './DateService';
import i18nService from './I18nService';

class ChartService {
    getOptions = (person: Person, userChart: UserChart, unit: string, labels: Moment[]) => {
        const isEmpty = userChart.series[0].measures.length === 0;
        const dateFormat = dateService.getDateFormat();
        const options: any = {
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            unit: 'month',
                        },
                        ticks: {
                            source: 'labels',
                            min: moment.min(labels),
                            max: moment.max(labels),
                            callback: function(value: string, index: number, values: any[]) {
                                const birthdate: Moment = person.birthdate as Moment;
                                const start: Moment = moment(values[0].value).utc();
                                const end: Moment = moment(values[values.length - 1].value).utc();
                                const current: Moment = moment(values[index].value).utc();
                                return axisService.getXAxisTickLabel(birthdate, start, end, current, isEmpty);
                            },
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            suggestedMax: this.getSuggestedMax(userChart, unit as Unit),
                            suggestedMin: this.getSuggestedMin(userChart, unit as Unit),
                        },
                    },
                ],
            },
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    title: (tooltipItems: any[], data: any): string => {
                        const date: Moment = data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
                        return dateService.getPeriodFromBirthdate(date, person);
                    },
                    beforeTitle: (tooltipItems: any[], data: any): string => {
                        const date: Moment = data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x;
                        const formmatedDate =
                            date.get('hour') > 0 || date.get('minute') > 0
                                ? date.format(dateFormat + ' HH:mm')
                                : date.format(dateFormat);
                        return formmatedDate;
                    },
                    label: (tooltipItem: any, data: any): string => {
                        const seriesName: string = data.datasets[tooltipItem.datasetIndex].label;
                        return ` ${seriesName}`;
                    },
                    afterLabel: (tooltipItem: any, data: any): string => {
                        const value: string = (+tooltipItem.value).toFixed(2);
                        return ` ${value} ${unit}`;
                    },
                },
            },
        };

        if (
            person.birthdate &&
            moment()
                .startOf('day')
                .diff(person.birthdate.clone().startOf('day'), 'years') >= 16
        ) {
            const label = i18nService.intl.formatMessage({ id: 'biometrics.chart.adult' });
            options.annotation = {
                annotations: [
                    {
                        type: 'line',
                        mode: 'vertical',
                        scaleID: 'x-axis-0',
                        value: person.birthdate && person.birthdate.clone().add(18, 'years'),
                        borderColor: '#000',
                        borderWidth: 1,
                        label: {
                            content: label,
                            enabled: true,
                            position: 'top',
                        },
                    },
                ],
            };
        } else {
            options.annotation = undefined;
        }

        return options;
    };

    private getSuggestedMax = (userChart: UserChart, unit: Unit): number | undefined => {
        let suggestedMax: number | undefined;
        switch (userChart.chartType) {
            case 'TEMPERATURE':
                suggestedMax = unit === 'FAHRENHEIT' ? 111.2 : 44;
                break;
            case 'BLOOD_SUGAR':
                suggestedMax = 380;
                break;
            case 'BLOOD_PRESSURE':
                suggestedMax = 220;
                break;
            default:
                break;
        }

        return suggestedMax;
    };

    private getSuggestedMin = (userChart: UserChart, unit: string): number | undefined => {
        let suggestedMin: number | undefined;
        switch (userChart.chartType) {
            case 'TEMPERATURE':
                suggestedMin = unit === 'FAHRENHEIT' ? 87.8 : 31;
                break;
            case 'BLOOD_SUGAR':
                suggestedMin = 50;
                break;
            case 'BLOOD_PRESSURE':
                suggestedMin = 40;
                break;

            default:
                break;
        }

        return suggestedMin;
    };

    getDatasets = (person: Person, userChart: UserChart): any[] => {
        const defaulDataset: any = {
            fill: false,
            lineTension: 0.4,
            borderColor: '#000',
            borderWidth: 1,
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            label: person.fullName || '-',
        };

        return this.createPersonDatasets(defaulDataset, userChart);
    };

    getTemplateDatasets = (templateChart: TemplateChart, person: Person): any[] => {
        let templateDatasets: any[];
        if (templateChart.type === 'standard') {
            templateDatasets = this.getStandardDatasets(templateChart.chart as StandardChart, person);
        } else {
            templateDatasets = this.getUserDatasets(templateChart.chart as UserChart, templateChart.name);
        }

        return templateDatasets;
    };

    private getUserDatasets = (userChart: UserChart, name: string): any[] => {
        const defaulDataset: any = {
            fill: false,
            lineTension: 0.4,
            borderColor: 'blue',
            borderWidth: 0.5,
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0.5,
            pointHitRadius: 10,
            label: name + '*',
            key: name,
        };

        return this.createPersonDatasets(defaulDataset, userChart);
    };

    private getStandardDatasets = (standardChart: StandardChart, person: Person): any[] => {
        const defaulDataset: any = {
            fill: false,
            lineTension: this.getStandardChartLineTension(standardChart.chartType),
            borderWidth: 0.5,
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointRadius: 0,
            pointHitRadius: 10,
        };

        return this.createStandardDatasets(defaulDataset, standardChart, person);
    };

    private getStandardChartLineTension = (chartType: string): number => {
        return chartType === 'BLOOD_PRESSURE' ? 0 : 0.4;
    };

    private createPersonDatasets = (defaultUserDataset: any, userChart: UserChart): any[] => {
        const datasets = [];
        if (userChart && userChart.chartType === 'BLOOD_PRESSURE') {
            const systolicDataset = Object.assign({}, defaultUserDataset);
            systolicDataset.label = `${systolicDataset.label} (${i18nService.intl.formatMessage({
                id: 'bloodPressure.systolic',
            })})`;
            systolicDataset.data = userChart.series[0].measures.map(um =>
                this.getPersonDatasetValue(um, () => um.value),
            );
            datasets.push(systolicDataset);
            const diastolicDataset = Object.assign({}, defaultUserDataset);
            diastolicDataset.label = `${diastolicDataset.label} (${i18nService.intl.formatMessage({
                id: 'bloodPressure.diastolic',
            })})`;
            diastolicDataset.data = userChart.series[0].measures.map(um =>
                this.getPersonDatasetValue(um, () => um.secondaryValue),
            );
            datasets.push(diastolicDataset);
        } else {
            const dataset = Object.assign({}, defaultUserDataset);
            dataset.data = userChart.series[0].measures.map(um =>
                this.getPersonDatasetValue(um, () => unitService.getTotalMeasure(um)),
            );
            datasets.push(dataset);
        }

        // sort datasets
        datasets.forEach(ud => ud.data.sort((a: any, b: any) => (a.x as Moment).valueOf() - (b.x as Moment).valueOf()));

        return datasets;
    };

    private createStandardDatasets = (defaultUserDataset: any, standardChart: StandardChart, person: Person): any[] => {
        const datasets: any[] = [];
        for (const series of standardChart.series) {
            const dataset = Object.assign({}, defaultUserDataset);
            dataset.borderColor = this.getSeriesColor(series);
            dataset.label = this.getSeriesName(series);
            dataset.data = series.measures.map(sm => this.getStandardDatasetValue(sm, person));
            datasets.push(dataset);
        }

        // sort datasets
        datasets.forEach(sd => sd.data.sort((a: any, b: any) => (a.x as Moment).valueOf() - (b.x as Moment).valueOf()));

        return datasets;
    };

    private getStandardDatasetValue = (standardMeasure: StandardMeasure, person: Person): any => {
        return {
            x: dateService.getDateFromPeriod(person.birthdate as Moment, standardMeasure.period),
            y: unitService.getTotalMeasure(standardMeasure),
        };
    };

    private getPersonDatasetValue = (userMeasure: UserMeasure, calculateValue: () => number | undefined): any => {
        const x = userMeasure.date;
        if (x && userMeasure.time) {
            x.set('hour', userMeasure.time.get('hour'));
            x.set('minute', userMeasure.time.get('minute'));
        }

        return {
            x: userMeasure.date,
            y: calculateValue(),
        };
    };

    getXScaleLabels = (birthdate: Moment, userChart: UserChart, full?: boolean): any => {
        let minDate: Moment | undefined;
        if (userChart.series[0].measures.length > 0) {
            minDate = moment.min(userChart.series[0].measures.map(m => m.date) as Moment[]);
        }
        return axisService.getXAxisTicks(birthdate, minDate, moment().startOf('day'), full);
    };

    getSeriesName = (standardSeries: StandardSeries): any => {
        let seriesName: string = standardSeries.name;
        if (
            [
                'verySeverelyUnderweight',
                'severelyUnderweight',
                'underweight',
                'normal',
                'overweight',
                'moderatelyObese',
                'severelyObese',
                'verySeverelyObese',
            ].includes(seriesName)
        ) {
            seriesName = i18nService.intl.formatMessage({ id: `bmi.level.${seriesName}` });
        }

        return seriesName;
    };

    getSeriesColor = (standardSeries: StandardSeries): any => {
        let seriesColor: string = 'blue';
        if (['P3', 'P97', 'SD3neg', 'SD3'].includes(standardSeries.name)) {
            seriesColor = '#ff0000';
        } else if (['P15', 'P85', 'SD2neg', 'SD2'].includes(standardSeries.name)) {
            seriesColor = '#ff4500';
        } else if (['P50', 'SD0', 'underweight', 'overweight'].includes(standardSeries.name)) {
            seriesColor = '#008000';
        } else {
            seriesColor = '#ff4500';
        }

        return seriesColor;
    };

    sort = (userMeasures: UserMeasure[]): UserMeasure[] => {
        return userMeasures.sort((a, b) => {
            const aDate = a.date as Moment;
            const bDate = b.date as Moment;
            const aTime = a.time || moment('00:00', 'HH:mm');
            const bTime = b.time || moment('00:00', 'HH:mm');

            let result = aDate.isBefore(bDate) ? 1 : 0;
            if (result === 0) {
                result = aDate.isAfter(bDate) ? -1 : 0;
            }
            if (result === 0) {
                result = aTime.isBefore(bTime) ? 1 : 0;
            }
            if (result === 0) {
                result = aTime.isAfter(bTime) ? -1 : 0;
            }

            return result;
        });
    };

    filterDuplicated = (userMeasures: UserMeasure[], userMeasure: UserMeasure): UserMeasure[] => {
        return userMeasures.filter(um => {
            let result = true;
            if (um.id === userMeasure.id) {
                result = false;
            } else if (moment(um.date).isSame(moment(userMeasure.date), 'date')) {
                if (!um.time && !userMeasure.time) {
                    result = false;
                } else if (um.time && moment(um.time).isSame(moment(userMeasure.time))) {
                    result = false;
                } else if (userMeasure.time && moment(userMeasure.time).isSame(moment(um.time))) {
                    result = false;
                }
            }

            return result;
        });
    };
}

const chartService: ChartService = new ChartService();
export default chartService;
