import React, { Component } from 'react';
import styles from './LinksPage.module.scss';
import { Button, message, List, Icon, Empty } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import {
    PersonLink,
    Status,
    Person,
    Page,
    ActionStatus,
    ScreenSizeProps,
    User,
    PublicUser,
} from '../../../model/model';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import personLinkApi from '../../../api/PersonLinkApi';
import { Link } from 'react-router-dom';
import AvatarComponent from '../../Shared/AvatarComponent/AvatarComponent';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import FormattedMessageComponent from '../../Shared/FormattedMessageComponent';
import nameService from '../../../service/NameService';
import Search from 'antd/lib/input/Search';
import i18nService from '../../../service/I18nService';

class LinksPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            personLinks: [],
            page: 0,
            last: true,
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list(this.state.page);
    };

    list = async (page: number, searchText?: string) => {
        const personLinksPage: Page<PersonLink> = await personLinkApi.list(
            this.props.person.id as number,
            page,
            20,
            searchText,
        );
        const personLinks: PersonLink[] =
            page === 0 ? personLinksPage.content : this.state.personLinks.concat(personLinksPage.content);
        const last: boolean = personLinksPage.last;
        this.setState({ personLinks, page, last, searchText });
    };

    /** HANDLERS **/

    handleSearch = async (searchText: string) => {
        try {
            message.destroy();
            this.setState({ status: 'loading' });
            await this.list(0, searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    };

    handleLoadMore = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'loading' });
            await this.list(this.state.page + 1, this.state.searchText);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.search}>{this.renderSearch()}</div>
                <div className={styles.add}>{this.renderCreateButton()}</div>
            </div>
        );
    };

    renderSearch = (): JSX.Element => {
        const placeholder: string = this.props.intl.formatMessage({
            id: i18nService.getCustomMessage('personLinks.search.placeholder', this.context.user),
        });
        return <Search placeholder={placeholder} onSearch={this.handleSearch} size="large" className={styles.search} />;
    };

    renderCreateButton = (): JSX.Element => {
        return (
            <Link to={`/persons/${this.props.person.id}/links/new`} data-test="add">
                <Button type="primary" size="large" icon="plus">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                </Button>
            </Link>
        );
    };

    renderLinkDescription = (personLink: PersonLink): JSX.Element => {
        if (personLink.linkedPersonId) {
            const inviter = personLink.inviter as PublicUser;
            const user = this.context.user as User;
            if (inviter.id === user.entity!.id) {
                return (
                    <>
                        {personLink.profile && <Icon type="user" className={styles.tag} />}
                        {personLink.diaryRecords && <Icon type="read" className={styles.tag} />}
                        {personLink.diaryAppointments && <Icon type="schedule" className={styles.tag} />}
                        {personLink.diaryMedications && <Icon type="medicine-box" className={styles.tag} />}
                        {personLink.biometrics && <Icon type="line-chart" className={styles.tag} />}
                        {personLink.documents && <Icon type="history" className={styles.tag} />}
                    </>
                );
            } else {
                return (
                    <>
                        {personLink.linkedProfile && <Icon type="user" className={styles.tag} />}
                        {personLink.linkedDiaryRecords && <Icon type="read" className={styles.tag} />}
                        {personLink.linkedDiaryAppointments && <Icon type="schedule" className={styles.tag} />}
                        {personLink.linkedDiaryMedications && <Icon type="medicine-box" className={styles.tag} />}
                        {personLink.linkedBiometrics && <Icon type="line-chart" className={styles.tag} />}
                        {personLink.linkedDocuments && <Icon type="history" className={styles.tag} />}
                    </>
                );
            }
        } else {
            return <FormattedMessage id="personLink.status.pending" />;
        }
    };

    renderLoadMore = (): JSX.Element => {
        if (this.state.last) {
            return <></>;
        } else {
            return (
                <div className={styles.load}>
                    <Button onClick={this.handleLoadMore} loading={this.state.actionStatus === 'loading'}>
                        <FormattedMessage id="common.loadMore" />
                    </Button>
                </div>
            );
        }
    };

    renderItem = (personLink: PersonLink): JSX.Element => {
        const name = nameService.getCorrespondingUserName(personLink, this.context.user as User);

        return (
            <Link to={`/persons/${this.props.person.id}/links/${personLink.id}`} className={styles.description}>
                <List.Item data-test="item">
                    <List.Item.Meta
                        avatar={<AvatarComponent name={name} />}
                        title={name}
                        description={this.renderLinkDescription(personLink)}
                    />
                </List.Item>
            </Link>
        );
    };

    renderList = (): JSX.Element => {
        return (
            <>
                <List
                    loading={this.state.status === 'loading'}
                    itemLayout="horizontal"
                    locale={{
                        emptyText: (
                            <Empty
                                className="empty"
                                description={
                                    <div data-test="empty">
                                        <span>
                                            <FormattedMessageComponent id="personLinks.empty" />
                                        </span>
                                        <Link to={`/persons/${this.props.person.id}/links/new`}>
                                            <Button size="large" type="primary" ghost>
                                                <FormattedMessageComponent id="personLinks.empty.add" />
                                            </Button>
                                        </Link>
                                    </div>
                                }
                            />
                        ),
                    }}
                    dataSource={this.state.personLinks}
                    loadMore={this.renderLoadMore()}
                    renderItem={personLink => this.renderItem(personLink)}
                />
            </>
        );
    };

    render() {
        return (
            <>
                {this.renderToolbar()}
                {this.renderList()}
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(LinksPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    person: Person;
}

interface State {
    personLinks: PersonLink[];
    searchText?: string;
    page: number;
    last: boolean;
    status?: Status;
    actionStatus?: ActionStatus;
}
