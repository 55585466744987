import { Measure, Person, BmrActivityLevelType } from '../model/model';
import unitService from './UnitService';
import moment, { Moment } from 'moment';

class BmrService {
    calculateBmr = (height: Measure, weight: Measure, person: Person, activity: BmrActivityLevelType): Measure => {
        const heightIMS = unitService.convertMeasure(height, unitService.getUnitTypeByValue('CM'));
        const weightIMS = unitService.convertMeasure(weight, unitService.getUnitTypeByValue('KG'));
        const birthdate = person.birthdate as Moment;
        const gender = person.gender as string;
        const age = moment()
            .startOf('day')
            .diff(birthdate.clone().startOf('day'), 'years');

        let value: number;
        if (gender === 'MALE') {
            value = 66.47 + 13.75 * (weightIMS.value as number) + 5.003 * (heightIMS.value as number) - 6.755 * age;
        } else {
            value = 655.1 + 9.563 * (weightIMS.value as number) + 1.85 * (heightIMS.value as number) - 4.676 * age;
        }
        value = value * this.getBmrActivityFactor(activity);

        const unit: string = 'KCAL_DAY';

        return { value, unit };
    };

    getBmrActivityByLevel = (level: number): BmrActivityLevelType => {
        let activity: BmrActivityLevelType;
        switch (level) {
            case 0:
                activity = 'none';
                break;
            case 1:
                activity = 'light';
                break;
            case 2:
                activity = 'moderate';
                break;
            case 3:
                activity = 'veryActive';
                break;
            case 4:
                activity = 'extraActive';
                break;
            default:
                activity = 'none';
                break;
        }

        return activity;
    };

    getBmrActivityFactor = (activity: BmrActivityLevelType): number => {
        let factor: number;
        switch (activity) {
            case 'none':
                factor = 1.2;
                break;
            case 'light':
                factor = 1.375;
                break;
            case 'moderate':
                factor = 1.55;
                break;
            case 'veryActive':
                factor = 1.725;
                break;
            case 'extraActive':
                factor = 1.9;
                break;
            default:
                factor = 1;
                break;
        }

        return factor;
    };
}

const bmrService = new BmrService();
export default bmrService;
