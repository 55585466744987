import { apiNoAuthAxios } from './CustomAxios';
import { CoronavirusTest } from '../model/model';
import { AxiosResponse } from 'axios';

class CoronavirusTestApi {
    create = async (coronavirusTest: CoronavirusTest): Promise<CoronavirusTest> => {
        const response: AxiosResponse<CoronavirusTest> = await apiNoAuthAxios.post(
            '/coronavirus-tests',
            coronavirusTest,
        );
        return response.data;
    };
}

const coronavirusTestApi: CoronavirusTestApi = new CoronavirusTestApi();
export default coronavirusTestApi;
