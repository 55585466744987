import React, { Component } from 'react';
import styles from './MessagesPage.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import { Status, ScreenSizeProps, Chat, ChatMember, Person, ChatMessage } from '../../model/model';
import { List, Row, Col, Badge, Empty } from 'antd';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import chatApi from '../../api/ChatApi';
import { Link } from 'react-router-dom';
import AvatarComponent from '../Shared/AvatarComponent/AvatarComponent';
import personApi from '../../api/PersonApi';
import i18nService from '../../service/I18nService';
import moment, { Moment } from 'moment';

class MessagesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            chats: [],
            persons: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list();
    };

    list = async () => {
        let chats = await chatApi.list();
        chats = chats.sort((a, b) => {
            let result;
            if (!a.lastMessage || !b.lastMessage) {
                result = a.lastMessage ? -1 : 1;
            } else {
                const lastMessageA = a.lastMessage as ChatMessage;
                const lastMessageB = b.lastMessage as ChatMessage;
                result = (lastMessageA.sent as Moment).isAfter(lastMessageB.sent as Moment) ? -1 : 1;
            }

            return result;
        });
        const personsPage = await personApi.list(0, 1000); // TODO: limited to 1000 persons, need to be reviewed
        const persons = personsPage.content;
        this.setState({ chats, persons });
    };

    getChatLastMessageDesc = (chat: Chat): string => {
        let lastMessage: string;
        if (chat.lastMessage) {
            const maxLength = this.props.isXs || this.props.isSm ? 26 : 80;
            lastMessage = chat.lastMessage.body;
            if (lastMessage && lastMessage.length > maxLength) {
                lastMessage = lastMessage.substring(0, maxLength - 3) + '...';
            }
        } else {
            const persons = this.state.persons;
            const personIds = persons.map(p => p.id);
            const currentMember = chat.members.find(m => personIds.includes(m.personId as number)) as ChatMember;
            const person = persons.find(p => p.id === currentMember.personId);
            const personName = person ? person.fullName : '';
            lastMessage = i18nService.intl.formatMessage({ id: 'messages.message.desc' }, { person: personName });
        }

        return lastMessage;
    };

    getDate = (lastMessage?: ChatMessage): string => {
        let date = '';
        if (lastMessage && lastMessage.sent && lastMessage.sent.isSame(moment(), 'day')) {
            date = lastMessage.sent.format('HH:mm');
        } else if (lastMessage && lastMessage.sent) {
            date = lastMessage.sent.fromNow();
        }

        return date;
    };

    /** COMPONENTS */

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.messages" />
                    </h1>
                    <p>
                        <FormattedMessage id="navigation.messages.desc" />
                    </p>
                </div>
            </div>
        );
    };

    renderItem = (chat: Chat): JSX.Element => {
        const persons = this.state.persons;
        const personIds = persons.map(p => p.id);
        const currentMember = chat.members.find(m => personIds.includes(m.personId as number)) as ChatMember;
        const correspondingMember = chat.members.find(m => !personIds.includes(m.personId as number)) as ChatMember;
        const description = this.getChatLastMessageDesc(chat);
        const date = this.getDate(chat.lastMessage);

        return (
            <Link to={`/persons/${currentMember.personId}/chats/${chat.id}`} className={styles.description}>
                <List.Item
                    actions={[
                        <Badge count={chat.unread} overflowCount={9} offset={[-8, 30]} key="date">
                            {date}
                        </Badge>,
                    ]}
                    className={styles.item}
                >
                    <List.Item.Meta
                        avatar={<AvatarComponent name={correspondingMember.currentName} />}
                        title={correspondingMember.currentName}
                        description={<span className={styles.desc}>{description}</span>}
                    />
                </List.Item>
            </Link>
        );
    };

    renderList = (): JSX.Element => {
        return (
            <List
                loading={this.state.status === 'loading'}
                itemLayout="horizontal"
                dataSource={this.state.chats}
                locale={{
                    emptyText: <Empty className="empty" description={<FormattedMessage id="messages.empty" />} />,
                }}
                renderItem={chat => this.renderItem(chat)}
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[28, 24]} type="flex">
                    <Col xs={24} xl={19} className={styles.messages}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderList()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(MessagesPage)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {}

interface State {
    chats: Chat[];
    persons: Person[];
    status?: Status;
}
