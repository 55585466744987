import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Notification } from '../model/model';

class NotificationApi {
    list = async (): Promise<Notification[]> => {
        const response: AxiosResponse<Notification[]> = await apiAxios.get<Notification[]>('/notifications');
        return response.data;
    };

    count = async (): Promise<number> => {
        const response: AxiosResponse<number> = await apiAxios.get<number>(`/notifications/count`);
        return response.data;
    };

    read = async (): Promise<void> => {
        const response: AxiosResponse<void> = await apiAxios.put(`/notifications/read`);
        return response.data;
    };
}

const notificationApi: NotificationApi = new NotificationApi();
export default notificationApi;
