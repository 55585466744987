import React, { Component } from 'react';
import styles from './SubscriptionPage.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import {
    Plan,
    Status,
    ScreenSizeProps,
    Subscription,
    PlanStatus,
    ActionStatus,
    PaymentMethod,
    User,
    Customer,
} from '../../../model/model';
import planApi from '../../../api/PlanApi';
import { Button, Result, Form, Row, Col } from 'antd';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import responsiveService from '../../../service/ResponsiveService';
import subscriptionApi from '../../../api/SubscriptionApi';
import planService from '../../../service/PlanService';
import { FormComponentProps } from 'antd/lib/form';
import SubscriptionActivate from './SubscriptionActivate/SubscriptionActivate';
import SubscriptionCancel from './SubscriptionCancel/SubscriptionCancel';
import SubscriptionPlan from './SubscriptionPlan/SubscriptionPlan';
import SubscriptionPaymentChange from './SubscriptionPaymentChange/SubscriptionPaymentChange';
import paymentMethodApi from '../../../api/PaymentMethodApi';
import SubscriptionPayment from './SubscriptionPayment/SubscriptionPayment';
import SubscriptionUpgrade from './SubscriptionUpgrade/SubscriptionUpgrade';
import SubscriptionBilling from './SubscriptionBilling/SubscriptionBilling';
import userApi from '../../../api/UserApi';
import customerApi from '../../../api/CustomerApi';
import SubscriptionEnd from './SubscriptionEnd/SubscriptionEnd';
import Loader from '../../Loader/Loader';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { StripeElements, Stripe } from '@stripe/stripe-js';

class SubscriptionPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            plan: {},
            paymentMethods: [],
            status: 'loading',
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const plans: Plan[] = await planApi.list();
        const plan: Plan = plans.find(p => p.id === this.props.match.params.id) as Plan;
        this.setState({ plan });

        const user: User = await userApi.getCurrentUser();
        if (user.stripeCustomerId) {
            const customer: Customer = await customerApi.get(user.stripeCustomerId);
            const subscriptions: Subscription[] = await subscriptionApi.list();
            const subscription: Subscription | undefined = subscriptions.length > 0 ? subscriptions[0] : undefined;
            const paymentMethods: PaymentMethod[] = await this.listPaymentMethods();
            this.setState({ customer, subscription, paymentMethods });
        }
    };

    listPaymentMethods = async (): Promise<PaymentMethod[]> => {
        return await paymentMethodApi.list();
    };

    /** HANDLERS **/

    handlePayment = async (): Promise<void> => {
        this.setState({ status: 'subscribed' });
    };

    handleUpgrade = async (): Promise<void> => {
        this.setState({ status: 'upgraded' });
    };

    handleBillingSave = (customer: Customer): void => {
        this.setState({ customer });
    };

    handlePaymentMethodChange = async (): Promise<void> => {
        try {
            const paymentMethods: PaymentMethod[] = await this.listPaymentMethods();
            this.setState({ paymentMethods });
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleActivation = (subscription: Subscription): void => {
        this.setState({ subscription });
    };

    handleCancellation = (subscription: Subscription): void => {
        this.setState({ subscription });
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1 data-test="planName">
                        <FormattedMessage id="navigation.subscriptions" />
                    </h1>
                    <p></p>
                </div>
            </div>
        );
    };

    renderSubscriptionResult = (): JSX.Element => {
        return (
            <Result
                status="success"
                title={<FormattedMessage id={`subscription.${this.state.status}.success.title`} />}
                subTitle={<FormattedMessage id={`subscription.${this.state.status}.success.subtitle`} />}
                extra={[
                    <Link to="/dashboard" key="home">
                        <Button type="primary" size="large">
                            <FormattedMessage id={`subscription.${this.state.status}.success.button`} />
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    renderSubscriptionPanel = (): JSX.Element => {
        const planStatus: PlanStatus = planService.getPlanStatus(this.state.plan, this.state.subscription);
        if (this.state.status === 'loading') {
            return <Loader />;
        } else if (this.state.status) {
            return this.renderSubscriptionResult();
        } else if (planStatus === 'included') {
            return (
                <>
                    <SubscriptionPlan plan={this.state.plan} planStatus={planStatus} />
                </>
            );
        } else if (planStatus === 'active') {
            const subscription: Subscription = this.state.subscription as Subscription;
            return (
                <>
                    <SubscriptionPlan plan={this.state.plan} planStatus={planStatus} />
                    <SubscriptionEnd subscription={subscription} />
                    <SubscriptionBilling customer={this.state.customer} handleSave={this.handleBillingSave} />
                    <ElementsConsumer>
                        {({ elements, stripe }) => (
                            <SubscriptionPaymentChange
                                subscription={subscription}
                                paymentMethods={this.state.paymentMethods}
                                handlePaymentMethodChange={this.handlePaymentMethodChange}
                                stripe={stripe as Stripe}
                                elements={elements as StripeElements}
                            />
                        )}
                    </ElementsConsumer>
                    <SubscriptionCancel subscription={subscription} handleCancellation={this.handleCancellation} />
                    <SubscriptionActivate subscription={subscription} handleActivation={this.handleActivation} />
                </>
            );
        } else if (planStatus === 'upgrade') {
            const subscription: Subscription = this.state.subscription as Subscription;
            return (
                <>
                    <SubscriptionPlan plan={this.state.plan} planStatus={planStatus} />
                    <SubscriptionUpgrade
                        plan={this.state.plan}
                        subscription={subscription}
                        handleUpgrade={this.handleUpgrade}
                    />
                </>
            );
        } else {
            return (
                <>
                    <SubscriptionPlan plan={this.state.plan} planStatus={planStatus} />
                    <ElementsConsumer>
                        {({ elements, stripe }) => (
                            <SubscriptionPayment
                                plan={this.state.plan}
                                customer={this.state.customer}
                                handlePayment={this.handlePayment}
                                stripe={stripe as Stripe}
                                elements={elements as StripeElements}
                            />
                        )}
                    </ElementsConsumer>
                </>
            );
        }
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19}>
                            <div className="panel">
                                {this.renderHeader()}
                                <div className={styles.subscription}>{this.renderSubscriptionPanel()}</div>
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(Form.create()(withRouter(SubscriptionPage))));

interface Props extends FormComponentProps, WrappedComponentProps, RouteComponentProps, ScreenSizeProps {
    match: any;
}

interface State {
    plan: Plan;
    customer?: Customer;
    subscription?: Subscription;
    paymentMethods: PaymentMethod[];
    status?: Status | 'subscribed' | 'upgraded';
    actionStatus?: ActionStatus;
}
