import apiAxios from './CustomAxios';
import { AxiosResponse } from 'axios';
import { Page, Medicine } from '../model/model';
import moment from 'moment';

class MedicineApi {
    list = async (country: string, page: number, size: number, searchText?: string): Promise<Page<Medicine>> => {
        const response: AxiosResponse<Page<Medicine>> = await apiAxios.get<Page<Medicine>>('/medicines', {
            params: { country, page, size, searchText },
        });
        response.data.content.forEach(p => (p.updated = moment(p.updated)));
        return response.data;
    };

    get = async (id: number): Promise<Medicine> => {
        const response: AxiosResponse<Medicine> = await apiAxios.get<Medicine>(`/medicines/${id}`);
        response.data.updated = moment(response.data.updated);
        return response.data;
    };
}

const medicineApi: MedicineApi = new MedicineApi();
export default medicineApi;
