import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import bmiService from '../../service/BmiService';
import { BmiMeasure, Measure } from '../../model/model';
import unitService from '../../service/UnitService';

export const IdealWeightComponent: React.FC<Props> = props => {
    const heightMeasure = props.bmiMeasure.height as Measure;
    const weightMeasure = props.bmiMeasure.weight as Measure;
    const weightUnit = unitService.getUnitTypeByUserMeasure(weightMeasure);
    const weightSecondaryUnit = unitService.getSecondaryUnitTypeByUnitType(weightUnit);
    const idealWeightMeasure: Measure = bmiService.calculateBmiIdealWeight(heightMeasure, weightUnit, props.bmi);

    if (weightSecondaryUnit && idealWeightMeasure.secondaryValue) {
        return (
            <FormattedMessage
                id="idealWeight.composed"
                values={{
                    weight: (
                        <FormattedNumber
                            value={idealWeightMeasure.value as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                        />
                    ),
                    unit: weightUnit.label,
                    secondaryWeight: (
                        <FormattedNumber
                            value={idealWeightMeasure.secondaryValue}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                        />
                    ),
                    secondaryUnit: weightSecondaryUnit.label,
                }}
            />
        );
    } else {
        return (
            <FormattedMessage
                id="idealWeight.simple"
                values={{
                    weight: (
                        <FormattedNumber
                            value={idealWeightMeasure.value as number}
                            minimumFractionDigits={0}
                            maximumFractionDigits={2}
                        />
                    ),
                    unit: weightUnit.label,
                }}
            />
        );
    }
};

interface Props {
    bmiMeasure: BmiMeasure;
    bmi?: number;
}
