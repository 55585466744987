import apiAxios from './CustomAxios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { Document, Page, DocumentFile, DocumentsBySpeciality } from '../model/model';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import FileSaver from 'file-saver';

class DocumentApi {
    list = async (
        personId: number,
        page: number,
        size: number,
        medicalSpeciality?: string,
        unclassified?: boolean,
        searchText?: string,
    ): Promise<Page<Document>> => {
        const response: AxiosResponse<Page<Document>> = await apiAxios.get<Page<Document>>('/documents', {
            params: { personId, page, size, medicalSpeciality, unclassified, searchText },
        });
        response.data.content.forEach(d => (d.documentDate = moment(d.documentDate)));
        return response.data;
    };

    listDocumentsBySpeciality = async (personId: number): Promise<DocumentsBySpeciality[]> => {
        const response: AxiosResponse<DocumentsBySpeciality[]> = await apiAxios.get<DocumentsBySpeciality[]>(
            '/documents/specialities',
            {
                params: { personId },
            },
        );
        response.data.forEach(d => (d.lastDate = moment(d.lastDate)));
        return response.data;
    };

    get = async (id: number): Promise<Document> => {
        const response: AxiosResponse<Document> = await apiAxios.get<Document>(`/documents/${id}`);
        response.data.documentDate = moment(response.data.documentDate).utc();
        return response.data;
    };

    create = async (document: Document, files: RcFile[]): Promise<Document> => {
        const formData: FormData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });
        formData.append('name', document.name!);
        formData.append('documentType', document.documentType!);
        formData.append('documentDate', document.documentDate!.format('YYYY-MM-DD'));
        if (document.medicalSpeciality) {
            formData.append('medicalSpeciality', document.medicalSpeciality!);
        }
        formData.append('remarks', document.remarks || '');
        formData.append('report', document.report || '');
        formData.append('personId', document.personId!.toString());
        const response: AxiosResponse<Document> = await apiAxios.post<Document>('/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        response.data.documentDate = moment(response.data.documentDate).utc();
        return response.data;
    };

    update = async (document: Document): Promise<Document> => {
        const response: AxiosResponse<Document> = await apiAxios.put<Document>(`/documents/${document.id}`, document);
        response.data.documentDate = moment(response.data.documentDate).utc();
        return response.data;
    };

    delete = async (document: Document): Promise<void> => {
        const response: AxiosResponse = await apiAxios.delete(`/documents/${document.id}`);
        return response.data;
    };

    getReport = async (document: Document): Promise<void> => {
        const config: AxiosRequestConfig = { responseType: 'blob' };
        const response: AxiosResponse<Blob> = await apiAxios.get<Blob>(`/documents/${document.id}/report`, config);
        FileSaver.saveAs(response.data, `${document.name}.pdf`);
    };

    getFiles = async (document: Document): Promise<void> => {
        const config: AxiosRequestConfig = { responseType: 'blob' };
        const response: AxiosResponse<Blob> = await apiAxios.get<Blob>(`/documents/${document.id}/files`, config);
        FileSaver.saveAs(response.data, `${document.name}.zip`);
    };

    getFile = async (documentFile: DocumentFile): Promise<void> => {
        const config: AxiosRequestConfig = { responseType: 'blob' };
        const response: AxiosResponse<Blob> = await apiAxios.get<Blob>(
            `/documents/${documentFile.documentId}/files/${documentFile.id}`,
            config,
        );
        FileSaver.saveAs(response.data, documentFile.name!);
    };

    countFileSizes = async (): Promise<number> => {
        const response: AxiosResponse<number> = await apiAxios.get<number>(`/documents/file-size-count`);
        return response.data;
    };
}

const documentApi: DocumentApi = new DocumentApi();
export default documentApi;
