import { PersonLink, PublicUser, User } from '../model/model';

class NameService {
    getFullName = (firstName: string = '', lastName: string = '', abbreviate?: boolean): string => {
        const formattedFirstName = firstName || '';
        const formattedLastName = lastName || '';

        let fullName: string;
        if (abbreviate) {
            fullName = `${formattedFirstName.charAt(0)}. ${formattedLastName}`;
            if (fullName.length > 7) {
                fullName = fullName.substring(0, 7);
            }
        } else {
            fullName = `${formattedFirstName} ${formattedLastName}`;
        }

        return fullName;
    };

    getFormalFullName = (firstName: string = '', lastName: string = ''): string => {
        const formattedFirstName = firstName || '';
        const formattedLastName = lastName || '';

        return `${formattedLastName}, ${formattedFirstName}`;
    };

    getInitial = (name: string): string => {
        return name.charAt(0).toUpperCase();
    };

    getBackgroundColor = (name: string): string => {
        const initial: string = this.getInitial(name);
        let color: string = 'rgba(75, 64, 122, 0.65)';
        if (initial >= 'W') {
            color = 'rgba(154, 127, 168, 0.65)';
        } else if (initial >= 'S') {
            color = 'rgba(77, 94, 126, 0.65)';
        } else if (initial >= 'O') {
            color = 'rgba(157, 150, 216, 0.65)';
        } else if (initial >= 'L') {
            color = 'rgba(146, 102, 138, 0.65)';
        } else if (initial >= 'H') {
            color = 'rgba(77, 94, 126, 0.65)';
        } else if (initial >= 'D') {
            color = 'rgba(154, 127, 168, 0.65)';
        }

        return color;
    };

    getCorrespondingUserName = (personLink: PersonLink, user: User): string => {
        const inviter = personLink.inviter as PublicUser;

        let name: string;
        if (inviter.id === user.entity!.id) {
            if (personLink.approver && (personLink.approver.firstName || personLink.approver.lastName)) {
                name = `${personLink.approver.fullName} (${personLink.email})`;
            } else {
                name = personLink.email as string;
            }
        } else {
            name = inviter.fullName as string;
        }

        return name;
    };
}

const nameService: NameService = new NameService();
export default nameService;
