import React, { Component, FormEvent } from 'react';
import styles from './PasswordResetPage.module.scss';
import { Button, Result, Input, Icon } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import userApi from '../../../api/UserApi';
import { ActionStatus, UserEmail } from '../../../model/model';
import Form, { FormComponentProps } from 'antd/lib/form';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import errorService from '../../../service/ErrorService';

class PasswordResetPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    resetPassword = async (values: any) => {
        const userEmail: UserEmail = {
            email: values.email,
        };

        await userApi.resetPassword(userEmail);
        this.setState({ status: 'success' });
    };

    /** HANDLERS **/

    handleResetPassword = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error, values) => {
            try {
                this.setState({ actionStatus: 'saving' });
                !error && (await this.resetPassword(values));
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    /** COMPONENTS **/

    renderPasswordReset = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={styles.layout}>
                <h1>
                    <FormattedMessage id="passwordReset.title" />
                </h1>
                <p>
                    <FormattedMessage id="passwordReset.subtitle" />
                </p>
                <Form onSubmit={this.handleResetPassword}>
                    <Form.Item label={<FormattedMessage id="passwordReset.email" />}>
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    required: true,
                                    message: <FormattedMessage id="passwordReset.email.error.required" />,
                                },
                                { type: 'email', message: <FormattedMessage id="passwordReset.email.error.invalid" /> },
                            ],
                        })(<Input prefix={<Icon type="user" className={styles.inputIcon} />} maxLength={100} />)}
                    </Form.Item>
                    <div className={styles.buttons}>
                        <Button type="primary" htmlType="submit" loading={this.state.actionStatus === 'saving'}>
                            <FormattedMessage id="passwordReset.resetPassword" />
                        </Button>
                        <Link to="/signin">
                            <Button type="link">
                                <FormattedMessage id="common.back" />
                            </Button>
                        </Link>
                    </div>
                </Form>
            </div>
        );
    };

    renderPasswordResetSuccess = (): JSX.Element => {
        return (
            <Result
                status="success"
                title={<FormattedMessage id="passwordReset.success.title" />}
                subTitle={<FormattedMessage id="passwordReset.success.subtitle" />}
                extra={[
                    <Link to="/" key="home">
                        <Button type="primary" size="large">
                            {<FormattedMessage id="passwordReset.success.button" />}
                        </Button>
                    </Link>,
                ]}
            />
        );
    };

    render() {
        return this.state.status ? this.renderPasswordResetSuccess() : this.renderPasswordReset();
    }
}
export default injectIntl(Form.create()(withRouter(PasswordResetPage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps {}

interface State {
    status?: 'success';
    actionStatus?: ActionStatus;
}
