import { BmiLevelType, BmiChildrenLevelType, Measure, UnitType, BmiLevel } from '../model/model';
import unitService from './UnitService';

class BmiService {
    calculateBmi = (height: Measure, weight: Measure): Measure => {
        const heightIMS: Measure = unitService.convertMeasure(height, unitService.getUnitTypeByValue('CM'));
        const weightIMS: Measure = unitService.convertMeasure(weight, unitService.getUnitTypeByValue('KG'));
        const value: number =
            unitService.getTotalMeasure(weightIMS) /
            (((unitService.getTotalMeasure(heightIMS) / 100) * unitService.getTotalMeasure(heightIMS)) / 100);
        const unit: string = 'KG_M2';

        return { value, unit };
    };

    calculateBmiIdealWeight = (height: Measure, weightUnit: UnitType, bmi?: number): Measure => {
        return this.calculateWeightByHeightAndBmi(height, weightUnit, bmi || 21.75);
    };

    calculateBmiNormalMinWeight = (height: Measure, weightUnit: UnitType, bmi?: number): Measure => {
        return this.calculateWeightByHeightAndBmi(height, weightUnit, bmi || 18.5);
    };

    calculateBmiNormalMaxWeight = (height: Measure, weightUnit: UnitType, bmi?: number): Measure => {
        return this.calculateWeightByHeightAndBmi(height, weightUnit, bmi || 25);
    };

    private calculateWeightByHeightAndBmi = (height: Measure, weightUnit: UnitType, bmi: number): Measure => {
        const heightIMS: Measure = unitService.convertMeasure(height, unitService.getUnitTypeByValue('CM'));
        const weightIMS: number =
            (unitService.getTotalMeasure(heightIMS) / 100) * (unitService.getTotalMeasure(heightIMS) / 100) * bmi;
        const weight: Measure = { value: weightIMS, unit: 'KG' };

        return unitService.convertMeasure(weight, weightUnit);
    };

    getBmiLevel = (bmi: number): BmiLevelType => {
        let bmiLevel: BmiLevelType;
        if (bmi < 15) {
            bmiLevel = 'verySeverelyUnderweight';
        } else if (bmi >= 15 && bmi < 16) {
            bmiLevel = 'severelyUnderweight';
        } else if (bmi >= 16 && bmi < 18.5) {
            bmiLevel = 'underweight';
        } else if (bmi >= 18.5 && bmi < 25) {
            bmiLevel = 'normal';
        } else if (bmi >= 25 && bmi < 30) {
            bmiLevel = 'overweight';
        } else if (bmi >= 30 && bmi < 35) {
            bmiLevel = 'moderatelyObese';
        } else if (bmi >= 35 && bmi < 40) {
            bmiLevel = 'severelyObese';
        } else {
            bmiLevel = 'verySeverelyObese';
        }

        return bmiLevel;
    };

    getBmiChildrenLevel = (percentileLabel: string): BmiChildrenLevelType => {
        const percentile: number = +percentileLabel.replace(/p0|p|>|</gi, '');
        let bmiChildrenLevel: BmiChildrenLevelType;
        if (percentile < 5) {
            bmiChildrenLevel = 'underweight';
        } else if (percentile >= 5 && percentile < 85) {
            bmiChildrenLevel = 'normal';
        } else if (percentile >= 85 && percentile < 95) {
            bmiChildrenLevel = 'overweight';
        } else {
            bmiChildrenLevel = 'obese';
        }

        return bmiChildrenLevel;
    };

    getBmiLevelDescription = (bmi: number): string => {
        const bmiLevel = this.getBmiLevel(bmi);
        return `bmi.level.${bmiLevel}.desc`;
    };

    getBmiChildrenLevelDescription = (percentileLabel: string): string => {
        const bmiChildrenLevel = this.getBmiChildrenLevel(percentileLabel);
        return `bmiChildren.level.${bmiChildrenLevel}.desc`;
    };

    listBmiLevels = (): BmiLevel[] => {
        return [
            { level: 'verySeverelyUnderweight', value: 15 },
            { level: 'severelyUnderweight', value: 16 },
            { level: 'underweight', value: 18.5 },
            { level: 'overweight', value: 25 },
            { level: 'moderatelyObese', value: 30 },
            { level: 'severelyObese', value: 35 },
            { level: 'verySeverelyObese', value: 40 },
        ];
    };
}

const bmiService = new BmiService();
export default bmiService;
